import React from 'react';
import shortid from 'shortid';
import { Button } from '@lib/components';
import _findIndex from 'lodash/findIndex';
import { ModalCustomCheckoutField } from './modal';
import { CustomCheckoutFieldPreview } from './preview';

interface ComponentProps {
  checkoutFields: ListType;
  onValueChange: (value: ListType) => void;
}

interface ComponentState {
  currentModal: string;
  modalType: 'Add' | 'Edit';
  customCheckoutFields: ListType;
}

type FieldType = T.Schema.Restaurant.Services.CustomCheckoutField;
type ListType = FieldType[];

const MAX_CHECKOUT_FIELD = 2;

export class CustomCheckoutFields extends React.Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      currentModal: '',
      modalType: 'Add',
      customCheckoutFields: this.props.checkoutFields,
    };
  }

  addField = () => {
    const currentFields = [...this.state.customCheckoutFields];
    if (currentFields.length < MAX_CHECKOUT_FIELD) {
      const newId = shortid.generate();
      currentFields.push({
        _id: newId,
        enabled: false,
        type: 'checkbox',
        icon: 'FaHandPaper',
        label: `Custom Field #${currentFields.length + 1}`,
        required: false,
        options: [],
      } as T.Schema.Restaurant.Services.CustomCheckoutField);

      this.setState({
        customCheckoutFields: currentFields,
        currentModal: newId,
      });
      this.props.onValueChange(currentFields);
    }
  };

  deleteField = (deletingField: FieldType) => {
    const fields = [...this.state.customCheckoutFields];
    const idx = _findIndex(fields, (field: FieldType) => field._id === deletingField._id);
    if (idx !== -1) {
      fields.splice(idx, 1);
      this.setState({ customCheckoutFields: fields, currentModal: '' });
      this.props.onValueChange(fields);
    }
  };

  editField = (id: string) => {
    this.setState({ modalType: 'Edit', currentModal: id });
  };

  updateField = (updatedField: FieldType) => {
    const newFields = [...this.state.customCheckoutFields];
    const index = newFields.findIndex(field => field._id === this.state.currentModal);
    newFields[index] = updatedField;
    this.setState({ customCheckoutFields: newFields, currentModal: '' });
    this.props.onValueChange(newFields);
  };

  render() {
    const fields = [...this.state.customCheckoutFields];

    return (
      <div>
        {fields.map(
          (field, index) =>
            !!field._id && (
              <CustomCheckoutFieldPreview
                key={index}
                field={field}
                editField={this.editField}
                index={index}
                onDelete={() => this.deleteField(field)}
              />
            ),
        )}

        {this.state.customCheckoutFields.length < MAX_CHECKOUT_FIELD && (
          <div className="flex-center m-t-2 p-4">
            <Button type="button" color="primary" size="xs" onClick={() => this.addField()}>
              Add New Field
            </Button>
          </div>
        )}

        <ModalCustomCheckoutField
          field={fields.find(field => field._id === this.state.currentModal) as FieldType}
          onUpdate={this.updateField}
          currentModal={this.state.currentModal}
          modalType={this.state.modalType}
          closeModal={() => this.setState({ currentModal: '' })}
        />
      </div>
    );
  }
}
