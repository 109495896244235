import React from 'react';
import { FastField } from 'formik';
import { FormGroup, Button, RotateLoader, TabSelect, Input, Switch } from '@lib/components';
import { inject, observer } from 'mobx-react';
import { ServiceOrderTimeFields, ServiceAutoStatusFields, ServiceHoursField, CustomCheckoutField } from './base';
import { MobxComponent } from '../../../../../../../mobx/components/index';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';
import { UI } from '../../../../../../../core/ui';
import { updateRestaurantNotificationOptions } from '../../../../../../../core/libs/receipt';

interface Props {}
interface State {
  tab: string;
}
type FormValues = T.Schema.Restaurant.RestaurantSchema['settings']['services']['pickup'];

export const SettingsFormServicesPickup = inject('store')(
  observer(
    class SettingsFormServicesPickup extends MobxComponent<Props, State> {
      initialValues: FormValues;

      constructor(props: Props) {
        super(props);
        this.state = {
          tab: '0',
        };
        const r = this.injected.store.restaurant!;
        this.initialValues = r.settings.services.pickup;
      }

      render() {
        const { tab } = this.state;
        return (
          <div>
            <TabSelect
              id="accounts-tab-options"
              className="border-white-tb-10"
              hasBorder
              screenWidth={this.injected.store.view.screen_width}
              onChange={v => this.setState({ tab: v.value })}
              value={tab}
              values={[
                { label: 'General', value: '0' },
                { label: 'Order Timing', value: '1' },
                { label: 'Available Hours', value: '2' },
                { label: 'Wait Times & Auto Statuses', value: '3' },
                { label: 'Custom Checkout Fields', value: '4' },
              ]}
            />
            <RestaurantForm<FormValues>
              submit={async (r, values) => {
                r.settings.services.pickup = values;
                const update = { $set: { 'settings.services.pickup': r.settings.services.pickup } };
                return { r, update };
              }}
              validators=
              initialValues={this.initialValues}
              onSuccess={async () => updateRestaurantNotificationOptions(this.injected.store)}
              onError={() => UI.notification.error('An error occurred')}
              onSuccessMessage="Settings Updated"
              onErrorMessage=""
            >
              {({ form, error, getFieldError }) => {
                const { isSubmitting, setFieldValue } = form;
                return (
                  <div className="p-4">
                    <FormGroup>
                      {tab === '0' && (
                        <div>
                          <FastField
                            name="enabled"
                            render={({ field }: any) => (
                              <FormGroup
                                title="Enabled"
                                help="Allow customers to place orders that can be collected at your store location"
                              >
                                <Switch
                                  id="enable-pickup-switch"
                                  checked={field.value}
                                  onChange={e => setFieldValue('enabled', e.target.checked)}
                                />
                              </FormGroup>
                            )}
                          />
                          <FastField
                            name="display_name"
                            render={({ field }: any) => (
                              <FormGroup
                                optional
                                title="Display Name"
                                help="Override the service name. This will override the translation as well. Defaults to Pickup."
                              >
                                <Input type="text" {...field} />
                              </FormGroup>
                            )}
                          />
                          <FastField
                            name="notes"
                            render={({ field }: any) => (
                              <FormGroup
                                optional
                                title="Pickup Notes"
                                help="This will be shown to customers if they choose this order method"
                              >
                                <Input type="text" {...field} />
                              </FormGroup>
                            )}
                          />
                        </div>
                      )}
                      {tab === '1' && <ServiceOrderTimeFields service="pickup" form={form} />}
                      {tab === '2' && (
                        <ServiceHoursField
                          service="pickup"
                          error={field => getFieldError(form, field) as string | null | undefined}
                        />
                      )}
                      {tab === '3' && <ServiceAutoStatusFields service="pickup" values={form.values} />}
                      {tab === '4' && <CustomCheckoutField form={form} />}
                    </FormGroup>

                    {error && <FormGroup error={error} />}

                    <Button full color="primary" type="submit" disabled={isSubmitting}>
                      {isSubmitting && <RotateLoader size={2} color="white" />}
                      {!isSubmitting && 'Save'}
                    </Button>
                  </div>
                );
              }}
            </RestaurantForm>
          </div>
        );
      }
    },
  ),
);
