import React from 'react';
import { FastField } from 'formik';
import { inject, observer } from 'mobx-react';
import { Switch, FormGroup, Button, RotateLoader, SelectAdv } from '@lib/components';
import { UI } from '../../../../../../../core/ui';
import { MobxComponent } from '../../../../../../../mobx/components';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';

interface Props {}
interface State {}
type FormValues = {
  itemLayout: T.Schema.Restaurant.RestaurantSchema['website']['sections']['item_layout'];
  hide_category_description: T.Schema.Restaurant.RestaurantSchema['website']['sections']['menus']['dish_hide_category_description'];
  hide_all_category: T.Schema.Restaurant.RestaurantSchema['website']['sections']['menus']['dish_hide_all_category'];
};

export const SettingsFormDesignMenus = inject('store')(
  observer(
    class SettingsFormDesignMenus extends MobxComponent<Props, State> {
      initialValues: FormValues;

      constructor(props: Props) {
        super(props);
        const r = this.injected.store.restaurant!;

        this.initialValues = {
          itemLayout: r.website.sections.item_layout,
          hide_category_description: r.website.sections.menus.dish_hide_category_description || false,
          hide_all_category: r.website.sections.menus.dish_hide_all_category || false,
        };
      }

      render() {
        return (
          <RestaurantForm<FormValues>
            submit={async (r, values) => {
              r.website.sections.item_layout = values.itemLayout;
              r.website.sections.menus.dish_hide_category_description = values.hide_category_description;
              r.website.sections.menus.dish_hide_all_category = values.hide_all_category;
              const update = {
                $set: {
                  'website.sections.item_layout': r.website.sections.item_layout,
                  'website.sections.menus.dish_hide_category_description':
                    r.website.sections.menus.dish_hide_category_description,
                  'website.sections.menus.dish_hide_all_category': r.website.sections.menus.dish_hide_all_category,
                },
              };
              return { r, update };
            }}
            validators=
            initialValues={this.initialValues}
            onSuccess={() => {}}
            onError={() => UI.notification.error('An error occurred')}
            onSuccessMessage="Settings Updated"
            onErrorMessage=""
          >
            {({ form, error }) => {
              const { isSubmitting, setFieldValue } = form;
              return (
                <div className="p-4">
                  <FastField
                    name="itemLayout.style"
                    render={({ field }: any) => (
                      <FormGroup title="Item Layout Style" help="Choose the style for the menu item layout.">
                        <SelectAdv
                          type="single"
                          id="item_layout_style"
                          value={field.value || 'divided-into-categories'}
                          options={[
                            {
                              value: 'one-grid',
                              label: 'One Grid',
                            },
                            {
                              value: 'divided-into-categories',
                              label: 'Divided Into Categories',
                            },
                          ]}
                          onChange={(options: string) => {
                            setFieldValue('itemLayout.style', options);
                          }}
                        />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="hide_category_description"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Hide Category Descriptions"
                        help="Use this option to hide category descriptions on your store menu grid. Only available when item layout style is Divided Into Categories"
                      >
                        <Switch
                          id="hide_category_description"
                          checked={field.value || false}
                          onChange={e => setFieldValue('hide_category_description', e.target.checked)}
                        />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="hide_all_category"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Hide `All` Category option in Category Bar"
                        help="Use this option to hide the `All` menu category link that shows all the items. Only available when item layout style is One Grid"
                      >
                        <Switch
                          id="hide_all_category"
                          checked={field.value || false}
                          onChange={e => setFieldValue('hide_all_category', e.target.checked)}
                        />
                      </FormGroup>
                    )}
                  />

                  {error && <FormGroup error={error} />}

                  <Button full color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting && <RotateLoader size={2} color="white" />}
                    {!isSubmitting && 'Save'}
                  </Button>
                </div>
              );
            }}
          </RestaurantForm>
        );
      }
    },
  ),
);
