import React from 'react';
import { FastField } from 'formik';
import { FormGroup, Button, RotateLoader, Input } from '@lib/components';
import { inject, observer } from 'mobx-react';
import { MobxComponent } from '../../../../../../../mobx/components/index';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';
import { UI } from '../../../../../../../core/ui';

interface Props {}
interface State {}
type FormValues = T.Schema.Restaurant.RestaurantSchema['website']['seo'];

export const SettingsFormSEO = inject('store')(
  observer(
    class SettingsFormSEO extends MobxComponent<Props, State> {
      initialValues: FormValues;

      constructor(props: Props) {
        super(props);
        this.state = { error: null };
        const r = this.injected.store.restaurant!;
        this.initialValues = r.website.seo;
      }

      render() {
        return (
          <RestaurantForm<FormValues>
            submit={async (r, values) => {
              r.website.seo = values;
              const update = { $set: { 'website.seo': r.website.seo } };
              return { r, update };
            }}
            validators=
            initialValues={this.initialValues}
            onSuccess={() => {}}
            onError={() => UI.notification.error('An error occurred')}
            onSuccessMessage="Settings Updated"
            onErrorMessage=""
          >
            {({ form, error }) => {
              const { isSubmitting } = form;
              return (
                <div className="p-4">
                  <FastField
                    name="title"
                    render={({ field }: any) => (
                      <FormGroup optional title="Store Page Title" help="The document title for your main store page">
                        <Input {...field} />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="meta"
                    render={({ field }: any) => (
                      <FormGroup
                        optional
                        title="Store Page Meta"
                        help="The page description / meta description for your main store page"
                      >
                        <Input {...field} />
                      </FormGroup>
                    )}
                  />

                  {error && <FormGroup error={error} />}

                  <Button full color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting && <RotateLoader size={2} color="white" />}
                    {!isSubmitting && 'Save'}
                  </Button>
                </div>
              );
            }}
          </RestaurantForm>
        );
      }
    },
  ),
);
