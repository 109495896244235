import { styled, shadeAlternate } from '@lib/components';

export const Header = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  background: ${({ theme }) => shadeAlternate(0.05, theme.box.background)};
  border-bottom: 1px solid ${({ theme }) => theme.box.border};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

export const List = styled('div')`
  height: 450px;
  overflow-y: auto;
`;

export const Loader = styled('div')`
  height: 450px;
  overflow-y: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 10px 20px;
  text-align: center;
`;

export const ItemLoader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 20px;
`;

export const Item = styled('div')`
  display: flex;
  align-items: stretch;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.box.border};
  &:hover {
    background: ${({ theme }) => shadeAlternate(0.03, theme.box.background)};
  }
`;

export const ItemContent = styled('div')`
  padding: 10px 20px;
`;

export const Stripe = styled('div')`
  flex: 1;
  min-width: 3px;
`;

export const Icon = styled('div')`
  font-size: 24px;
  margin-right: 20px;
  color: ${({ theme }) => shadeAlternate(0.3, theme.box.background)};
`;

export const event_colors = {
  age_verification: '#45709c',
  order_new: '#489b37',
};
