import React from 'react';
import { styled } from '@lib/components';

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {}

const Content = styled('div')`
  padding: 20px 30px;
  margin-top: ${({ theme }) => theme.top_nav.height + 30}px;
  margin-bottom: ${({ theme }) => theme.top_nav.height + 5}px;
  width: 100%;
`;

export function RestaurantLayoutContent(props: Props) {
  return <Content {...props} />;
}
