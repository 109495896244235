import React from 'react';
import { FastField, Field } from 'formik';
import { ButtonGroup, FormGroup, FormUpload, Button, RotateLoader, Input } from '@lib/components';
import { inject, observer } from 'mobx-react';
import { MobxComponent } from '../../../../../../mobx/components/index';
import { WebsiteForm } from '../../../../../../mobx/components/website-form';
import { UI } from '../../../../../../core/ui';

interface Props {}
interface State {}
type FormValues = T.Schema.Website.WebsiteSchema['sections']['locations'];

export const WebsiteFormLocations = inject('store')(
  observer(
    class WebsiteFormLocations extends MobxComponent<Props, State> {
      initialValues: FormValues;

      constructor(props: Props) {
        super(props);
        const w = this.injected.store.website!;
        this.state = {};
        this.initialValues = w.sections.locations || {
          map_zoom: 12,
          card_type: 'map',
        };
      }

      render() {
        return (
          <WebsiteForm<FormValues>
            submit={async (w, values) => {
              w.sections.locations = values;
              const update = { $set: { 'sections.locations': values } };
              return { w, update };
            }}
            validators=
            initialValues={this.initialValues}
            onSuccess={() => {}}
            onError={() => UI.notification.error('An error occurred')}
            onSuccessMessage="Settings Updated"
            onErrorMessage=""
          >
            {({ form, error }) => {
              const { isSubmitting, setFieldValue } = form;
              return (
                <div className="p-4">
                  <Field
                    name="card_type"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Card Header"
                        help={
                          [undefined, 'map'].includes(form.values?.card_type)
                            ? `Show a map widget with a marker on the store's address.`
                            : form.values?.card_type === 'image'
                            ? `Upload an image to show on this store's location card.`
                            : `Don't show any image or map`
                        }
                      >
                        <ButtonGroup
                          size="xs"
                          selected={field.value || 'map'}
                          options={[
                            {
                              value: 'map',
                              name: 'Map',
                            },
                            {
                              value: 'image',
                              name: 'Image',
                            },
                            {
                              value: 'none',
                              name: 'None',
                            },
                          ]}
                          buttonClassName="p-lr-2"
                          onSelect={v => {
                            setFieldValue('card_type', v.value);
                          }}
                          width="auto"
                        />
                      </FormGroup>
                    )}
                  />

                  {form.values && [undefined, 'map'].includes(form.values.card_type) && (
                    <Field
                      name="map_type"
                      render={({ field }: any) => (
                        <FormGroup
                          title="Card Header"
                          help="Interactive maps can be zoomed in and out; and panned. Static image is a snapshot of the same map without the controls."
                        >
                          <ButtonGroup
                            size="xs"
                            selected={field.value || 'interactive'}
                            options={[
                              {
                                value: 'interactive',
                                name: 'Interactive',
                              },
                              {
                                value: 'static',
                                name: 'Static Image',
                              },
                            ]}
                            buttonClassName="p-lr-2"
                            onSelect={v => setFieldValue('map_type', v.value)}
                            width="auto"
                          />
                        </FormGroup>
                      )}
                    />
                  )}

                  {form.values && form.values.card_type === 'image' && (
                    <FastField
                      name="image"
                      render={({ field }: any) => (
                        <FormGroup help="Recomended size is 550x200 pixels. PNG or JPG only">
                          <FormUpload
                            maxSize={550}
                            imagesOnly
                            inputAcceptTypes="image/png/jpg"
                            value={[field.value]}
                            onRemove={() => setFieldValue('image', null)}
                            onChange={files => setFieldValue('image', files[0])}
                          />
                        </FormGroup>
                      )}
                    />
                  )}

                  {form.values && [undefined, 'map'].includes(form.values.card_type) && (
                    <FastField
                      name="map_zoom"
                      render={({ field }: any) => (
                        <FormGroup
                          title="Map Zoom"
                          help="The zoom level of your embedded store map. Higher value is more zoomed in. Default is 12 if left empty"
                        >
                          <Input type="number" {...field} />
                        </FormGroup>
                      )}
                    />
                  )}

                  {error && <FormGroup error={error} />}

                  <Button full color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting && <RotateLoader size={2} color="white" />}
                    {!isSubmitting && 'Save'}
                  </Button>
                </div>
              );
            }}
          </WebsiteForm>
        );
      }
    },
  ),
);
