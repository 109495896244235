import React from 'react';
import InputMask from 'react-input-mask';
import { FastField } from 'formik';
import {
  FormGroup,
  Input,
  Button,
  RotateLoader,
  Switch,
  TabSelect,
  SelectAdv,
  FieldOpeningHours,
  Modal,
  ModalContent,
  Tag,
} from '@lib/components';
import { inject, observer } from 'mobx-react';
import { ListOrderTimesOptions, ListServicesOptionsNoB } from '@lib/common';
import { MobxComponent } from '../../../../../../mobx/components';
import { RestaurantForm } from '../../../../../../mobx/components/restaurant-form';

interface Option {
  label: string;
  value: string;
}
type FormValues = T.Schema.Restaurant.Menu.RestaurantMenu;
interface Props {
  type: 'edit' | 'create';
  abacus_type?: 'edit' | 'create';
  initialValues: FormValues | null;
  close: () => void;
}
interface State {
  tab: string;
  companiesFetched: boolean;
  options: Option[];
  fetching: boolean;
  generating: boolean;
  generatingAbacus: boolean;
  generated: boolean;
  generatedAbacus: boolean;
  // menu_id: string;
  company_id: string;
  isChecking: boolean;
  isCheckingAbacus: boolean;
  backgroundStatus: string;
  backgroundStatusAbacus: string;
  disableGenerate: boolean;
  disableAbacusGenerate: boolean;
}

export const RestaurantFormMenu = inject('store')(
  observer(
    class RestaurantFormMenu extends MobxComponent<Props, State> {
      constructor(props: Props) {
        super(props);
        this.state = {
          tab: '0',
          companiesFetched: false,
          options: [],
          fetching: false,
          generating: false,
          generatingAbacus: false,
          generated: false,
          generatedAbacus: false,
          // menu_id: "",
          company_id: '',
          isChecking: false,
          isCheckingAbacus: false,
          backgroundStatus: 'Unknown',
          backgroundStatusAbacus: 'Unknown',
          disableGenerate: false,
          disableAbacusGenerate: false,
        };
      }

      setTab = (tab: string) => {
        this.setState({ tab });
      };

      componentDidMount = () => {
        this.setState({
          tab: '0',
          companiesFetched: false,
          options: [],
          fetching: false,
          generating: false,
          generated: false,
          // menu_id: ""
        });
      };

      componentDidUpdate(prevProps: Props) {
        if (this.props.initialValues && !prevProps.initialValues) {
          this.setTab('0');
        }
      }

      checkStatus = async () => {
        this.setState(
          {
            isChecking: true,
          },
          async () => {
            const _id =
              this.injected.store.restaurant && this.injected.store.restaurant._id
                ? this.injected.store.restaurant._id
                : '';
            const result = await this.injected.store.checkStatus(_id);
            if (this.state.backgroundStatus !== result) {
              // If the status changed, refresh the data
              const res = await this.injected.store.api.restaurant({
                _id,
              });
              /// @ts-ignore
              const { restaurant } = res;
              this.injected.store.setRestaurant(restaurant);
            }
            this.setState({
              isChecking: false,
              backgroundStatus: result,
            });
          },
        );
      };

      checkAbacusStatus = async () => {
        console.log('Abacus Called');
        this.setState(
          {
            isCheckingAbacus: true,
          },
          async () => {
            const _id =
              this.injected.store.restaurant && this.injected.store.restaurant._id
                ? this.injected.store.restaurant._id
                : '';
            const result = await this.injected.store.checkStatusAbacus(_id);
            if (this.state.backgroundStatusAbacus !== result) {
              // If the status changed, refresh the data
              const res = await this.injected.store.api.restaurant({
                _id,
              });
              /// @ts-ignore
              const { restaurant } = res;
              this.injected.store.setRestaurant(restaurant);
            }
            this.setState({
              isCheckingAbacus: false,
              backgroundStatusAbacus: result,
            });
          },
        );
      };

      generateMenu = async (menu_id: string) => {
        this.setState({
          generating: true,
          generated: false,
          disableGenerate: true,
        });
        const _id =
          this.injected.store.restaurant && this.injected.store.restaurant._id
            ? this.injected.store.restaurant._id
            : '';
        await this.injected.store.generateMenu(_id, menu_id);
        this.setState(
          {
            generating: false,
            generated: true,
          },
          async () => {
            // Refresh the data immediately
            const res = await this.injected.store.api.restaurant({
              _id,
            });
            /// @ts-ignore
            const { restaurant } = res;
            this.injected.store.setRestaurant(restaurant);
            setTimeout(() => {
              this.setState({
                disableGenerate: false,
              });
            }, 1000);
          },
        );
      };

      generateMenuv2 = async (menu_id: string) => {
        this.setState({
          generating: true,
          generated: false,
          disableGenerate: true,
        });
        const _id =
          this.injected.store.restaurant && this.injected.store.restaurant._id
            ? this.injected.store.restaurant._id
            : '';
        await this.injected.store.generateMenuv2(_id, menu_id);
        this.setState(
          {
            generating: false,
            generated: true,
          },
          async () => {
            const res = await this.injected.store.api.restaurant({
              _id,
            });
            /// @ts-ignore
            const { restaurant } = res;
            this.injected.store.setRestaurant(restaurant);
            setTimeout(() => {
              this.setState({
                disableGenerate: false,
              });
            }, 1000);
          },
        );
      };

      generateAbacusMenu = async (menu_id: string) => {
        this.setState({
          generatingAbacus: true,
          generatedAbacus: false,
          disableAbacusGenerate: true,
        });
        const _id =
          this.injected.store.restaurant && this.injected.store.restaurant._id
            ? this.injected.store.restaurant._id
            : '';
        await this.injected.store.generateAbacusMenu(_id, menu_id);
        this.setState(
          {
            generating: false,
            generated: true,
          },
          async () => {
            const res = await this.injected.store.api.restaurant({
              _id,
            });
            /// @ts-ignore
            const { restaurant } = res;
            this.injected.store.setRestaurant(restaurant);
            setTimeout(() => {
              this.setState({
                disableAbacusGenerate: false,
              });
            }, 1000);
          },
        );
      };

      render() {
        const { type, initialValues, close } = this.props;
        const { tab } = this.state;
        const r = this.injected.store.restaurant!;
        //
        const disableImport = this.props.type === 'create';
        const disableAbacusImport = this.props.abacus_type === 'create';
        const disableImportMessage =
          this.props.type === 'create' ? 'This menu has not been created yet. Please create it by clicking save.' : '';
        const disableImportAbacusMessage =
          this.props.abacus_type === 'create'
            ? 'This menu has not been created yet. Please create it by clicking save.'
            : '';
        //
        let openItems = false;
        if (
          initialValues &&
          r &&
          r.abacus &&
          typeof r.abacus.abacus_open_items === 'boolean' &&
          r.abacus.abacus_open_items === true
        ) {
          openItems = true;
        }
        //
        let menu_options: any = [{}];
        /// @ts-ignore
        if (
          r._id === 'kFkeSP16a' ||
          /// @ts-ignore
          r._id === '58b19c3a1667c9685d0f8c63' ||
          /// @ts-ignore
          // || r._id === "RESVqm6Ka2KYjhtWTcJn-iMm"
          /// @ts-ignore
          r._id === 'RES1T_qjKy9PUdDPiVTsVPcd' ||
          /// @ts-ignore
          r.reseller_id === 'cloudwaitress-test' ||
          /// @ts-ignore
          r.reseller_id === 'yoom'
        ) {
          if (r?.kounta?.enabled) {
            /// @ts-ignore
            menu_options = [
              { label: 'General', value: '0' },
              { label: 'Conditions', value: '1' },
              { label: 'Kounta', value: '2' },
            ];
          } else {
            menu_options = [
              { label: 'General', value: '0' },
              { label: 'Conditions', value: '1' },
            ];
          }
        } else if (
          r &&
          r._id &&
          r._id !== 'RESVqm6Ka2KYjhtWTcJn-iMm' &&
          r.abacus &&
          r.abacus.abacus_status === true &&
          openItems === false
        ) {
          menu_options = [
            { label: 'General', value: '0' },
            { label: 'Conditions', value: '1' },
            // { label: "Kounta", value: "2" },
            { label: 'Abacus', value: '3' },
          ];
        } else if (
          /// @ts-ignore
          (r._id === 'RESoMB_ZxZYNZaa_anfvZol1' ||
            /// @ts-ignore
            r._id === 'RESVqm6Ka2KYjhtWTcJn-iMm') &&
          openItems === false
        ) {
          console.log('Open Items false');
          if (r?.kounta?.enabled) {
            /// @ts-ignore
            menu_options = [
              { label: 'General', value: '0' },
              { label: 'Conditions', value: '1' },
              { label: 'Kounta', value: '2' },
              { label: 'Abacus', value: '3' },
            ];
          } else {
            menu_options = [
              { label: 'General', value: '0' },
              { label: 'Conditions', value: '1' },
              { label: 'Abacus', value: '3' },
            ];
          }
        } else {
          menu_options = [
            { label: 'General', value: '0' },
            { label: 'Conditions', value: '1' },
          ];
        }
        let initialKountaValues = initialValues && initialValues.kounta_sync ? initialValues.kounta_sync : {};
        if (initialKountaValues === {}) {
          initialKountaValues = {
            kounta_sync_export_enabled: false,
          };
        }
        return (
          <Modal width="md" alignTop active={!!initialValues} close={close}>
            <ModalContent>
              <h3 className="">{type === 'create' ? 'Create Menu' : 'Edit Menu'}</h3>
              {type === 'edit' && initialValues && (
                <p className="m-t-1">
                  {initialValues.name} <Tag className="m-l-1">{initialValues._id}</Tag>
                </p>
              )}
            </ModalContent>

            <TabSelect
              id="menu-tab-options"
              className="border-white-tb-15"
              hasBorder
              screenWidth={this.injected.store.view.screen_width}
              onChange={v => this.setTab(v.value)}
              value={tab}
              values={menu_options}
            />

            <RestaurantForm<FormValues>
              submit={async (r, values) => {
                const existing = r.menus.findIndex(p => p._id === values._id);
                if (existing === -1) {
                  r.menus.push(values);
                } else {
                  r.menus[existing] = values;
                }
                const update = { $set: { menus: r.menus } };
                return { r, update };
              }}
              validators={{
                // @ts-ignore
                name: values => {
                  if (!values.name) return { name: 'This field is required' };
                },
              }}
              initialValues={initialValues}
              onSuccess={close}
              onSuccessMessage={type === 'edit' ? 'Menu edited' : 'Menu created'}
              onErrorMessage=""
            >
              {({ form, error, getFieldError }) => {
                //
                const { isSubmitting, setFieldValue } = form;
                //
                // if (this.state.menu_id === "") {
                // this.setState({
                // 	menu_id: form.values._id
                // })
                // }
                //
                // if (this.state.company_id !== "") {
                // 	setFieldValue("kounta.kounta_company_id", this.state.company_id)
                // }
                //
                // let options: Option[] = []
                // if (
                // 	this.state.companiesFetched === false
                // 	&& form.values.kounta
                // 	// && form.values.kounta.kounta_site_id
                // 	// && form.values.kounta.kounta_site_name
                // 	// && form.values.kounta.kounta_site_id.length > 0
                // 	// && form.values.kounta.kounta_site_name.length > 0
                // ) {
                // 	options = [{
                // 		label: form.values.kounta.kounta_site_name,
                // 		value: form.values.kounta.kounta_site_id,
                // 	}]
                // } else {
                // 	options = this.state.options
                // }
                //
                // const noUsernameOrPassword = !form.values.kounta ||
                // 	!form.values.kounta.kounta_username ||
                // 	!form.values.kounta.kounta_password ||
                // 	form.values.kounta.kounta_username === "" ||
                // 	form.values.kounta.kounta_password === ""
                //
                return (
                  <div>
                    {tab === '0' && (
                      <ModalContent>
                        <FastField
                          name="name"
                          render={({ field }: any) => (
                            <FormGroup
                              title="Name"
                              help="A unique name for your menu"
                              error={getFieldError(form, 'name')}
                            >
                              <Input type="text" {...field} required />
                            </FormGroup>
                          )}
                        />
                        <FastField
                          name="display_name"
                          render={({ field }: any) => (
                            <FormGroup
                              optional
                              title="Display Name"
                              help="Will override the unique name in your store"
                              error={getFieldError(form, 'display_name')}
                            >
                              <Input type="text" {...field} />
                            </FormGroup>
                          )}
                        />
                        <FastField
                          name="description"
                          render={({ field }: any) => (
                            <FormGroup
                              optional
                              title="Description"
                              help="Will be displayed above your menu. You can use this to summarise your menu requirements. E.g. 'Available Monday-Thursday after 9:00pm, Pickup's Only'"
                            >
                              <Input type="text" {...field} />
                            </FormGroup>
                          )}
                        />
                      </ModalContent>
                    )}

                    {tab === '1' && (
                      <ModalContent>
                        <FastField
                          name="conditions.hide_unavailable_menu"
                          render={({ field }: any) => (
                            <FormGroup
                              title="Hide Unavailable Menu"
                              help="Enabling this will hide this menu in your store if it's unavailable. Otherwise, this menu will still be displayed with a warning message that it is not available."
                            >
                              <Switch
                                id="enabled-hide-unavailable-menu"
                                checked={field.value || false}
                                onChange={e => setFieldValue('conditions.hide_unavailable_menu', e.target.checked)}
                              />
                            </FormGroup>
                          )}
                        />

                        <FastField
                          name="conditions.times"
                          render={({ field }: any) => (
                            <FormGroup
                              optional
                              title="Order Times"
                              help="Select which order times this menu will be available for. Leave empty for this menu to apply at all times"
                            >
                              <SelectAdv
                                type="multi"
                                value={field.value}
                                onChange={(options: string[]) => {
                                  setFieldValue('conditions.times', options);
                                }}
                                options={ListOrderTimesOptions}
                              />
                            </FormGroup>
                          )}
                        />

                        <FastField
                          name="conditions.services"
                          render={({ field }: any) => (
                            <FormGroup
                              optional
                              title="Services"
                              help="Select which services this menu will be available for. Leave empty for this menu to apply for services"
                            >
                              <SelectAdv
                                type="multi"
                                value={field.value}
                                onChange={(options: string[]) => {
                                  setFieldValue('conditions.services', options);
                                }}
                                options={ListServicesOptionsNoB}
                              />
                            </FormGroup>
                          )}
                        />

                        <FastField
                          name="conditions.hours"
                          render={({ field }: any) => (
                            <FormGroup
                              optional
                              title="Applicable Hours"
                              help="Set which hours this menu will be available for. If no hours entered, the menu is applicable at all times. Enter time in 24H format, e.g. 21:00 for 9:00pm. Ensure time slots do not overlap or close before they open"
                              error={getFieldError(form, 'hours')}
                            >
                              <div className="m-tb-3 block">
                                <FieldOpeningHours
                                  allowNone
                                  hours={field.value}
                                  onChange={opening_hours => setFieldValue('conditions.hours', opening_hours)}
                                />
                              </div>
                            </FormGroup>
                          )}
                        />

                        <FastField
                          name="conditions.age_restricted"
                          render={({ field }: any) => (
                            <FormGroup
                              optional
                              title="Enable Age Restricted Access"
                              help="Enabling this will only allow logged in customers with their age verified to order from this menu. Age verification must be enabled for this feature to work"
                            >
                              <Switch
                                id="enable-age_restricted-switch"
                                checked={field.value}
                                onChange={e => setFieldValue('conditions.age_restricted', e.target.checked)}
                              />
                            </FormGroup>
                          )}
                        />

                        <FastField
                          name="conditions.pre_order.enabled"
                          render={({ field }: any) => (
                            <FormGroup
                              optional
                              title="Enable Pre-orders Only"
                              help="Enabling this will disable immediate orders for this menu and require that people pre-order according to the conditions below"
                            >
                              <Switch
                                id="enable-pre-order-switch"
                                checked={field.value}
                                onChange={e => setFieldValue('conditions.pre_order.enabled', e.target.checked)}
                              />
                            </FormGroup>
                          )}
                        />

                        <FastField
                          name="conditions.pre_order.days_in_advance"
                          render={({ field }: any) => (
                            <FormGroup
                              optional
                              title="Pre-order Days In Advance"
                              help="Ensures customers must pre-order items from this menu a certain amount of days in advance"
                            >
                              <Input type="number" step={1} min={0} {...field} />
                            </FormGroup>
                          )}
                        />

                        <FastField
                          name="conditions.pre_order.cutoff_time"
                          render={({ field }: any) => (
                            <FormGroup
                              optional
                              title="Pre-order Cutoff Time (24H Format)"
                              help="Use in conjunction with the above option to ensure customers must place orders before a certain time on the last pre-order day. For example, if you set the cut off to 8:00pm and you require orders 2 day's in advance. For a customer to order for Friday, the latest they can put in their order is at 8:00pm on Wednesday"
                            >
                              <InputMask mask="99:99" {...field}>
                                {(inputProps: any) => <Input type="text" placeholder="00:00" {...inputProps} />}
                              </InputMask>
                            </FormGroup>
                          )}
                        />
                      </ModalContent>
                    )}

                    {tab === '2' && (
                      <ModalContent>
                        {/* <FastField
											name="kounta.kounta_sync_export_enabled"
											render={({ field }: any) => (
												<FormGroup
													optional={true}
													title="Enable Restaurant to Kounta Sync?"
													help="Enabling this will synchronise your restaurants menu and orders to Kounta">
													<Switch
														id="enabled-kounta-integration"
														checked={field.value}
														onChange={(e) => setFieldValue("kounta.kounta_sync_export_enabled", e.target.checked)} />
												</FormGroup>
											)}
										/> */}

                        {/* <FastField
											name="kounta.kounta_username"
											render={({ field }: any) => (
												<FormGroup
													optional={true}
													title="Kounta Username"
													help="The username to log into Kounta">
													<Input type="text" {...field} />
												</FormGroup>
											)} />

										<FastField
											name="kounta.kounta_password"
											render={({ field }: any) => (
												<FormGroup
													optional={true}
													title="Kounta Password"
													help="The password to log into Kounta">
													<Input type="text" {...field} />
												</FormGroup>
											)} /> */}

                        {/* <Button full={false} color="primary" type="button"
											style={{ marginBottom: "10px" }}
											onClick={() => {
												console.log(1)
												this.fetchSites()
												console.log(2)
											}}
											disabled={noUsernameOrPassword}
										>
											Fetch Kounta Sites
										</Button> */}

                        {/* <div hidden={!this.state.fetching}>
											<RotateLoader />
										</div> */}

                        {/* {noUsernameOrPassword
											?
											<div style={{ marginBottom: "30px" }}>Please click settings and enter a username and password to interact with the Kounta service.</div>
											:
											<div></div>
										} */}

                        {/* {this.state.companiesFetched === false
										?
											<FastField
												name="kounta.kounta_site_id"
												render={({ field }: any) => (
													<FormGroup
														optional={true}
														title="Kounta Site"
														help="Select which Kounta site this menu should synchronise with">
														<SelectAdv
															type="single"
															value={field.value}
															onChange={(option: string) => {
																setFieldValue("kounta.kounta_site_id", option);
																options.map(optionElement => {
																	if (optionElement.value === option) {
																		setFieldValue("kounta.kounta_site_name", optionElement.label);
																	}
																})
															}}
															options={options} />
													</FormGroup>
												)}
											/>
										:
											<div></div>
										} */}

                        {/* {this.state.companiesFetched === true
										?
											<FastField
												name="kounta.kounta_site_id"
												render={({ field }: any) => (
													<FormGroup
														optional={true}
														title="Kounta Site"
														help="Select which Kounta site this menu should synchronise with">
														<SelectAdv
															type="single"
															value={field.value}
															onChange={(option: string) => {
																setFieldValue("kounta.kounta_site_id", option);
																options.map(optionElement => {
																	if (optionElement.value === option) {
																		setFieldValue("kounta.kounta_site_name", optionElement.label);
																	}
																})
															}}
															options={options} />
													</FormGroup>
												)}
											/>
										:
											<div></div>
										} */}

                        {/* <FormGroup
											hide={!this.state.companiesFetched}
											optional={false}
											title="Kounta Sites"
											help="Select a Kounta site to sync from/to"
											style={{ marginTop: "10px" }}
										>
											<SelectAdv
												type="single"
												options={this.state.options}
												value={this.state.currentOption || null}
												onChange={(option_id: string) => {
													this.setState({
														currentOption: option_id
													})
												}}
											/>
										</FormGroup> */}

                        {/* {form.values.kounta && form.values.kounta.kounta_site_id && form.values.kounta.kounta_site_id.length > 0
											? */}
                        <div hidden={!disableImport}>{disableImportMessage}</div>
                        <div hidden={disableImport}>
                          <p>
                            <Button
                              full={false}
                              color="primary"
                              type="button"
                              disabled={this.state.disableGenerate}
                              style={{
                                marginBottom: '10px',
                              }}
                              onClick={() => {
                                this.generateMenu(form.values._id);
                              }}
                            >
                              {this.state.disableGenerate && <RotateLoader size={2} color="white" />}
                              {!this.state.disableGenerate && 'Copy Kounta menu over this menu'}
                            </Button>
                          </p>
                          <p>
                            <Button
                              full={false}
                              color="primary"
                              type="button"
                              disabled={this.state.disableGenerate}
                              style={{
                                marginBottom: '10px',
                              }}
                              onClick={() => {
                                this.generateMenuv2(form.values._id);
                              }}
                            >
                              {this.state.disableGenerate && <RotateLoader size={2} color="white" />}
                              {!this.state.disableGenerate && 'Copy Kounta menu over this menu (V2) - ADMIN USE ONLY'}
                            </Button>
                          </p>
                          <div style={{ marginBottom: '20px' }}>
                            This is intended to be a tool used to copy menus and option sets from Kounta.
                          </div>
                          <div style={{ marginBottom: '20px' }}>
                            Please note, syncing menu will take some time. Please be patient.
                          </div>
                          <div style={{ marginBottom: '40px' }}>
                            Please also note, the imported Kounta menu will "overwrite" the categories and dishes, and
                            will generate new option sets.
                          </div>
                          <div style={{ marginBottom: '20px' }}>
                            <p>The copy menu function must run in the background due to the large amount of data.</p>
                          </div>
                          <Button full={false} color="primary" type="button" onClick={this.checkStatus}>
                            {this.state.isChecking && <RotateLoader size={2} color="white" />}
                            {!this.state.isChecking && 'Refresh Status'}
                          </Button>
                          <div style={{ marginTop: '20px' }}>Status: {this.state.backgroundStatus}</div>
                        </div>
                        {/* :
											<div></div>
										} */}
                      </ModalContent>
                    )}

                    {tab === '3' && (
                      <ModalContent>
                        <div hidden={!disableAbacusImport}>{disableImportAbacusMessage}</div>
                        <div hidden={disableAbacusImport}>
                          <Button
                            full={false}
                            color="primary"
                            type="button"
                            disabled={this.state.disableAbacusGenerate}
                            style={{ marginBottom: '10px' }}
                            onClick={() => {
                              this.generateAbacusMenu(form.values._id);
                            }}
                          >
                            {this.state.disableAbacusGenerate && <RotateLoader size={2} color="white" />}
                            {!this.state.disableAbacusGenerate && 'Copy Abacus menu over this menu'}
                          </Button>
                          <div hidden={openItems} style={{ marginBottom: '20px' }}>
                            This is intended to be a tool used to copy menus and option sets from Abacus.
                          </div>
                          <div hidden={openItems} style={{ marginBottom: '40px' }}>
                            Please also note, the imported Abacus menu will "overwrite" the categories and dishes, and
                            will generate new option sets.
                          </div>
                          {/* <Button full={false} color="primary" type="button"
												onClick={this.checkAbacusStatus}>
												{this.state.isCheckingAbacus && <RotateLoader size={2} color="white" />}
												{!this.state.isCheckingAbacus && "Refresh Status"}
											</Button>
											<div style={{ marginTop: "20px" }}>
												Status: {this.state.backgroundStatusAbacus}
											</div> */}
                        </div>
                        {/* :
											<div></div>
										} */}
                      </ModalContent>
                    )}

                    <ModalContent>
                      {error && <FormGroup error={error} />}
                      <Button full color="primary" type="submit" disabled={isSubmitting || tab === '2'}>
                        {isSubmitting && <RotateLoader size={2} color="white" />}
                        {!isSubmitting && 'Save'}
                      </Button>
                    </ModalContent>
                  </div>
                );
              }}
            </RestaurantForm>
          </Modal>
        );
      }
    },
  ),
);
