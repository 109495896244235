import React from 'react';
import { inject } from 'mobx-react';
import { LinkTag } from '@lib/components';
import { RootStore } from '../../../mobx/store';

interface Props extends React.HTMLAttributes<HTMLAnchorElement> {
  to: string;
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLElement>) => any;
  [key: string]: any;
}
interface InjectedProps extends Props {
  store: RootStore;
}

function LinkComponent(props: Props) {
  const { to, children, onClick, store, ...other } = props as InjectedProps;

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    // Ignore any click other than a left click
    // eslint-disable-next-line
    if (
      (event.button && event.button !== 0) ||
      event.metaKey ||
      event.altKey ||
      event.ctrlKey ||
      event.shiftKey ||
      event.defaultPrevented
    ) {
      return;
    }

    // Prevent the default behaviour (page reload, etc.)
    event.preventDefault();

    if (onClick) onClick(event);

    store.router.push(to);
  };

  return (
    <LinkTag href={to} onClick={handleClick} {...other}>
      {children}
    </LinkTag>
  );
}

export const Link = inject('store')(LinkComponent);
