/* eslint-disable @typescript-eslint/no-misused-promises */

import React, { SyntheticEvent } from 'react';
import { FaArrowCircleUp, FaArrowCircleDown, FaTrashAlt, FaCheck, FaTimes } from 'react-icons/fa';
import { ThreeDsPaymentMethods, cloneDeepSafe, arraySwapItem, logger, Untrusive } from '@lib/common';
import { SecureIcon, Button, Tooltip, IconCircle } from '@lib/components';
import { UI } from '../../../../../../../core/ui';
import { RootStore } from '../../../../../../../mobx/store';
import { SaveRestaurantOpts, SaveRestaurantSilentOpts } from '../../../../../../../mobx/components';
import { IconWrapper } from './styled';

interface Props {
  store: RootStore;
  methodKey: string;
  title: string;
  active: boolean;
  saveRestaurant: (opts: SaveRestaurantOpts) => Promise<void>;
  saveRestaurantSilent: (opts: SaveRestaurantSilentOpts) => Promise<void>;
}

export function PaymentMethodList(props: Props) {
  const methodReorder = async (e: SyntheticEvent, direction: 'up' | 'down', key: string) => {
    e.stopPropagation();

    try {
      const { restaurant } = props.store;
      const r = cloneDeepSafe(restaurant)!;
      const settings = cloneDeepSafe(r.settings);
      const paymentKeys = Object.keys(settings.payments);
      const index = paymentKeys.indexOf(key);

      let swapIndex = -1;
      if (direction === 'up' && index > 0) {
        swapIndex = index - 1;
      } else if (direction === 'down' && index < paymentKeys.length - 1) {
        swapIndex = index + 1;
      }

      if (swapIndex === -1) {
        return;
      }

      arraySwapItem(paymentKeys, index, swapIndex);

      const payments: {
        [key: string]: T.Schema.Restaurant.Payments.RestaurantPayment | undefined;
      } = {};

      // eslint-disable-next-line no-restricted-syntax
      for (const pk of paymentKeys) {
        payments[pk] = settings.payments[pk];
      }

      settings.payments = payments;

      props.store.updateRestaurant({ settings });

      await props.saveRestaurantSilent({
        backup: r,
        update: { $set: { settings } },
        errorMsg: 'Failed to save payment order, check connection and try again',
      });
    } catch (err) {
      logger.captureException(err);
    }
  };

  const methodRemove = async (e: SyntheticEvent, key: string) => {
    e.stopPropagation();

    const restaurant = props.store.restaurant!;
    const methods = Object.keys(restaurant.settings.payments);

    if (methods.length === 1) {
      UI.notification.error('Cannot remove last payment method');
      return;
    }

    if (
      // eslint-disable-next-line no-alert
      !confirm(
        'Removing this payment method will permanently delete any data associated with it. You can disable it instead. Proceed with removal?',
      )
    ) {
      return;
    }

    Untrusive.start();

    await props.saveRestaurant({
      successMsg: 'Payment method deleted',
      process: r => {
        delete r.settings.payments[key];
        return {
          update: {
            $set: {
              'settings.payments': r.settings.payments,
            },
          },
        };
      },
    });

    Untrusive.stop();
  };

  return (
    <div className="flex-l-r-center">
      <div className="flex-line centered">
        {props.active ? (
          <IconCircle
            size={16}
            className="m-r-2"
            icon={<FaCheck />}
            iconSizeModifier={6}
            background={props.store.theme.s.status_colors.complete}
          />
        ) : (
          <IconCircle size={16} className="m-r-2" icon={<FaTimes />} iconSizeModifier={6} background="grey" />
        )}
        <span>{props.title}</span>

        {ThreeDsPaymentMethods.includes(props.methodKey) && (
          <IconWrapper>
            <SecureIcon />
          </IconWrapper>
        )}
      </div>
      <div className="flex-line centered m-r-4">
        <Tooltip text="Remove" width={65} position="top">
          <Button size="xs" color="white" className="p-lr-1" onClick={e => methodRemove(e, props.methodKey)}>
            <FaTrashAlt />
          </Button>
        </Tooltip>
        <Tooltip text="Move Up" width={70} position="top">
          <Button
            size="xs"
            color="white"
            className="p-lr-1 m-l-1"
            onClick={e => methodReorder(e, 'up', props.methodKey)}
          >
            <FaArrowCircleUp />
          </Button>
        </Tooltip>
        <Tooltip text="Move Down" width={90} position="top">
          <Button
            size="xs"
            color="white"
            className="p-lr-1 m-l-1"
            onClick={e => methodReorder(e, 'down', props.methodKey)}
          >
            <FaArrowCircleDown />
          </Button>
        </Tooltip>
      </div>
    </div>
  );
}
