/* eslint-disable import/order */
import React from 'react';
import {
  Button,
  Input,
  Switch,
  FormGroup,
  InputAddress,
  FieldOpeningHours,
  FieldSubdomain,
  RotateLoader,
  SelectAdv,
  SubscriptionPlansV4,
  withTheme,
  ButtonGroup,
  StripeElementCard,
} from '@lib/components';
import {
  DataLocales,
  DistanceMeasures,
  logger,
  validators,
  DataCurrencies,
  DataCurrenciesList,
  ChargebeeSubscriptionPlans,
} from '@lib/common';
import { UI } from '../../../../../core/ui';
import { Formik, Form, FastField, FormikProps, FormikHelpers as FormikActions } from 'formik';
import { inject, observer } from 'mobx-react';
import { MobxComponent } from '../../../../../mobx/components';
import { FormFieldValidators, FormHelpers } from '../../../../../core/form';
import moment from 'moment-timezone';
import { GoogleCreateService } from '../../../../../core/libs/google';
import { MapboxAPI } from '../../../../../core/libs/mapbox';

let GoogleService: any = {};

interface Props {
  back?: () => void;
  theme: T.Core.BaseTheme.ThemeInterface;
}

interface State {
  error: string | null;
  card_name: string;
  card_error: string;
}

type FormValues = T.API.RestaurantCreateRequest;

const initialValues: FormValues = {
  subdomain: '',
  name: '',
  address: '',
  map_data: {
    type: 'osm',
    components: {},
    lat: 0,
    lng: 0,
  },
  phone: '',
  opening_hours: [
    {
      day: 'Monday',
      open: '09:00',
      close: '21:00',
      h24: false,
    },
  ],
  locale: '',
  timezone: '',
  formats: {
    date: 'DD/MM/YYYY',
    time: 'h:mm a',
  },
  currency: null,
  taxes: [],
  tax_in_prices: false,
  subscription_plan: '',
  kmmile: 'KM',
};

const timezoneList = moment.tz.names();

const RestaurantFormSetupClass = inject('store')(
  observer(
    class RestaurantFormSetupClass extends MobxComponent<Props, State> {
      prePopulated: boolean = false;

      constructor(props: Props) {
        super(props);
        this.state = {
          error: null,
          card_name: '',
          card_error: '',
        };
        //
        const { reseller } = this.injected.store;
        if (
          reseller &&
          reseller.billing &&
          reseller.billing.reseller_billing_google_api_key &&
          reseller.billing.reseller_billing_google_api_key.length > 0
        ) {
          GoogleService = GoogleCreateService; // (reseller.billing.reseller_billing_google_api_key)
        } else {
          GoogleService = GoogleCreateService; // (config.services.google.api_key)
        }
        //
      }

      onSubmit = async (values: FormValues, form: FormikActions<FormValues>) => {
        const { service, reseller } = this.injected.store;

        const stop = () => {
          form.setSubmitting(false);
        };

        try {
          this.setState({ error: null });

          form.setSubmitting(true);

          if (reseller!.chargebee && reseller!.chargebee.subscription.plan_structure === 'multi') {
            if (values.subscription_plan.indexOf('free') === -1) {
              const stripeData = await window.stripe!.createToken(window.stripeCard!, { name: this.state.card_name });
              if (stripeData.error || !stripeData.token) {
                const defaultErrorMessage = 'Credit card error, try again or another method';
                const error = stripeData.error ? stripeData.error.message || defaultErrorMessage : defaultErrorMessage;
                this.setState({ error });
                form.setSubmitting(false);
                return;
              }
              values.stripe_token = stripeData.token.id;
            }
          }

          console.log(`FORM VALUES: ${JSON.stringify(values)}`);

          const res = await this.injected.store.api.restaurant_create(values);

          stop();

          if (res.outcome) {
            this.setState({ error: res.message });
            return;
          }

          UI.notification.success('Restaurant created');

          this.props.back!();

          service.track('Restaurant: Created', {
            name: values.name,
            timestamp: Date.now(),
            reseller_id: reseller!._id,
          });
        } catch (e) {
          logger.captureException(e);
          this.setState({ error: 'Error creating restaurant, please try again or contact us' });
          stop();
        }
      };

      validate = (values: FormValues) => {
        const { errors, isError } = FormHelpers.validate<FormValues>(values, this.validators);
        if (this.state.error && !isError) {
          this.setState({ error: '' });
        } else if (!this.state.error && isError) {
          this.setState({
            error:
              'There are errors in your form that need correcting, please scroll up and check other tabs if needed',
          });
        }
        return errors;
      };

      validators: FormFieldValidators<FormValues> = {
        name: values => {
          if (!values.name) return { name: 'Required - Enter a name for your store location' };
          return undefined;
        },
        address: values => {
          const md = values.map_data;
          if (md.type === 'google_maps' || md.type === 'osm') {
            if (!values.address || !md.components || !md.lat || !md.lng) {
              return { address: 'Required - search and select your store address' };
            }
          } else if (!values.address) {
            return { address: 'Required - enter your store address' };
          }
          return undefined;
        },
        opening_hours: values => {
          const { error } = validators.business_hours(values.opening_hours);
          if (error) {
            return { opening_hours: error };
          }
          return undefined;
        },
        locale: values => {
          if (!values.locale) return { locale: 'Required - search and select your locale' };
          return undefined;
        },
        timezone: values => {
          if (!values.timezone) return { timezone: 'Required - search and select your timezone' };
          return undefined;
        },
        currency: values => {
          if (!values.currency) return { currency: 'Required - search and select your currency' };
          return undefined;
        },
        taxes: values => {
          if (values.taxes) {
            // eslint-disable-next-line no-restricted-syntax
            for (const tax of values.taxes) {
              if (!tax.rate || !tax.name) {
                return {
                  taxes: `One of your taxes is missing a name or tax rate. Please ensure all the fields are completed`,
                };
              }
            }
          }
          return undefined;
        },
        subscription_plan: values => {
          const res = this.injected.store.reseller!;
          if (res.chargebee && res.chargebee.subscription.plan_structure === 'multi' && !values.subscription_plan) {
            return { subscription_plan: 'Required - Select a plan for this restaurant' };
          }
          return undefined;
        },
      };

      getFieldError = (form: FormikProps<FormValues>, field: keyof FormValues) => FormHelpers.error(form, field);

      prePopulate = async (updateForm: (data: { locale: string; timezone: string }) => void) => {
        try {
          if (!this.prePopulated) {
            this.prePopulated = true;

            let locale = '';
            let timezone = '';

            const languages = navigator.languages || [navigator.language];
            // eslint-disable-next-line no-restricted-syntax
            for (const l of languages) {
              const found = DataLocales.find(v => v.value.toLowerCase() === l.toLowerCase());
              if (found) {
                locale = found.value;
                break;
              }
            }

            const { location } = await GoogleService.cords_get_current();
            const data = await GoogleService.timezone_from_cords(location.lat, location.lng);
            if (timezoneList.indexOf(data.timeZoneId) !== -1) {
              timezone = data.timeZoneId;
            }

            updateForm({ locale, timezone });
          }
        } catch (e) {
          logger.captureException(e);
        }
      };

      render() {
        const { isMainReseller } = this.injected.store;
        const res = this.injected.store.reseller!;
        const plans = ChargebeeSubscriptionPlans[res._id];
        return (
          <Formik initialValues={initialValues} validate={this.validate} onSubmit={this.onSubmit}>
            {form => {
              const { isSubmitting, setFieldValue, setFieldError, submitCount } = form;
              this.prePopulate(({ locale, timezone }) => {
                if (locale) setFieldValue('locale', locale);
                if (timezone) setFieldValue('timezone', timezone);
              });
              const mapType = form.values.map_data.type;
              return (
                <Form>
                  <FastField
                    name="name"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Name"
                        help="The name of this restaurant location"
                        error={this.getFieldError(form, 'name')}
                      >
                        <Input type="text" {...field} required />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="subdomain"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Sub-domain"
                        help="Enter the sub-domain name where your online store will be located. This can be changed any time. A custom domain can be set after creation"
                        error={form.errors.subdomain || null}
                      >
                        <FieldSubdomain
                          restaurant_id=""
                          website_id=""
                          base_domain={res.store_host}
                          value={field.value}
                          checkDomain={this.injected.store.api.proxy_subdomain_check}
                          onChange={subdomain => setFieldValue('subdomain', subdomain, false)}
                          onCheckCallback={e => setFieldError('subdomain', e)}
                        />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="map_data.type"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Map Data Source"
                        help="We recommend using Open Street Maps. If you cannot find you store address, try use Google Maps instead. Selecting 'None' will allow you to enter any address but certain delivery related features will be disabled."
                      >
                        <ButtonGroup
                          size="xs"
                          selected={field.value}
                          options={[
                            { value: 'osm', name: 'Open Street Maps' },
                            { value: 'google_maps', name: 'Google Maps' },
                            { value: 'custom', name: 'None' },
                          ]}
                          buttonClassName="p-lr-2"
                          onSelect={v => {
                            setFieldValue('address', '');
                            setFieldValue('map_data', { type: v.value });
                          }}
                          width="auto"
                        />
                      </FormGroup>
                    )}
                  />

                  {(mapType === 'google_maps' || mapType === 'osm') && (
                    <FormGroup
                      title="Store Address"
                      help="Search for your address and select from the dropdown"
                      error={this.getFieldError(form, 'address')}
                    >
                      <InputAddress
                        type={mapType}
                        gm={GoogleService}
                        mb={MapboxAPI}
                        value={form.values.address}
                        onChange={(address, map_data) => {
                          setFieldValue('address', address);
                          setFieldValue('map_data', map_data);
                        }}
                        onError={() => {}}
                      />
                    </FormGroup>
                  )}

                  {mapType === 'custom' && (
                    <FormGroup
                      title="Store Address"
                      help="Enter your complete store address"
                      error={this.getFieldError(form, 'address')}
                    >
                      <FastField name="address" render={({ field }: any) => <Input {...field} required />} />
                    </FormGroup>
                  )}

                  <FastField
                    name="phone"
                    render={({ field }: any) => (
                      <FormGroup optional title="Phone Number" help="Enter your store contact number">
                        <Input {...field} />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="opening_hours"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Opening Hours"
                        help="Enter time in 24H format, e.g. 21:00 for 9:00pm. To avoid errors, ensure time slots do not overlap or close before they open"
                        error={this.getFieldError(form, 'opening_hours')}
                      >
                        <div className="m-tb-3">
                          <FieldOpeningHours
                            hours={field.value}
                            onChange={opening_hours => setFieldValue('opening_hours', opening_hours)}
                          />
                        </div>
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="locale"
                    render={({ field }: any) => (
                      <FormGroup
                        title="System Locale"
                        help="Determines your default store language and how certain dates / currencies are formatted"
                        error={this.getFieldError(form, 'locale')}
                      >
                        <SelectAdv
                          type="single"
                          options={DataLocales}
                          value={field.value}
                          onChange={(option: string) => setFieldValue('locale', option)}
                        />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="kmmile"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Kilometres/Miles"
                        help="Determines your preferred distance unit"
                        error={this.getFieldError(form, 'kmmile')}
                      >
                        <SelectAdv
                          type="single"
                          options={DistanceMeasures}
                          value={field.value}
                          onChange={(option: string) => setFieldValue('kmmile', option)}
                        />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="timezone"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Timezone"
                        help="Used to accurately calculate store timings"
                        error={this.getFieldError(form, 'timezone')}
                      >
                        <SelectAdv
                          type="single"
                          options={timezoneList.map(n => ({ label: n, value: n }))}
                          value={field.value}
                          onChange={(option: string) => setFieldValue('timezone', option)}
                        />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="formats.time"
                    render={({ field }: any) => (
                      <FormGroup title="Time Formatting" help="Determines how times are formatted for this store">
                        <ButtonGroup
                          size="xs"
                          selected={field.value}
                          options={[
                            { value: 'h:mm a', name: '12 Hour (9:00pm)' },
                            { value: 'HH:mm', name: '24 Hour (21:00)' },
                          ]}
                          onSelect={v => setFieldValue('formats.time', v.value)}
                          width={150}
                        />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="formats.date"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Date Formatting"
                        help="Determines how dates are formatted. DD is the day, MM is the month and YYYY is year. Ensure characters are in uppercase. From example, DD/MM/YYYY will display dates as 21/03/2019"
                      >
                        <Input type="text" required placeholder="DD-MM-YYYY or MM/DD/YYYY" {...field} />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="currency"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Currency"
                        help="Select your store currency. This affects financial calculations and how your prices are disabled"
                        error={this.getFieldError(form, 'currency')}
                      >
                        <SelectAdv
                          type="single"
                          options={DataCurrenciesList}
                          value={field.value ? field.value.code : null}
                          onChange={(option: string) => {
                            const currency = DataCurrencies[option as keyof typeof DataCurrencies];
                            setFieldValue('currency', {
                              code: currency?.code,
                              symbol: currency?.symbol_native,
                              precision: currency?.decimal_digits,
                            });
                          }}
                        />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="tax_in_prices"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Tax In Prices"
                        help="Enable this if all your prices are already inclusive of tax. If this is disabled, taxes will be calculated and added to an order's total cost"
                      >
                        <Switch
                          id="tax_in_prices"
                          checked={field.value}
                          onChange={e => setFieldValue('tax_in_prices', e.target.checked)}
                        />
                      </FormGroup>
                    )}
                  />

                  {!!plans && (
                    <FastField
                      name="subscription_plan"
                      render={({ field }: any) => (
                        <FormGroup title="Subscription Plan" error={this.getFieldError(form, 'subscription_plan')}>
                          <div className="p-t-1">
                            <SubscriptionPlansV4
                              plans={plans}
                              selected={field.value}
                              onChange={value => setFieldValue('subscription_plan', value)}
                              isMainReseller={isMainReseller}
                            />
                          </div>
                        </FormGroup>
                      )}
                    />
                  )}

                  {!!form.values.subscription_plan && form.values.subscription_plan.indexOf('free') === -1 && (
                    <FormGroup
                      title="Credit Card Details"
                      help={
                        res._id === 'airwaiters'
                          ? 'You will only be billed after 30 days'
                          : 'You will only be billed after 24 hours'
                      }
                    >
                      <div className="grid-2 md sm-gap">
                        <div className="col">
                          <Input
                            type="text"
                            placeholder="Name On Card"
                            value={this.state.card_name}
                            onChange={e => this.setState({ card_name: e.target.value })}
                            required
                          />
                        </div>
                        <div className="col">
                          <StripeElementCard onError={e => this.setState({ card_error: e })} />
                        </div>
                      </div>
                    </FormGroup>
                  )}

                  {submitCount > 0 && this.state.error && <FormGroup error={this.state.error} />}

                  <Button full color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting && <RotateLoader size={2} color="white" />}
                    {!isSubmitting && 'Create Restaurant'}
                  </Button>
                </Form>
              );
            }}
          </Formik>
        );
      }
    },
  ),
);

export const RestaurantFormSetup = withTheme(RestaurantFormSetupClass);
