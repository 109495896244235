/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { inject, observer } from 'mobx-react';
import { WithTranslation, withTranslation } from 'react-i18next';
import _isEmpty from 'lodash/isEmpty';
import { logger, Untrusive } from '@lib/common';
import {
  Modal,
  ModalContent,
  ModalTitle,
  Select,
  FormGroup,
  Button,
  RotateLoader,
  InputGroup,
  InputNumber,
  Input,
} from '@lib/components';
import { UI } from '../../../../../../core/ui';
import { MobxComponent } from '../../../../../../mobx/components';

interface State {
  submitting: boolean;
  reason: string;
  amountError: boolean;
  fetchStripeError: boolean;
  stripeData: {
    amount_captured: number;
    amount_refunded: number;
  };
}

interface Props extends WithTranslation {
  order: T.Schema.Order.OrderSchema;
  restaurant: T.Schema.Restaurant.RestaurantSchema;
  showModal: boolean;
  onClose: () => void;
}
const REFUND_REASONS = [
  { label: '', value: '' },
  { label: 'Duplicate', value: 'duplicate' },
  { label: 'Fraudulent', value: 'fraudulent' },
  { label: 'Requested By Customer', value: 'requested_by_customer' },
];

const StripeConnectRefundModalClass = inject('store')(
  observer(
    class StripeConnectRefundModalClass extends MobxComponent<Props, State> {
      _amount: any;

      constructor(props: Props) {
        super(props);
        this._amount = React.createRef();
        this.state = {
          submitting: false,
          reason: '',
          amountError: false,
          fetchStripeError: false,
          stripeData: {
            amount_captured: 0,
            amount_refunded: 0,
          },
        };
      }

      handleModalClose = () => {
        this.props.onClose();
      };

      handleReasonChange = (e: any) => {
        this.setState({ reason: e.target.value });
      };

      handleChange = (value: number): void => {
        const {
          payment: { currency, total_cents },
        } = this.props.order;
        const divider = currency !== 'JPY' ? 100 : 1;
        const amount = total_cents / divider;

        if (Number(value) > amount || Number(value) < 0) {
          this.setState({ amountError: true });
        } else if (Number(value) + this.state.stripeData.amount_refunded / 100 > amount) {
          this.setState({ amountError: true });
        } else {
          this.setState({ amountError: false });
          this._amount.current = Number(value);
        }
      };

      performRefund = async (e: any) => {
        e.preventDefault();

        const { store } = this.injected;
        const { reason } = this.state;
        const amount = this._amount.current;
        const {
          order: { _id: order_id },
          restaurant: { _id: restaurant_id },
        } = this.props;

        if (_isEmpty(reason)) {
          UI.notification.error('Refund reason cannot be empty');
          return;
        }

        try {
          const proceed = confirm('Once refunded, it cannot be reversed. Refund the order?');
          if (!proceed) {
            return;
          }
          Untrusive.start();
          const payload: T.API.DashboardOrderStripeConnectRefundRequest = {
            order_id,
            restaurant_id,
            refund_reason: reason,
            amount,
          };

          const response = await store.api.order_stripe_connect_refund(payload);
          if (response.outcome) {
            UI.notification.error(response.message, { timeout: 6000 });
          } else {
            store.updateOrderComplete(response.order);
            UI.notification.success('Payment refunded');
          }
        } catch (e) {
          logger.captureException(e);
          UI.notification.error('An error occurred, try again soon or contact us', {
            timeout: 6000,
          });
        } finally {
          this.props.onClose();
          Untrusive.stop();
        }
      };

      getStripe = (paymentIntent: string) => {
        const { store } = this.injected;
        return store.api.getStripeTransaction({ paymentIntent });
      };

      async componentDidUpdate(prevProps: any) {
        if (this.props.showModal !== prevProps.showModal) {
          const {
            payment: { stripe_connect_payment_intent_id },
          } = this.props.order;
          if (stripe_connect_payment_intent_id) {
            try {
              const response = await this.getStripe(stripe_connect_payment_intent_id);
              if (response.outcome) {
                UI.notification.error('Cannot get Stripe transaction.');
                this.setState({ fetchStripeError: true });
                return;
              }

              if (!response.data.charges?.data[0]) {
                UI.notification.error('Cannot get Stripe transaction.');
                this.setState({ fetchStripeError: true });
                return;
              }
              this.setState({
                stripeData: {
                  amount_captured: response.data.charges.data[0].amount_captured,
                  amount_refunded: response.data.charges.data[0].amount_refunded,
                },
                fetchStripeError: false,
              });
            } catch (error: any) {
              UI.notification.error(error.message);
            }
          }
        }
      }

      render() {
        const { showModal } = this.props;
        const { t } = this.injected;
        const { submitting, reason, stripeData, fetchStripeError } = this.state;
        const {
          payment: { currency, total_cents },
        } = this.props.order;
        const currencyConfig = this.injected.store.intl.s.currency;

        const divider = currency !== 'JPY' ? 100 : 1;
        const amount = total_cents / divider;
        const refundAmount = (stripeData.amount_captured - stripeData.amount_refunded) / 100;
        const { order } = this.props;
        const refundToConnectedAccount = order.payment.stripe_connect_refunded_to_connected_account;
        const refundToCustomer = order.payment.stripe_connect_refunded_to_customer;
        const refundStatus = order.payment.stripe_connect_refunded_status;
        let isRefundable: boolean;
        if (typeof refundStatus === 'undefined') {
          isRefundable = !refundToConnectedAccount && !refundToCustomer;
        } else {
          isRefundable = refundStatus !== 'full_refund';
        }
        const displayRefundAmount = t('currency', { value: refundAmount });
        return (
          <Modal
            width="xs"
            active={showModal}
            preventClose
            id="stripe-connect-refund-modal"
            close={this.handleModalClose}
          >
            <ModalTitle className="round-top">
              <h4>Refund</h4>
            </ModalTitle>

            <ModalContent>
              <FormGroup title="Total Paid">
                <Input value={this.state.stripeData.amount_captured / 100} readOnly />
              </FormGroup>
              <FormGroup title="Refunded">
                <Input value={this.state.stripeData.amount_refunded / 100} readOnly />
              </FormGroup>

              <FormGroup title="Please select refund reason (required)">
                <Select
                  className="no-round"
                  options={REFUND_REASONS}
                  style={{ width: '100%' }}
                  value={reason}
                  onChange={this.handleReasonChange}
                  required
                />
              </FormGroup>

              <FormGroup
                title={`Refund Amount (Max: ${displayRefundAmount})`}
                error={this.state.amountError ? 'Invalid refund amount' : ''}
              >
                <InputGroup iconHtml={<p className="font-semi-bold">{currency}</p>}>
                  <InputNumber
                    type="number"
                    min={0}
                    max={amount}
                    required
                    step={currencyConfig.step}
                    change={(amount: any) => this.handleChange(amount)}
                  />
                </InputGroup>
              </FormGroup>

              <FormGroup>
                <Button
                  full
                  color="primary"
                  type="button"
                  disabled={submitting || !isRefundable || fetchStripeError || this.state.amountError}
                  onClick={this.performRefund}
                >
                  {submitting && <RotateLoader size={2} color="white" />}
                  {!submitting && 'Refund'}
                </Button>
              </FormGroup>
            </ModalContent>
          </Modal>
        );
      }
    },
  ),
);

export const StripeConnectRefundModal = withTranslation()(StripeConnectRefundModalClass);
