import { styled } from '@lib/components';

export const IconWrapper = styled.div`
  width: 1.2rem;
  height: 1.2rem;
  position: relative;
  align-self: flex-end;
  margin-left: 0.5rem;
  & > svg {
    width: 100%;
    height: 100%;
  }
`;

export const Centered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
