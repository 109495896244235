import React, { useMemo } from 'react';
import { FaBars, FaChevronDown, FaChevronUp, FaMinusCircle } from 'react-icons/fa';
import { arrayMove, SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { RestaurantUtils } from '@lib/common';
import { Box, Button, FormGroup, FormTreeSelect, Input, InputBox, Tooltip } from '@lib/components';
import { MobXProviderContext } from 'mobx-react';

type ItemType = T.Schema.Restaurant.FreeItemPromo.ConditionTiers;
type ListType = ItemType[];
type ToggleActive = (id: string) => void;
type HandleChange = (i: number, key: keyof ItemType, value: string | string[]) => void;
type HandleRemove = (i: number) => void;
type HandleSort = (data: { oldIndex: number; newIndex: number }) => void;

interface ComponentProps {
  r: T.Schema.Restaurant.RestaurantSchema;
  values: ListType;
  onChange: (values: ListType) => void;
  form: any;
}
interface ListProps {
  r: T.Schema.Restaurant.RestaurantSchema;
  items: ListType;
  change: HandleChange;
  remove: HandleRemove;
  toggleActive: ToggleActive;
  inActive: string[];
  form: any;
}
interface ItemProps {
  r: T.Schema.Restaurant.RestaurantSchema;
  item: ItemType;
  itemIndex: number;
  isLast: boolean;
  isActive: boolean;
  change: HandleChange;
  remove: HandleRemove;
  toggleActive: ToggleActive;
}

const DragHandle = SortableHandle(() => (
  <InputBox className="no-round width40 flex-center cursor-move nb-t">
    <p className="big font-bold">
      <FaBars />
    </p>
  </InputBox>
));

const SortableItem = SortableElement((props: ItemProps) => {
  const { item, itemIndex, change, remove, isLast, isActive, toggleActive } = props;
  const { store } = React.useContext(MobXProviderContext);
  const menuTree = useMemo(() => RestaurantUtils.menu.getTreeFilter(store.restaurant!, () => true), [store.restaurant]);

  const normalizeTreeData = (input: any) => {
    if (!input) return [];

    const restaurant: T.Schema.Restaurant.RestaurantSchema = store.restaurant!;
    const { menus } = restaurant;
    let dishIds: string[] = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const menuId of Object.keys(input)) {
      const selection = input[menuId];
      const menu = menus.find(m => m._id === menuId);
      if (!menu) continue;

      if (selection.menu.length > 0) {
        menu.categories.forEach(category => category.dishes.forEach(dish => dishIds.push(dish._id)));
        continue;
      }

      if (selection.category.length > 0) {
        menu.categories.forEach(category => {
          if (selection.category.includes(category._id)) {
            category.dishes.forEach(dish => dishIds.push(dish._id));
          }
        });
      }

      if (selection.dish.length > 0) {
        dishIds = dishIds.concat(selection.dish);
      }
    }

    return dishIds;
  };

  return (
    <Box key={itemIndex} className={isLast ? 'no-round' : 'no-round nb-b'}>
      <div className="flex-line m-t-1">
        <InputBox className="no-round width40 text-center nb-t nb-l cursor" onClick={() => toggleActive(item.id)}>
          <p className="big font-bold" style={{ lineHeight: '36px' }}>
            {isActive ? <FaChevronDown /> : <FaChevronUp />}
          </p>
        </InputBox>

        <DragHandle />

        <InputBox className="no-round width64 text-center nb-t">
          <p className="big font-bold" style={{ lineHeight: '36px' }}>
            {itemIndex + 1}
          </p>
        </InputBox>

        <div className="flex-grow" style={{ alignItems: 'flex-end' }}>
          <FormGroup className="m-b-0" small_title no_border>
            <div className="flex m-b-1">
              <Tooltip text="From" width={65} position="top">
                <Input
                  type="number"
                  className="no-round nb-t m-l-1 m-b-1"
                  step={0.01}
                  min={1}
                  value={item.lower_limit}
                  placeholder="From"
                  required
                  onChange={(e: any) => change(itemIndex, 'lower_limit', e.target.value)}
                />
              </Tooltip>
              <Tooltip text="To" width={65} position="top">
                <Input
                  type="number"
                  className="no-round nb-t m-l-1 m-b-1"
                  step={0.01}
                  min={1}
                  value={item.upper_limit}
                  placeholder="To"
                  required
                  onChange={(e: any) => change(itemIndex, 'upper_limit', e.target.value)}
                />
              </Tooltip>
              <Tooltip text="Free Quantity" width={65} position="top">
                <Input
                  type="number"
                  className="no-round nb-t m-l-1 m-b-1"
                  step={1}
                  min={1}
                  value={item.free_quantity}
                  placeholder="FreeQty"
                  required
                  onChange={(e: any) => change(itemIndex, 'free_quantity', e.target.value)}
                />
              </Tooltip>
            </div>
          </FormGroup>
        </div>

        <Tooltip text="Delete" width={65} position="top" disable={itemIndex !== 0}>
          <Button
            type="button"
            className="no-round nb-t nb-r"
            paddinglr={5}
            color="white"
            onClick={() => remove(itemIndex)}
          >
            <FaMinusCircle />
          </Button>
        </Tooltip>
      </div>
      <FormTreeSelect
        id="tiers"
        selected={normalizeTreeData(item.free_items)}
        nodes={menuTree}
        typePriority={['menu', 'category', 'dish']}
        onChange={(_, prioritizedSelected: any) => change(itemIndex, 'free_items', prioritizedSelected)}
      />
    </Box>
  );
});

const SortableItemContainer = SortableContainer((props: ListProps) => (
  <div>
    {props.items.map((item, i) => (
      <SortableItem
        key={`item-${i}`}
        index={i}
        r={props.r}
        item={item}
        itemIndex={i}
        isActive={props.inActive.indexOf(item.id) === -1}
        isLast={props.items.length - 1 === i}
        change={props.change}
        remove={props.remove}
        toggleActive={props.toggleActive}
      />
    ))}
  </div>
));

export class ConditionTiers extends React.Component<ComponentProps, { inActive: string[] }> {
  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      inActive: [],
    };
  }

  toggleActive: ToggleActive = (id: string) => {
    const inActive = [...this.state.inActive];
    const existing = inActive.indexOf(id);
    if (existing === -1) inActive.push(id);
    else inActive.splice(existing, 1);
    this.setState({ inActive });
  };

  remove: HandleRemove = i => {
    const values = [...this.props.values];
    values.splice(i, 1);
    this.props.onChange(values);
  };

  change: HandleChange = (i, key, value) => {
    const values: any = [...this.props.values];
    values[i][key] = value;
    this.props.onChange(values);
  };

  onSortEnd: HandleSort = ({ oldIndex, newIndex }) => {
    const { onChange, values } = this.props;
    onChange(arrayMove(values, oldIndex, newIndex));
  };

  render() {
    const { values, r } = this.props;
    return (
      <SortableItemContainer
        helperClass="zindex-15 bsl-2"
        pressDelay={50}
        useDragHandle
        r={r}
        items={values}
        change={this.change}
        remove={this.remove}
        onSortEnd={this.onSortEnd}
        toggleActive={this.toggleActive}
        inActive={this.state.inActive}
        form={this.props.form}
      />
    );
  }
}
