import React from 'react';
import { BoxHeading, BoxSection, FormGroupIcon, Input, Button, LinkTag } from '@lib/components';
import { inject, observer } from 'mobx-react';
import { validators, logger } from '@lib/common';
import { FaEnvelope } from 'react-icons/fa';
import { MobxComponent } from '../../../../mobx/components/index';
import { Link } from '../../../components/router/Link';
import { UI } from '../../../../core/ui';
import { AuthLayout } from '../layout';

interface Props {}
interface State {
  loading: boolean;
  email: string;
  success: boolean;
}

export const AuthPasswordResetInit = inject('store')(
  observer(
    class AuthPasswordResetInit extends MobxComponent<Props, State> {
      constructor(props: Props) {
        super(props);
        this.state = {
          loading: false,
          email: '',
          success: false,
        };
      }

      load = (loading: boolean) => {
        this.setState({ loading });
      };

      onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        // VARS
        e.preventDefault();
        const { store } = this.injected;
        try {
          let { email } = this.state;

          if (!email) {
            return UI.notification.error('Please enter your account e-mail');
          }

          const email_valid = validators.email(email);
          if (email_valid.error) {
            return UI.notification.error('Invalid e-mail address');
          }

          email = email_valid.value;

          this.load(true);

          await store.api.user_password_reset_init({ email });

          this.load(false);

          /*
			if (data.outcome) {
				UI.notification.error(data.message, { timeout: 6000 });
			}
			else {
			*/
          this.setState({ success: true, email: '' });
          // }
        } catch (e) {
          logger.captureException(e);
          UI.notification.error('Something went wrong, try again or contact us', { timeout: 6000 });
          this.load(false);
        }
        return null;
      };

      onChange = <T extends keyof State>(e: React.ChangeEvent<HTMLInputElement>) => {
        const newState = UI.helpers.handleChange(e);
        this.setState(newState as { [P in T]: State[P] });
      };

      render() {
        const { email, loading, success } = this.state;
        return (
          <AuthLayout loading={loading}>
            <BoxHeading className="text-center">
              <h3>Reset Password</h3>
            </BoxHeading>
            {!success && (
              <form onSubmit={this.onSubmit}>
                <BoxSection>
                  <FormGroupIcon icon={<FaEnvelope />} no_border>
                    <Input
                      type="email"
                      name="email"
                      placeholder="E-Mail"
                      value={email}
                      onChange={this.onChange}
                      required
                    />
                  </FormGroupIcon>

                  <Button color="primary" type="submit" full>
                    Reset Password
                  </Button>
                </BoxSection>
                <BoxSection className="text-center">
                  <p className="m-b-2">
                    Don't have an account - <Link to="/register">Sign Up</Link>
                  </p>
                  <p>
                    Remember your password - <Link to="/login">Login</Link>
                  </p>
                </BoxSection>
              </form>
            )}
            {success && (
              <>
                <BoxSection className="text-center">
                  <p className="lhp big">
                    An e-mail has been sent to your address. Please follow the link inside the e-mail to reset your
                    password. If you have not received a mail, check your spam or{' '}
                    <LinkTag onClick={() => this.setState({ success: false })}>try again</LinkTag>
                  </p>
                </BoxSection>
                <BoxSection className="text-center">
                  <p className="m-b-2">
                    Don't have an account - <Link to="/register">Sign Up</Link>
                  </p>
                  <p>
                    Remember your password - <Link to="/login">Login</Link>
                  </p>
                </BoxSection>
              </>
            )}
          </AuthLayout>
        );
      }
    },
  ),
);
