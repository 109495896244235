import React from 'react';
import { inject, observer } from 'mobx-react';
import { logger } from '@lib/common';
import { FastField, Form, Formik, FormikHelpers as FormikActions, FormikProps } from 'formik';
import { Modal, FormGroup, Input, Button, RotateLoader, ModalContent, ModalTitle } from '@lib/components';
import { FormHelpers } from '../../../../../core/form';
import { UI } from '../../../../../core/ui';
import { MobxComponent } from '../../../../../mobx/components/index';

interface FormValues {
  login: string;
  password: string;
  password_confirm: string;
}

interface Props {
  active: boolean;
  close: () => void;
}

interface State {
  error: string;
}

const errorStrings: T.ObjectString = {
  error_invalid_email: 'Invalid e-mail address',
  error_exists: 'Customer with this e-mail address already exists',
};

const initialValues: FormValues = {
  login: '',
  password: '',
  password_confirm: '',
};

export const RestaurantCustomerCreateModal = inject('store')(
  observer(
    class RestaurantCustomerCreateModal extends MobxComponent<Props, State> {
      constructor(props: Props) {
        super(props);
        this.state = {
          error: '',
        };
      }

      onSubmit = async (values: FormValues, form: FormikActions<FormValues>) => {
        form.setSubmitting(false);
        const { store } = this.injected;
        const restaurant_id = store.restaurant!._id;
        const { login, password, password_confirm } = values;
        try {
          if (password.length < 6 || password.length > 512) {
            this.setState({ error: 'Password must be at least 6 characters long' });
            return;
          }
          if (password !== password_confirm) {
            this.setState({ error: 'Passwords do not match' });
            return;
          }

          form.setSubmitting(true);

          this.setState({ error: '' });

          const res = await store.api.customer_create({ login, password, restaurant_id });

          if (res.outcome) {
            this.setState({ error: errorStrings[res.message] || res.message });
            form.setSubmitting(false);
            return;
          }

          const { customer } = res;

          const items = [...this.injected.store.customers.items];

          items.unshift(customer);

          store.updateCustomers({ items });

          UI.notification.success('Customer created');

          this.props.close();
        } catch (e) {
          logger.captureException(e);
          this.setState({ error: 'Error creating customer, please try again or contact us' });
        } finally {
          form.setSubmitting(false);
        }
      };

      validate = (values: FormValues) => {
        const { errors, isError } = FormHelpers.validate<FormValues>(values, {});
        if (this.state.error && !isError) {
          this.setState({ error: '' });
        } else if (!this.state.error && isError) {
          this.setState({ error: 'There are errors in your form that need correcting, please scroll up' });
        }
        return errors;
      };

      getFieldError = (form: FormikProps<FormValues>, field: keyof FormValues) => FormHelpers.error(form, field);

      render() {
        const { active, close } = this.props;
        const { error } = this.state;
        return (
          <Modal width={500} active={active} close={close}>
            <ModalTitle className="round-top">
              <h3>Create Customer</h3>
            </ModalTitle>
            <ModalContent>
              <Formik<FormValues> initialValues={initialValues} validate={this.validate} onSubmit={this.onSubmit}>
                {form => {
                  const { isSubmitting, submitCount } = form;
                  return (
                    <Form>
                      <FastField
                        name="login"
                        render={({ field }: any) => (
                          <FormGroup title="E-mail" help="The e-mail address your customer will login with">
                            <Input type="email" {...field} required />
                          </FormGroup>
                        )}
                      />

                      <FastField
                        name="password"
                        render={({ field }: any) => (
                          <FormGroup
                            title="Password"
                            help="The password your customer will login with. Minimum 5 characters"
                          >
                            <Input type="password" {...field} required />
                          </FormGroup>
                        )}
                      />
                      <FastField
                        name="password_confirm"
                        render={({ field }: any) => (
                          <FormGroup
                            title="Confirm Password"
                            help="Confirm the password that your customer will login with"
                          >
                            <Input type="password" {...field} required />
                          </FormGroup>
                        )}
                      />

                      {submitCount > 0 && error && <FormGroup error={error} />}

                      <Button full color="primary" type="submit" disabled={isSubmitting}>
                        {isSubmitting && <RotateLoader size={2} color="white" />}
                        {!isSubmitting && 'Create'}
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
            </ModalContent>
          </Modal>
        );
      }
    },
  ),
);
