import React from 'react';
import { FastField } from 'formik';
import { inject, observer } from 'mobx-react';
import { Select, Switch, FormGroup, Button, RotateLoader } from '@lib/components';
import { UI } from '../../../../../../../core/ui';
import { MobxComponent } from '../../../../../../../mobx/components';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';

interface Props {}

type FormValues = T.Schema.Restaurant.RestaurantSchema['website']['sections']['menus'];

export const SettingsFormDesignItems = inject('store')(
  observer(
    class SettingsFormDesignItems extends MobxComponent<Props> {
      initialValues: FormValues;

      constructor(props: Props) {
        super(props);
        const r = this.injected.store.restaurant!;
        this.initialValues = r.website.sections.menus;
      }

      render() {
        const menuTemplate = this.injected.store.restaurant!.website.sections.menu_nav_bar?.menu_template || 'v1';

        return (
          <RestaurantForm<FormValues>
            submit={async (r, values) => {
              r.website.sections.menus = values;
              const update = {
                $set: {
                  'website.sections.menus': r.website.sections.menus,
                },
              };
              return { r, update };
            }}
            validators=
            initialValues={this.initialValues}
            onSuccess={() => {}}
            onError={() => UI.notification.error('An error occurred')}
            onSuccessMessage="Settings Updated"
            onErrorMessage=""
          >
            {({ form, error }) => {
              const { isSubmitting, setFieldValue } = form;
              return (
                <div className="p-4">
                  <FastField
                    name="dish_style"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Item Style"
                        help="Determines the position of your item image if there is one. List style shows the image to the left of the item. Card puts it on the top. We recommend using list"
                      >
                        <Select
                          value={field.value}
                          options={[
                            {
                              value: 'list',
                              label: 'List',
                            },
                            {
                              value: 'list-rounded',
                              label: 'Rounded List',
                            },
                            {
                              value: 'card',
                              label: 'Card',
                            },
                          ]}
                          onChange={e => setFieldValue('dish_style', e.target.value)}
                        />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="dish_tag_position"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Item Tag Position"
                        help="Determines the position of your item tags if there are any. Top edge is the default option"
                      >
                        <Select
                          value={field.value}
                          options={[
                            {
                              value: 'edge_top',
                              label: 'Top Edge',
                            },
                            {
                              value: 'inside_bottom',
                              label: 'Inner Bottom',
                            },
                          ]}
                          onChange={e => setFieldValue('dish_tag_position', e.target.value)}
                        />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="dish_hide_description"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Hide Item Descriptions"
                        help="Use this option to hide item descriptions on your store menu grid. They will still show in the item popup."
                      >
                        <Switch
                          id="dish_hide_description"
                          checked={field.value || false}
                          onChange={e => setFieldValue('dish_hide_description', e.target.checked)}
                        />
                      </FormGroup>
                    )}
                  />

                  {menuTemplate === 'v1' && (
                    <>
                      <FastField
                        name="dish_hide_all_category"
                        render={({ field }: any) => (
                          <FormGroup
                            title="Hide `All` Category"
                            help="Use this option to hide the `All` menu category link that shows all the items."
                          >
                            <Switch
                              id="dish_hide_all_category"
                              checked={field.value || false}
                              onChange={e => setFieldValue('dish_hide_all_category', e.target.checked)}
                            />
                          </FormGroup>
                        )}
                      />

                      <FastField
                        name="dish_hide_category_description"
                        render={({ field }: any) => (
                          <FormGroup
                            title="Hide Category Descriptions"
                            help="Use this option to hide category descriptions on your store menu grid. This is only available when the horizontal category bar is hidden. (Menu Nav Bar > Menu Selector Only: On)"
                          >
                            <Switch
                              id="dish_hide_category_description"
                              checked={field.value || false}
                              onChange={e => setFieldValue('dish_hide_category_description', e.target.checked)}
                            />
                          </FormGroup>
                        )}
                      />

                      <FastField
                        name="enable_condensed_menu"
                        render={({ field }: any) => (
                          <FormGroup
                            title="Enable Condensed Menu"
                            help="Use this option to enable the condensed menu style."
                          >
                            <Switch
                              id="enable_condensed_menu"
                              checked={field.value || false}
                              onChange={e => setFieldValue('enable_condensed_menu', e.target.checked)}
                            />
                          </FormGroup>
                        )}
                      />
                    </>
                  )}

                  {error && <FormGroup error={error} />}

                  <Button full color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting && <RotateLoader size={2} color="white" />}
                    {!isSubmitting && 'Save'}
                  </Button>
                </div>
              );
            }}
          </RestaurantForm>
        );
      }
    },
  ),
);
