import React from 'react';
import { FastField } from 'formik';
import { FormGroup, Button, RotateLoader, Input } from '@lib/components';
import { inject, observer } from 'mobx-react';
import { UI } from '../../../../../../../core/ui';
import { MobxComponent } from '../../../../../../../mobx/components/index';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';

interface Props {
  close?: () => void;
}
interface State {}

type FormValues = T.Schema.Restaurant.RestaurantSchema['settings']['services']['delivery']['providers'];

export const SettingsFormIntegrationDriveYello = inject('store')(
  observer(
    class SettingsFormIntegrationDriveYello extends MobxComponent<Props, State> {
      initialValues: FormValues;

      constructor(props: Props) {
        super(props);
        this.state = {};
        const r = this.injected.store.restaurant!;
        this.initialValues = r.settings.services.delivery.providers;
      }

      render() {
        return (
          <RestaurantForm<FormValues>
            submit={async (r, values) => {
              r.settings.services.delivery.providers = values;
              const update = {
                $set: { 'settings.services.delivery.providers': r.settings.services.delivery.providers },
              };
              return { r, update };
            }}
            validators=
            initialValues={this.initialValues}
            onSuccess={() => {
              if (this.props.close) {
                this.props.close();
              }
            }}
            onError={() => UI.notification.error('An error occurred')}
            onSuccessMessage="Settings Updated"
            onErrorMessage=""
          >
            {({ form, error }) => {
              const { isSubmitting } = form;
              return (
                <div className="p-4">
                  <FormGroup>
                    <p className="font-semi-bold big m-b-2">Important Instructions</p>
                    <p className="lhp">
                      For Drive Yello to function correctly, please get the store key from Drive Yello and insert it
                      below.
                    </p>
                  </FormGroup>

                  <FastField
                    name="yello.secret_key"
                    render={({ field }: any) => (
                      <FormGroup title="Drive Yello Store Key" help="Your unique Drive Yello store API key.">
                        <Input type="text" {...field} value={field.value || ''} />
                      </FormGroup>
                    )}
                  />

                  {error && <FormGroup error={error} />}

                  <Button full color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting && <RotateLoader size={2} color="white" />}
                    {!isSubmitting && 'Save'}
                  </Button>
                </div>
              );
            }}
          </RestaurantForm>
        );
      }
    },
  ),
);
