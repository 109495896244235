import { logger, Untrusive, OrderUtils, CoreUtils } from '@lib/common';
import localStorage from 'store';
import { lighten } from 'polished';
import qs from 'querystring';
import * as Sentry from '@sentry/browser';
import { runInAction } from 'mobx';
import amplitude from 'amplitude-js';
import { constants } from '../../core/constants';
import { UI } from '../../core/ui';
import { routeMatch, routePathIsAuth, routePathIsGuest } from '../../routes';
import { config } from '../../config';
import { RootStore } from '../store';

const IntroJs = require('intro.js');

const ERROR_LOADER_GENERIC_MESSAGE =
  'We have been notified of the error. In the mean time, try clearing your cookies and refreshing your page or contact us';
let timer_auth_logout: number | NodeJS.Timer | null = null;
let timer_auth_refresh: number | NodeJS.Timer | null = null;
let initialized_count: number = 0;

export class ServiceStore {
  store: RootStore;

  constructor(store: RootStore) {
    this.store = store;
  }

  // LIFECYCLE
  init = async () => {
    const { api, router, theme } = this.store;
    try {
      logger.info('INIT SERVICE');

      // PREVENT DOUBLE INITIALIZATION
      initialized_count += 1;
      if (initialized_count > 1) {
        return;
      }

      // GET & SET RESELLER DETAILS
      let host = config.isProduction ? config.host : 'localhost:3001';
      if (config.isProduction && host.indexOf('localhost') !== -1) {
        host = 'admin.cloudwaitress.com';
      }

      if (host.indexOf('netlify') !== -1) {
        this.store.setLoader({
          active: true,
          title: 'Accessing Dashboard From In-Correct Domain Name',
          message: '',
          opacity: 1,
        });
        return;
      }

      const data = await api.reseller_find_public({ host });
      if (data.outcome) {
        throw new Error(`Reseller not found ${config.host}`);
      }

      this.store.reseller = data.reseller;

      // Init reseller configurations
      if (data.reseller.admin.colors.primary) {
        theme.init(data.reseller);
      }

      if (
        data.reseller._id === 'cloudwaitress' ||
        data.reseller._id === 'cloudwaitress-test' ||
        data.reseller._id === 'cloudwaitressau' ||
        data.reseller._id === 'feedme-ph' ||
        data.reseller._id === 'orders4u'
      ) {
        (async () => {
          // @ts-ignore
          // prettier-ignore
          !function (t, h, e, j, s, n) { t.hj = t.hj || function () { (t.hj.q = t.hj.q || []).push(arguments) }, t._hjSettings = { hjid: 1316767, hjsv: 6 }, s = h.getElementsByTagName("head")[0], (n = h.createElement("script")).async = 1, n.src = "https://static.hotjar.com/c/hotjar-" + t._hjSettings.hjid + ".js?sv=" + t._hjSettings.hjsv, s.appendChild(n) }(window, document);
          // @ts-ignore
          // prettier-ignore
          (function () { var w = window; var ic = w.Intercom; if (typeof ic === "function") { ic('reattach_activator'); ic('update', w.intercomSettings); } else { var d = document; var i = function () { i.c(arguments); }; i.q = []; i.c = function (args) { i.q.push(args); }; w.Intercom = i; var l = function () { var s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://widget.intercom.io/widget/uu9mk1kg'; var x = d.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); }; if (w.attachEvent) { w.attachEvent('onload', l); } else { w.addEventListener('load', l, false); } } })();
          window.Intercom('boot', { app_id: 'uu9mk1kg' });
        })().catch(logger.captureException);

        this.load_stripe(config.services.stripe.public_key);
      } else if (data.reseller._id === 'ceorestaurant') {
        (async () => {
          // @ts-ignore
          // prettier-ignore
          !function (e, n, t, s, c) { e[t] = e[t] || function () { (e[t].q = e[t].q || []).push(arguments) }, c = n.getElementsByTagName("head")[0], (s = n.createElement("script")).async = 1, s.src = "https://cdn.plutio.com/messenger/main.js", c.appendChild(s) }(window, document, "$plutio_msg"), $plutio_msg("ZD3SeLKdPkbgH9Aw5", {});
        })().catch(logger.captureException);
      } else if (data.reseller._id === 'getordernow') {
        (async () => {
          // @ts-ignore
          // prettier-ignore
          (function () { var w = window; var ic = w.Intercom; if (typeof ic === "function") { ic('reattach_activator'); ic('update', w.intercomSettings); } else { var d = document; var i = function () { i.c(arguments); }; i.q = []; i.c = function (args) { i.q.push(args); }; w.Intercom = i; var l = function () { var s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://widget.intercom.io/widget/xofbe5og'; var x = d.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); }; if (w.attachEvent) { w.attachEvent('onload', l); } else { w.addEventListener('load', l, false); } } })();
          window.Intercom('boot', { app_id: 'xofbe5og' });
        })().catch(logger.captureException);
      } else {
        // @ts-ignore
        window.Intercom = () => {};
        if (data.reseller.chargebee && data.reseller.chargebee.stripe_publishable_key) {
          this.load_stripe(data.reseller.chargebee.stripe_publishable_key);
        }
      }

      Untrusive.init({
        bgColor: lighten(0.2, theme.s.colors.primary),
        barColor: theme.s.colors.primary,
      });

      // GET AUTH TOKEN
      const auth_token = this.get_auth_token();

      // IF NO TOKEN AND PROTECTED ROUTE, REDIRECT TO LOGIN, STANDARD ROUTE CHANGE FN IS IN STORE
      const pathname = router.s.path;
      if (!auth_token && routePathIsAuth(pathname)) {
        router.push(`/login?${qs.stringify({ redirect: `${window.location.pathname}${window.location.search}` })}`);
      }

      // IF NO - TOKEN, RESOLVE LOADER AND HALT
      if (!auth_token) {
        this.store.toggleLoader(false);
        return;
      }

      // IF TOKEN - INIT AUTH PROCESS
      await this.login(auth_token);
    } catch (e) {
      logger.captureException(e, 'INIT ERROR');
      this.store.setLoader({
        active: true,
        title: 'Oops... Something Went Wrong',
        message: ERROR_LOADER_GENERIC_MESSAGE,
        opacity: 1,
      });
    }
  };

  login = async (token: string, path?: string) => {
    try {
      this.store.setLoader({
        active: true,
        title: 'Logging In...',
        message: 'Getting your account information',
        opacity: 1,
      });

      // SET TOKEN HERE SO API WORKS
      this.store.updateAuth({ token });

      const data = await this.store.api.dashboard_data();
      if (data.outcome) {
        UI.notification.error(data.message);
        return;
      }

      logger.info(data);

      // SUCCESSFUL LOGIN
      this.set_auth_token_storage(token);
      this.auth_token_timers(data.decoded.exp);

      this.store.setAuth({
        type: data.decoded.data.type,
        item: data.user,
        decoded: data.decoded,
        token,
      });
      this.store.setOrganisation(data.organisation);
      this.store.notifications.ablyOrgStart(token, data.user.organisation_id);

      if (path) {
        this.store.router.push(path);
      } else {
        // IF YOU ARE ON A GUEST ROUTE TAKE TO DASHBOARD
        const pathname = this.store.router.s.path;
        if (routePathIsGuest(pathname)) {
          this.store.router.push('/');
        }
      }

      // TRIGGER TO CHECK IF STAFF IS ABLE TO VIEW ROUTE
      this.store.routeOnChange(this.store.router.s);

      this.store.toggleLoader(false);
      // const { user, restaurant, subscription, customer, card, expiry } = await api.user_all_data();

      (() => {
        try {
          Sentry.configureScope(scope => {
            scope.setUser({
              _id: data.user._id,
              email: data.user.email,
              reseller_id: data.user.reseller_id,
            });
          });

          if (data.user.type !== 'staff') {
            let app_id = 'uu9mk1kg';
            if (data.user.reseller_id === 'getordernow') {
              app_id = 'xofbe5og';
            }
            window.Intercom('boot', {
              app_id,
              user_id: data.user._id,
              email: data.user.email,
              created_at: Math.round(data.user.created / 1000),
              reseller_id: data.user.reseller_id,
            });
          }

          const amp = amplitude.getInstance();

          amp.setUserId(data.user._id);

          amp.setUserProperties({
            type: data.user.type,
            email: data.user.email,
            created: data.user.created,
            organisation_id: data.user.organisation_id,
            reseller_id: data.user.reseller_id,
          });

          this.track('User: Login', undefined, { intercom: true });
        } catch (e) {
          logger.captureException(e);
        }
      })();
    } catch (e) {
      logger.captureException(e, 'LOGIN ERROR');
      this.store.setLoader({
        active: true,
        title: 'Oops... Something Went Wrong Logging In',
        message: ERROR_LOADER_GENERIC_MESSAGE,
        opacity: 1,
      });
    }
  };

  login_refresh = async () => {
    try {
      logger.dev('Session refresh init');
      const data = await this.store.api.user_token_refresh();
      if (data.outcome) {
        return UI.notification.error(data.message);
      }
      this.set_auth_token_storage(data.token);
      this.auth_token_timers(data.decoded.exp);
      this.store.setAuth({
        type: data.decoded.data.type, // RESELLER AUTH TYPES STILL SHOW
        token: data.token,
        decoded: data.decoded,
        item: data.user,
      });
      this.store.notifications.ablyOrgStart(data.token, data.user.organisation_id);
      if (this.store.restaurant) {
        this.store.notifications.ablyRestaurantInit(this.store.restaurant._id);
      }
      logger.dev('Session refresh success');
    } catch (e) {
      logger.captureException(e, 'AUTH REFRESH ERROR');
      this.logout({
        title: 'Oops... Something Went Wrong Refreshing Your Session',
        message: 'Please try logging in again, refreshing page...',
        delay: 4000,
      });
    }
    return null;
  };

  logout = async (opts?: { title?: string; message?: string; delay?: number }) => {
    if (opts && opts.title && opts.message) {
      this.store.setLoader({
        active: true,
        title: opts.title,
        message: opts.message,
        opacity: 1,
      });
    }

    const delay = opts ? opts.delay || 0 : 0;

    setTimeout(() => {
      this.store.notifications.ablyOrgStop();
      this.store.notifications.ablyRestaurantStop();
      this.set_auth_token_storage('');
      window.location.href = `${window.location.origin}/login`;
    }, delay);

    window.Intercom('boot', { app_id: 'uu9mk1kg' });

    this.track('User: Logout', undefined, { intercom: true });

    // @ts-ignore
    amplitude.getInstance().setUserId(null);
  };

  // AUTH
  auth_token_timers = (expiry: number) => {
    if (expiry <= 0) {
      // PREVENT INFINITE REDIRECT LOOP
      this.logout();
      return;
    }

    expiry *= 1000;

    const logout_time = Math.round(expiry - 120000 - Date.now()); // 2 min"s before expiry logout
    const refresh_time = Math.round(expiry - 60000 * 60 * 24 - Date.now()); // 1 day before expiry refresh

    if (timer_auth_logout) clearTimeout(timer_auth_logout as number);
    if (timer_auth_refresh) clearTimeout(timer_auth_refresh as number);

    timer_auth_logout = setTimeout(
      () =>
        this.logout({
          title: 'Login Session Expired',
          message: 'Please login again, refreshing page...',
        }),
      Math.max(logout_time, 0),
    );
    timer_auth_refresh = setTimeout(this.login_refresh, Math.max(refresh_time, 0));

    logger.dev('Init Timer Logout - %d', logout_time + Date.now());
    logger.dev('Init Timer Refresh - %d', refresh_time + Date.now());
  };

  // HANDLERS
  handle_auth_token_error = async () => {
    logger.info('AUTH TOKEN ERROR');
    this.logout({
      title: 'Login Session Expired',
      message: 'Please login again, refreshing page...',
      delay: 2000,
    });
  };

  // ADVANCED APIS
  restaurant = {
    get: async () => {
      const { store } = this;
      const r = store.restaurant;
      const match = routeMatch(store.router.s.path)!;
      const restaurant_id = match.rid!;
      if (r && r._id !== restaurant_id) {
        store.setRestaurant(null);
      }
      const res = await store.api.restaurant({ _id: restaurant_id });
      if (res.outcome) {
        UI.notification.error(res.message, { timeout: 6000 });
        return;
      }

      const { restaurant, restaurantStock, restaurantIntegrationApps, notificationToken } = res;

      // SET RESTAURANT
      store.setRestaurantStock(restaurantStock);
      store.setRestaurant(restaurant);
      store.setRestaurantIntegrationBaseApps(restaurantIntegrationApps);

      this.store.notifications.ablyRestaurantInit(restaurant._id);
      this.store.notifications.start(restaurant.api, notificationToken);
      setTimeout(() => this.tour_restaurant_dashboard(), 500);
    },
  };

  order = {
    get_board: async () => {
      const { store } = this;
      const r = store.restaurant!;
      try {
        store.updateOrdersBoard({ loading: true, error: '', lists: {} });
        const response = await store.api.orders_board_find({
          restaurant_id: store.restaurant!._id,
        });
        if (response.outcome) {
          store.updateOrdersBoard({ loading: false, error: response.message });
        } else {
          const { active_orders, complete_orders, cancelled_orders } = response;
          store.updateOrdersBoard({
            loading: false,
            lists: {
              unconfirmed: {
                items: active_orders
                  .filter(o => o.status === 'unconfirmed')
                  .sort(OrderUtils.sortFunctionByStatus('unconfirmed', r.settings.region.timezone)),
              },
              awaiting_payment: {
                items: active_orders
                  .filter(o => o.status === 'awaiting_payment')
                  .sort(OrderUtils.sortFunctionByStatus('awaiting_payment', r.settings.region.timezone)),
              },
              due_soon: {
                items: active_orders
                  .filter(o => {
                    if (o.status === 'confirmed') {
                      const dueIn = OrderUtils.dueInMillis(o, r.settings.region.timezone);
                      if (dueIn < CoreUtils.dates.getMillisFromMinutes(120)) {
                        return true;
                      }
                    }
                    return false;
                  })
                  .sort(OrderUtils.sortFunctionByStatus('due_soon', r.settings.region.timezone)),
              },
              upcoming: {
                items: active_orders
                  .filter(o => {
                    if (o.status === 'confirmed') {
                      const dueIn = OrderUtils.dueInMillis(o, r.settings.region.timezone);
                      if (dueIn > CoreUtils.dates.getMillisFromMinutes(120)) {
                        return true;
                      }
                    }
                    return false;
                  })
                  .sort(OrderUtils.sortFunctionByStatus('upcoming', r.settings.region.timezone)),
              },
              ready: {
                items: active_orders
                  .filter(o => o.status === 'ready')
                  .sort(OrderUtils.sortFunctionByStatus('ready', r.settings.region.timezone)),
              },
              on_route: {
                items: active_orders
                  .filter(o => o.status === 'on_route')
                  .sort(OrderUtils.sortFunctionByStatus('on_route', r.settings.region.timezone)),
              },
              complete: {
                items: complete_orders,
              },
              cancelled: {
                items: cancelled_orders,
              },
            },
          });
        }
      } catch (e) {
        logger.captureException(e);
        store.updateOrdersBoard({ loading: false, error: 'Something went wrong' });
      }
    },
    update_status: async (_id: string, status: T.Schema.Order.OrderStatuses) => {
      try {
        Untrusive.start();
        const response = await this.store.api.order_update_status({ _id, status });
        if (response.outcome) {
          UI.notification.error(response.message);
        } else {
          this.store.updateOrderComplete(response.order);
          UI.notification.success('Order status updated');
          this.store.notifications.mark_read_object('order', _id);
        }
      } catch (e) {
        logger.captureException(e);
        UI.notification.error('Error updating order status, try again');
      } finally {
        Untrusive.stop();
      }
    },
    update_ready_time: async (_id: string, add_minutes: number) => {
      try {
        Untrusive.start();
        const response = await this.store.api.order_update_ready_time({ _id, add_minutes });
        if (response.outcome) {
          UI.notification.error(response.message);
        } else {
          this.store.updateOrderComplete(response.order);
        }
      } catch (e) {
        logger.captureException(e);
        UI.notification.error('Error updating order, try again');
      } finally {
        Untrusive.stop();
      }
    },
    handle_new: async (_id: string) => {
      const s = this.store;
      const r = s.restaurant;
      if (!r) return;
      try {
        const response = await this.store.api.order_find({ _id });
        if (response.outcome) {
          logger.captureWarning(`Handle new order, response error: ${response.message}`);
        } else {
          const tz = r.settings.region.timezone;
          const order = response.item;

          runInAction(() => {
            if (s.ordersView.layout === 0) {
              const newListId = OrderUtils.getOrderManagementStatus(order, tz);
              if (this.store.ordersBoard.lists[newListId]) {
                this.store.ordersBoard.lists[newListId]?.items.push(order);
                // @ts-ignore
                this.store.ordersBoard.lists[newListId].items = this.store.ordersBoard.lists[newListId].items
                  .slice()
                  .sort(OrderUtils.sortFunctionByStatus(newListId, tz));
              }
            } else if (s.ordersView.layout === 1 && s.orders.page === 1) {
              this.store.orders.items.unshift(order);
              this.store.orders.count += 1;
            }
          });
        }
      } catch (e) {
        logger.captureException(e);
      }
    },
    handle_update: async (_id: string) => {
      const s = this.store;
      const r = s.restaurant;
      if (!r) return;
      try {
        const response = await this.store.api.order_find({ _id });
        if (response.outcome) {
          logger.captureWarning(`Handle new order, response error: ${response.message}`);
        } else {
          const order = response.item;
          this.store.updateOrderComplete(order);
        }
      } catch (e) {
        logger.captureException(e);
      }
    },
  };

  booking = {
    update_status: async (_id: string, status: T.Schema.Booking.BookingStatuses) => {
      try {
        Untrusive.start();
        const response = await this.store.api.booking_update_status({ _id, status });
        if (response.outcome) {
          UI.notification.error(response.message);
        } else {
          this.store.updateBookingComplete(response.booking);
        }
      } catch (e) {
        logger.captureException(e);
        UI.notification.error('Error updating booking status, try again');
      } finally {
        Untrusive.stop();
      }
    },
    handle_new_booking: async (_id: string) => {
      try {
        const response = await this.store.api.booking_find({ _id });
        if (response.outcome) {
          logger.captureWarning(`Handle new booking, response error: ${response.message}`);
        } else {
          const { item } = response;
          const items = [...this.store.bookings.items];
          items.unshift(item);
          this.store.updateBookings({
            items,
            count: this.store.bookings.count + 1,
          });
        }
      } catch (e) {
        logger.captureException(e);
      }
    },
  };

  user = {
    update_profile: async (data: Partial<T.Schema.User.UserProfile>) => {
      const response = await this.store.api.user_profile_update({ profile: data });
      if (response.outcome) {
        throw new Error(response.message);
      }
      runInAction(() => {
        if (this.store.auth.item) {
          this.store.auth.item.profile = response.profile;
        }
      });
    },
  };

  // Payments
  load_stripe(publishable_key: string) {
    const stripeTimer = setInterval(() => {
      try {
        if (window.Stripe && !window.stripe) {
          // @ts-ignore
          window.stripe = window.Stripe(publishable_key);
          window.stripeElements = window.stripe!.elements({ locale: 'en' });
          clearInterval(stripeTimer);
        }
      } catch (e) {
        logger.captureException(e);
      }
    }, 100);
  }

  // HELPERS
  get_auth_token = () => {
    const { query } = this.store.router.s;
    const auth_token_param = query.authToken;
    const auth_token_stored = this.get_auth_token_storage();

    // TOKEN IN URL PARAM
    if (auth_token_param) this.store.router.push('/'); // REMOVE THE TOKEN FROM THE URL

    // RETURN TOKEN IF AVAILABLE
    if (auth_token_param || auth_token_stored) {
      return auth_token_param || auth_token_stored;
    }

    return null;
  };

  set_auth_token_storage = (token: string) => {
    localStorage.set(constants.storage_keys.auth_token, token);
  };

  get_auth_token_storage = () => localStorage.get(constants.storage_keys.auth_token);

  // ANALYTICS
  track = (event: string, meta?: T.ObjectAny, exclude?: { amplitude?: boolean; intercom?: boolean }) => {
    try {
      const user = this.store.auth.item;
      if (!exclude || !exclude.amplitude) {
        amplitude.getInstance().logEvent(event, meta);
      }
      if ((!exclude || !exclude.intercom) && user && user.type !== 'staff') {
        window.Intercom('trackEvent', event, meta || {});
      }
    } catch (e) {
      logger.captureException(e);
    }
  };

  // TOURS
  tour_new_restaurant = () => {
    const user = this.store.auth.item;

    const { showMainUserSupport } = this.store;

    if (!showMainUserSupport) {
      return;
    }

    if (!user || !user.profile || user.profile.tour_new_restaurant) {
      return;
    }

    this.user.update_profile({ tour_new_restaurant: true }).catch(logger.captureException);

    const startTime = Date.now();

    const tour = IntroJs();

    tour.setOptions({
      doneLabel: 'Done',
      skipLabel: 'Skip',
      exitOnEsc: false,
      exitOnOverlayClick: false,
      scrollToElement: false,
      overlayOpacity: 0.25,
      disableInteraction: true,
      steps: [
        {
          intro: 'Great job on creating your restaurant, the next step is to set it up and start accepting orders',
        },
        {
          uid: '1',
          element: '#restaurant-list-item-0',
          intro:
            'Clicking on your restaurant will take you to the restaurant dashboard. There you can configure everything and manage your orders',
          position: 'bottom',
        },
        {
          uid: '1',
          element: '#restaurant-list-item-view-store-0',
          intro: 'The view store link will take you to your ordering website for this particular restaurant',
          position: 'bottom',
        },
        {
          uid: '2',
          element: '#restaurant-list-item-billing-0',
          intro: 'The billing section allows you to manage your subscription for this store',
          position: 'bottom',
        },
        {
          uid: '3',
          intro: 'Go to your restaurant dashboard to start setting things up',
        },
      ],
    });

    tour.start();

    tour.oncomplete(() => {
      this.track('Complete Tour: New Restaurant', { duration: (Date.now() - startTime) / 1000 });
    });
  };

  tour_restaurant_dashboard = () => {
    const user = this.store.auth.item;

    const { showMainUserSupport } = this.store;

    if (!showMainUserSupport) {
      return;
    }

    if (!user || !user.profile || user.profile.tour_restaurant_dashboard) {
      return;
    }

    this.user.update_profile({ tour_restaurant_dashboard: true }).catch(logger.captureException);

    const startTime = Date.now();

    const tour = IntroJs();

    tour.setOptions({
      doneLabel: 'Done',
      skipLabel: 'Skip',
      exitOnEsc: false,
      exitOnOverlayClick: false,
      scrollToElement: false,
      overlayOpacity: 0.25,
      disableInteraction: true,
      steps: [
        {
          intro:
            'Welcome to your restaurant dashboard. Here you can manage your settings, orders and bookings. This page gives you an overview of your orders and sales',
        },
        {
          uid: '1',
          element: '#nav-link-orders',
          intro: 'The orders page will allow you to manage orders in real-time',
          position: 'right',
        },
        {
          uid: '2',
          element: '#nav-link-bookings',
          intro: 'The bookings page will allow you to manage bookings in real-time',
          position: 'right',
        },
        {
          uid: '3',
          element: '#nav-link-customers',
          intro: 'The customers page will allow you to view the details of all your store customers and accounts',
          position: 'right',
        },
        {
          uid: '3',
          element: '#nav-link-menus',
          intro: 'The menus page is where you can create and manage your store menus, categories and dishes',
          position: 'right',
        },
        {
          uid: '3',
          element: '#nav-link-settings',
          intro: 'The settings page is where you can configure your services, payments, business and design',
          position: 'right',
        },
        {
          uid: '3',
          element: '#nav-link-view-store',
          intro: 'View your online store at any time by pressing this button',
          position: 'right',
        },
        {
          uid: '3',
          element: '#nav-link-documentation',
          intro: 'See our documentation if you need assistance. It alo has a complete guide for setting everything up',
          position: 'right',
        },
        {
          uid: '1',
          element: '#top-nav-notifications',
          intro:
            'Click the notifications icon to view alerts on new orders and bookings. A green connection indicator confirms that you will receive real-time updates',
          position: 'bottom-right',
        },
        {
          intro:
            'Start by creating your menus and configuring your settings. Use the getting started guide in the documentation to assists you. Contact us if you require any help',
        },
      ],
    });

    tour.start();

    tour.oncomplete(() => {
      this.track('Complete Tour: Restaurant Dashboard', { duration: (Date.now() - startTime) / 1000 });
    });
  };
}
