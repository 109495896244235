import React from 'react';
import { FastField } from 'formik';
import { FormGroup, Button, RotateLoader, Input, LinkTag } from '@lib/components';
import { inject, observer } from 'mobx-react';
import { UI } from '../../../../../../../core/ui';
import { MobxComponent } from '../../../../../../../mobx/components/index';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';

interface Props {
  close?: () => void;
}
interface State {}

type FormValues = T.Schema.Restaurant.RestaurantSchema['settings']['integrations'];

export const SettingsFormIntegrationFacebookPixel = inject('store')(
  observer(
    class SettingsFormIntegrationFacebookPixel extends MobxComponent<Props, State> {
      initialValues: FormValues;

      constructor(props: Props) {
        super(props);
        this.state = {};
        const r = this.injected.store.restaurant!;
        this.initialValues = r.settings.integrations || {};
      }

      render() {
        return (
          <RestaurantForm<FormValues>
            submit={async (r, values) => {
              r.settings.integrations = values;
              const update = { $set: { 'settings.integrations': r.settings.integrations } };
              return { r, update };
            }}
            validators=
            initialValues={this.initialValues}
            onSuccess={() => {
              if (this.props.close) {
                this.props.close();
              }
            }}
            onError={() => UI.notification.error('An error occurred')}
            onSuccessMessage="Settings Updated"
            onErrorMessage=""
          >
            {({ form, error }) => {
              const { isSubmitting } = form;
              return (
                <div className="p-4">
                  <FormGroup>
                    <p className="lhp m-b-2">
                      Facebook pixel is a great way to measure your ROI on Facebook advertising. The pixel will track
                      the following events:
                    </p>
                    <ul>
                      <li>Add To Cart</li>
                      <li>Complete Registration</li>
                      <li>Initiate Checkout</li>
                      <li>Page View</li>
                      <li>Purchase</li>
                      <li>Schedule (Table Booking)</li>
                      <li>View Content</li>
                    </ul>
                  </FormGroup>

                  <FastField
                    name="facebook_pixel.pixel_id"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Pixel ID"
                        help={
                          <span>
                            Your unique pixel ID. Follow these{' '}
                            <LinkTag href="https://www.facebook.com/business/help/952192354843755" target="_blank">
                              instructions
                            </LinkTag>{' '}
                            to create a pixel
                          </span>
                        }
                      >
                        <Input type="text" {...field} value={field.value || ''} />
                      </FormGroup>
                    )}
                  />

                  {error && <FormGroup error={error} />}

                  <Button full color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting && <RotateLoader size={2} color="white" />}
                    {!isSubmitting && 'Save'}
                  </Button>
                </div>
              );
            }}
          </RestaurantForm>
        );
      }
    },
  ),
);
