import React from 'react';
import { FastField } from 'formik';
import {
  FormGroup,
  Button,
  RotateLoader,
  FieldSubdomain,
  FieldDomain,
  TabSelect,
  BlockError,
  BlockSuccess,
  LinkTag,
} from '@lib/components';
import { inject, observer } from 'mobx-react';
import { logger } from '@lib/common';
import cloneDeep from 'lodash/cloneDeep';
import { MobxComponent } from '../../../../../../mobx/components/index';
import { WebsiteForm } from '../../../../../../mobx/components/website-form';
import { UI } from '../../../../../../core/ui';

interface Props {}

interface FormValuesSubdomain {
  subdomain: string;
}

interface FormValuesDomain {
  domain: string | null;
}

interface State {
  tab: string;
  certExists: boolean;
  certLoading: boolean;
  certError: boolean;
}

export const WebsiteFormDomain = inject('store')(
  observer(
    class WebsiteFormDomain extends MobxComponent<Props, State> {
      initialValuesSubdomain: FormValuesSubdomain;

      initialValuesDomain: FormValuesDomain;

      constructor(props: Props) {
        super(props);
        const w = this.injected.store.website!;
        this.initialValuesSubdomain = {
          subdomain: w.subdomain,
        };
        this.initialValuesDomain = {
          domain: w.domain,
        };
        this.state = {
          tab: '0',
          certExists: false,
          certLoading: false,
          certError: false,
        };
      }

      checkCertificate = async () => {
        try {
          const { store } = this.injected;
          const w = store.website!;

          if (!w.domain) return;

          this.setState({
            certExists: false,
            certLoading: true,
            certError: false,
          });

          const response = await store.api.proxy_domain_cert_check({
            domain: w.domain,
          });

          if (response.outcome) {
            UI.notification.error(response.message, { timeout: 6000 });
            this.setState({
              certExists: false,
              certLoading: false,
              certError: true,
            });
          } else {
            this.setState({
              certExists: response.exists,
              certLoading: false,
              certError: false,
            });
          }
        } catch (e) {
          logger.captureException(e);
          UI.notification.error('Something went wrong, loading your domain status');
          this.setState({
            certExists: false,
            certLoading: false,
            certError: true,
          });
        }
      };

      issueCertificate = async () => {
        try {
          const { store } = this.injected;
          const w = store.website!;

          if (!w.domain) return;

          this.setState({
            certExists: false,
            certLoading: true,
            certError: false,
          });

          const response = await store.api.proxy_domain_cert_issue({
            restaurant_id: '',
            website_id: w._id,
          });

          if (response.outcome) {
            UI.notification.error(response.message, { timeout: 9000 });
            this.setState({
              certExists: false,
              certLoading: false,
              certError: false,
            });
          } else {
            this.setState({
              certExists: true,
              certLoading: false,
              certError: false,
            });
          }
        } catch (e) {
          logger.captureException(e);
          UI.notification.error('Something went wrong, try again or contact us');
          this.setState({
            certExists: false,
            certLoading: false,
            certError: true,
          });
        }
      };

      validate(subdomain: string) {
        if (
          !subdomain.match(/^[A-Za-z0-9](?:[A-Za-z0-9-]{2,61}[A-Za-z0-9])?$/) ||
          subdomain.length <= 3 ||
          subdomain.indexOf('.') !== -1
        ) {
          return false;
        }
        return true;
      }

      render() {
        const { tab, certError, certExists, certLoading } = this.state;

        const { store } = this.injected;
        const reseller = this.injected.store.reseller!;
        const website = this.injected.store.website!;

        return (
          <div>
            <TabSelect
              id="notifications-tab-options"
              className="border-white-tb-10"
              hasBorder
              screenWidth={this.injected.store.view.screen_width}
              onChange={v => {
                this.setState({ tab: v.value });
                if (v.value === '1') {
                  this.checkCertificate();
                } else {
                  this.initialValuesSubdomain = {
                    subdomain: website.subdomain,
                  };
                }
              }}
              value={tab}
              values={[
                { label: 'Sub-domain', value: '0' },
                { label: 'Custom Domain', value: '1' },
              ]}
            />

            {tab === '0' && (
              <WebsiteForm<FormValuesSubdomain>
                customSubmit={async (values, form) => {
                  try {
                    form.setSubmitting(true);
                    const { subdomain } = values;
                    const check_subdomain = this.validate(subdomain);
                    if (check_subdomain) {
                      const response = await store.api.proxy_subdomain_update({
                        subdomain: subdomain.toLowerCase(),
                        restaurant_id: '',
                        website_id: website._id,
                      });
                      if (response.outcome) {
                        UI.notification.error(response.message, { timeout: 6000 });
                      } else {
                        UI.notification.success('Sub-domain updated');
                        const w = cloneDeep(website);
                        w.subdomain = subdomain.toLowerCase();
                        store.setWebsite(w);
                      }
                    } else {
                      UI.notification.error('Invalid sub-domain name, try again !');
                    }
                  } catch (e) {
                    logger.captureException(e);
                    UI.notification.error('Something went wrong, try again or contact us');
                  } finally {
                    form.setSubmitting(false);
                  }
                }}
                validators=
                initialValues={this.initialValuesSubdomain}
                onSuccess={() => {}}
                onError={() => UI.notification.error('An error occurred')}
                onSuccessMessage="Settings Updated"
                onErrorMessage=""
              >
                {({ form, error }) => {
                  const { isSubmitting, setFieldValue, setFieldError } = form;
                  return (
                    <div className="p-4">
                      <FastField
                        name="subdomain"
                        render={({ field }: any) => (
                          <FormGroup
                            title="Sub-domain"
                            help="Enter the sub-domain name where your website will be located"
                            error={form.errors.subdomain}
                          >
                            <FieldSubdomain
                              restaurant_id=""
                              website_id={website._id}
                              base_domain={reseller.store_host}
                              value={field.value}
                              checkDomain={this.injected.store.api.proxy_subdomain_check}
                              onChange={subdomain => setFieldValue('subdomain', subdomain, false)}
                              onCheckCallback={e => setFieldError('subdomain', e)}
                            />
                          </FormGroup>
                        )}
                      />

                      {error && <FormGroup error={error} />}

                      <Button full color="primary" type="submit" disabled={form.errors.subdomain !== ''}>
                        {isSubmitting && <RotateLoader size={2} color="white" />}
                        {!isSubmitting && 'Save'}
                      </Button>
                    </div>
                  );
                }}
              </WebsiteForm>
            )}

            {tab === '1' && (
              <WebsiteForm<FormValuesDomain>
                customSubmit={async (values, form) => {
                  try {
                    form.setSubmitting(true);
                    const { domain } = values;
                    const response = await store.api.proxy_domain_update({
                      restaurant_id: '',
                      website_id: website._id,
                      domain: domain || null,
                    });
                    if (response.outcome) {
                      UI.notification.error(response.message, { timeout: 6000 });
                    } else {
                      UI.notification.success('Domain updated');
                      const w = cloneDeep(website);
                      w.domain = domain || null;
                      store.setWebsite(w);
                      this.setState({
                        certLoading: false,
                        certExists: response.cert_exists,
                        certError: false,
                      });
                    }
                  } catch (e) {
                    logger.captureException(e);
                    UI.notification.error('Something went wrong, try again or contact us');
                  } finally {
                    form.setSubmitting(false);
                  }
                }}
                validators=
                initialValues={this.initialValuesDomain}
                onSuccess={() => {}}
                onError={() => UI.notification.error('An error occurred')}
                onSuccessMessage="Settings Updated"
                onErrorMessage=""
              >
                {({ form, error }) => {
                  const { isSubmitting, setFieldValue, setFieldError } = form;
                  return (
                    <div className="p-4">
                      <FormGroup title="How It Works">
                        <p className="lhp">
                          Start by entering your domain name below and save the form. To connect your custom domain name
                          to your website, you will need to add an "A record" to your domains DNS settings. The "A
                          record" should match your chosen domain name and have a value of "35.238.2.132". If you need
                          help with this step, please contact us.
                        </p>
                        <p className="lhp m-t-2">
                          Once the domain record is added, give it a few minutes to take effect and press the "Generate
                          SSL Certificate" button below. If it fails, check your DNS record and allow more time for it
                          to take effect, then try again
                        </p>
                      </FormGroup>

                      <FastField
                        name="domain"
                        render={({ field }: any) => (
                          <FormGroup
                            title="Custom Domain"
                            help="Enter the sub-domain name where your website be located. This can be changed any time and you can set a custom domain later"
                            error={form.errors.domain}
                          >
                            <FieldDomain
                              website_id={website._id}
                              restaurant_id=""
                              value={field.value}
                              check={this.injected.store.api.proxy_domain_check}
                              onChange={value => setFieldValue('domain', value, false)}
                              onError={e => setFieldError('domain', e)}
                            />
                          </FormGroup>
                        )}
                      />

                      {website.domain && (
                        <FormGroup title="Domain Status">
                          {certError && (
                            <p>
                              Failed to load domain status, <LinkTag onClick={this.checkCertificate}>try again</LinkTag>
                            </p>
                          )}
                          {!certError && certLoading && <RotateLoader size={2} />}
                          {!certError && !certLoading && (
                            <div>
                              {!certExists && (
                                <div>
                                  <BlockError
                                    text="No SSL certificate found, your site won't work till you generate one"
                                    className="inline-block"
                                  />
                                  <div />
                                  <Button
                                    size="xs"
                                    color="primary-inverse"
                                    type="button"
                                    className="m-t-3"
                                    onClick={this.issueCertificate}
                                  >
                                    Generate SSL Certificate
                                  </Button>
                                </div>
                              )}
                              {certExists && (
                                <BlockSuccess
                                  text="SSL certificate generated, your domain is live"
                                  className="inline-block"
                                />
                              )}
                            </div>
                          )}
                        </FormGroup>
                      )}

                      {error && <FormGroup error={error} />}

                      <Button full color="primary" type="submit" disabled={isSubmitting}>
                        {isSubmitting && <RotateLoader size={2} color="white" />}
                        {!isSubmitting && 'Save'}
                      </Button>
                    </div>
                  );
                }}
              </WebsiteForm>
            )}
          </div>
        );
      }
    },
  ),
);
