// @ts-nocheck

import React from 'react';
import { BoxLoader } from '@lib/components';
import { inject, observer } from 'mobx-react';
import { AuthLayoutComponents } from './layout.c';
import { MobxComponent } from '../../../mobx/components/index';

interface Props {
  loading: boolean;
  children: React.ReactNode;
}

export const AuthLayout = inject('store')(
  observer(
    class AuthLayout extends MobxComponent<Props, {}> {
      componentDidMount() {
        if (this.injected.store.auth.token) {
          this.injected.store.router.push('/');
        }
      }

      render() {
        const { children, loading } = this.props;
        const reseller = this.injected.store.reseller!;
        const { logo_auth } = reseller.admin;
        const backgroundImage = reseller?.admin.images?.background;
        const backgroundColorTint = reseller?.admin.colors?.backgroundTint;
        const loginBoxStyle = reseller?.admin.login_box_style ?? 'classic';
        return (
          <AuthLayoutComponents.Wrapper bg_color={backgroundColorTint} bg_img={backgroundImage}>
            <AuthLayoutComponents.Main>
              <AuthLayoutComponents.BoxWrapper boxStyle={loginBoxStyle}>
                <AuthLayoutComponents.Content>
                  <AuthLayoutComponents.Logo>
                    <img src={logo_auth} className="res-img" />
                  </AuthLayoutComponents.Logo>
                  <AuthLayoutComponents.ContentBox boxStyle={loginBoxStyle}>
                    <BoxLoader active={loading} boxStyle={loginBoxStyle} />
                    {children}
                  </AuthLayoutComponents.ContentBox>
                </AuthLayoutComponents.Content>
              </AuthLayoutComponents.BoxWrapper>
            </AuthLayoutComponents.Main>
          </AuthLayoutComponents.Wrapper>
        );
      }
    },
  ),
);
