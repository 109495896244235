/* eslint-disable @typescript-eslint/unbound-method */
import React from 'react';
import { RestaurantUtils, defaultCardConnectSettings } from '@lib/common';
import { RootStore } from '../../../../../../../mobx/store';

import { SettingsFormPaymentsCash } from '../../forms/payments/cash';
import { SettingsFormPaymentsCard } from '../../forms/payments/card';
import { SettingsFormPaymentsStripe } from '../../forms/payments/stripe';
import { SettingsFormPaymentsPayPal } from '../../forms/payments/paypal';
import { SettingsFormPaymentsCardConnect } from '../../forms/payments/cardconnect';
import { SettingsFormPaymentsPoliPay } from '../../forms/payments/polipay';
import { SettingsFormPaymentsPaygate } from '../../forms/payments/paygate';
import { SettingsFormPaymentsIpay88 } from '../../forms/payments/ipay88';
import { SettingsFormPaymentsBamboraAPAC } from '../../forms/payments/bambora_apac';
import { SettingsFormPaymentsPesapal } from '../../forms/payments/pesapal';
import { SettingsFormPaymentsAuthorized } from '../../forms/payments/authorized';
import { SettingsFormPaymentsATHMovil } from '../../forms/payments/ath_movil';
import { SettingsFormPaymentsCheckout } from '../../forms/payments/checkout';
import { SettingsFormPaymentsGravity } from '../../forms/payments/gravity';
import { SettingsFormPaymentsBamboraNa } from '../../forms/payments/bambora_na';
import { SettingsFormPaymentsElavon } from '../../forms/payments/elavon';
import { SettingsFormPaymentsCheckoutApplePay } from '../../forms/payments/checkout_apple_pay';
import { SettingsFormPaymentsCheckoutGooglePay } from '../../forms/payments/checkout_google_pay';
import { SettingsFormPaymentsStripeDigitalWallet } from '../../forms/payments/stripe_digital_wallet';
import { SettingsFormPaymentsRedDot } from '../../forms/payments/red_dot';
import { SettingsFormPaymentsRazorPay } from '../../forms/payments/razor_pay';
import { SettingsFormPaymentsGkash } from '../../forms/payments/gkash';
import { SettingsFormPaymentsSGE } from '../../forms/payments/sgepay';
import { SettingsFormPaymentsPayWay } from '../../forms/payments/payway';

export const PAYMENT_VALIDATORS = {
  cash: RestaurantUtils.settings.paymentActiveCash,
  card: RestaurantUtils.settings.paymentActiveCard,
  stripe: RestaurantUtils.settings.paymentActiveStripe,
  paypal: RestaurantUtils.settings.paymentActivePaypal,
  cardconnect: RestaurantUtils.settings.paymentActiveCardConnect,
  paygate_payweb: RestaurantUtils.settings.paymentActivePaygatePayweb,
  poli_pay: RestaurantUtils.settings.paymentActivePoliPay,
  ipay88: RestaurantUtils.settings.paymentActiveIpay88,
  bambora_apac: RestaurantUtils.settings.paymentActiveBamboraAPAC,
  bambora_na: RestaurantUtils.settings.paymentActiveBamboraNA,
  pesapal: RestaurantUtils.settings.paymentActivePesapal,
  ath_movil: RestaurantUtils.settings.paymentActiveATHMovil,
  checkout: RestaurantUtils.settings.paymentActiveCheckout,
  checkout_apple_pay: RestaurantUtils.settings.paymentActiveCheckoutApplePay,
  checkout_google_pay: RestaurantUtils.settings.paymentActiveCheckoutGooglePay,
  stripe_digital_wallet: RestaurantUtils.settings.paymentActiveStripeDigitalWallet,
  razor_pay: RestaurantUtils.settings.paymentActiveRazorPay,
  gravity: RestaurantUtils.settings.paymentActiveGravity,
  authorized: RestaurantUtils.settings.paymentActiveAuthorized,
  elavon: RestaurantUtils.settings.paymentActiveElavon,
  red_dot: RestaurantUtils.settings.paymentActiveRedDot,
  gkash: RestaurantUtils.settings.paymentActiveGkash,
  sgepay: RestaurantUtils.settings.paymentActiveSGE,
  payway: RestaurantUtils.settings.paymentActivePayWay,
};

export const PAYMENT_FORMS = {
  cash: <SettingsFormPaymentsCash />,
  card: <SettingsFormPaymentsCard />,
  stripe: <SettingsFormPaymentsStripe />,
  paypal: <SettingsFormPaymentsPayPal />,
  cardconnect: <SettingsFormPaymentsCardConnect />,
  paygate_payweb: <SettingsFormPaymentsPaygate />,
  poli_pay: <SettingsFormPaymentsPoliPay />,
  ipay88: <SettingsFormPaymentsIpay88 />,
  bambora_apac: <SettingsFormPaymentsBamboraAPAC />,
  pesapal: <SettingsFormPaymentsPesapal />,
  ath_movil: <SettingsFormPaymentsATHMovil />,
  checkout: <SettingsFormPaymentsCheckout />,
  checkout_apple_pay: <SettingsFormPaymentsCheckoutApplePay />,
  checkout_google_pay: <SettingsFormPaymentsCheckoutGooglePay />,
  stripe_digital_wallet: <SettingsFormPaymentsStripeDigitalWallet />,
  razor_pay: <SettingsFormPaymentsRazorPay />,
  gravity: <SettingsFormPaymentsGravity />,
  authorized: <SettingsFormPaymentsAuthorized />,
  bambora_na: <SettingsFormPaymentsBamboraNa />,
  elavon: <SettingsFormPaymentsElavon />,
  red_dot: <SettingsFormPaymentsRedDot />,
  gkash: <SettingsFormPaymentsGkash />,
  sgepay: <SettingsFormPaymentsSGE />,
  payway: <SettingsFormPaymentsPayWay />,
};

export async function initPaymentData(type: string, store: RootStore) {
  if (type === 'cash' || type === 'card' || type === 'custom') {
    return {
      enabled: false,
      services: [],
      label: type === 'custom' ? 'Custom Payment Method' : '',
    } as T.Schema.Restaurant.Payments.RestaurantPayment;
  }

  if (type === 'stripe') {
    return {
      enabled: false,
      label: '',
      services: [],
      currency: '',
      secret_key: '',
      publishable_key: '',
      min_order: 0,
    } as T.Schema.Restaurant.Payments.RestaurantPaymentStripe;
  }

  if (type === 'paypal') {
    return {
      enabled: false,
      label: '',
      services: [],
      currency: '',
      client_id: '',
      secret: '',
    } as T.Schema.Restaurant.Payments.RestaurantPaymentPayPal;
  }

  if (type === 'cardconnect') {
    return defaultCardConnectSettings();
  }

  if (type === 'paygate_payweb') {
    return {
      enabled: false,
      label: '',
      services: [],
      currency: '',
      secret: '',
      paygate_id: '',
    } as T.Schema.Restaurant.Payments.RestaurantPaymentPaygatePayweb;
  }

  if (type === 'poli_pay') {
    return {
      enabled: false,
      label: '',
      services: [],
      currency: '',
      merchant_id: '',
      auth_code: '',
    } as T.Schema.Restaurant.Payments.RestaurantPaymentPoliPay;
  }

  if (type === 'ipay88') {
    return {
      enabled: false,
      label: '',
      services: [],
      currency: '',
      merchant_code: '',
      merchant_key: '',
    } as T.Schema.Restaurant.Payments.RestaurantPaymentIpay88;
  }

  if (type === 'bambora_apac') {
    return {
      enabled: false,
      testing: false,
      label: '',
      services: [],
      currency: '',
      merchant_id: '',
      api_username: '',
      api_password: '',
    } as T.Schema.Restaurant.Payments.RestaurantPaymentBamboraAPAC;
  }

  if (type === 'pesapal') {
    return {
      enabled: false,
      label: 'Pesapal',
      services: [],
      testing: false,
      consumer_key: '',
      consumer_secret: '',
      currency: '',
    } as T.Schema.Restaurant.Payments.RestaurantPaymentPesapal;
  }

  if (type === 'ath_movil') {
    return {
      enabled: false,
      env: '',
      label: 'ATH Móvil',
      public_token: '',
      private_token: '',
      theme: 'btn',
      lang_code: '',
      timeout: 0,
    } as T.Schema.Restaurant.Payments.RestaurantPaymentATHMovil;
  }

  if (type === 'checkout') {
    return {
      enabled: false,
      label: 'Credit Card',
      services: [],
      currency: '',
      checkout_public_key: '',
      checkout_secret_key: '',
    } as T.Schema.Restaurant.Payments.RestaurantPaymentCheckout;
  }

  if (type === 'checkout_apple_pay') {
    return {
      enabled: false,
      label: 'Apple Pay (Checkout.com)',
      services: [],
      currency: '',
      checkout_public_key: '',
      checkout_secret_key: '',
      apple_pay_merchant_id: '',
      apple_pay_merchant_domain: '',
      apple_pay_merchant_identity_cert: '',
      apple_pay_merchant_identity_key: '',
    } as T.Schema.Restaurant.Payments.RestaurantPaymentCheckoutApplePay;
  }

  if (type === 'checkout_google_pay') {
    return {
      enabled: false,
      label: 'Google Pay (Checkout.com)',
      services: [],
      currency: '',
      checkout_public_key: '',
      checkout_secret_key: '',
    } as T.Schema.Restaurant.Payments.RestaurantPaymentCheckoutGooglePay;
  }

  if (type === 'stripe_digital_wallet') {
    let stripeDigitalPaySettings;
    const restaurantId = store.restaurant?._id!;
    if (restaurantId) {
      const response = await store.api.dashboard_restaurant_payment_prefill_stripedigitalpay({
        restaurant_id: restaurantId,
      });
      if (!response.outcome) {
        stripeDigitalPaySettings = response.data as T.Schema.Restaurant.Payments.RestaurantPaymentStripeDigitalWallet;
      }
    }

    if (stripeDigitalPaySettings) {
      stripeDigitalPaySettings.enabled = false;
      stripeDigitalPaySettings.label = 'Apple Pay | Google Pay (Stripe)';
      return stripeDigitalPaySettings;
    }

    return {
      enabled: false,
      label: 'Apple Pay | Google Pay (Stripe)',
      services: [],
      currency: '',
      stripe_public_key: '',
      stripe_secret_key: '',
      stripe_domain_association: '',
    } as T.Schema.Restaurant.Payments.RestaurantPaymentStripeDigitalWallet;
  }

  if (type === 'razor_pay') {
    return {
      enabled: false,
      label: 'Razor Pay',
      services: [],
      currency: '',
      razor_pay_public_key: '',
      razor_pay_secret_key: '',
    } as T.Schema.Restaurant.Payments.RestaurantPaymentRazorPay;
  }

  if (type === 'gravity') {
    return {
      enabled: false,
      label: 'Credit Card',
      services: [],
      gravity_oid: '',
      gravity_auth_key: '',
      gravity_environment: 'https://api.emergepay.chargeitpro.com/virtualterminal/v1',
      gravity_assets: 'https://assets.emergepay.chargeitpro.com',
    } as T.Schema.Restaurant.Payments.RestaurantPaymentGravity;
  }

  if (type === 'authorized') {
    return {
      enabled: false,
      label: 'Credit Card',
      services: [],
      authorized_login_id: '',
      authorized_transaction_id: '',
    } as T.Schema.Restaurant.Payments.RestaurantPaymentAuthorized;
  }

  if (type === 'bambora_na') {
    return {
      enabled: false,
      label: 'Bambora North America',
      services: [],
      merchant_id: '',
      passcode: '',
    } as T.Schema.Restaurant.Payments.RestaurantPaymentBamboraNA;
  }

  if (type === 'elavon') {
    return {
      enabled: false,
      label: 'Elavon',
      services: [],
      ssl_merchant_id: '',
      ssl_user_id: '',
      ssl_pin: '',
    } as T.Schema.Restaurant.Payments.RestaurantPaymentElavon;
  }

  if (type === 'red_dot') {
    return {
      enabled: false,
      label: 'Red Dot Payment',
      services: [],
      testing: false,
      merchant_id: '',
      secret_key: '',
    } as T.Schema.Restaurant.Payments.RestaurantPaymentRedDot;
  }

  if (type === 'gkash') {
    return {
      enabled: false,
      label: 'Gkash',
      services: [],
      testing: false,
      merchant_id: '',
      signature_key: '',
    } as T.Schema.Restaurant.Payments.RestaurantPaymentGkash;
  }

  if (type === 'sgepay') {
    return {
      enabled: false,
      label: 'Credit Card',
      services: [],
      testing: false,
      Mid: '',
      RequestId: '',
    } as T.Schema.Restaurant.Payments.RestaurantPaymentSGE;
  }

  if (type === 'payway') {
    return {
      enabled: false,
      label: 'ABA Pay',
      services: [],
      testing: false,
      merchant_id: '',
      api_key: '',
    } as T.Schema.Restaurant.Payments.RestaurantPaymentPayway;
  }

  throw new Error(`Unknown payment type: ${type}`);
}
