import React from 'react';
import { inject, observer } from 'mobx-react';
import { RotateLoader, Button, FieldSubdomain, FormGroup } from '@lib/components';
import { logger } from '@lib/common';
import { MobxComponent } from '../../../../../mobx/components';
import { UI } from '../../../../../core/ui';
import { SettingsSection } from '../../../restaurant/views/settings/layout/section';
import { SettingsSections } from '../../../restaurant/views/settings/layout/sections';
import { SettingsSectionBlock } from '../../../restaurant/views/settings/layout/block';
import { WebsiteFormDomain } from './settings/domain';
import { WebsiteFormFonts } from './settings/fonts';
import { WebsiteFormColors } from './settings/colors';
import { WebsiteFormFavicon } from './settings/favicon';
import { WebsiteFormSEO } from './settings/seo';
import { WebsiteFormTopNav } from './sections/top_nav';
import { WebsiteFormHeader } from './sections/header';
import { WebsiteFormAboutUs } from './sections/about_us';
import { WebsiteFormGallery } from './sections/galley';
import { WebsiteFormLocations } from './sections/locations';
import { WebsiteFormContactForm } from './sections/contact_form';
import { WebsiteFormFooter } from './sections/footer';
import { WebsiteFormInjections } from './sections/injections';

interface Props {}
interface State {
  active: string;
  create_subdomain_value: string;
  create_subdomain_error: string;
  create_loading: boolean;
  loading: boolean;
  error: boolean;
}

export const DashboardWebsite = inject('store')(
  observer(
    class DashboardWebsite extends MobxComponent<Props, State> {
      constructor(props: Props) {
        super(props);
        this.state = {
          active: '',
          create_subdomain_value: '',
          create_subdomain_error: '',
          create_loading: false,
          loading: true,
          error: false,
        };
      }

      componentDidMount(): void {
        this.website_find();
      }

      setActive = (active: string) => {
        if (this.state.active === active) this.setState({ active: '' });
        else this.setState({ active });
      };

      website_find = async () => {
        const s = this.injected.store;
        try {
          const res = await s.api.website_find({});
          if (res.outcome) {
            UI.notification.error(res.message, { timeout: 6000 });
          } else {
            s.setWebsite(res.website);
          }
          this.setState({ loading: false });
        } catch (e) {
          logger.captureException(e);
          UI.notification.error('Something went wrong loading your website, try refresh the page', { timeout: 8000 });
        }
      };

      website_create = async () => {
        const s = this.injected.store;
        const { create_subdomain_value } = this.state;
        if (!create_subdomain_value) {
          UI.notification.error('Enter a valid subdomain');
          return;
        }
        try {
          this.setState({ create_loading: true });
          const res = await s.api.website_create({
            subdomain: this.state.create_subdomain_value,
          });
          if (res.outcome) {
            UI.notification.error(res.message, { timeout: 6000 });
          } else {
            s.setWebsite(res.website);
          }
        } catch (e) {
          logger.captureException(e);
          UI.notification.error('Something went wrong, try again or contact us', { timeout: 6000 });
        } finally {
          this.setState({ create_loading: false });
        }
      };

      loading = () => (
        <div className="m-t-12">
          <RotateLoader size={3} />
        </div>
      );

      start = () => {
        const { reseller, api } = this.injected.store;
        const { create_subdomain_error, create_subdomain_value, create_loading } = this.state;
        return (
          <div className="m-t-12">
            <h1 className="m-b-4">Create A Landing Page Website</h1>

            <p className="bigger m-b-8 lhp-lg">
              A landing page website can be used either as a home page for your business or a store locator page
              connecting to all your restaurant ordering sites. This feature is completely optional but is useful if you
              need a simple website or store location selector.
            </p>

            <FormGroup
              title="Website Sub-domain Name"
              help="A custom domain name can be added after creating your website"
              error={create_subdomain_error}
              no_border
              small_title
              className=""
            >
              <FieldSubdomain
                restaurant_id=""
                website_id=""
                base_domain={reseller!.store_host}
                value={create_subdomain_value}
                checkDomain={api.proxy_subdomain_check}
                onChange={subdomain => this.setState({ create_subdomain_value: subdomain })}
                onCheckCallback={error => this.setState({ create_subdomain_error: error })}
              />
            </FormGroup>

            <div className="m-t-8">
              <Button type="button" className="width150" color="primary" round onClick={this.website_create}>
                {!create_loading && 'Create Website'}
                {create_loading && <RotateLoader size={2} />}
              </Button>
            </div>
          </div>
        );
      };

      settings = () => {
        const { active } = this.state;
        return (
          <div>
            <SettingsSections>
              <SettingsSection title="General">
                <SettingsSectionBlock
                  name="Domains"
                  active={active === 'Domains'}
                  onClick={() => this.setActive('Domains')}
                >
                  <WebsiteFormDomain />
                </SettingsSectionBlock>
                <SettingsSectionBlock name="Fonts" active={active === 'Fonts'} onClick={() => this.setActive('Fonts')}>
                  <WebsiteFormFonts />
                </SettingsSectionBlock>
                <SettingsSectionBlock
                  name="Colors"
                  active={active === 'Colors'}
                  onClick={() => this.setActive('Colors')}
                >
                  <WebsiteFormColors />
                </SettingsSectionBlock>
                <SettingsSectionBlock
                  name="Favicon"
                  active={active === 'Favicon'}
                  onClick={() => this.setActive('Favicon')}
                >
                  <WebsiteFormFavicon />
                </SettingsSectionBlock>
                <SettingsSectionBlock
                  name="Search Engine Optimization"
                  active={active === 'Search Engine Optimization'}
                  onClick={() => this.setActive('Search Engine Optimization')}
                >
                  <WebsiteFormSEO />
                </SettingsSectionBlock>
              </SettingsSection>

              <SettingsSection title="Website Sections">
                <SettingsSectionBlock
                  name="Nav Bar"
                  active={active === 'Top Navigation Bar'}
                  onClick={() => this.setActive('Top Navigation Bar')}
                >
                  <WebsiteFormTopNav />
                </SettingsSectionBlock>
                <SettingsSectionBlock
                  name="Header"
                  active={active === 'Header'}
                  onClick={() => this.setActive('Header')}
                >
                  <WebsiteFormHeader />
                </SettingsSectionBlock>
                <SettingsSectionBlock
                  name="About Us"
                  active={active === 'About Us'}
                  onClick={() => this.setActive('About Us')}
                >
                  <WebsiteFormAboutUs />
                </SettingsSectionBlock>
                <SettingsSectionBlock
                  name="Gallery"
                  active={active === 'Gallery'}
                  onClick={() => this.setActive('Gallery')}
                >
                  <WebsiteFormGallery />
                </SettingsSectionBlock>
                <SettingsSectionBlock
                  name="Locations"
                  active={active === 'Locations'}
                  onClick={() => this.setActive('Locations')}
                >
                  <WebsiteFormLocations />
                </SettingsSectionBlock>
                <SettingsSectionBlock
                  name="Contact Form"
                  active={active === 'Contact Form'}
                  onClick={() => this.setActive('Contact Form')}
                >
                  <WebsiteFormContactForm />
                </SettingsSectionBlock>
                <SettingsSectionBlock
                  name="Footer"
                  active={active === 'Footer'}
                  onClick={() => this.setActive('Footer')}
                >
                  <WebsiteFormFooter />
                </SettingsSectionBlock>
                <SettingsSectionBlock
                  name="Custom CSS & Javascript"
                  active={active === 'Custom CSS & Javascript'}
                  onClick={() => this.setActive('Custom CSS & Javascript')}
                >
                  <WebsiteFormInjections />
                </SettingsSectionBlock>
              </SettingsSection>
            </SettingsSections>
          </div>
        );
      };

      render() {
        const { store } = this.injected;
        const { reseller, website, restrictions } = store;
        const { loading } = this.state;
        return (
          <div>
            {loading && this.loading()}

            {!loading && !website && restrictions.website.create && this.start()}
            {!loading && !website && !restrictions.website.create && (
              <div className="m-t-12">
                <div className="text-center">
                  <p className="big center lhp">
                    Your account does not have the necessary permission to create a website. Contact your admin
                  </p>
                </div>
              </div>
            )}

            {!loading && website && restrictions.website.edit && (
              <div>
                <div className="flex-l-r-center">
                  <h2>Landing Page Website</h2>
                  <a
                    target="_blank"
                    href={
                      website.domain
                        ? `https://${website.domain}`
                        : `https://${website.subdomain}.${reseller!.store_host}`
                    }
                    rel="noreferrer"
                  >
                    <Button round type="button" color="primary" size="xs">
                      View Website
                    </Button>
                  </a>
                </div>
                {this.settings()}
              </div>
            )}

            {!loading && website && !restrictions.website.edit && (
              <div className="m-t-12">
                <div className="text-center">
                  <p className="big center lhp">
                    Your account does not have the necessary permission to edit the website. Contact your admin
                  </p>
                </div>
              </div>
            )}
          </div>
        );
      }
    },
  ),
);
