import React from 'react';
import { inject, observer } from 'mobx-react';
import { routes, cleanURLForMatch } from '../../../routes';
import { RootStore } from '../../../mobx/store';
import { RouteKeys } from '../../../types/routes.d';

interface Props {
  pathKey: RouteKeys | RouteKeys[];
  children: React.ReactNode;
  auth?: boolean;
}
interface InjectedProps extends Props {
  store: RootStore;
}

function Route(p: Props) {
  const { pathKey, children, auth, store } = p as InjectedProps;

  const { token } = store.auth;
  const currentPath = cleanURLForMatch(store.router.s.path) || '';

  if (auth && !token) return null;

  const render = <div className="fade fade-in active">{children}</div>;

  if (typeof pathKey === 'string') {
    const route = routes[pathKey];
    if (route.pattern.match(currentPath) !== null) {
      return render;
    }
  } else {
    // eslint-disable-next-line no-restricted-syntax
    for (const key of pathKey) {
      const route = routes[key];
      if (route.pattern.match(currentPath) !== null) {
        return render;
      }
    }
  }

  return null;
}

export default inject('store')(observer(Route));
