import React from 'react';
import { FastField } from 'formik';
import { FormGroup, Button, RotateLoader, Input, Switch } from '@lib/components';
import { inject, observer } from 'mobx-react';
import { UI } from '../../../../../../../core/ui';
import { MobxComponent } from '../../../../../../../mobx/components/index';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';

interface Props {
  close?: () => void;
}
interface State {
  generatingAbacus: boolean;
  generatedAbacus: boolean;
  disableAbacusGenerate: boolean;
  generating: boolean;
  generated: boolean;
}

type FormValues = T.Schema.Restaurant.RestaurantSchema['abacus'];

export const SettingsFormIntegrationAbacus = inject('store')(
  observer(
    class SettingsFormIntegrationAbacus extends MobxComponent<Props, State> {
      initialValues: FormValues;

      constructor(props: Props) {
        super(props);
        this.state = {
          generatingAbacus: false,
          generatedAbacus: false,
          disableAbacusGenerate: false,
          generating: false,
          generated: false,
        };
        const r = this.injected.store.restaurant!;
        this.initialValues = r.abacus || {};
      }

      generateAbacusMenu = async () => {
        this.setState(
          {
            generatingAbacus: true,
            generatedAbacus: false,
            disableAbacusGenerate: true,
          },
          async () => {
            const _id =
              this.injected.store.restaurant && this.injected.store.restaurant._id
                ? this.injected.store.restaurant._id
                : '';
            await this.injected.store.generateAbacusMenu(_id, '');
            this.setState(
              {
                generating: false,
                generated: true,
              },
              async () => {
                const res = await this.injected.store.api.restaurant({ _id });
                /// @ts-ignore
                const { restaurant } = res;
                this.injected.store.setRestaurant(restaurant);
                setTimeout(() => {
                  this.setState({
                    disableAbacusGenerate: false,
                  });
                }, 1000);
              },
            );
          },
        );
      };

      render() {
        // console.log({ disableAbacusGenerate: this.state.disableAbacusGenerate })
        return (
          <RestaurantForm<FormValues>
            submit={async (r, values) => {
              r.abacus = values;
              if (!r.abacus) {
                r.abacus = {};
              }
              r.abacus.abacus_open_item_list =
                this.injected.store.restaurant && this.injected.store.restaurant.abacus
                  ? this.injected.store.restaurant.abacus.abacus_open_item_list
                  : [];
              const update = { $set: { abacus: r.abacus } };
              return { r, update };
            }}
            validators=
            initialValues={this.initialValues}
            onSuccess={() => {
              if (this.props.close) {
                this.props.close();
              }
            }}
            onError={() => UI.notification.error('An error occurred')}
            onSuccessMessage="Settings Updated"
            onErrorMessage=""
          >
            {({ form, error }) => {
              const { isSubmitting, setFieldValue } = form;
              return (
                <div className="p-4">
                  <FastField
                    name="abacus_api_key"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Abacus API Key"
                        help="Your unique Abacus API key. This is provided under your Abacus account settings."
                      >
                        <Input type="text" {...field} value={field.value || ''} />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="abacus_status"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Enabled?"
                        help="Turning this on will enable the Abacus features in the Menus and automatic sending of orders to your POS."
                      >
                        <Switch
                          id="abacus-enabled-switch"
                          checked={field.value || false}
                          onChange={e => setFieldValue('abacus_status', e.target.checked)}
                        />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="abacus_open_items"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Open Items (Admin Use Only)"
                        help="Do not use. This will break the link to Abacus"
                      >
                        <Switch
                          id="enabled-open-items"
                          checked={field.value || false}
                          onChange={e => setFieldValue('abacus_open_items', e.target.checked)}
                        />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="abacus_kitchen_display_mode"
                    render={({ field }: any) => (
                      <FormGroup title="Kitchen Display Mode" help="Use uppercase text instead of markdown (<bold>...)">
                        <Switch
                          id="enabled-abacus_kitchen_display_mode"
                          checked={field.value || false}
                          onChange={e => setFieldValue('abacus_kitchen_display_mode', e.target.checked)}
                        />
                      </FormGroup>
                    )}
                  />

                  <FormGroup
                    title="Refresh Open Items List"
                    // help="Do not use. This will break the link to Abacus"
                  >
                    <Button
                      full={false}
                      color="primary"
                      type="button"
                      disabled={this.state.disableAbacusGenerate}
                      style={{ marginBottom: '10px' }}
                      onClick={() => {
                        this.generateAbacusMenu();
                      }}
                    >
                      {this.state.disableAbacusGenerate && <RotateLoader size={2} color="white" />}
                      {!this.state.disableAbacusGenerate && 'Refresh the list of open items'}
                    </Button>
                  </FormGroup>

                  {error && <FormGroup error={error} />}

                  <Button full color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting && <RotateLoader size={2} color="white" />}
                    {!isSubmitting && 'Save'}
                  </Button>
                </div>
              );
            }}
          </RestaurantForm>
        );
      }
    },
  ),
);
