import shortid from 'shortid';
import { nanoid } from 'nanoid';
import { GenericPromo, PromoType } from './type';

export const constructPlainPromoInstance = (type: PromoType): GenericPromo | null => {
  switch (type) {
    case 'discount':
      return {
        _id: shortid.generate(),
        name: '',
        code: '',
        fixed_discount: 0,
        percent_discount: 0,
        free_delivery: false,
        min_order: '',
        max_uses: '',
        max_amount: 0,
        auto_apply: false,
        once_per_customer: false,
        logged_in_only: false,
        valid_times: [],
        services: [],
        payment_methods: [],
        disabled: false,
        stats: {
          used: 0,
        },
      };
    case 'free_item':
      return {
        _id: nanoid(),
        name: '',
        code: '',
        type: 'free_item',
        description: '',
        notification: '',
        condition: {
          id: nanoid(),
          type: 'order_amount',
          eligible_items: {},
          tiers: conditionTierAmount,
        },
        disabled: false,
        restrictions: {
          service_type: [],
          payment_methods: [],
          order_times: [],
          once_per_customer: false,
          authenticated_user_only: false,
          available_date_range: [],
          available_times: TimeSlotValues,
          automatically_apply: false,
        },
      };
    case 'conventional_discount':
      return {
        _id: nanoid(),
        name: '',
        code: '',
        type: 'conventional_discount',
        description: '',
        notification: '',
        condition: {
          type: 'percentage',
          eligible_items: {},
          apply_to: 'specific_products',
          discount_value: 0,
          deal: '',
        },
        restrictions: {
          service_type: [],
          payment_methods: [],
          order_times: [],
          once_per_customer: false,
          authenticated_user_only: false,
          available_date_range: [],
          available_times: TimeSlotValues,
          automatically_apply: false,
        },
        disabled: false,
      };
    default:
      return null;
  }
};

const TimeSlotValues: Array<any> = [
  {
    day: 'Monday',
    open: '09:00',
    close: '21:00',
    h24: true,
  },
  {
    day: 'Tuesday',
    open: '09:00',
    close: '21:00',
    h24: true,
  },
  {
    day: 'Wednesday',
    open: '09:00',
    close: '21:00',
    h24: true,
  },
  {
    day: 'Thursday',
    open: '09:00',
    close: '21:00',
    h24: true,
  },
  {
    day: 'Friday',
    open: '09:00',
    close: '21:00',
    h24: true,
  },
  {
    day: 'Saturday',
    open: '09:00',
    close: '21:00',
    h24: true,
  },
  {
    day: 'Sunday',
    open: '09:00',
    close: '21:00',
    h24: true,
  },
];

const conditionTierAmount: Array<any> = [
  {
    id: nanoid(),
    lower_limit: 1,
    upper_limit: 20.99,
    free_items: {},
    free_quantity: 1,
  },
  {
    id: nanoid(),
    lower_limit: 21,
    upper_limit: 30.99,
    free_items: {},
    free_quantity: 2,
  },
  {
    id: nanoid(),
    lower_limit: 31,
    upper_limit: 40.99,
    free_items: {},
    free_quantity: 3,
  },
];

export const getPromoType = (promo: GenericPromo): T.Schema.Restaurant.Promo.PromoType => {
  if ('type' in promo) return promo.type;
  return 'discount'; // for backward compatibility
};

export const promosByType = (
  restaurant: T.Schema.Restaurant.RestaurantSchema,
  type: T.Schema.Restaurant.Promo.PromoType | null,
): GenericPromo[] => {
  switch (type) {
    case 'discount':
      return restaurant.promos;
    case 'free_item':
      return restaurant.free_item_promos || [];
    case 'conventional_discount':
      return restaurant.conventional_discount_promos || [];
    default:
      return [];
  }
};

export const getPaymentMethods = (
  restaurant: T.Schema.Restaurant.RestaurantSchema | null,
): Array<{ value: string; label: string }> => {
  const paymentMethods = new Array<{ value: string; label: string }>();
  if (restaurant && restaurant.settings.payments) {
    Object.keys(restaurant.settings.payments).forEach(method => {
      if (restaurant.settings.payments[method]?.label && restaurant.settings.payments[method]?.enabled) {
        paymentMethods.push({
          value: method,
          label: restaurant.settings.payments[method]?.label || '',
        });
      }
    });
  }
  return paymentMethods;
};
