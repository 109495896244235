import { styled, CS } from '@lib/components';

export const MenuListFloatingButtonWrapper = styled('div')`
  position: sticky;
  top: 180px;
  z-index: 2;
`;

export const MenuListFloatingTitle = styled('div')`
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primary_text};
  padding: 10px;
  text-align: center;
`;

export const MenuListFloatingContent = styled('div')`
  ${CS.shadow.heavy.two};
  width: 130px;
  border-radius: 5px;
  background: white;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 100px;
  right: -25px;
  > * {
    border-radius: 0;
    &:first-child {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    &:last-child {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
    top: 30px;
    right: 0;
    transform: translateX(125%);
  }
`;
