import React from 'react';
import { TopNav, RotateLoader, SideNav, SideNavLink, Tooltip } from '@lib/components';
import { observer, inject } from 'mobx-react';
import {
  FaArrowCircleLeft,
  FaPowerOff,
  FaHome,
  FaShoppingBag,
  FaCalendarAlt,
  FaUser,
  FaUtensils,
  FaCog,
  FaExternalLinkAlt,
  FaLifeRing,
  FaMoneyCheck,
} from 'react-icons/fa';
import { RestaurantLayoutContent } from './layout.c';
import { routes } from '../../../routes';
import { MobxComponent } from '../../../mobx/components/index';
import { Notifications } from './notifications/index';
import { AudioUnlock } from '../../components/AudioUnlock';

interface Props {
  children: React.ReactNode;
}
interface State {}

export const LayoutRestaurant = inject('store')(
  observer(
    class LayoutRestaurant extends MobxComponent<Props, State> {
      constructor(props: Props) {
        super(props);
        this.state = {};
        const { store } = this.injected;
        store.updateView({ sidenav_active: true });
        store.service.restaurant.get();
      }

      componentWillUnmount() {
        this.injected.store.updateView({ sidenav_active: false });
        this.injected.store.notifications.stop();
      }

      loading() {
        return (
          <div className="m-t-12">
            <RotateLoader />
          </div>
        );
      }

      sidenav() {
        const { store } = this.injected;
        const { restrictions, storeURL, showMainSupport } = store;
        const r = store.restaurant;
        const { reseller } = store;
        const pathname = store.router.s.path;

        if (!r || !reseller) {
          return <SideNav />;
        }

        // Should move to reseller setting section later
        let documentationLink = 'https://support.cloudwaitress.com';
        if (reseller._id === 'ceorestaurant') {
          documentationLink = 'https://support.ceorestaurant.my';
        }

        const shouldOnlinePaymentAllowed =
          !!r.settings.payments.stripe?.connect_account_id?.length && restrictions._.onlinePaymentEnabled;

        const links = [
          {
            id: 'nav-link-home',
            tip: 'Home',
            icon: <FaHome />,
            active: !!routes.restaurant_dashboard.pattern.match(pathname),
            onClick: () => store.router.push(`/restaurant/${r._id}`),
            allowed: restrictions.restaurant.dashboard,
          },
          {
            id: 'nav-link-orders',
            tip: 'Orders',
            icon: <FaShoppingBag />,
            active: !!routes.restaurant_orders.pattern.match(pathname),
            onClick: () => store.router.push(`/restaurant/${r._id}/orders`),
            allowed: restrictions._.restaurantOrderViews.length > 0,
          },
          {
            id: 'nav-link-bookings',
            tip: 'Bookings',
            icon: <FaCalendarAlt />,
            active: !!routes.restaurant_bookings.pattern.match(pathname),
            onClick: () => store.router.push(`/restaurant/${r._id}/bookings`),
            allowed: restrictions.restaurant.bookings,
          },
          {
            id: 'nav-link-customers',
            tip: 'Customers',
            icon: <FaUser />,
            active: !!routes.restaurant_customers.pattern.match(pathname),
            onClick: () => store.router.push(`/restaurant/${r._id}/customers`),
            allowed: restrictions.restaurant.customers,
          },
          {
            id: 'nav-link-menus',
            tip: 'Menus',
            icon: <FaUtensils />,
            active: !!routes.restaurant_menus.pattern.match(pathname),
            onClick: () => store.router.push(`/restaurant/${r._id}/menus`),
            allowed: restrictions.restaurant.menus,
          },
          {
            id: 'nav-link-connect',
            tip: 'Online Payments',
            icon: <FaMoneyCheck />,
            active: !!routes.restaurant_stripe_connect.pattern.match(pathname),
            onClick: () => {
              store.router.push(`/restaurant/${r._id}/online_payments`);
            },
            allowed: shouldOnlinePaymentAllowed,
          },
          {
            id: 'nav-link-settings',
            tip: 'Settings',
            icon: <FaCog />,
            active: !!routes.restaurant_settings.pattern.match(pathname),
            onClick: () => store.router.push(`/restaurant/${r._id}/settings`),
            allowed: restrictions._.restaurantSettingsEnabled,
          },
          {
            id: 'nav-link-view-store',
            tip: 'View Store',
            icon: <FaExternalLinkAlt />,
            active: false,
            onClick: () => {
              window.open(storeURL, '_blank');
            },
            allowed: restrictions._.restaurantSettingsEnabled || restrictions.restaurant.menus,
          },
          {
            id: 'nav-link-documentation',
            tip: 'Documentation',
            icon: <FaLifeRing />,
            active: false,
            onClick: () => {
              window.open(documentationLink, '_blank');
            },
            allowed: showMainSupport,
          },
        ];

        return (
          <SideNav>
            {links.map((link, i) =>
              !link.allowed ? null : (
                <SideNavLink
                  id={link.id}
                  key={i}
                  tip={link.tip}
                  icon={link.icon}
                  active={link.active}
                  onClick={link.onClick}
                />
              ),
            )}
          </SideNav>
        );
      }

      back = () => {
        const { store } = this.injected;
        store.router.push('/');
        store.notifications.ablyRestaurantStop();
      };

      render() {
        const { children } = this.props;
        const { store } = this.injected;
        const { restrictions } = store;
        const r = store.restaurant;
        const notificationsEnabled = restrictions._.restaurantNotificationsEnabled;
        return (
          <div>
            <TopNav.Wrapper>
              <TopNav.Content width="full" align="space-between">
                <div className="flex-line centered">
                  <a onClick={this.back} className="font22 m-l-1 m-r-4">
                    <FaArrowCircleLeft />
                  </a>
                  {r && <h4 className="m-r-4">{r.name}</h4>}
                </div>

                <div className="flex-line centered child-mr-20">
                  {notificationsEnabled && <AudioUnlock className="m-r-5" />}
                  {notificationsEnabled && <Notifications />}
                  <Tooltip text="Logout" width={60} position="bottom" offset={5}>
                    <TopNav.Icon onClick={() => store.service.logout()}>
                      <FaPowerOff />
                    </TopNav.Icon>
                  </Tooltip>
                </div>
              </TopNav.Content>
            </TopNav.Wrapper>

            {this.sidenav()}

            <RestaurantLayoutContent>
              {!r && this.loading()}
              {r && children}
            </RestaurantLayoutContent>
          </div>
        );
      }
    },
  ),
);
