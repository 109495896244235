import React from 'react';
import { styled, keyframes, shadeAlternate, DropDown, ConnectionIndicatorCircle } from '@lib/components';
import { withTranslation, WithTranslation } from 'react-i18next';
import { FaBell } from 'react-icons/fa';
import { inject, observer } from 'mobx-react';
import { MobxComponent } from '../../../../mobx/components/index';
import { NotificationsList } from './list';

interface Props extends WithTranslation {}
interface State {}

const Wrapper = styled('div')`
  position: relative;
  cursor: pointer;
`;

const Icon = styled('div')`
  font-size: 20px;
`;

const pulse = keyframes`
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1);
  }
`;

const Bubble = styled('div')`
  position: absolute;
  left: -50%;
  bottom: -30%;
  width: 18px;
  height: 18px;
  line-height: 18px;
  font-size: 12px;
  text-align: center;
  border-radius: 3px;
  background: ${({ theme }) => shadeAlternate(0.05, theme.colors.primary)};
  color: ${({ theme }) => theme.colors.primary_text};
  animation: ${pulse} 0.4s linear infinite;
  animation-direction: alternate-reverse;
`;

const ConnectionIndicator = styled(ConnectionIndicatorCircle)`
  position: absolute;
  left: 90%;
  top: -20%;
`;

export const NotificationsClass = inject('store')(
  observer(
    class NotificationsClass extends MobxComponent<Props, State> {
      render() {
        const { store } = this.injected;
        const { notifications } = store;
        const state = notifications.s;
        return (
          <DropDown
            width={340}
            flow="left"
            active={state.active}
            open={notifications.open}
            close={notifications.close}
            content={<NotificationsList />}
          >
            <Wrapper id="top-nav-notifications">
              <Icon>
                <FaBell />
              </Icon>
              <ConnectionIndicator status={store.ably.status} />
              {state.unseen !== 0 && <Bubble>{state.unseen}</Bubble>}
            </Wrapper>
          </DropDown>
        );
      }
    },
  ),
);

export const Notifications = withTranslation()(NotificationsClass);
