import React from 'react';
import { FastField } from 'formik';
import { FormGroup, Button, RotateLoader, Switch, Input } from '@lib/components';
import { inject, observer } from 'mobx-react';
import { MobxComponent } from '../../../../../../../mobx/components';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';
import { UI } from '../../../../../../../core/ui';

interface Props {}
interface State {}
type FormValues = T.Schema.Restaurant.RestaurantSchema['settings']['business']['age_verification'];

export const SettingsFormAgeVerification = inject('store')(
  observer(
    class SettingsFormAgeVerification extends MobxComponent<Props, State> {
      initialValues: FormValues;

      constructor(props: Props) {
        super(props);
        this.state = {};
        const r = this.injected.store.restaurant!;
        this.initialValues = r.settings.business.age_verification;
      }

      render() {
        return (
          <RestaurantForm<FormValues>
            submit={async (r, values) => {
              r.settings.business.age_verification = values;
              const update = { $set: { 'settings.business.age_verification': values } };
              return { r, update };
            }}
            validators=
            initialValues={this.initialValues}
            onSuccess={() => {}}
            onError={() => UI.notification.error('An error occurred')}
            onSuccessMessage="Settings Updated"
            onErrorMessage=""
          >
            {({ form, error }) => {
              const { isSubmitting, setFieldValue } = form;
              return (
                <div className="p-4">
                  <FormGroup
                    title="Enabled"
                    help="Age verification allows customers to upload their legal documents. You can use this to manually verify the identity and age of the customer. Useful for selling age restricted products"
                  >
                    <FastField
                      name="enabled"
                      render={({ field }: any) => (
                        <Switch
                          id="age_verification.enabled"
                          checked={field.value}
                          onChange={e => setFieldValue('enabled', e.target.checked)}
                        />
                      )}
                    />
                  </FormGroup>

                  <FormGroup
                    title="Minimum Age (Years)"
                    help="The minimum age required is displayed to the customer during the age verification process and on your online store"
                  >
                    <FastField
                      name="min_age"
                      render={({ field }: any) => (
                        <Input type="number" step="1" min="0" max="200" required {...field} />
                      )}
                    />
                  </FormGroup>

                  <FormGroup
                    title="Estimated Wait Time (Hours)"
                    help="Give your customers an estimated approval time. This will be shown to customers during the age verification process"
                  >
                    <FastField
                      name="estimated_wait_time"
                      render={({ field }: any) => (
                        <Input type="number" step="1" min="0" max="200" required {...field} />
                      )}
                    />
                  </FormGroup>

                  <FormGroup
                    title="Store Popup Notification"
                    help="Notify your customers as soon as they load your online store that the items you sell are age restricted"
                  >
                    <FastField
                      name="store_popup_notification"
                      render={({ field }: any) => (
                        <Switch
                          id="age_verification.store_popup_notification"
                          checked={field.value}
                          onChange={e => setFieldValue('store_popup_notification', e.target.checked)}
                        />
                      )}
                    />
                  </FormGroup>

                  {error && <FormGroup error={error} />}

                  <Button full color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting && <RotateLoader size={2} color="white" />}
                    {!isSubmitting && 'Save'}
                  </Button>
                </div>
              );
            }}
          </RestaurantForm>
        );
      }
    },
  ),
);
