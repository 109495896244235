import { Button } from '@lib/components';
import React from 'react';

interface Props {
  children: React.ReactNode;
  color: string;
  onClick: () => void;
}

export function BoardDropdownButton(props: Props) {
  return (
    <Button
      size="sm"
      display="flex"
      className="no-round flex-grow flex-basis-0"
      overrideColor={props.color}
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
}
