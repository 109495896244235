import React from 'react';
import { inject, observer } from 'mobx-react';
import { withTheme } from 'styled-components';
import { withTranslation } from 'react-i18next';
import { Field, Form, Formik, FormikProps } from 'formik';
import { Input, styled } from '@lib/components';
import { MobxComponent } from '../../../../../mobx/components';

const InputWrapper = styled.div`
  width: 260px;
  margin-right: 1rem;

  input {
    height: 28px;
  }
`;

interface Props {
  restaurantId: string;
  onSearchCompleted: (orderId: string) => void;
  onSearchFailed: () => void;
}

const LalamoveOrderSearchClass = inject('store')(
  observer(
    class LalamoveOrderSearchClass extends MobxComponent<Props, {}> {
      handleSearch = async (deliveryId: string) => {
        const { store } = this.injected;
        const { restaurantId, onSearchCompleted, onSearchFailed } = this.props;
        const response = await store.api.findOrderByLalamoveDeliveryId({ restaurantId, deliveryId });

        if (response.outcome) {
          onSearchFailed();
          return;
        }

        onSearchCompleted(response.item._id);
      };

      render() {
        const { t } = this.injected;

        return (
          <Formik
            initialValues={{ query: '' }}
            onSubmit={async (values, { resetForm }) => {
              await this.handleSearch(values.query);
              resetForm();
            }}
          >
            {({ setFieldValue }: FormikProps<any>) => (
              <Form>
                <Field
                  name="query"
                  render={({ field }: any) => (
                    <InputWrapper>
                      <Input
                        type="text"
                        {...field}
                        autoSave="false"
                        autoCorrect="false"
                        placeholder={t('order.searching.lalamove_search_placeholder')}
                        onChange={e => setFieldValue('query', e.target.value)}
                      />
                    </InputWrapper>
                  )}
                />
              </Form>
            )}
          </Formik>
        );
      }
    },
  ),
);

// @ts-ignore
export const LalamoveOrderSearch = withTheme(withTranslation()(LalamoveOrderSearchClass));
