import React from 'react';
import { FormGroup, Button } from '@lib/components';
import { inject, observer } from 'mobx-react';
import { Untrusive } from '@lib/common';
import { MobxComponent } from '../../../../../../../mobx/components';

interface Props {}
interface State {}

export const SettingsFormDesignThemes = inject('store')(
  observer(
    class SettingsFormDesignThemes extends MobxComponent<Props, State> {
      constructor(props: Props) {
        super(props);
        this.state = {};
      }

      setTheme = async (theme: 'light' | 'dark') => {
        // eslint-disable-next-line no-alert
        const proceed = confirm(
          'Changing your theme will overwrite your existing colors across your entire store. Proceed?',
        );

        if (!proceed) {
          return;
        }

        await this.saveRestaurant({
          process: r => {
            if (theme === 'light') {
              r.website.colors.primary_text = 'white';
              r.website.colors.text = 'rgba(50, 50, 50, 1)';
              r.website.colors.background = 'rgba(244, 244, 244, 1)';

              r.website.colors.box = 'rgba(255, 255, 255, 1)';
              r.website.colors.box_text = 'rgba(50, 50, 50, 1)';

              r.website.sections.top_nav.colors.background = 'rgba(255, 255, 255, 1)';
              r.website.sections.top_nav.colors.text = 'rgba(50, 50, 50, 1)';

              r.website.sections.header.colors.background = 'rgba(33, 33, 33, 1)';
              r.website.sections.header.colors.text = 'rgba(255, 255, 255, 1)';

              r.website.sections.footer.colors.background = 'rgba(45, 45, 45, 1)';
              r.website.sections.footer.colors.text = 'rgba(255, 255, 255, 1)';
            } else if (theme === 'dark') {
              r.website.colors.primary_text = 'white';
              r.website.colors.text = 'rgba(240, 240, 240, 1)';
              r.website.colors.background = 'rgba(20, 20, 20, 1)';

              r.website.colors.box = 'rgba(45, 45, 45, 1)';
              r.website.colors.box_text = 'rgba(240, 240, 240, 1)';

              r.website.sections.top_nav.colors.background = 'rgba(30, 30, 30, 1)';
              r.website.sections.top_nav.colors.text = 'rgba(240, 240, 240, 1)';

              r.website.sections.header.colors.background = 'rgba(240, 240, 240, 1)';
              r.website.sections.header.colors.text = 'rgba(45, 45, 45, 1)';

              r.website.sections.footer.colors.background = 'rgba(30, 30, 30, 1)';
              r.website.sections.footer.colors.text = 'rgba(240, 240, 240, 1)';
            }

            return {
              update: {
                $set: {
                  'website.colors': r.website.colors,
                  'website.sections': r.website.sections,
                },
              },
            };
          },
          successMsg: 'Theme applied',
          before: () => Untrusive.start(),
          onSuccess: () => Untrusive.stop(),
          onFail: () => Untrusive.stop(),
          onError: () => Untrusive.stop(),
        });
      };

      render() {
        return (
          <div className="p-4">
            <FormGroup>
              <p className="lhp m-b-4">
                We recommend picking a theme and then changing your primary color under the colors section
              </p>

              <Button size="xs" color="primary-inverse" className="m-r-2" onClick={() => this.setTheme('light')}>
                Light Theme
              </Button>
              <Button size="xs" color="primary-inverse" onClick={() => this.setTheme('dark')}>
                Dark Theme
              </Button>
            </FormGroup>
          </div>
        );
      }
    },
  ),
);
