import React from 'react';
import { Input, Select, FormGroup } from '@lib/components';
import { Parser } from 'expr-eval';
import Big from 'big.js';
import { withTranslation, WithTranslation } from 'react-i18next';

interface Props extends WithTranslation {
  formula: string;
  kmmile: string;
  currencySymbol?: string;
}

interface State {
  CT: string;
  DD: string;
  DT: string;
  LI: string;
}

class DeliveryFeeFormulaTestClass extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      CT: '',
      DD: '',
      DT: '',
      LI: '0',
    };
  }

  onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    // @ts-ignore
    this.setState({ [name]: value });
  };

  formulaResult = () => {
    try {
      const { t, formula } = this.props;

      const { CT, DD, DT, LI } = this.state;

      const exp = Parser.parse(formula);

      const value = Big(exp.evaluate({ CT, DD, DT, LI: LI === '0' ? 0 : 1 })).toFixed(2);

      return t('currency', {
        value: Big(value).lt(0) ? 0 : value,
      });
    } catch (e) {
      return 'Invalid formula';
    }
  };

  render() {
    const fgProps = {
      className: 'col',
      small_title: true,
      no_border: true,
    };

    const currencySymbol = this.props.currencySymbol || '$';

    return (
      <>
        <div className="grid-4-2 sm-2 md-4 xs-gap">
          <FormGroup title={`CT (${currencySymbol})`} {...fgProps}>
            <Input name="CT" type="number" value={this.state.CT} onChange={this.onChange} />
          </FormGroup>
          <FormGroup title={`"DD (meters)`} {...fgProps}>
            <Input name="DD" type="number" value={this.state.DD} onChange={this.onChange} />
          </FormGroup>
          <FormGroup title="DT (seconds)" {...fgProps}>
            <Input name="DT" type="number" value={this.state.DT} onChange={this.onChange} />
          </FormGroup>
          <FormGroup title="LI" {...fgProps}>
            <Select
              name="LI"
              value={this.state.LI}
              onChange={this.onChange}
              options={[
                { value: '0', label: 'No' },
                { value: '1', label: 'Yes' },
              ]}
            />
          </FormGroup>
        </div>
        <p className="big m-t-3">
          <span className="font-semi-bold">Delivery Cost:</span> {this.formulaResult()}
        </p>
      </>
    );
  }
}

export const DeliveryFeeFormulaTest = withTranslation()(DeliveryFeeFormulaTestClass);
