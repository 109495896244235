import React from 'react';
import { inject, observer, MobXProviderContext } from 'mobx-react';
import styled from 'styled-components';
import { ModalContent, Tag, ModalTitle, Modal } from '@lib/components';
import { RestaurantUtils } from '@lib/common';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import { MobxComponent } from '../../../../../../mobx/components';
import { SettingsSuccessIndicator, SettingsInactiveIndicator } from '../components';
import { SettingsFormIntegrationTookan } from '../forms/integrations/tookan';
import { SettingsFormIntegrationGoogleAnalytics } from '../forms/integrations/google-analytics';
import { SettingsFormIntegrationFacebookPixel } from '../forms/integrations/facebook-pixel';
import { SettingsFormIntegrationDriveYello } from '../forms/integrations/drive-yello';
import { SettingsFormIntegrationQuestTag } from '../forms/integrations/questtag';
import { SettingsFormIntegrationPostmates } from '../forms/integrations/postmates';
import { OAuthAppForm } from '../oauth/app_form';
import { SettingsFormIntegrationAbacus } from '../forms/integrations/abacus';
import { SettingsFormIntegrationLalamove } from '../forms/integrations/lalamove';
import { SettingsFormIntegrationWalletly } from '../forms/integrations/walletly';

interface Props {}

interface State {
  appModal: string;
  currentApp: T.Schema.Restaurant.Integrations.BaseApp | null;
  legacyAppModal: string;
}

interface ItemProps {
  title: string;
  description: string;
  category: string;
  image: string;
  active: boolean;
  price: string;
  setActive: () => void;
}

const ItemStatusWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 15px;
  background: rgb(225, 225, 225);
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: 5px;
  transform: translateY(50%);
`;

const ItemWrapper = styled.div`
  background: white;
  cursor: pointer;
  transition: 0.23s box-shadow;
  &:hover {
    box-shadow: 0 0 22px rgba(0, 0, 0, 0.2) !important;
  }
`;

const DisplayPrice = styled.div`
  margin-top: 25px;
`;
const Item = observer((props: ItemProps) => {
  const { store } = React.useContext(MobXProviderContext);
  const { theme } = store;
  const { title, description, category, image, active, price, setActive } = props;

  const mapping: { [key: string]: string } = {
    'Drive Yellow': '60px',
    Abacus: '35px',
  };
  const height = mapping[title] || '40px';
  const _mapping: { [key: string]: string } = {
    'Drive Yellow': 'p-tb-6',
    Abacus: 'p-tb-6',
  };
  const padding = _mapping[title] || 'p-tb-8';
  return (
    <ItemWrapper className="col bsm-1 border-white-10" onClick={setActive}>
      <div className={`p-lr-4 ${padding} flex-center border-white-b-10 relative`}>
        <img src={image} style={{ height, width: 'auto' }} />
        <ItemStatusWrapper>
          {active ? (
            <SettingsSuccessIndicator background={theme.s.status_colors.complete} />
          ) : (
            <SettingsInactiveIndicator />
          )}
          {active ? 'Enabled' : 'Disabled'}
        </ItemStatusWrapper>
      </div>
      <div className="p-tb-4 p-lr-5">
        <p className="bigger font-semi-bold">{title}</p>
        <p className="big m-tb-2 lhp">{description}</p>
        <Tag>{category}</Tag>
        {price ? (
          <DisplayPrice>
            <p className="bigger font-semi-bold">${price} per month</p>{' '}
          </DisplayPrice>
        ) : (
          ''
        )}
      </div>
    </ItemWrapper>
  );
});

const IntegrationApp = observer(
  (props: { app: T.Schema.Restaurant.Integrations.BaseApp; enabled: boolean; onClick: (e: any) => void }) => {
    const { store } = React.useContext(MobXProviderContext);
    const { theme } = store;
    const { name, description, logo, tags } = props.app;
    const height = '40px';
    const padding = 'p-tb-8';
    const tagList = tags ? tags.split(',') : [];

    return (
      <ItemWrapper className="col bsm-1 border-white-10" onClick={props.onClick}>
        <div className={`p-lr-4 ${padding} flex-center border-white-b-10 relative`}>
          <img src={logo} style={{ height, width: 'auto' }} />
          <ItemStatusWrapper>
            {props.enabled ? (
              <SettingsSuccessIndicator background={theme.s.status_colors.complete} />
            ) : (
              <SettingsInactiveIndicator />
            )}
            {props.enabled ? 'Enabled' : 'Disabled'}
          </ItemStatusWrapper>
        </div>
        <div className="p-tb-4 p-lr-5">
          <p className="bigger font-semi-bold">{name}</p>
          <p className="big m-tb-2 lhp">{description}</p>
          {tagList.map((tag, index) => (
            <Tag key={index}>{tag}</Tag>
          ))}
        </div>
      </ItemWrapper>
    );
  },
);

export const RestaurantSettingsIntegrations = inject('store')(
  observer(
    class RestaurantSettingsIntegrations extends MobxComponent<Props, State> {
      constructor(props: Props) {
        super(props);
        this.state = {
          appModal: '',
          currentApp: null,
          legacyAppModal: '',
        };
      }

      closeLegacyAppModal = () => this.setState({ legacyAppModal: '' });

      closeAppModal = () => this.setState({ appModal: '', currentApp: null });

      isOAuth2AppEnabled = (
        restaurant: T.Schema.Restaurant.RestaurantSchema,
        app: T.Schema.Restaurant.Integrations.BaseApp,
      ): boolean => {
        const { integrations } = restaurant.settings;
        const enabled_kounta = restaurant.kounta?.enabled;
        if (enabled_kounta) {
          return true;
        }
        if (_isEmpty(integrations) || !_has(integrations, app.slug)) {
          return false;
        }
        const integration = _get(integrations, app.slug);
        return !_isEmpty(_get(integration, 'tokens.access_token'));
      };

      isAppEnabled = (app: T.Schema.Restaurant.Integrations.BaseApp): boolean => {
        const restaurant = this.injected.store.restaurant!;
        if (app.type === 'oauth2') {
          return this.isOAuth2AppEnabled(restaurant, app);
        }

        if (app.slug === 'postmates') {
          return RestaurantUtils.settings.integrationPostmatesActive(restaurant);
        }

        if (app.slug === 'lalamove') {
          return RestaurantUtils.settings.integrationLalamoveActive(restaurant);
        }

        if (app.slug === 'walletly') {
          return RestaurantUtils.settings.integrationWalletlyActive(restaurant);
        }

        return false;
      };

      getLegacyIntegrationApps = (restaurant: T.Schema.Restaurant.RestaurantSchema) => [
        {
          active: RestaurantUtils.settings.integrationFacebookPixelActive(restaurant),
          image: '/images/integrations/facebook-pixel.png',
          title: 'Facebook Pixel',
          description: 'Add Facebook Pixel tracking to your website to track visitors and conversions',
          category: 'Marketing & Tracking',
        },
        {
          active: RestaurantUtils.settings.integrationGoogleAnalyticsActive(restaurant),
          image: '/images/integrations/google-analytics.png',
          title: 'Google Analytics',
          description: 'Add google analytics tracking to your ordering site to track visitors',
          category: 'Marketing & Tracking',
        },
        {
          active: RestaurantUtils.settings.integrationTookanActive(restaurant),
          image: '/images/integrations/tookan.png',
          title: 'Tookan',
          description: 'Automatically send delivery orders to Tookan for better delivery management',
          category: 'Delivery Management',
        },
        {
          active: RestaurantUtils.settings.integrationQuestTagActive(restaurant),
          image: '/images/integrations/shipday.png',
          title: 'Shipday',
          description: 'Automatically send delivery orders to Shipday for better delivery management',
          category: 'Delivery Management',
        },
        {
          active: restaurant?.abacus?.abacus_status,
          image: '/images/integrations/abacus.png',
          title: 'Abacus',
          description: 'Enable features to copy Abacus menus and automatic sending of orders to your POS.',
          category: 'POS Integration',
          price: '20',
        },
        // {
        // 	active: RestaurantUtils.settings.integrationDriveYelloActive(restaurant),
        // 	image: "/images/integrations/yello.png",
        // 	title: "Drive Yello",
        // 	description: "Automatically send delivery orders to Drive Yello for better delivery management",
        // 	category: "Delivery Management",
        // },
      ];

      // @ts-ignore
      getApplicationForm = (
        restaurant: T.Schema.Restaurant.RestaurantSchema,
        app: T.Schema.Restaurant.Integrations.BaseApp,
      ) => {
        const integrationApps = restaurant.settings.integrations || {};
        const appDetails = _get(integrationApps, app.slug);

        if (app.type === 'oauth2') {
          return (
            <OAuthAppForm
              restaurant={restaurant}
              baseApp={app}
              submitButtonText="Save"
              initialValues={appDetails ? (appDetails as T.Schema.Restaurant.Integrations.OAuthApp) : undefined}
              onSuccess={this.closeAppModal}
            />
          );
        }

        if (app.slug === 'postmates') {
          return <SettingsFormIntegrationPostmates close={this.closeAppModal} />;
        }

        if (app.slug === 'lalamove') {
          return <SettingsFormIntegrationLalamove close={this.closeAppModal} />;
        }

        if (app.slug === 'walletly') {
          return <SettingsFormIntegrationWalletly close={this.closeAppModal} />;
        }
      };

      render() {
        const { appModal, currentApp, legacyAppModal } = this.state;
        const { store } = this.injected;
        const restaurant = store.restaurant!;

        const integrationApps = store.restaurant_integration_base_apps || [];
        const legacyIntegrationApps = this.getLegacyIntegrationApps(restaurant);

        return (
          <>
            <div className="m-t-4 grid-2 md sm-gap">
              {/* Legacy applications */}
              {legacyIntegrationApps.map((item, i) => (
                // @ts-ignore
                <Item {...item} key={i} setActive={() => this.setState({ legacyAppModal: item.title })} />
              ))}

              {legacyIntegrationApps.length % 2 !== 0 && <div className="col" />}

              {/* New applications */}
              {integrationApps.map((app: T.Schema.Restaurant.Integrations.BaseApp) => (
                <IntegrationApp
                  key={app._id}
                  app={app}
                  enabled={this.isAppEnabled(app)}
                  onClick={() => {
                    this.setState({
                      appModal: app.name,
                      currentApp: app,
                    });
                  }}
                />
              ))}

              {integrationApps.length % 2 !== 0 && <div className="col" />}
            </div>

            {/* Legacy application modal */}
            <Modal width={600} active={!!legacyAppModal} close={this.closeLegacyAppModal}>
              <ModalTitle className="round-top">
                <h4>{legacyAppModal}</h4>
              </ModalTitle>

              {legacyAppModal === 'Tookan' && <SettingsFormIntegrationTookan close={this.closeLegacyAppModal} />}
              {legacyAppModal === 'Google Analytics' && (
                <SettingsFormIntegrationGoogleAnalytics close={this.closeLegacyAppModal} />
              )}
              {legacyAppModal === 'Facebook Pixel' && (
                <SettingsFormIntegrationFacebookPixel close={this.closeLegacyAppModal} />
              )}
              {legacyAppModal === 'Drive Yello' && (
                <SettingsFormIntegrationDriveYello close={this.closeLegacyAppModal} />
              )}
              {legacyAppModal === 'Shipday' && <SettingsFormIntegrationQuestTag close={this.closeLegacyAppModal} />}
              {legacyAppModal === 'Abacus' && <SettingsFormIntegrationAbacus close={this.closeLegacyAppModal} />}
            </Modal>

            {/* New application modal */}
            <Modal width={600} active={!!appModal} close={this.closeAppModal}>
              <ModalTitle className="round-top">
                <h4>{appModal}</h4>
              </ModalTitle>

              <ModalContent>{currentApp && this.getApplicationForm(restaurant, currentApp)}</ModalContent>
            </Modal>
          </>
        );
      }
    },
  ),
);
