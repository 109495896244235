import React from 'react';
import { FastField, FastFieldProps, Field, FormikProps } from 'formik';

import { Input, FormGroup, Switch } from '@lib/components';
import { FreeItemPromo, ConventionalDiscountPromo } from '../promos/type';

interface Props {
  form: FormikProps<FreeItemPromo | ConventionalDiscountPromo>;
  getFieldError: (
    form: FormikProps<FreeItemPromo | ConventionalDiscountPromo>,
    field: string,
  ) => T.ObjectAny | string | undefined | null;
  promoType: 'free_item' | 'conventional_discount';
}

export function GeneralInformationTab({ form, getFieldError }: Props) {
  return (
    <div className="p-4">
      <FastField name="name">
        {({ field }: FastFieldProps) => (
          <FormGroup
            title="Name"
            help="A name for your promotion, will be displayed to the customer when checking out"
            error={getFieldError(form, 'name')}
          >
            <Input type="text" {...field} />
          </FormGroup>
        )}
      </FastField>

      <FastField name="code">
        {({ field }: FastFieldProps) => (
          <FormGroup
            title="Promo Code"
            help="The promotional code that your customers must enter to use the promotion. Case-insensitive"
            error={getFieldError(form, 'code')}
          >
            <Input type="text" {...field} />
          </FormGroup>
        )}
      </FastField>

      <FastField name="description">
        {({ field }: FastFieldProps) => (
          <FormGroup
            optional
            title="Description"
            help="What is this promotion about?"
            error={getFieldError(form, 'description')}
          >
            <Input type="text" {...field} />
          </FormGroup>
        )}
      </FastField>

      <FastField name="notification">
        {({ field }: FastFieldProps) => (
          <FormGroup
            optional
            title="Notification"
            help="The message that will be shown once the criteria is reached."
            error={getFieldError(form, 'notification')}
          >
            <Input type="text" {...field} />
          </FormGroup>
        )}
      </FastField>

      <Field name="disabled">
        {({ field }: FastFieldProps) => (
          <FormGroup title="Disable Promotion" help="Disable the usage of this promo code without deleting it">
            <Switch
              id="disabled"
              checked={field.value}
              onChange={e => form.setFieldValue('disabled', e.target.checked)}
            />
          </FormGroup>
        )}
      </Field>
    </div>
  );
}
