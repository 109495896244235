import styled from '@lib/components/src/styled';

export const StyledColumn = styled('div')`
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  h2 {
    margin: 0;
    padding: 0 16px;
  }
`;

export const StyledList = styled('div')`
  background-color: #eee;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 8px;
`;

export const StyledItem = styled('div')`
  background-color: #fff;
  border-radius: 4px;
  padding: 12px 8px;
  transition: background-color 0.8s ease-out;
  margin-top: 8px;
  font-size: 14px;

  &:hover {
    background-color: #fff;
    transition: background-color 0.1s ease-in;
  }
`;

export const StyledColumns = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 10px auto;
  width: 80%;
  height: auto;
  gap: 8px;
`;

export const AlignItems = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
