import React from 'react';
import { IconCircle } from '@lib/components';
import { FaCheck, FaTimes } from 'react-icons/fa';

export function SettingsSuccessIndicator(props: { background?: string }) {
  return (
    <IconCircle
      size={16}
      className="m-r-2"
      icon={<FaCheck />}
      iconSizeModifier={6}
      background={props.background || 'complete'}
    />
  );
}
export function SettingsInactiveIndicator(props: { background?: string }) {
  return (
    <IconCircle
      size={16}
      className="m-r-2"
      icon={<FaTimes />}
      iconSizeModifier={6}
      background={props.background || 'grey'}
    />
  );
}
