import React from 'react';
import { FastField, Field } from 'formik';
import { ESCPOSFontSizes, logger, RestaurantUtils, SortType, printDetailList, DataLocales } from '@lib/common';
import {
  FormGroup,
  Select,
  FormTreeSelect,
  Input,
  Button,
  RotateLoader,
  InputBox,
  Switch,
  TabSelect,
  ScrollList,
  ScrollListItem,
  SwitchListSortable,
  ModalContent,
  Modal,
  Tag,
  SelectAdv,
  LogoRender,
} from '@lib/components';
import { arrayMove } from 'react-sortable-hoc';
import { inject, observer } from 'mobx-react';
import { action, observable, makeObservable } from 'mobx';
import { MobxComponent } from '../../../../../../../mobx/components';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';
import { HTMLEditor } from '../../../../../../components/html-editor';
import { transfromCheckoutFieldsToReceiptPrintOptions } from '../../../../../../../core/libs/receipt';

interface TreeNode {
  value: string;
  label: string;
  children?: TreeNode[] | null;
}

type FormValues = T.Schema.Restaurant.Printer.RestaurantPrinter;
interface Props {
  type: 'edit' | 'create';
  initialValues: FormValues | null;
  close: () => void;
}

const localeList = [{ label: 'Store Default', value: '' }, ...DataLocales];

export const SettingsPrintersForm = inject('store')(
  observer(
    class SettingsPrintersForm extends MobxComponent<Props, {}> {
      tab: string = '0';

      menuTree: TreeNode[];

      currentLogo: T.Core.Business.BusinessImage | undefined | null;

      setTab = (tab: string) => {
        this.tab = tab;
      };

      constructor(props: Props) {
        super(props);

        makeObservable(this, {
          tab: observable,
          setTab: action,
        });

        this.menuTree = RestaurantUtils.menu.getTree(this.injected.store.restaurant!);

        const r = this.injected.store.restaurant!;

        const customLogo = r.settings.printers_receipt_logo?.custom_logo;
        this.currentLogo = customLogo ? r.settings.printers_receipt_logo?.logo : r.website.sections.top_nav.images.logo;
      }

      componentDidUpdate(prevProps: Props) {
        if (this.props.initialValues && !prevProps.initialValues) {
          this.setTab('0');
        }
      }

      nodeGetDeepestChildrenValues = (node: TreeNode): string[] => {
        let children: string[] = [];
        if (node.children) {
          // eslint-disable-next-line no-restricted-syntax
          for (const child of node.children) {
            if (child.children) {
              children = [...children, ...this.nodeGetDeepestChildrenValues(child)];
            } else {
              children.push(child.value);
            }
          }
        }
        return children;
      };

      gatherAllChildrenNodes = (): string[] => {
        let children: string[] = [];
        this.menuTree.forEach((node: any) => {
          children = children.concat(this.nodeGetDeepestChildrenValues(node));
        });
        return children;
      };

      render() {
        const { type, initialValues, close } = this.props;
        const { tab } = this;
        const units = this.injected.store.restaurant ? this.injected.store.restaurant.settings.region.kmmile : null;
        const tabValues = [
          { label: 'General', value: '0' },
          { label: 'Printer Settings', value: '1' },
          { label: 'Customization', value: '2' },
          { label: 'Header & Footer', value: '3' },
          { label: 'Cloud Printing', value: '4' },
        ];
        if (
          initialValues !== null &&
          // @ts-ignore
          (initialValues.mqtt_enabled === null || initialValues.mqtt_enabled === undefined)
        ) {
          // @ts-ignore
          initialValues.mqtt_enabled === false;
        }
        return (
          <Modal width="md" alignTop active={!!initialValues} close={close}>
            <ModalContent>
              <h3>{type === 'create' ? 'Create Printer' : 'Edit Printer'}</h3>
              {initialValues && (
                <p className="m-t-1">
                  API Key: <Tag className="m-l-1">{initialValues.api_key}</Tag>
                </p>
              )}
            </ModalContent>

            <TabSelect
              id="printer-tab-options"
              className="border-white-tb-15"
              hasBorder
              screenWidth={this.injected.store.view.screen_width}
              onChange={v => this.setTab(v.value)}
              value={tab}
              values={tabValues}
            />

            <RestaurantForm<FormValues>
              submit={async (r, printer) => {
                const existingPrinter = r.settings.printers.findIndex(p => p._id === printer._id);
                const margin = printer.paper.margins.top;
                printer.paper.margins.bottom = margin;
                printer.paper.margins.left = margin;
                printer.paper.margins.right = margin;

                if (existingPrinter === -1) {
                  r.settings.printers.push(printer);
                } else {
                  r.settings.printers[existingPrinter] = printer;
                }

                const update = {
                  $set: { 'settings.printers': r.settings.printers },
                };

                return { r, update };
              }}
              validators={{
                // @ts-ignore
                name: values => {
                  if (!values.name) return { name: 'This field is required' };
                },
              }}
              initialValues={initialValues}
              onSuccess={close}
              onSuccessMessage={type === 'edit' ? 'Printer edited' : 'Printer created'}
              onErrorMessage=""
            >
              {({ form, error, getFieldError }) => {
                const { isSubmitting, setFieldValue, values } = form;
                return (
                  <div>
                    {tab === '0' && (
                      <ModalContent>
                        <FastField
                          name="name"
                          render={({ field }: any) => (
                            <FormGroup
                              title="Name"
                              help="Printer configuration name. It is only used to help you identify this configuration"
                              error={getFieldError(form, 'name')}
                            >
                              <Input type="text" {...field} required />
                            </FormGroup>
                          )}
                        />
                        <FastField
                          name="auto_print_enabled"
                          render={({ field }: any) => (
                            <FormGroup
                              title="Auto Print Orders"
                              help="Automatically print out new orders as they are placed"
                            >
                              <Switch
                                id="auto_print_enabled"
                                checked={field.value}
                                onChange={e => setFieldValue('auto_print_enabled', e.target.checked)}
                              />
                            </FormGroup>
                          )}
                        />
                        <FastField
                          name="auto_print_confirmed_order_only"
                          render={({ field }: any) => (
                            <FormGroup
                              title="Auto Print Orders (Confirmed Only)"
                              help="Turn on to auto print receipt when order is confirmed, instead of unconfirmed or awaiting payment."
                            >
                              <Switch
                                id="auto_print_confirmed_order_only"
                                checked={field.value || false}
                                onChange={e => setFieldValue('auto_print_confirmed_order_only', e.target.checked)}
                              />
                            </FormGroup>
                          )}
                        />
                        <FastField
                          name="auto_print_booking_enabled"
                          render={({ field }: any) => (
                            <FormGroup
                              title="Auto Print Bookings"
                              help="Automatically print out new bookings as they are placed"
                            >
                              <Switch
                                id="auto_print_booking_enabled"
                                checked={field.value}
                                onChange={e => setFieldValue('auto_print_booking_enabled', e.target.checked)}
                              />
                            </FormGroup>
                          )}
                        />

                        <FastField
                          name="auto_print_confirmed_booking_only"
                          render={({ field }: any) => (
                            <FormGroup
                              title="Auto Print Bookings (Confirmed Only)"
                              help="Turn on to auto print receipt when booking is confirmed, instead of unconfirmed."
                            >
                              <Switch
                                id="auto_print_confirmed_booking_only"
                                checked={field.value || false}
                                onChange={e => setFieldValue('auto_print_confirmed_booking_only', e.target.checked)}
                              />
                            </FormGroup>
                          )}
                        />

                        <FastField
                          name="auto_print_fail_mail"
                          render={({ field }: any) => (
                            <FormGroup
                              optional
                              title="Auto Print Fail E-Mail"
                              help="If an e-mail address is entered, whenever an automated print job fails, you will receive an e-mail notification"
                            >
                              <Input type="email" {...field} />
                            </FormGroup>
                          )}
                        />

                        <FastField
                          name="locale"
                          render={({ field }: any) => (
                            <FormGroup
                              optional
                              title="Receipt Language & Locale"
                              help="Determines the language and formatting of the receipt. Defaults to your system locale"
                              error={getFieldError(form, 'locale')}
                            >
                              <SelectAdv
                                type="single"
                                options={localeList}
                                value={field.value || ''}
                                onChange={(option: string) => setFieldValue('locale', option)}
                              />
                            </FormGroup>
                          )}
                        />

                        <FastField
                          name="date_format"
                          render={({ field }: any) => (
                            <FormGroup
                              optional
                              title="Override Date Format"
                              help="Override your default date formatting style here"
                              error={getFieldError(form, 'date_format')}
                            >
                              <Input type="text" {...field} value={field.value || ''} />
                            </FormGroup>
                          )}
                        />

                        <FastField
                          name="time_format"
                          render={({ field }: any) => (
                            <FormGroup
                              optional
                              title="Override Time Format"
                              help="Override your default time formatting style here"
                              error={getFieldError(form, 'time_format')}
                            >
                              <Input type="text" {...field} value={field.value || ''} />
                            </FormGroup>
                          )}
                        />

                        <FastField
                          name="disabled"
                          render={({ field }: any) => (
                            <FormGroup
                              title="Disable Printer"
                              help="Disable this printer from being used without deleting it"
                            >
                              <Switch
                                id="disabled-switch"
                                checked={field.value || false}
                                onChange={e => setFieldValue('disabled', e.target.checked)}
                              />
                            </FormGroup>
                          )}
                        />
                      </ModalContent>
                    )}

                    {tab === '1' && (
                      <ModalContent>
                        <FastField
                          name="type"
                          render={({ field }: any) => (
                            <FormGroup
                              title="Printing Method"
                              help="Determines whether the receipt should be printed through an installed print driver or ESCPOS format"
                              error={getFieldError(form, 'type')}
                            >
                              <SelectAdv
                                type="single"
                                options={[
                                  {
                                    label: 'Printer Driver',
                                    value: 'driver',
                                  },
                                  {
                                    label: 'ESCPOS',
                                    value: 'escpos',
                                  },
                                ]}
                                value={field.value || 'driver'}
                                onChange={(option: string) => setFieldValue('type', option)}
                              />
                            </FormGroup>
                          )}
                        />
                        {values.type === 'escpos' && (
                          <>
                            <FastField
                              name="escpos_type"
                              render={({ field }: any) => (
                                <FormGroup
                                  title="ESCPOS Printing Type"
                                  help="Determines what style of ESCPOS receipt to use. Image printing is significantly better but not all printers support it"
                                  error={getFieldError(form, 'escpos_type')}
                                >
                                  <SelectAdv
                                    type="single"
                                    options={[
                                      {
                                        label: 'ESCPOS Image',
                                        value: 'image',
                                      },
                                      {
                                        label: 'ESCPOS Text Only',
                                        value: 'text',
                                      },
                                    ]}
                                    value={field.value || 'image'}
                                    onChange={(option: string) => setFieldValue('escpos_type', option)}
                                  />
                                </FormGroup>
                              )}
                            />
                            {values.escpos_type === 'text' && (
                              <>
                                <FastField
                                  name="escpos_characters_per_line"
                                  render={({ field }: any) => (
                                    <FormGroup
                                      title="Characters Per Line"
                                      help="Enter the number of characters per line. Typically 80mm thermal printers are 48 characters per-line and 58mm are 32. Adjust this setting if the print doesn't fill the receipt or if lines overflow"
                                      error={getFieldError(form, 'escpos_characters_per_line')}
                                    >
                                      <Input {...field} type="number" required placeholder="Usually 32 or 48" />
                                    </FormGroup>
                                  )}
                                />
                                <FastField
                                  name="escpos_feed_line_start"
                                  render={({ field }: any) => (
                                    <FormGroup
                                      optional
                                      title="Feed Lines At Start Of Receipt"
                                      help="Add additional empty lines / whitespace to the top of the receipt"
                                      error={getFieldError(form, 'escpos_feed_line_start')}
                                    >
                                      <Input
                                        {...field}
                                        value={typeof field.value === 'number' ? field.value : ''}
                                        type="number"
                                        min={0}
                                      />
                                    </FormGroup>
                                  )}
                                />
                                <FastField
                                  name="escpos_feed_line_end"
                                  render={({ field }: any) => (
                                    <FormGroup
                                      optional
                                      title="Feed Lines At End Of Receipt"
                                      help="Add additional empty lines / whitespace to the bottom of the receipt. Default is 5"
                                      error={getFieldError(form, 'escpos_feed_line_end')}
                                    >
                                      <Input
                                        {...field}
                                        value={typeof field.value === 'number' ? field.value : ''}
                                        type="number"
                                        min={0}
                                      />
                                    </FormGroup>
                                  )}
                                />
                                <FastField
                                  name="escpos_font_size.details_phone_address.width"
                                  render={({ field }: any) => (
                                    <FormGroup
                                      title="Phone and Address Font Width"
                                      optional
                                      help="Setup font width of phone and address on the receipt"
                                    >
                                      <Select
                                        value={field.value}
                                        options={ESCPOSFontSizes}
                                        onChange={e =>
                                          setFieldValue('escpos_font_size.details_phone_address.width', e.target.value)
                                        }
                                      />
                                    </FormGroup>
                                  )}
                                />
                                <FastField
                                  name="escpos_font_size.details_phone_address.height"
                                  render={({ field }: any) => (
                                    <FormGroup
                                      title="Phone and Address Font Height"
                                      optional
                                      help="Set font height of phone and address on the receipt"
                                    >
                                      <Select
                                        value={field.value}
                                        options={ESCPOSFontSizes}
                                        onChange={e =>
                                          setFieldValue('escpos_font_size.details_phone_address.height', e.target.value)
                                        }
                                      />
                                    </FormGroup>
                                  )}
                                />
                                <FastField
                                  name="escpos_font_size.items.width"
                                  render={({ field }: any) => (
                                    <FormGroup
                                      title="Items Font Width"
                                      optional
                                      help="Setup font width of item details on the receipt"
                                    >
                                      <Select
                                        value={field.value}
                                        options={ESCPOSFontSizes}
                                        onChange={e => setFieldValue('escpos_font_size.items.width', e.target.value)}
                                      />
                                    </FormGroup>
                                  )}
                                />
                                <FastField
                                  name="escpos_font_size.items.height"
                                  render={({ field }: any) => (
                                    <FormGroup
                                      title="Items Font Height"
                                      optional
                                      help="Set font height of item details on the receipt"
                                    >
                                      <Select
                                        value={field.value}
                                        options={ESCPOSFontSizes}
                                        onChange={e => setFieldValue('escpos_font_size.items.height', e.target.value)}
                                      />
                                    </FormGroup>
                                  )}
                                />
                              </>
                            )}
                            {values.escpos_type === 'image' && (
                              <>
                                <FastField
                                  name="escpos_paper_width"
                                  render={({ field }: any) => (
                                    <FormGroup
                                      title="Paper Width (pixels)"
                                      help="This is not your actual paper width, rather it is the width of the receipt image generated. For 80mm thermal printers, use 285 with a scale factor of 2"
                                      error={getFieldError(form, 'escpos_paper_width')}
                                    >
                                      <Input {...field} type="number" required placeholder="285" />
                                    </FormGroup>
                                  )}
                                />
                                <FastField
                                  name="escpos_paper_height"
                                  render={({ field }: any) => (
                                    <FormGroup
                                      title="Paper Height (pixels)"
                                      help="This is not your actual paper height, rather it is the height of the receipt image generated. Use '1' to enable automatic cropping of excess whitespace at the bottom"
                                      error={getFieldError(form, 'escpos_paper_height')}
                                    >
                                      <Input {...field} type="number" required placeholder="1" />
                                    </FormGroup>
                                  )}
                                />
                                <FastField
                                  name="escpos_paper_scale_factor"
                                  render={({ field }: any) => (
                                    <FormGroup
                                      title="Paper Scale Factor"
                                      help="Determines how 'zoomed' in the image is. Usually a value of 2 will suit 80mm thermal printers or 1.5-1.7 for 58mm thermal printers"
                                      error={getFieldError(form, 'escpos_paper_scale_factor')}
                                    >
                                      <Input {...field} type="number" required placeholder="2" />
                                    </FormGroup>
                                  )}
                                />
                                <FastField
                                  name="escpos_paper_density"
                                  render={({ field }: any) => (
                                    <FormGroup
                                      title="Paper Density"
                                      help="Determined by your thermal printer. Most printers support 'D24'"
                                      error={getFieldError(form, 'escpos_paper_width')}
                                    >
                                      <SelectAdv
                                        type="single"
                                        options={[
                                          {
                                            label: 'D24',
                                            value: 'd24',
                                          },
                                          {
                                            label: 'S24',
                                            value: 's24',
                                          },
                                          {
                                            label: 'D8',
                                            value: 'd8',
                                          },
                                          {
                                            label: 'S8',
                                            value: 's8',
                                          },
                                        ]}
                                        value={field.value || ''}
                                        onChange={(option: string) => setFieldValue('escpos_paper_density', option)}
                                      />
                                    </FormGroup>
                                  )}
                                />
                                <FastField
                                  name="paper.font_size"
                                  render={({ field }: any) => (
                                    <FormGroup
                                      title="Font Size"
                                      help="The base font size used when generating your receipt, we recommend between 12-16"
                                    >
                                      <Input {...field} type="number" required />
                                    </FormGroup>
                                  )}
                                />
                                <FastField
                                  name="paper.title_font_size"
                                  render={({ field }: any) => (
                                    <FormGroup
                                      optional
                                      title="Title Font Size"
                                      help="The font size of title used when generating your receipt"
                                    >
                                      <Input {...field} type="number" />
                                    </FormGroup>
                                  )}
                                />
                                <FastField
                                  name="paper.details_font_size"
                                  render={({ field }: any) => (
                                    <FormGroup
                                      optional
                                      title="Details Font Size"
                                      help="The font size of details used when generating your receipt"
                                    >
                                      <Input {...field} type="number" />
                                    </FormGroup>
                                  )}
                                />
                                <FastField
                                  name="paper.items_font_size"
                                  render={({ field }: any) => (
                                    <FormGroup
                                      optional
                                      title="Items Font Size"
                                      help="The font size of items used when generating your receipt"
                                    >
                                      <Input {...field} type="number" />
                                    </FormGroup>
                                  )}
                                />
                                <FastField
                                  name="paper.totals_font_size"
                                  render={({ field }: any) => (
                                    <FormGroup
                                      optional
                                      title="Totals Font Size"
                                      help="The font size of totals used when generating your receipt"
                                    >
                                      <Input {...field} type="number" />
                                    </FormGroup>
                                  )}
                                />
                              </>
                            )}
                            <FastField
                              name="escpos_beeps"
                              render={({ field }: any) => (
                                <FormGroup
                                  optional
                                  title="Number Of Beeps"
                                  help="If your printer has a buzzer, you can enter the number of beeps the printer should make after printing"
                                  error={getFieldError(form, 'escpos_beeps')}
                                >
                                  <Input
                                    {...field}
                                    value={typeof field.value === 'number' ? field.value : ''}
                                    type="number"
                                    min={0}
                                  />
                                </FormGroup>
                              )}
                            />
                          </>
                        )}
                        {values.type !== 'escpos' && (
                          <FormGroup help="Your paper setting are used to generate the receipts correctly. If your paper settings do not match your printer's paper size as set on your printer driver, the receipt may be scaled up or down to fit inside.">
                            <div className="flex-line align-items-end">
                              <FastField
                                name="paper.width"
                                render={({ field }: any) => (
                                  <FormGroup className="m-b-0" title="Width" small_title no_border>
                                    <Input
                                      type="number"
                                      required
                                      className="no-round width100px"
                                      placeholder="72"
                                      {...field}
                                    />
                                  </FormGroup>
                                )}
                              />
                              <InputBox className="no-round width32 flex-center p-lr-2 m-r-2">
                                <p className="">mm</p>
                              </InputBox>
                              <FastField
                                name="paper.height"
                                render={({ field }: any) => (
                                  <FormGroup className="m-b-0" title="Height" small_title no_border>
                                    <Input
                                      type="number"
                                      required
                                      className="no-round width100px"
                                      placeholder="210"
                                      {...field}
                                    />
                                  </FormGroup>
                                )}
                              />
                              <InputBox className="no-round width32 flex-center p-lr-2 m-r-2">
                                <p className="">mm</p>
                              </InputBox>
                              <FastField
                                name="paper.margins.top"
                                render={({ field }: any) => (
                                  <FormGroup className="m-b-0" title="Margin" small_title no_border>
                                    <Input
                                      type="number"
                                      required
                                      className="no-round width100px"
                                      placeholder="0"
                                      {...field}
                                    />
                                  </FormGroup>
                                )}
                              />
                              <InputBox className="no-round width32 flex-center p-lr-2 m-r-2">
                                <p className="">mm</p>
                              </InputBox>
                              <FastField
                                name="paper.font_size"
                                render={({ field }: any) => (
                                  <FormGroup className="m-b-0" title="Font Size" small_title no_border>
                                    <Input
                                      type="number"
                                      required
                                      className="no-round width100px"
                                      placeholder="16"
                                      {...field}
                                    />
                                  </FormGroup>
                                )}
                              />
                              <InputBox className="no-round width32 flex-center p-lr-2">
                                <p className="">px</p>
                              </InputBox>
                            </div>
                          </FormGroup>
                        )}
                      </ModalContent>
                    )}

                    {tab === '2' && (
                      <ModalContent>
                        <FormGroup
                          title="Detail Customization"
                          help="Customize which order details are displayed on the receipt. Rearrange the order using the drag handles"
                        >
                          <FastField
                            name="receipt.details"
                            render={({ field }: any) => (
                              <SwitchListSortable
                                height={250}
                                units={units}
                                items={field.value}
                                onCheck={(i, item) => {
                                  logger.dev(i);
                                  const items = [...field.value];
                                  items[i].enabled = !item.enabled;
                                  setFieldValue('receipt.details', items);
                                }}
                                onSortEnd={({ oldIndex, newIndex }) => {
                                  setFieldValue('receipt.details', arrayMove(field.value, oldIndex, newIndex));
                                }}
                              />
                            )}
                          />
                          <Button
                            size="xs"
                            type="button"
                            className="m-t-3"
                            onClick={() => {
                              const options = transfromCheckoutFieldsToReceiptPrintOptions(
                                this.injected.store.restaurant!,
                                printDetailList(),
                              );
                              setFieldValue('receipt.details', options);
                            }}
                          >
                            Reset
                          </Button>
                        </FormGroup>

                        <FormGroup
                          optional
                          title="Highlighted Detail Fields"
                          help="Select which detail fields will be highlighted for better readability."
                        >
                          <Field
                            name="receipt.highlighted_details"
                            render={({ field }: any) => (
                              <SelectAdv
                                type="multi"
                                value={field.value || []}
                                onChange={selected => setFieldValue('receipt.highlighted_details', selected)}
                                options={form.values.receipt.details
                                  .filter(element => element.enabled)
                                  .map(element => ({
                                    value: element._id,
                                    label: element.label,
                                  }))}
                              />
                            )}
                          />
                        </FormGroup>

                        <FormGroup
                          title="Item Customization"
                          help="Customize how the items are displayed on your receipts. Useful for cutting down on un-needed information. For example, cooks don't need to know the cost of the item"
                        >
                          <ScrollList height={null}>
                            <FastField
                              name="receipt.dishes.enabled"
                              render={({ field }: any) => (
                                <ScrollListItem
                                  className="flex-line centered"
                                  onClick={() => setFieldValue('receipt.dishes.enabled', !field.value)}
                                >
                                  <Switch
                                    id="customization_dishes_enabled"
                                    checked={field.value}
                                    onChange={e => setFieldValue('receipt.dishes.enabled', e.target.checked)}
                                  />
                                  <p className="m-l-3">Show Items</p>
                                </ScrollListItem>
                              )}
                            />
                            <FastField
                              name="receipt.dishes.prices"
                              render={({ field }: any) => (
                                <ScrollListItem
                                  className="flex-line centered"
                                  onClick={() => setFieldValue('receipt.dishes.prices', !field.value)}
                                >
                                  <Switch
                                    id="customization_dishes_prices"
                                    checked={field.value}
                                    onChange={e => setFieldValue('receipt.dishes.prices', e.target.checked)}
                                  />
                                  <p className="m-l-3">Show Item Prices</p>
                                </ScrollListItem>
                              )}
                            />
                            <FastField
                              name="receipt.dishes.option_prices"
                              render={({ field }: any) => (
                                <ScrollListItem
                                  className="flex-line centered"
                                  onClick={() => setFieldValue('receipt.dishes.option_prices', !field.value)}
                                >
                                  <Switch
                                    id="customization_dishes_option_prices"
                                    checked={field.value}
                                    onChange={e => setFieldValue('receipt.dishes.option_prices', e.target.checked)}
                                  />
                                  <p className="m-l-3">Show Item Option-set Prices</p>
                                </ScrollListItem>
                              )}
                            />
                            <FastField
                              name="receipt.dishes.combo_choice_names"
                              render={({ field }: any) => (
                                <ScrollListItem
                                  className="flex-line centered"
                                  onClick={() => setFieldValue('receipt.dishes.combo_choice_names', !field.value)}
                                >
                                  <Switch
                                    id="customization_dishes_combo_choice_names"
                                    checked={field.value}
                                    onChange={e => setFieldValue('receipt.dishes.combo_choice_names', e.target.checked)}
                                  />
                                  <p className="m-l-3">Show Combo Item Choice Headings</p>
                                </ScrollListItem>
                              )}
                            />
                            <FastField
                              name="receipt.dishes.disable_print_name"
                              render={({ field }: any) => (
                                <ScrollListItem
                                  className="flex-line centered"
                                  onClick={() => setFieldValue('receipt.dishes.disable_print_name', !field.value)}
                                >
                                  <Switch
                                    id="customization_dishes_disable_print_name"
                                    checked={field.value || false}
                                    onChange={e => setFieldValue('receipt.dishes.disable_print_name', e.target.checked)}
                                  />
                                  <p className="m-l-3">Disable Item Printing Name</p>
                                </ScrollListItem>
                              )}
                            />
                          </ScrollList>
                        </FormGroup>

                        <FastField
                          name="receipt.dishes.sort_type"
                          render={({ field }: any) => (
                            <FormGroup
                              title="Item Sorting"
                              help={<span>Choose what type of item in menu will order by</span>}
                            >
                              <SelectAdv
                                type="single"
                                options={[
                                  {
                                    value: SortType.ByCart.toString(),
                                    label: 'Sort By Cart',
                                  },
                                  {
                                    value: SortType.ByCategory.toString(),
                                    label: 'Sort By Category',
                                  },
                                ]}
                                value={field.value || SortType.ByCart.toString()}
                                onChange={(option: string) => setFieldValue('receipt.dishes.sort_type', option)}
                              />
                            </FormGroup>
                          )}
                        />

                        <FastField
                          name="receipt.dishes.cutting_per_category"
                          render={({ field }: any) => (
                            <FormGroup
                              title="Receipt Cutting Per Category"
                              help="Turn it on will make this printer cut dockets/receipts between categories. Only available in ESCPOS Text Only Mode and Item Sorting is Sort By Category."
                            >
                              <Switch
                                id="customization_receipt_cutting_per_category"
                                checked={field.value || false}
                                onChange={e => setFieldValue('receipt.dishes.cutting_per_category', e.target.checked)}
                              />
                            </FormGroup>
                          )}
                        />

                        <FastField
                          name="receipt.eligible_items"
                          render={({ field }: any) => (
                            <FormGroup
                              title="Eligible Items"
                              help="Select which items should be printed on this printer."
                            >
                              <FormTreeSelect
                                id="category_selection"
                                selected={field.value || this.gatherAllChildrenNodes()}
                                // @ts-ignore
                                nodes={this.menuTree}
                                onChange={values => {
                                  setFieldValue('receipt.eligible_items', values);
                                }}
                              />
                            </FormGroup>
                          )}
                        />

                        <FastField
                          name="receipt.totals"
                          render={({ field }: any) => (
                            <FormGroup
                              title="Show Order Totals"
                              help="Disabling this will remove the order totals including any discounts or taxes from the bottom of the receipt"
                            >
                              <Switch
                                id="customization_receipt_total"
                                checked={field.value}
                                onChange={e => setFieldValue('receipt.totals', e.target.checked)}
                              />
                            </FormGroup>
                          )}
                        />
                      </ModalContent>
                    )}

                    {tab === '3' && (
                      <ModalContent>
                        <FormGroup
                          title="Print Logo"
                          help="Print the logo center on the top of your receipt. You can set the logo in Setting > Receipt Printing > Receipt Logo. The logo won’t work on ESCPOS text mode."
                        >
                          <FastField
                            name="logo"
                            render={({ field }: any) => (
                              <Switch
                                id="printer_receipt_logo"
                                checked={field.value || false}
                                onChange={e => setFieldValue('logo', e.target.checked)}
                              />
                            )}
                          />

                          <div className="p-t-4">
                            <LogoRender
                              image={this.currentLogo}
                              transformations={{
                                resize: 'x150',
                                format: 'auto',
                              }}
                            />
                          </div>
                        </FormGroup>
                        <FormGroup
                          optional
                          title="Receipt Header"
                          help="Customize your receipt header with information such as your store name, business number, etc."
                        >
                          <FastField
                            name="header"
                            render={({ field }: any) => (
                              <HTMLEditor
                                id="receipt-header-html"
                                initialValue={field.value}
                                onChange={val => setFieldValue('header', val)}
                              />
                            )}
                          />
                        </FormGroup>
                        <FormGroup
                          optional
                          title="Receipt Footer"
                          help="Customize your receipt footer with information such as promotions or a thank you message"
                        >
                          <FastField
                            name="footer"
                            render={({ field }: any) => (
                              <HTMLEditor
                                id="receipt-footer-html"
                                initialValue={field.value}
                                onChange={val => setFieldValue('footer', val)}
                              />
                            )}
                          />
                        </FormGroup>
                      </ModalContent>
                    )}

                    {tab === '4' && (
                      <ModalContent>
                        <FastField
                          name="mqtt.enabled"
                          render={({ field }: any) => (
                            <FormGroup
                              title="Enable MQTT Printing"
                              help="Disabling this stop sending the orders to the MQTT topics below"
                            >
                              <Switch
                                id="mqtt.enabled"
                                checked={field.value}
                                onChange={e => setFieldValue('mqtt.enabled', e.target.checked)}
                              />
                            </FormGroup>
                          )}
                        />

                        <FastField
                          name="mqtt.server"
                          render={({ field }: any) => (
                            <FormGroup title="MQTT Server" help="The hostname of the mqtt server">
                              <Input type="text" {...field} />
                            </FormGroup>
                          )}
                        />

                        <FastField
                          name="mqtt.port"
                          render={({ field }: any) => (
                            <FormGroup title="MQTT Server Port" help="The port of the mqtt host">
                              <Input type="text" {...field} />
                            </FormGroup>
                          )}
                        />

                        <FastField
                          name="mqtt.username"
                          render={({ field }: any) => (
                            <FormGroup title="MQTT Username" help="The MQTT username">
                              <Input type="text" {...field} />
                            </FormGroup>
                          )}
                        />

                        <FastField
                          name="mqtt.password"
                          render={({ field }: any) => (
                            <FormGroup title="MQTT Password" help="The MQTT password">
                              <Input type="text" {...field} />
                            </FormGroup>
                          )}
                        />

                        <FastField
                          name="mqtt.topics"
                          render={({ field }: any) => (
                            <FormGroup title="MQTT Topics" help="The MQTT topics separated by commas">
                              <Input type="text" {...field} />
                            </FormGroup>
                          )}
                        />

                        <FastField
                          name="mqtt.qos"
                          render={({ field }: any) => (
                            <FormGroup
                              title="MQTT QoS"
                              help="The Quality of Service of the message. 1, 2, or 3. A QoS of 2 will only allow 1 copy of the order to be sent. Also, the MQTT client (your printer) will override this value. More information: https://www.hivemq.com/blog/mqtt-essentials-part-6-mqtt-quality-of-service-levels/"
                            >
                              <Input type="number" {...field} />
                            </FormGroup>
                          )}
                        />

                        <FastField
                          name="mqtt.copies"
                          render={({ field }: any) => (
                            <FormGroup
                              title="MQTT Copies"
                              help="The number of copies of each order to be sent to each printer. This will only work with a QoS of 1."
                            >
                              <Input type="number" {...field} />
                            </FormGroup>
                          )}
                        />

                        <FormGroup>
                          <div>
                            <div
                              style={{
                                marginBottom: '10px',
                                marginTop: '10px',
                              }}
                            >
                              <p
                                style={{
                                  fontSize: '16px',
                                  lineHeight: '18px',
                                }}
                              >
                                If MQTT printing is enabled, the order output will ALSO go to the MQTT topics listed
                                below.
                              </p>
                              <p
                                style={{
                                  fontSize: '16px',
                                  lineHeight: '18px',
                                }}
                              >
                                MQTT printing will not stop printing going through any other means.
                              </p>
                              <p
                                style={{
                                  fontSize: '16px',
                                  lineHeight: '18px',
                                }}
                              >
                                MQTT printing is not dependant on whether this printer is online.
                              </p>
                              <p
                                style={{
                                  fontSize: '16px',
                                  lineHeight: '18px',
                                }}
                              >
                                MQTT printing is not dependant on whether this printer is ENABLED. If MQTT is enabled,
                                the order will be sent regardless.
                              </p>
                            </div>
                          </div>
                        </FormGroup>
                      </ModalContent>
                    )}

                    <ModalContent>
                      {error && <FormGroup error={error} />}
                      <Button full color="primary" type="submit" disabled={isSubmitting}>
                        {isSubmitting && <RotateLoader size={2} color="white" />}
                        {!isSubmitting && 'Save'}
                      </Button>
                    </ModalContent>
                  </div>
                );
              }}
            </RestaurantForm>
          </Modal>
        );
      }
    },
  ),
);
