import React from 'react';
import ReactDOM from 'react-dom';
import RenderApp from './react/render';
import { RootStore } from './mobx/store';

const store = new RootStore();

function renderApp() {
  const root = document.getElementById('root');
  if (root) {
    ReactDOM.render(<RenderApp store={store} />, root);
  }
}

window.onload = () => {
  renderApp();
};
