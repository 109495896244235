import React from 'react';
import { FastField } from 'formik';
import {
  FormGroup,
  Input,
  SelectAdv,
  InputTags,
  Button,
  RotateLoader,
  TabSelect,
  Switch,
  SwitchListSortable,
  ScrollList,
  ScrollListItem,
  LogoRender,
} from '@lib/components';
import cloneDeep from 'lodash/cloneDeep';
import validator from 'validator';
import { inject, observer } from 'mobx-react';
import { printDetailListCustomer, SortType } from '@lib/common';
import { arrayMove } from 'react-sortable-hoc';
import { HTMLEditor } from '../../../../../../components/html-editor';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';
import { MobxComponent } from '../../../../../../../mobx/components/index';
import { transfromCheckoutFieldsToReceiptPrintOptions } from '../../../../../../../core/libs/receipt';
import { UI } from '../../../../../../../core/ui/index';
import { getOptionalOverviewReportEmail } from '../../../../../../utils/overview_report_email';

interface Props {}
interface State {
  tab: string;
}
type FormValues = T.Schema.Restaurant.RestaurantSchema['settings']['notifications'];

export const SettingsFormNotification = inject('store')(
  observer(
    class SettingsFormNotification extends MobxComponent<Props, State> {
      initialValues: FormValues;

      currentLogo: T.Core.Business.BusinessImage | undefined | null;

      constructor(props: Props) {
        super(props);
        const r = this.injected.store.restaurant!;
        this.state = {
          tab: '0',
        };

        this.initialValues = cloneDeep(r.settings.notifications);
        this.currentLogo = r.website.sections.top_nav.images.logo;
      }

      render() {
        const { tab } = this.state;
        const r = this.injected.store.restaurant!;
        const optionalOverviewReportEmail = getOptionalOverviewReportEmail(r.settings.notifications.email.store);

        return (
          <div>
            <TabSelect
              id="notifications-tab-options"
              className="border-white-tb-10"
              hasBorder
              screenWidth={this.injected.store.view.screen_width}
              onChange={v => this.setState({ tab: v.value })}
              value={tab}
              values={[
                { label: 'Store', value: '0' },
                { label: 'Customer', value: '1' },
                { label: 'PDF Receipt', value: '2' },
              ]}
            />

            <RestaurantForm<FormValues>
              submit={async (r, v) => {
                r.settings.notifications = v;
                const update = {
                  $set: { 'settings.notifications': v },
                };
                return { r, update };
              }}
              validators=
              initialValues={this.initialValues}
              onSuccess={() => {}}
              onError={() => UI.notification.error('An error occurred')}
              onSuccessMessage="Settings Updated"
              onErrorMessage=""
            >
              {({ form, error }) => {
                const { isSubmitting, setFieldValue } = form;

                return (
                  <div className="p-4">
                    <FormGroup>
                      {tab === '0' && (
                        <div>
                          <FastField
                            name="email.store.support_reply_to"
                            render={({ field }: any) => (
                              <FormGroup
                                optional
                                title="Notification Reply-To Email"
                                help="Use this to update the reply-to email address for notifications. This defaults to the account owner's email address."
                              >
                                <Input
                                  type="email"
                                  placeholder="Enter an e-mail address"
                                  onChange={email => {
                                    validator.isEmail(email.target.value)
                                      ? setFieldValue('email.store.support_reply_to', email.target.value)
                                      : '';
                                  }}
                                  {...field}
                                />
                              </FormGroup>
                            )}
                          />
                          <FastField
                            name="email.store.addresses"
                            render={({ field }: any) => (
                              <FormGroup
                                optional
                                title="Notification E-Mail Addresses"
                                help="Enter up to 3 e-mail addresses where you would like to receive notifications"
                              >
                                <InputTags
                                  max={3}
                                  placeholder="Enter an e-mail and press enter"
                                  validator={val => (validator.isEmail(val) ? null : 'Invalid e-mail address')}
                                  onError={e =>
                                    UI.notification.error(e, {
                                      timeout: 5000,
                                    })
                                  }
                                  tags={field.value}
                                  onChange={tags => setFieldValue('email.store.addresses', tags)}
                                />
                              </FormGroup>
                            )}
                          />
                          <FastField
                            name="email.store.overview_report_emails"
                            render={({ field }: any) => (
                              <FormGroup
                                optional
                                title="Overview Report E-Mail Addresses"
                                help="Enter up to 4 e-mail addresses where you could pick and send Overview Report in Home Section. You also can select from Notification Reply-To Email and Notification Email Addresses (if any). If you want to use other emails, please add them here."
                              >
                                <InputTags
                                  max={4}
                                  placeholder="Enter an e-mail and press enter"
                                  validator={val => (validator.isEmail(val) ? null : 'Invalid e-mail address')}
                                  onError={e =>
                                    UI.notification.error(e, {
                                      timeout: 5000,
                                    })
                                  }
                                  tags={field.value || optionalOverviewReportEmail}
                                  onChange={tags => setFieldValue('email.store.overview_report_emails', tags)}
                                />
                              </FormGroup>
                            )}
                          />
                          <FastField
                            name="email.store.order_created"
                            render={({ field }: any) => (
                              <FormGroup
                                title="Order Created"
                                help="Receive an e-mail notification with a PDF copy of the order receipt when a new order is placed"
                              >
                                <Switch
                                  id="email.store.order_created"
                                  checked={field.value}
                                  onChange={e => setFieldValue('email.store.order_created', e.target.checked)}
                                />
                              </FormGroup>
                            )}
                          />
                          <FastField
                            name="email.store.booking_created"
                            render={({ field }: any) => (
                              <FormGroup
                                title="Booking Created"
                                help="Receive an e-mail notification whenever a new booking is created by a customer"
                              >
                                <Switch
                                  id="email.store.booking_created"
                                  checked={field.value}
                                  onChange={e => setFieldValue('email.store.booking_created', e.target.checked)}
                                />
                              </FormGroup>
                            )}
                          />
                          <FastField
                            name="email.store.age_verification"
                            render={({ field }: any) => (
                              <FormGroup
                                title="Age Verification"
                                help="Receive an e-mail notification whenever a customer uploads their documents for age verification"
                              >
                                <Switch
                                  id="email.store.age_verification"
                                  checked={field.value}
                                  onChange={e => setFieldValue('email.store.age_verification', e.target.checked)}
                                />
                              </FormGroup>
                            )}
                          />
                        </div>
                      )}
                      {tab === '1' && (
                        <div>
                          <FastField
                            name="email.customer.order_created.enabled"
                            render={({ field }: any) => (
                              <FormGroup
                                title="Order Created"
                                help="Customers will receive an e-mail notification with a PDF copy of their order receipt upon placing a new order"
                              >
                                <Switch
                                  id="email.customer.order_created.enabled"
                                  checked={field.value}
                                  onChange={e =>
                                    setFieldValue('email.customer.order_created.enabled', e.target.checked)
                                  }
                                />
                              </FormGroup>
                            )}
                          />
                        </div>
                      )}
                      {tab === '2' && (
                        <div>
                          <FormGroup
                            title="Print Logo"
                            help="Show the restaurant logo center on the top of your PDF receipt. (Set logo in Settings > Website > Top Nav Bar > Logo Image). The logo won’t work on ESCPOS text mode."
                          >
                            <FastField
                              name="email.customer.logo"
                              render={({ field }: any) => (
                                <Switch
                                  id="email_receipt_logo"
                                  checked={field.value || false}
                                  onChange={e => setFieldValue('email.customer.logo', e.target.checked)}
                                />
                              )}
                            />

                            <div className="p-t-4">
                              <LogoRender
                                image={this.currentLogo}
                                transformations={{
                                  resize: 'x150',
                                  format: 'auto',
                                }}
                              />
                            </div>
                          </FormGroup>

                          <FormGroup
                            optional
                            title="Receipt Header"
                            help="Customize your receipt header with information such as your store name, business number, etc."
                          >
                            <FastField
                              name="email.customer.header"
                              render={({ field }: any) => (
                                <HTMLEditor
                                  id="receipt-email-header-html"
                                  initialValue={field.value}
                                  onChange={val => setFieldValue('email.customer.header', val)}
                                />
                              )}
                            />
                          </FormGroup>

                          <FormGroup
                            optional
                            title="Receipt Footer"
                            help="Customize your footer header with information such as promotions or a thank you message"
                          >
                            <FastField
                              name="email.customer.footer"
                              render={({ field }: any) => (
                                <HTMLEditor
                                  id="receipt-email-footer-html"
                                  initialValue={field.value}
                                  onChange={val => setFieldValue('email.customer.footer', val)}
                                />
                              )}
                            />
                          </FormGroup>

                          <FormGroup
                            title="Detail Customization"
                            help="Customize which order details are displayed on the receipt. Rearrange them using the drag handles"
                          >
                            <FastField
                              name="email.customer.order_created.details"
                              render={({ field }: any) => (
                                <SwitchListSortable
                                  height={450}
                                  items={field.value}
                                  onCheck={(i, item) => {
                                    const items = [...field.value];
                                    items[i].enabled = !item.enabled;
                                    setFieldValue('email.customer.order_created.details', items);
                                  }}
                                  units={null}
                                  onSortEnd={({ oldIndex, newIndex }) => {
                                    setFieldValue(
                                      'email.customer.order_created.details',
                                      arrayMove(field.value, oldIndex, newIndex),
                                    );
                                  }}
                                />
                              )}
                            />
                            <Button
                              size="xs"
                              type="button"
                              className="m-t-3"
                              onClick={() => {
                                const options = transfromCheckoutFieldsToReceiptPrintOptions(
                                  this.injected.store.restaurant!,
                                  printDetailListCustomer(),
                                );
                                setFieldValue('email.customer.order_created.details', options);
                              }}
                            >
                              Reset
                            </Button>
                          </FormGroup>

                          <FormGroup
                            title="Item Customization"
                            help="Customize how the items are displayed on your receipts. Useful for cutting down on un-needed information"
                          >
                            <ScrollList height={null}>
                              <FastField
                                name="email.customer.order_created.dishes.enabled"
                                render={({ field }: any) => (
                                  <ScrollListItem
                                    className="flex-line centered"
                                    onClick={() =>
                                      setFieldValue('email.customer.order_created.dishes.enabled', !field.value)
                                    }
                                  >
                                    <Switch
                                      id="customization_dishes_enabled"
                                      checked={field.value}
                                      onChange={e =>
                                        setFieldValue('email.customer.order_created.dishes.enabled', e.target.checked)
                                      }
                                    />
                                    <p className="m-l-3">Show Items</p>
                                  </ScrollListItem>
                                )}
                              />
                              <FastField
                                name="email.customer.order_created.dishes.prices"
                                render={({ field }: any) => (
                                  <ScrollListItem
                                    className="flex-line centered"
                                    onClick={() =>
                                      setFieldValue('email.customer.order_created.dishes.prices', !field.value)
                                    }
                                  >
                                    <Switch
                                      id="customization_dishes_prices"
                                      checked={field.value}
                                      onChange={e =>
                                        setFieldValue('email.customer.order_created.dishes.prices', e.target.checked)
                                      }
                                    />
                                    <p className="m-l-3">Show Item Prices</p>
                                  </ScrollListItem>
                                )}
                              />
                              <FastField
                                name="email.customer.order_created.dishes.option_prices"
                                render={({ field }: any) => (
                                  <ScrollListItem
                                    className="flex-line centered"
                                    onClick={() =>
                                      setFieldValue('email.customer.order_created.dishes.option_prices', !field.value)
                                    }
                                  >
                                    <Switch
                                      id="customization_dishes_option_prices"
                                      checked={field.value}
                                      onChange={e =>
                                        setFieldValue(
                                          'email.customer.order_created.dishes.option_prices',
                                          e.target.checked,
                                        )
                                      }
                                    />
                                    <p className="m-l-3">Show Item Option-set Prices</p>
                                  </ScrollListItem>
                                )}
                              />
                              <FastField
                                name="email.customer.order_created.dishes.combo_choice_names"
                                render={({ field }: any) => (
                                  <ScrollListItem
                                    className="flex-line centered"
                                    onClick={() =>
                                      setFieldValue(
                                        'email.customer.order_created.dishes.combo_choice_names',
                                        !field.value,
                                      )
                                    }
                                  >
                                    <Switch
                                      id="customization_dishes_combo_choice_names"
                                      checked={field.value}
                                      onChange={e =>
                                        setFieldValue(
                                          'email.customer.order_created.dishes.combo_choice_names',
                                          e.target.checked,
                                        )
                                      }
                                    />
                                    <p className="m-l-3">Show Combo Item Choice Headings</p>
                                  </ScrollListItem>
                                )}
                              />
                            </ScrollList>
                          </FormGroup>

                          <FastField
                            name="email.customer.order_created.dishes.sort_type"
                            render={({ field }: any) => (
                              <FormGroup
                                title="Item Sorting"
                                help={<span>Choose what type of item in menu will order by.</span>}
                              >
                                <SelectAdv
                                  type="single"
                                  options={[
                                    {
                                      value: SortType.ByCart.toString(),
                                      label: 'Sort By Cart',
                                    },
                                    {
                                      value: SortType.ByCategory.toString(),
                                      label: 'Sort By Category',
                                    },
                                  ]}
                                  value={field.value || SortType.ByCart.toString()}
                                  onChange={(option: string) =>
                                    setFieldValue('email.customer.order_created.dishes.sort_type', option)
                                  }
                                />
                              </FormGroup>
                            )}
                          />

                          <FastField
                            name="email.customer.order_created.totals"
                            render={({ field }: any) => (
                              <FormGroup
                                title="Show Order Totals"
                                help="Disabling this will remove the order totals including any discounts or taxes from the bottom of the receipt"
                              >
                                <Switch
                                  id="customization_receipt_total"
                                  checked={field.value}
                                  onChange={e => setFieldValue('email.customer.order_created.totals', e.target.checked)}
                                />
                              </FormGroup>
                            )}
                          />
                        </div>
                      )}
                    </FormGroup>

                    {error && <FormGroup error={error} />}

                    <Button full color="primary" type="submit" disabled={isSubmitting}>
                      {isSubmitting && <RotateLoader size={2} color="white" />}
                      {!isSubmitting && 'Save'}
                    </Button>
                  </div>
                );
              }}
            </RestaurantForm>
          </div>
        );
      }
    },
  ),
);
