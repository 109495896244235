import React from 'react';
import { FastField } from 'formik';
import { FormGroup, Button, RotateLoader, Switch, Input } from '@lib/components';
import { inject, observer } from 'mobx-react';
import { MobxComponent } from '../../../../../../../mobx/components/index';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';
import { UI } from '../../../../../../../core/ui';

interface Props {}
interface State {}
interface FormValues {
  business: T.Schema.Restaurant.RestaurantSchema['settings']['business'];
}

export const SettingsFormMisc = inject('store')(
  observer(
    class SettingsFormMisc extends MobxComponent<Props, State> {
      initialValues: FormValues;

      constructor(props: Props) {
        super(props);
        this.state = {};
        const r = this.injected.store.restaurant!;
        this.initialValues = {
          business: r.settings.business,
        };
      }

      validateRegularExpression(value: string) {
        let error;
        try {
          new RegExp(value);
        } catch (e) {
          error = 'Invalid Phone Number RegExp.';
        }
        return error;
      }

      render() {
        return (
          <RestaurantForm<FormValues>
            submit={async (r, values) => {
              r.settings.business = values.business;
              const update = { $set: { 'settings.business': values.business } };
              return { r, update };
            }}
            validators=
            initialValues={this.initialValues}
            onSuccess={() => {}}
            onError={() => UI.notification.error('An error occurred')}
            onSuccessMessage="Settings Updated"
            onErrorMessage=""
          >
            {({ form, error }) => {
              const { isSubmitting, setFieldValue, errors } = form;
              return (
                <div className="p-4">
                  <FormGroup
                    optional
                    title="Phone Number RegExp"
                    help="Enter a javascript regex to validate phone numbers against in order checkout"
                  >
                    <FastField
                      name="business.validation.phone_regex"
                      validate={this.validateRegularExpression}
                      render={({ field }: any) => <Input type="text" {...field} />}
                    />
                  </FormGroup>

                  <FormGroup
                    title="Logged In Only"
                    help="Enable this to blur your store and prevent orders unless a customer has signed in with their account"
                  >
                    <FastField
                      name="business.logged_in_only"
                      render={({ field }: any) => (
                        <Switch
                          id="business.logged_in_only"
                          checked={field.value || false}
                          onChange={e => setFieldValue('business.logged_in_only', e.target.checked)}
                        />
                      )}
                    />
                  </FormGroup>

                  <FormGroup
                    title="Disable Signup's"
                    help="Disable any sign up options available for creating a new customer account. You can manually create accounts in the customers page of your dashboard. This will also disable social login methods"
                  >
                    <FastField
                      name="business.disable_signup"
                      render={({ field }: any) => (
                        <Switch
                          id="business.disable_signup"
                          checked={field.value || false}
                          onChange={e => setFieldValue('business.disable_signup', e.target.checked)}
                        />
                      )}
                    />
                  </FormGroup>

                  <FormGroup
                    title="Service Pop-up Required"
                    help="Enable this setting to force users to choose a pick-up or delivery service before seeing the menu."
                  >
                    <FastField
                      name="business.service_required"
                      render={({ field }: any) => (
                        <Switch
                          id="business.service_required"
                          checked={field.value || false}
                          onChange={e => setFieldValue('business.service_required', e.target.checked)}
                        />
                      )}
                    />
                  </FormGroup>

                  {error && <FormGroup error={error} />}
                  {
                    // @ts-ignore
                    errors.business?.validation?.phone_regex && (
                      // @ts-ignore
                      <FormGroup error={errors.business?.validation?.phone_regex} />
                    )
                  }

                  <Button full color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting && <RotateLoader size={2} color="white" />}
                    {!isSubmitting && 'Save'}
                  </Button>
                </div>
              );
            }}
          </RestaurantForm>
        );
      }
    },
  ),
);
