import { inject, observer } from 'mobx-react';
import { Modal, ModalContent, ModalTitle, FormGroup, Switch, Button, RotateLoader, TabSelect } from '@lib/components';
import { FastField } from 'formik';
import React from 'react';
import { RestaurantForm } from '../../../../../mobx/components/restaurant-form';
import { MobxComponent } from '../../../../../mobx/components';
import { ServiceAutoStatusFields } from '../settings/forms/services/base';

interface Props {
  active: boolean;
  close: () => void;
}
interface State {
  tab: string;
}

export const RestaurantOrdersQuickSettings = inject('store')(
  observer(
    class RestaurantOrdersQuickSettings extends MobxComponent<Props, State> {
      constructor(props: Props) {
        super(props);
        this.state = {
          tab: '0',
        };
      }

      componentDidUpdate(prevProps: Props) {
        if (this.props.active && !prevProps.active) {
          this.setState({ tab: '0' });
        }
      }

      render() {
        const { active, close } = this.props;
        const { tab } = this.state;
        const R = this.injected.store.restaurant!;
        return (
          <Modal width="md" alignTop active={active} close={close}>
            <ModalContent>
              <h3 className="">Quick Service Settings</h3>
            </ModalContent>

            <TabSelect
              id="quick-settings-tab-options"
              className="border-white-tb-15"
              hasBorder
              screenWidth={this.injected.store.view.screen_width}
              onChange={v => this.setState({ tab: v.value })}
              value={tab}
              values={[
                { label: 'Pickup', value: '0' },
                { label: 'Delivery', value: '1' },
                { label: 'Dine-In', value: '2' },
              ]}
            />

            {tab === '0' && (
              <RestaurantForm<T.Schema.Restaurant.RestaurantSchema['settings']['services']['pickup']>
                submit={async (r, values) => {
                  r.settings.services.pickup = values;
                  const update = { $set: { 'settings.services.pickup': r.settings.services.pickup } };
                  return { r, update };
                }}
                validators=
                initialValues={R.settings.services.pickup}
                onSuccess={close}
                onSuccessMessage="Settings updated"
                onErrorMessage=""
              >
                {({ form, error }) => {
                  const { isSubmitting, setFieldValue } = form;
                  return (
                    <div>
                      <ModalContent>
                        <FastField
                          name="enabled"
                          render={({ field }: any) => (
                            <FormGroup
                              title="Enabled"
                              help="Allow customers to place orders that can be collected at your store location"
                            >
                              <Switch
                                id="enable-pickup-switch"
                                checked={field.value}
                                onChange={e => setFieldValue('enabled', e.target.checked)}
                              />
                            </FormGroup>
                          )}
                        />
                      </ModalContent>

                      <ModalTitle paddingtb={15}>
                        <p className="big font-semi-bold">Wait Times & Auto Status</p>
                      </ModalTitle>

                      <ModalContent>
                        <ServiceAutoStatusFields service="pickup" values={form.values} />
                      </ModalContent>

                      <ModalContent>
                        {error && <FormGroup error={error} />}
                        <Button full color="primary" type="submit" disabled={isSubmitting}>
                          {isSubmitting && <RotateLoader size={2} color="white" />}
                          {!isSubmitting && 'Save'}
                        </Button>
                      </ModalContent>
                    </div>
                  );
                }}
              </RestaurantForm>
            )}

            {tab === '1' && (
              <RestaurantForm<T.Schema.Restaurant.RestaurantSchema['settings']['services']['delivery']>
                submit={async (r, values) => {
                  r.settings.services.delivery = values;
                  const update = { $set: { 'settings.services.delivery': r.settings.services.delivery } };
                  return { r, update };
                }}
                validators=
                initialValues={R.settings.services.delivery}
                onSuccess={close}
                onSuccessMessage="Settings updated"
                onErrorMessage=""
              >
                {({ form, error }) => {
                  const { isSubmitting, setFieldValue } = form;
                  return (
                    <div>
                      <ModalContent>
                        <FastField
                          name="enabled"
                          render={({ field }: any) => (
                            <FormGroup
                              title="Enabled"
                              help="Allow customers to place orders that will be delivered to their address"
                            >
                              <Switch
                                id="delivery-enabled-switch"
                                checked={field.value}
                                onChange={e => setFieldValue('enabled', e.target.checked)}
                              />
                            </FormGroup>
                          )}
                        />
                      </ModalContent>

                      <ModalTitle paddingtb={15}>
                        <p className="big font-semi-bold">Wait Times & Auto Status</p>
                      </ModalTitle>

                      <ModalContent>
                        <ServiceAutoStatusFields service="delivery" values={form.values} />
                      </ModalContent>

                      <ModalContent>
                        {error && <FormGroup error={error} />}
                        <Button full color="primary" type="submit" disabled={isSubmitting}>
                          {isSubmitting && <RotateLoader size={2} color="white" />}
                          {!isSubmitting && 'Save'}
                        </Button>
                      </ModalContent>
                    </div>
                  );
                }}
              </RestaurantForm>
            )}

            {tab === '2' && (
              <RestaurantForm<T.Schema.Restaurant.RestaurantSchema['settings']['services']['dine_in']>
                submit={async (r, values) => {
                  r.settings.services.dine_in = values;
                  const update = { $set: { 'settings.services.dine_in': r.settings.services.dine_in } };
                  return { r, update };
                }}
                validators=
                initialValues={R.settings.services.dine_in}
                onSuccess={close}
                onSuccessMessage="Settings updated"
                onErrorMessage=""
              >
                {({ form, error }) => {
                  const { isSubmitting, setFieldValue } = form;
                  return (
                    <div>
                      <ModalContent>
                        <FastField
                          name="enabled"
                          render={({ field }: any) => (
                            <FormGroup title="Enabled" help="Allow customers to place orders while seated at a table">
                              <Switch
                                id="dine-in-pickup-switch"
                                checked={field.value}
                                onChange={e => setFieldValue('enabled', e.target.checked)}
                              />
                            </FormGroup>
                          )}
                        />
                      </ModalContent>

                      <ModalTitle paddingtb={15}>
                        <p className="big font-semi-bold">Wait Times & Auto Status</p>
                      </ModalTitle>

                      <ModalContent>
                        <ServiceAutoStatusFields service="dine_in" values={form.values} />
                      </ModalContent>

                      <ModalContent>
                        {error && <FormGroup error={error} />}
                        <Button full color="primary" type="submit" disabled={isSubmitting}>
                          {isSubmitting && <RotateLoader size={2} color="white" />}
                          {!isSubmitting && 'Save'}
                        </Button>
                      </ModalContent>
                    </div>
                  );
                }}
              </RestaurantForm>
            )}
          </Modal>
        );
      }
    },
  ),
);
