import React from 'react';
import { FastField } from 'formik';
import { FormGroup, Button, RotateLoader, ColorPicker, Input, FormUpload } from '@lib/components';
import { inject, observer } from 'mobx-react';
import { MobxComponent } from '../../../../../../../mobx/components';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';
import { UI } from '../../../../../../../core/ui';

interface Props {}
interface State {}
type FormValues = T.Schema.Restaurant.RestaurantSchema['website']['sections']['header'];

export const SettingsFormDesignHeader = inject('store')(
  observer(
    class SettingsFormDesignHeader extends MobxComponent<Props, State> {
      initialValues: FormValues;

      constructor(props: Props) {
        super(props);
        const r = this.injected.store.restaurant!;
        this.state = {};
        this.initialValues = r.website.sections.header;
      }

      render() {
        const col_props = {
          className: 'col',
          small_title: true,
          no_border: true,
        };
        return (
          <RestaurantForm<FormValues>
            submit={async (r, values) => {
              r.website.sections.header = values;
              const update = { $set: { 'website.sections.header': values } };
              return { r, update };
            }}
            validators=
            initialValues={this.initialValues}
            onSuccess={() => {}}
            onError={() => UI.notification.error('An error occurred')}
            onSuccessMessage="Settings Updated"
            onErrorMessage=""
          >
            {({ form, error }) => {
              const { isSubmitting, setFieldValue } = form;
              return (
                <div className="p-4">
                  <FormGroup title="Colors" contentClassName="grid-2 sm sm-gap max300">
                    <FastField
                      name="colors.background"
                      render={({ field }: any) => (
                        <FormGroup title="Background" {...col_props}>
                          <ColorPicker
                            color={field.value}
                            onChange={color => setFieldValue('colors.background', color)}
                          />
                        </FormGroup>
                      )}
                    />
                    <FastField
                      name="colors.text"
                      render={({ field }: any) => (
                        <FormGroup title="Text" {...col_props}>
                          <ColorPicker color={field.value} onChange={color => setFieldValue('colors.text', color)} />
                        </FormGroup>
                      )}
                    />
                  </FormGroup>

                  <FastField
                    name="images.background"
                    render={({ field }: any) => (
                      <FormGroup
                        optional
                        title="Header Background Image"
                        help="This image will be stretched across your users screen. It's recommend to use a an image at least 1900x500 pixels. This will override the header background color. Max file size is 1mb. File name can't contain special charaters"
                      >
                        <FormUpload
                          maxSize={1000}
                          imagesOnly
                          stockImages
                          value={[field.value]}
                          onRemove={() => setFieldValue('images.background', null)}
                          onChange={files => setFieldValue('images.background', files[0])}
                          stockImageQuery="?fit=min&w=1920&h=800&q=60"
                        />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="colors.tint"
                    render={({ field }: any) => (
                      <FormGroup
                        optional
                        title="Header Background Image Tint"
                        help={
                          <span>
                            Add a tint over your background image to darken or lighten it to increase text legibility.
                            Ensure your <span className="font-semi-bold underline">tint is transparent</span> to avoid
                            covering the background image
                          </span>
                        }
                      >
                        <div className="flex-line centered">
                          <ColorPicker color={field.value} onChange={color => setFieldValue('colors.tint', color)} />
                          <Button
                            type="button"
                            className="m-l-3"
                            size="xxs"
                            onClick={() => setFieldValue('colors.tint', '')}
                          >
                            Clear
                          </Button>
                        </div>
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="text.title"
                    render={({ field }: any) => (
                      <FormGroup
                        optional
                        title="Header Title"
                        help="Defaults to the name of your restaurant. Use a single space to hide"
                      >
                        <Input type="text" {...field} />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="text.subtitle"
                    render={({ field }: any) => (
                      <FormGroup
                        optional
                        title="Header Subtitle"
                        help="Text displayed below your header title. Leave blank to hide"
                      >
                        <Input type="text" {...field} />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="images.logo"
                    render={({ field }: any) => (
                      <FormGroup
                        optional
                        title="Header Logo Image"
                        help="Logo image displayed above your header title. The image will be displayed at resolution you upload it. File name can't contain special charaters"
                      >
                        <FormUpload
                          maxSize={300}
                          imagesOnly
                          value={[field.value]}
                          onRemove={() => setFieldValue('images.logo', null)}
                          onChange={files => setFieldValue('images.logo', files[0])}
                        />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="images.logo_link"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Header Logo Link"
                        help="If a URL is entered, your logo image will link to this URL when clicked. Make sure to include http:// or https://"
                      >
                        <Input type="url" {...field} />
                      </FormGroup>
                    )}
                  />

                  {error && <FormGroup error={error} />}

                  <Button full color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting && <RotateLoader size={2} color="white" />}
                    {!isSubmitting && 'Save'}
                  </Button>
                </div>
              );
            }}
          </RestaurantForm>
        );
      }
    },
  ),
);
