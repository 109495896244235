import React from 'react';
import { inject, observer } from 'mobx-react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { styled, Button, Modal, ModalContent, ModalTitle, Input, FormGroup } from '@lib/components';
import { getLocalDate, getLocalTime } from '@lib/common';
import { MobxComponent } from '../../../../../mobx/components';

interface State {
  loading: boolean;
  message: string;
  isVisibleRequestReportButton: boolean;
  isVisibleCloseButton: boolean;
}

interface Props extends WithTranslation {
  payout: T.Schema.Stripe.StripePayout;
  restaurant: T.Schema.Restaurant.RestaurantSchema;
  showModal: boolean;
  onClose: () => void;
}

export const ItemButton = styled(Button)`
  color: ${({ theme }) => theme.colors.primary_text};
  background-color: ${({ theme }) => theme.colors.primary};
  margin: auto;
  display: block;
`;

const PayoutModalClass = inject('store')(
  observer(
    class PayoutModalClass extends MobxComponent<Props, State> {
      constructor(props: Props) {
        super(props);
        this.state = {
          loading: false,
          message: '',
          isVisibleRequestReportButton: false,
          isVisibleCloseButton: false,
        };
      }

      handleModalClose = () => {
        this.props.onClose();
        this.showMessage('');
        this.showRequestReportButton(false);
        this.showCloseButton(false);
      };

      showMessage = (message: string) => {
        this.setState({ message });
      };

      showRequestReportButton = (show: boolean) => {
        this.setState({ isVisibleRequestReportButton: show });
      };

      showCloseButton = (show: boolean) => {
        this.setState({ isVisibleCloseButton: show });
      };

      render() {
        const { showModal } = this.props;
        const { payout } = this.props;
        const { restaurant } = this.props;
        const tz = restaurant.settings.region.timezone;
        const isManualRequest = this.state.isVisibleRequestReportButton;
        const showCloseButton = this.state.isVisibleCloseButton;

        // TODO: May need to retrieve more data to retrieve fees and other breakdown.

        const downloadPayoutDetails = async (payout: T.Schema.Stripe.StripePayout) => {
          const res = await this.injected.store.api.stripe_connect_payout_details({
            restaurant_id: restaurant._id,
            payout_id: payout.id,
          });
          if (res && res.outcome === 0) {
            // success
            const element = document.createElement('a');
            const file = new Blob([res.data], { type: 'text/csv' });
            element.href = URL.createObjectURL(file);
            element.download = res.fileName;
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
            this.showMessage('');
          } else if (res.outcome === 1 && res.code === 'report_empty') {
            this.showRequestReportButton(true);
            this.showMessage(res.message);
          } else {
            this.showMessage(res.message);
          }
        };

        const requestManualSending = async (payout: T.Schema.Stripe.StripePayout) => {
          const res = await this.injected.store.api.stripe_connect_payout_request_report({
            restaurant_id: restaurant._id,
            payout_id: payout.id,
          });
          if (res && res.outcome === 0) {
            // success
            this.showMessage("Report requested. We'll send it as soon as it's available.");
            this.showRequestReportButton(false);
            this.showCloseButton(true);
          } else {
            this.showMessage(res.message);
          }
        };

        return (
          <Modal
            width="xs"
            active={showModal}
            preventClose
            id="stripe-connect-payout-modal"
            close={this.handleModalClose}
          >
            <ModalTitle className="round-top">
              <h4>Payout Details</h4>
            </ModalTitle>

            <ModalContent>
              <FormGroup title="Created">
                <Input
                  value={`${getLocalDate(payout.created * 1000, tz)} ${getLocalTime(payout.created * 1000, tz)}`}
                  readOnly
                />
              </FormGroup>

              <FormGroup title={`Amount (${payout.currency.toUpperCase()})`}>
                <Input value={(payout.amount / 100).toFixed(2)} readOnly />
              </FormGroup>

              <FormGroup title="Status">
                <Input value={payout.status} readOnly />
              </FormGroup>

              <FormGroup title="Reference">
                <Input value={payout.id} readOnly />
              </FormGroup>

              {this.state.message && (
                <FormGroup>
                  <p>{this.state.message}</p>
                </FormGroup>
              )}
              {!this.state.message && (
                <FormGroup>
                  <p>Note: Downloading payout details may take a while the first time.</p>
                </FormGroup>
              )}

              {!isManualRequest && !showCloseButton && (
                <ItemButton
                  type="button"
                  paddinglr={10}
                  onClick={async e => {
                    e.stopPropagation();
                    // call download
                    downloadPayoutDetails(payout);
                  }}
                >
                  Download
                </ItemButton>
              )}
              {isManualRequest && !showCloseButton && (
                <ItemButton
                  type="button"
                  paddinglr={10}
                  onClick={async e => {
                    e.stopPropagation();
                    // call download
                    requestManualSending(payout);
                  }}
                >
                  Request Report
                </ItemButton>
              )}
              {showCloseButton && (
                <ItemButton
                  type="button"
                  paddinglr={10}
                  onClick={async e => {
                    e.stopPropagation();
                    // call download
                    this.handleModalClose();
                  }}
                >
                  Close
                </ItemButton>
              )}
            </ModalContent>
          </Modal>
        );
      }
    },
  ),
);

export const PayoutModal = withTranslation()(PayoutModalClass);
