import { darken } from 'polished';
import { styled } from '@lib/components';

export const SettingsSectionHeading = styled('div')`
  width: 100%;
  background: ${darken(0.03, 'white')};
  border-bottom: 1px solid ${darken(0.1, 'white')};
  padding: 10px 20px;
  p {
    font-weight: 600;
    font-size: 13px;
  }
`;
