import React from 'react';
import { Button, Input, Switch, FormGroup, RotateLoader, SelectAdv, ScrollList, ScrollListItem } from '@lib/components';
import { logger } from '@lib/common';
import { Formik, Form, FastField, FormikProps, FormikHelpers as FormikActions } from 'formik';
import { inject, observer } from 'mobx-react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { isEmail } from 'validator';
import { MobxComponent } from '../../../../../mobx/components/index';
import { FormHelpers } from '../../../../../core/form';
import { UI } from '../../../../../core/ui/index';
import { generateStaffRestrictions } from './index';

type FormValues = T.API.StaffCreateRequest;

interface Props extends WithTranslation {
  restaurants: Array<{ value: string; label: string }>;
  initialValues: FormValues;
  type: 'create' | 'edit';
  back: () => void;
}

const restaurantRestrictions = [
  { key: 'restrictions.restaurant.create', label: 'Create' },
  { key: 'restrictions.restaurant.dashboard', label: 'Dashboard' },
  { key: 'restrictions.restaurant.orders_board', label: 'Orders - Board View' },
  { key: 'restrictions.restaurant.orders_list', label: 'Orders - List View' },
  { key: 'restrictions.restaurant.orders_delete', label: 'Orders - Delete' },
  { key: 'restrictions.restaurant.bookings', label: 'Bookings' },
  { key: 'restrictions.restaurant.bookings_delete', label: 'Bookings - Delete' },
  { key: 'restrictions.restaurant.customers', label: 'Customers' },
  { key: 'restrictions.restaurant.customers_actions', label: 'Customers - Create, Edit, Delete' },
  { key: 'restrictions.restaurant.menus', label: 'Menus' },
  { key: 'restrictions.restaurant.settings_detail.system', label: 'Settings - System' },
  { key: 'restrictions.restaurant.settings_detail.services', label: 'Settings - Services' },
  { key: 'restrictions.restaurant.settings_detail.payments', label: 'Settings - Payments' },
  { key: 'restrictions.restaurant.settings_detail.website', label: 'Settings - Website' },
  { key: 'restrictions.restaurant.settings_detail.integrations', label: 'Settings - Integrations' },
  { key: 'restrictions.restaurant.billing', label: 'Billing' },
  { key: 'restrictions.restaurant.delete', label: 'Delete' },
];
const websiteRestrictions = [
  { key: 'restrictions.website.create', label: 'Create' },
  { key: 'restrictions.website.edit', label: 'Edit' },
  { key: 'restrictions.website.delete', label: 'Delete' },
];

const onlinePaymentsRestrictions = [
  { key: 'restrictions.online_payment.view_transaction', label: 'View Transactions' },
];
const staffRestrictions = [
  { key: 'restrictions.staff.create', label: 'Create' },
  { key: 'restrictions.staff.edit', label: 'Edit' },
  { key: 'restrictions.staff.delete', label: 'Delete' },
];
const apiRestrictions = [
  { key: 'restrictions.api.create', label: 'Create' },
  { key: 'restrictions.api.edit', label: 'Edit' },
  { key: 'restrictions.api.delete', label: 'Delete' },
];
const miscRestrictions = [{ key: 'restrictions.misc.hide_prices', label: 'Hide Prices' }];

const StaffFormClass = inject('store')(
  observer(
    class StaffFormClass extends MobxComponent<Props, { error: string }> {
      constructor(props: Props) {
        super(props);
        this.state = {
          error: '',
        };
      }

      onSubmit = async (values: FormValues, form: FormikActions<FormValues>) => {
        const { type, back } = this.props;
        const { store } = this.injected;
        const isCreate = type === 'create';
        try {
          this.setState({ error: '' });

          values.restrictions!.restaurant.settings = false;

          const res =
            type === 'create'
              ? await this.injected.store.api.staff_create(values)
              : await this.injected.store.api.staff_update(values);

          form.setSubmitting(false);
          if (res.outcome) {
            this.setState({ error: res.message });
            return;
          }
          const { staff } = res;
          const items = [...this.injected.store.staff.items];
          if (isCreate) {
            items.push(staff);
          } else {
            const index = items.findIndex(s => s.email === values.email);
            items[index] = staff;
          }
          store.updateStaff({ items });
          UI.notification.success('Staff saved');
          back();
        } catch (e) {
          logger.captureException(e);
          this.setState({ error: 'Error saving staff, please try again or contact us' });
          form.setSubmitting(false);
        }
      };

      validate = (values: FormValues) => {
        const { type } = this.props;
        const isCreate = type === 'create';
        const { errors, isError } = FormHelpers.validate<FormValues>(values, {
          email: v => {
            if (!v.email) return { email: 'Required - Enter an email address for your staff account' };
            if (!isEmail(v.email, { require_tld: true })) return { email: 'Invalid e-mail address, please correct it' };
            return undefined;
          },
          password: v => {
            if (isCreate && (values.password.length < 5 || v.password.length > 100)) {
              return { password: 'Invalid password, must be at least 5 characters long' };
            }
            return undefined;
          },
        });
        if (this.state.error && !isError) {
          this.setState({ error: '' });
        } else if (!this.state.error && isError) {
          this.setState({ error: 'There are errors in your form that need correcting, please scroll up' });
        }
        return errors;
      };

      getFieldError = (form: FormikProps<FormValues>, field: keyof FormValues) => FormHelpers.error(form, field);

      presetRestrictions = (
        type: 'admin' | 'kitchen' | 'menu' | 'driver',
        form: FormikProps<T.API.StaffCreateRequest>,
      ) => {
        const c = form.values.restrictions;
        const r = generateStaffRestrictions();

        if (c) {
          r.restaurants = [...c.restaurants];
        }

        if (type === 'admin') {
          form.setFieldValue('restrictions', r);
        } else {
          r.restaurant.create = false;
          r.restaurant.billing = false;
          r.restaurant.delete = false;
          r.restaurant.dashboard = false;
          r.restaurant.orders_board = false;
          r.restaurant.orders_list = false;
          r.restaurant.orders_delete = false;
          r.restaurant.bookings = false;
          r.restaurant.bookings_delete = false;
          r.restaurant.customers = false;
          r.restaurant.customers_actions = false;
          r.restaurant.menus = false;

          if (!r.restaurant.settings_detail) {
            r.restaurant.settings_detail = {};
          }

          r.restaurant.settings_detail.system = false;
          r.restaurant.settings_detail.services = false;
          r.restaurant.settings_detail.payments = false;
          r.restaurant.settings_detail.website = false;
          r.restaurant.settings_detail.integrations = false;

          r.online_payment = {
            view_transaction: false,
          };

          r.website.create = false;
          r.website.edit = false;
          r.website.delete = false;

          r.staff.create = false;
          r.staff.edit = false;
          r.staff.delete = false;

          if (r.api) {
            r.api.create = false;
            r.api.edit = false;
            r.api.delete = false;
          }

          if (r.misc) {
            r.misc.hide_prices = false;
          }

          if (type === 'kitchen') {
            r.restaurant.orders_board = true;
            r.restaurant.orders_list = true;
            r.restaurant.bookings = true;
          } else if (type === 'menu') {
            r.restaurant.menus = true;
          } else if (type === 'driver') {
            r.restaurant.orders_board = true;
          }

          form.setFieldValue('restrictions', r);
        }
      };

      render() {
        const { type, initialValues, restaurants } = this.props;
        const isCreate = type === 'create';
        return (
          <Formik initialValues={initialValues} validate={this.validate} onSubmit={this.onSubmit}>
            {form => {
              const { isSubmitting, setFieldValue, submitCount } = form;
              return (
                <Form>
                  <FastField
                    name="email"
                    render={({ field }: any) => (
                      <FormGroup
                        title="E-Mail"
                        help={
                          isCreate
                            ? 'Enter an e-mail address that will be used by your staff member to login'
                            : 'Cannot be changed, you can delete this user and create another'
                        }
                        error={this.getFieldError(form, 'email')}
                      >
                        <Input type="email" {...field} required disabled={!isCreate} />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="password"
                    render={({ field }: any) => (
                      <FormGroup
                        title={isCreate ? 'Password' : 'Update Password'}
                        help={
                          isCreate
                            ? 'Enter a password that will be used to login. Minimum of 5 characters'
                            : 'Enter a value only if you want to change the users password. Changing a users password will log them out of their current session'
                        }
                        error={this.getFieldError(form, 'password')}
                      >
                        <Input type="password" {...field} required={isCreate} autoComplete="new-password" />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="restrictions.restaurants"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Restaurant Access"
                        help="Restrict your staff member's access to particular restaurants. Leaving this empty will disable access to any restaurants"
                      >
                        <SelectAdv
                          type="multi"
                          value={field.value || []}
                          options={restaurants}
                          onChange={(selectedValues: string[]) => {
                            setFieldValue('restrictions.restaurants', selectedValues);
                          }}
                        />
                      </FormGroup>
                    )}
                  />

                  <FormGroup title="Restriction Presets" contentClassName="child-mr-10 child-mb-5">
                    <Button
                      size="xxs"
                      type="button"
                      color="primary-inverse"
                      className="p-lr-2"
                      onClick={() => this.presetRestrictions('admin', form)}
                    >
                      Manager
                    </Button>
                    <Button
                      size="xxs"
                      type="button"
                      color="primary-inverse"
                      className="p-lr-2"
                      onClick={() => this.presetRestrictions('kitchen', form)}
                    >
                      Front Staff & Kitchen
                    </Button>
                    <Button
                      size="xxs"
                      type="button"
                      color="primary-inverse"
                      className="p-lr-2"
                      onClick={() => this.presetRestrictions('driver', form)}
                    >
                      Driver
                    </Button>
                    <Button
                      size="xxs"
                      type="button"
                      color="primary-inverse"
                      className="p-lr-2"
                      onClick={() => this.presetRestrictions('menu', form)}
                    >
                      Menu Manager
                    </Button>
                  </FormGroup>

                  <FormGroup
                    title="Restaurant Management"
                    help="Restrict your staff member's access to particular pages of the restaurant dashboard"
                  >
                    <ScrollList height={null}>
                      {restaurantRestrictions.map(({ key, label }) => (
                        <FastField
                          key={key}
                          name={key}
                          render={({ field }: any) => (
                            <ScrollListItem
                              className="flex-line centered"
                              onClick={() => setFieldValue(key, !field.value)}
                            >
                              <Switch
                                id={key}
                                checked={field.value || false}
                                wrapperProps={{ style: { transform: 'scale(0.8)' } }}
                                onChange={e => setFieldValue(key, e.target.checked)}
                              />
                              <p className="m-l-3">{label}</p>
                            </ScrollListItem>
                          )}
                        />
                      ))}
                    </ScrollList>
                  </FormGroup>

                  <FormGroup
                    title="Website Management"
                    help="Restrict your staff member's access to particular website management functions"
                  >
                    <ScrollList height={null}>
                      {websiteRestrictions.map(({ key, label }) => (
                        <FastField
                          key={key}
                          name={key}
                          render={({ field }: any) => (
                            <ScrollListItem
                              className="flex-line centered"
                              onClick={() => setFieldValue(key, !field.value)}
                            >
                              <Switch
                                id={key}
                                checked={field.value || false}
                                wrapperProps={{ style: { transform: 'scale(0.8)' } }}
                                onChange={e => setFieldValue(key, e.target.checked)}
                              />
                              <p className="m-l-3">{label}</p>
                            </ScrollListItem>
                          )}
                        />
                      ))}
                    </ScrollList>
                  </FormGroup>

                  <FormGroup
                    title="Online Payments"
                    help="Restrict your staff member's access to particular online payments functions"
                  >
                    <ScrollList height={null}>
                      {onlinePaymentsRestrictions.map(({ key, label }) => (
                        <FastField
                          key={key}
                          name={key}
                          render={({ field }: any) => (
                            <ScrollListItem
                              className="flex-line centered"
                              onClick={() => setFieldValue(key, !field.value)}
                            >
                              <Switch
                                id={key}
                                checked={field.value || false}
                                wrapperProps={{ style: { transform: 'scale(0.8)' } }}
                                onChange={e => setFieldValue(key, e.target.checked)}
                              />
                              <p className="m-l-3">{label}</p>
                            </ScrollListItem>
                          )}
                        />
                      ))}
                    </ScrollList>
                  </FormGroup>

                  <FormGroup
                    title="Staff Management"
                    help="Restrict your staff member's access to particular staff management functions"
                  >
                    <ScrollList height={null}>
                      {staffRestrictions.map(({ key, label }) => (
                        <FastField
                          key={key}
                          name={key}
                          render={({ field }: any) => (
                            <ScrollListItem
                              className="flex-line centered"
                              onClick={() => setFieldValue(key, !field.value)}
                            >
                              <Switch
                                id={key}
                                checked={field.value || false}
                                wrapperProps={{ style: { transform: 'scale(0.8)' } }}
                                onChange={e => setFieldValue(key, e.target.checked)}
                              />
                              <p className="m-l-3">{label}</p>
                            </ScrollListItem>
                          )}
                        />
                      ))}
                    </ScrollList>
                  </FormGroup>

                  <FormGroup
                    title="API Management"
                    help="Restrict your staff member's access to particular api management functions"
                  >
                    <ScrollList height={null}>
                      {apiRestrictions.map(({ key, label }) => (
                        <FastField
                          key={key}
                          name={key}
                          render={({ field }: any) => (
                            <ScrollListItem
                              className="flex-line centered"
                              onClick={() => setFieldValue(key, !field.value)}
                            >
                              <Switch
                                id={key}
                                checked={field.value || false}
                                wrapperProps={{ style: { transform: 'scale(0.8)' } }}
                                onChange={e => setFieldValue(key, e.target.checked)}
                              />
                              <p className="m-l-3">{label}</p>
                            </ScrollListItem>
                          )}
                        />
                      ))}
                    </ScrollList>
                  </FormGroup>

                  <FormGroup title="Misc">
                    <ScrollList height={null}>
                      {miscRestrictions.map(({ key, label }) => (
                        <FastField
                          key={key}
                          name={key}
                          render={({ field }: any) => (
                            <ScrollListItem
                              className="flex-line centered"
                              onClick={() => setFieldValue(key, !field.value)}
                            >
                              <Switch
                                id={key}
                                checked={field.value || false}
                                wrapperProps={{ style: { transform: 'scale(0.8)' } }}
                                onChange={e => setFieldValue(key, e.target.checked)}
                              />
                              <p className="m-l-3">{label}</p>
                            </ScrollListItem>
                          )}
                        />
                      ))}
                    </ScrollList>
                  </FormGroup>

                  {submitCount > 0 && this.state.error && <FormGroup error={this.state.error} />}

                  <Button full color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting && <RotateLoader size={2} color="white" />}
                    {!isSubmitting && 'Save Staff'}
                  </Button>
                </Form>
              );
            }}
          </Formik>
        );
      }
    },
  ),
);

export const StaffForm = withTranslation()(StaffFormClass);
