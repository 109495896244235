import React from 'react';
import { FastField } from 'formik';
import { FormGroup, Button, RotateLoader, ColorPicker, Switch, FormUpload, Input } from '@lib/components';
import { inject, observer } from 'mobx-react';
import { MobxComponent } from '../../../../../../mobx/components/index';
import { WebsiteForm } from '../../../../../../mobx/components/website-form';
import { UI } from '../../../../../../core/ui';

interface Props {}
interface State {}
type FormValues = T.Schema.Website.WebsiteSchema['sections']['header'];

export const WebsiteFormHeader = inject('store')(
  observer(
    class WebsiteFormHeader extends MobxComponent<Props, State> {
      initialValues: FormValues;

      constructor(props: Props) {
        super(props);
        const w = this.injected.store.website!;
        this.state = {};
        this.initialValues = w.sections.header;
      }

      render() {
        return (
          <WebsiteForm<FormValues>
            submit={async (w, values) => {
              w.sections.header = values;
              const update = { $set: { 'sections.header': values } };
              return { w, update };
            }}
            validators=
            initialValues={this.initialValues}
            onSuccess={() => {}}
            onError={() => UI.notification.error('An error occurred')}
            onSuccessMessage="Settings Updated"
            onErrorMessage=""
          >
            {({ form, error }) => {
              const { isSubmitting, setFieldValue } = form;
              return (
                <div className="p-4">
                  <FastField
                    name="hidden"
                    render={({ field }: any) => (
                      <FormGroup title="Hide Section">
                        <Switch
                          id="section.hidden"
                          checked={field.value}
                          onChange={e => setFieldValue('hidden', e.target.checked)}
                        />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="text"
                    render={({ field }: any) => (
                      <FormGroup title="Title">
                        <Input type="text" {...field} />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="subtitle"
                    render={({ field }: any) => (
                      <FormGroup title="Subtitle">
                        <Input type="text" {...field} />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="image_background"
                    render={({ field }: any) => (
                      <FormGroup title="Background Image" help="File name can't contain special charaters">
                        <FormUpload
                          maxSize={1000}
                          imagesOnly
                          value={[field.value]}
                          onRemove={() => setFieldValue('image_background', null)}
                          onChange={files => setFieldValue('image_background', files[0])}
                        />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="image_background_tint"
                    render={({ field }: any) => (
                      <FormGroup title="Background Image Tint">
                        <ColorPicker
                          color={field.value}
                          onChange={color => setFieldValue('image_background_tint', color)}
                        />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="color_background"
                    render={({ field }: any) => (
                      <FormGroup title="Background Color">
                        <ColorPicker color={field.value} onChange={color => setFieldValue('color_background', color)} />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="color_text"
                    render={({ field }: any) => (
                      <FormGroup title="Text Color">
                        <ColorPicker color={field.value} onChange={color => setFieldValue('color_text', color)} />
                      </FormGroup>
                    )}
                  />

                  {error && <FormGroup error={error} />}

                  <Button full color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting && <RotateLoader size={2} color="white" />}
                    {!isSubmitting && 'Save'}
                  </Button>
                </div>
              );
            }}
          </WebsiteForm>
        );
      }
    },
  ),
);
