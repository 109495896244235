import React from 'react';
import { BoxHeading } from '@lib/components';
import { inject, observer } from 'mobx-react';
import { AuthLayout } from '../layout';
import { AuthLoginForm } from './login.form';
import { MobxComponent } from '../../../../mobx/components/index';

interface Props {}
interface State {
  loading: boolean;
}

export const AuthLogin = inject('store')(
  observer(
    class AuthLogin extends MobxComponent<Props, State> {
      constructor(props: Props) {
        super(props);
        this.state = {
          loading: false,
        };
      }

      load = (loading: boolean) => {
        this.setState({ loading });
      };

      render() {
        return (
          <AuthLayout loading={this.state.loading}>
            <BoxHeading className="text-center">
              <h3>Login</h3>
            </BoxHeading>
            <AuthLoginForm load={this.load} />
          </AuthLayout>
        );
      }
    },
  ),
);
