import React from 'react';
import cn from 'classnames';
import { inject, observer } from 'mobx-react';
import Avatar from 'react-avatar';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ModalContent, styled, ModalDropContent, DetailRowList, Select } from '@lib/components';
import { logger, Untrusive, cloneDeepSafe } from '@lib/common';
import { CustomerModalAgeVerification } from './age-verification';
import { UI } from '../../../../../core/ui';
import { RestaurantItemModal } from '../../../common/item-modal';
import { MobxComponent } from '../../../../../mobx/components/index';

interface Props extends WithTranslation {}
interface State {}

const Thumbnail = styled('div')`
  border-radius: 50%;
  overflow: hidden;
  > * {
    width: 48px;
    height: 48px;
  }
`;

export const RestaurantCustomerModalClass = inject('store')(
  observer(
    class RestaurantCustomerModalClass extends MobxComponent<Props, State> {
      query = async () => {
        try {
          const { store } = this.injected;
          const { query } = store.router.s;
          const { customer } = store;
          const queryButNoCustomer = query._id && !customer;
          const queryCustomerMismatch = query._id && customer && customer._id !== query._id;
          if (queryButNoCustomer || queryCustomerMismatch) {
            const { _id } = query;
            const response = await store.api.customer_find({ _id });
            if (response.outcome) {
              store.router.push(`/restaurant/${store.restaurant!._id}/customers`);
              store.setCustomer(null);
              UI.notification.error(response.message);
            } else {
              store.setCustomer(response.item);
            }
          }
        } catch (e) {
          logger.captureException(e);
          UI.notification.error('Error finding customer, please try again', { timeout: 5000 });
        }
      };

      close = () => {
        const { store } = this.injected;
        const r = store.restaurant!;
        store.router.push(`/restaurant/${r._id}/customers`);
        store.setCustomer(null);
      };

      clearSelectInput = (id: string) => {
        const el = document.getElementById(id) as HTMLSelectElement | null;
        if (el) {
          el.value = '';
        }
      };

      handleActionSelect = async (item: T.Schema.Customer.CustomerSchema, value: string) => {
        this.clearSelectInput('customer-action-select');

        if (!value) {
          return;
        }

        const { store } = this.injected;

        try {
          if (value === 'edit-email') {
            // eslint-disable-next-line no-alert
            const login = prompt('Enter a new e-mail address for the customer');
            if (login === null) {
              //
            } else if (login === '') {
              UI.notification.error('Please enter an e-mail address');
            } else {
              Untrusive.start();
              const { _id } = item;
              const response = await store.api.customer_update_email({ _id, login });
              if (response.outcome) {
                UI.notification.error(response.message, { timeout: 6000 });
              } else {
                const newCustomer = cloneDeepSafe(item);
                newCustomer.login = login;
                newCustomer.details.email = login;
                store.updateCustomerComplete(newCustomer);
                UI.notification.success('Customer e-mail updated');
              }
            }
          }

          if (value === 'edit-password') {
            // eslint-disable-next-line no-alert
            const password = prompt(
              'Enter a new password for the customer. Minimum 6 characters. This will log the customer out',
            );
            if (password === null) {
              //
            } else if (password.length < 6 || password.length > 1024) {
              UI.notification.error('Minimum password length 6 characters');
            } else {
              Untrusive.start();
              const { _id } = item;
              const response = await store.api.customer_update_password({ _id, password });
              if (response.outcome) {
                UI.notification.error(response.message, { timeout: 6000 });
              } else {
                UI.notification.success('Customer password updated');
              }
            }
          }

          if (value === 'delete') {
            // eslint-disable-next-line no-alert
            const proceed = confirm('Once deleted, it cannot be recovered. Delete the customer?');
            if (proceed) {
              Untrusive.start();
              const { _id } = item;
              const restaurant_id = store.restaurant!._id;
              const response = await store.api.customer_delete({ _id, restaurant_id });
              if (response.outcome) {
                UI.notification.error(response.message, { timeout: 6000 });
              } else {
                this.close();
                store.removeCustomer(_id);
                UI.notification.success('Customer deleted');
              }
            }
          }
        } catch (e) {
          logger.captureException(e);
          UI.notification.error('An error occurred, try again soon or contact us', { timeout: 6000 });
        } finally {
          Untrusive.stop();
        }
      };

      render() {
        const { restrictions } = this.injected.store;
        const { store, t } = this.injected;
        const { query } = store.router.s;
        const _id = query._id || null;
        const item = store.customer;

        const actionSelectOptions: Array<{ label: string; value: string }> = [];

        if (restrictions.restaurant.customers_actions) {
          if (item && item.type === 'email') {
            actionSelectOptions.push({
              label: 'Edit e-mail address',
              value: 'edit-email',
            });

            actionSelectOptions.push({
              label: 'Edit password',
              value: 'edit-password',
            });
          }

          actionSelectOptions.push({
            label: 'Delete customer',
            value: 'delete',
          });
        }

        return (
          <RestaurantItemModal<T.Schema.Customer.CustomerSchema>
            active={!!_id}
            item={item}
            query={this.query}
            close={this.close}
          >
            {c => (
              <div>
                <ModalContent
                  className={cn(
                    'flex-line centered justify-center',
                    actionSelectOptions.length > 0 && 'no-border-bottom',
                  )}
                >
                  {c.avatar && (
                    <Thumbnail>
                      <img src={c.avatar} />
                    </Thumbnail>
                  )}
                  {!c.avatar && (
                    <Thumbnail>
                      <Avatar name={c.details.name} email={c.details.email} size="48" />
                    </Thumbnail>
                  )}
                  <div className="m-l-4">
                    <h3 className="font-semi-bold">{c.details.name || c.details.email || c.login}</h3>
                  </div>
                </ModalContent>

                {actionSelectOptions.length > 0 && (
                  <Select
                    id="customer-action-select"
                    className="no-round no-border-lr"
                    placeholder="Actions"
                    options={actionSelectOptions}
                    onChange={e => this.handleActionSelect(c, e.target.value)}
                  />
                )}

                <ModalContent>
                  <DetailRowList
                    items={[
                      { l: 'Type', v: c.type.charAt(0).toUpperCase() + c.type.slice(1) },
                      { l: 'Name', v: c.details.name },
                      { l: 'Phone', v: c.details.phone || 'N/A' },
                      { h: !c.login || c.login === c.details.email, l: 'Login E-Mail', v: c.login },
                      { l: 'E-Mail', v: c.details.email },
                      { l: 'E-Mail Verified', v: c.verified ? 'Yes' : 'No' },
                      { l: 'Created', v: t('datetimeFromTimestamp', { value: c.created }) },
                      {
                        l: 'Last Seen',
                        v: c.meta.last_seen ? t('datetimeFromTimestamp', { value: c.meta.last_seen }) : 'N/A',
                      },
                      {
                        l: 'Last Order',
                        v: c.meta.last_order ? t('datetimeFromTimestamp', { value: c.meta.last_order }) : 'N/A',
                      },
                      {
                        l: 'Last IP Address',
                        v: c.meta.last_ip && c.meta.last_ip.indexOf('::') === -1 ? c.meta.last_ip : 'N/A',
                      },
                      { l: 'Delivery Address', v: c.delivery.destination || 'N/A' },
                    ]}
                  />
                </ModalContent>

                <ModalDropContent title={<h4>Statistics</h4>}>
                  <DetailRowList
                    items={[
                      { l: 'Sessions', v: c.stats.sessions },
                      { l: 'Total Sales', v: t('currency', { value: c.stats.sales }) },
                      { l: 'Total Orders', v: c.stats.orders },
                      { l: 'Total Promos Used', v: c.stats.promos_count },
                    ]}
                  />
                </ModalDropContent>

                <CustomerModalAgeVerification />
              </div>
            )}
          </RestaurantItemModal>
        );
      }
    },
  ),
);

export const RestaurantCustomerModal = withTranslation()(RestaurantCustomerModalClass);
