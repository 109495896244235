import React from 'react';
import { inject, observer } from 'mobx-react';
import { withTheme } from 'styled-components';
import { Protable, styled, Tooltip, CreditCardIcon, TooltipText } from '@lib/components';
import { exportColumns, cloneDeepSafe, getLocalDate, getLocalTime } from '@lib/common';
import { withTranslation, WithTranslation } from 'react-i18next';
import { pull } from 'lodash';
import { FaUndoAlt } from 'react-icons/fa';
import lighten from 'polished/lib/color/lighten';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { ItemButton } from '../menus/layout/item';
import { RestaurantOrderModal } from '../orders/modal';
import { StripeConnectRefundModal } from '../orders/stripe/stripe-connect-refund-modal';
import { MobxComponent } from '../../../../../mobx/components';

interface Props extends WithTranslation {}
interface State {
  customExportModal: boolean;
  activeCol: string[];
  tempColumns: { [key: string]: { name: string; active: boolean } };
  loading: boolean;
  orderExport: {
    columns: { [key: string]: { name: string; active: boolean } };
  };
  openStripeConnectRefundModal: boolean;
}

const StyledTooltip = styled(Tooltip)`
  ${TooltipText} {
    background-color: #fff;
    color: #383838;
    border: 1px solid ${props => lighten(0.2, props.theme.colors.primary)};
    text-align: left;
    padding: 11px 10px;
    border-radius: 8px;
    font-weight: 400;
    font-size: 16px;
    ::after {
      top: 50%;
      left: 100%;
      margin-top: -5px;
      border-color: transparent transparent transparent transparent;
    }
  }
`;

const OnlinePaymentListClass = inject('store')(
  observer(
    class OnlinePaymentListClass extends MobxComponent<Props, State> {
      constructor(props: Props) {
        super(props);

        this.state = {
          customExportModal: false,
          activeCol: [],
          tempColumns: {},
          loading: false,
          orderExport: {
            columns: {},
          },
          openStripeConnectRefundModal: false,
        };
      }

      componentDidMount() {
        const restaurant = this.injected.store.restaurant!;

        let orderExport;
        if (restaurant.settings.order_export) {
          orderExport = cloneDeepSafe(restaurant.settings.order_export);

          // handle case with new Checkout Field column
          if (!orderExport.columns.Custom_Checkout_Fields) {
            orderExport.columns.Custom_Checkout_Fields = {
              name: 'Custom Checkout Field',
              active: false,
            };
          }
        } else {
          orderExport = {
            columns: exportColumns,
          };
        }

        const tempColumns = orderExport.columns;

        const activeCol: string[] = [];
        Object.values(orderExport.columns).forEach(c => {
          if (c.active) activeCol.push(c.name);
        });

        this.setState({ activeCol, tempColumns, orderExport });
      }

      openCustomExportModal = () => {
        this.setState({ customExportModal: true });
      };

      closeAppModal = () => {
        this.setState({ customExportModal: false });
      };

      updateColumnsOrder = (columnOrder: any) => {
        this.setState({ tempColumns: columnOrder });
      };

      updateActiveColumns = (colName: string) => {
        const { activeCol } = this.state;
        if (activeCol.includes(colName)) {
          pull(activeCol, colName);
        } else {
          activeCol.push(colName);
        }
        this.setState({ activeCol });
      };

      handleSubmit = async () => {
        const { store } = this.injected;
        const r = store.restaurant!;
        const { activeCol } = this.state;
        const { tempColumns } = this.state;
        this.setState({ loading: true });

        Object.values(tempColumns).forEach(c => {
          if (activeCol.includes(c.name)) {
            c.active = true;
          } else {
            c.active = false;
          }
        });

        const order_export_temp = {
          columns: tempColumns,
        };

        const apiResult = await store.api.restaurant_update({
          _id: r._id,
          update: {
            $set: {
              'settings.order_export': order_export_temp,
            },
          },
        });

        if (apiResult.outcome === 0) {
          r.settings.order_export = order_export_temp;
          this.setState({
            orderExport: order_export_temp,
            loading: false,
          });
          this.closeAppModal();
        }
      };

      capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

      handleStripeRefundModalClose = () => {
        this.setState({ openStripeConnectRefundModal: false });
      };

      getOrder = async (orderID: string) => {
        await this.injected.store.getOrder(orderID);
        this.setState({ openStripeConnectRefundModal: true });
      };

      RenderInfoPayment = (
        <p style={{ lineHeight: 1.25 }}>
          <strong>Payment Intent Status.</strong> This is the same status in your Payment Gateway Dashboard. Hover on
          each status to see what it means.
        </p>
      );

      render() {
        const { store } = this.injected;
        const r = store.restaurant!;
        const tz = r.settings.region.timezone;
        const rid = r._id;
        const { onlinePaymentOrders } = store;
        const reseller = this.injected.store.reseller?.name;

        const isRefundAllowed = (row: T.Schema.Stripe.StripeTransactions): boolean =>
          !row.refunded && row.status === 'succeeded' && !!row.orderId;

        const handleRefundStatusToShow = (row: T.Schema.Stripe.StripeTransactions) => {
          if (row.refunded) return 'Yes';
          if (row.stripe_connect_refunded_status === 'full_refund') return 'Yes';
          if (row.stripe_connect_refunded_status === 'partial_refund') return row.amount_refunded?.toFixed(2);
          return 'No';
        };
        const columns = [
          {
            title: 'Order #',
            render: (row: T.Schema.Stripe.StripeTransactions) => (
              <span className="font-semi-bold m-b-1">{row.orderNumber}</span>
            ),
          },
          {
            title: 'Charge',
            render: (row: T.Schema.Stripe.StripeTransactions) => <span>{row.charge.toFixed(2)}</span>,
          },
          {
            title: 'Fees',
            render: (row: T.Schema.Stripe.StripeTransactions) => <span>{row.fees}</span>,
          },
          {
            title: 'Nett',
            render: (row: T.Schema.Stripe.StripeTransactions) => <span>{row.nett}</span>,
          },
          {
            title: 'Currency',
            render: (row: T.Schema.Stripe.StripeTransactions) => (
              <div>
                <p>{row.currency}</p>
              </div>
            ),
          },
          {
            title: 'Date | Time',
            render: (row: T.Schema.Stripe.StripeTransactions) => (
              <>
                <p className="font-semi-bold m-b-1">{getLocalDate(row.created * 1000, tz)}</p>
                <p>{getLocalTime(row.created * 1000, tz)}</p>
              </>
            ),
          },
          {
            title: 'Customer Name',
            render: (row: T.Schema.Stripe.StripeTransactions) => (
              <div>
                <p>{row.customerName}</p>
              </div>
            ),
          },
          {
            title: 'Customer Email',
            render: (row: T.Schema.Stripe.StripeTransactions) => <span>{row.customerEmail}</span>,
          },
          {
            title: 'Customer Phone',
            breakpoint: 1200,
            render: (row: T.Schema.Stripe.StripeTransactions) => <span>{row.customerPhone}</span>,
          },
          {
            title: 'Paid',
            render: (row: T.Schema.Stripe.StripeTransactions) => <span>{row.paid ? 'Yes' : 'No'}</span>,
          },
          {
            title: 'Status',
            render: (row: T.Schema.Stripe.StripeTransactions) => (
              <span>{row.status.split('_').map(this.capitalize).join(' ')}</span>
            ),
          },
          {
            title: 'Refunded',
            render: (row: T.Schema.Stripe.StripeTransactions) => (
              <>
                <span>{handleRefundStatusToShow(row)}</span>

                {isRefundAllowed(row) && (
                  <Tooltip text="Refund this order" width={120} position="top" className="m-1">
                    <ItemButton
                      type="button"
                      color="white"
                      className="no-border width32"
                      paddinglr={5}
                      onClick={async e => {
                        e.stopPropagation();
                        await this.getOrder(row.orderId);
                      }}
                    >
                      <FaUndoAlt />
                    </ItemButton>
                  </Tooltip>
                )}
              </>
            ),
          },
          {
            title: 'Card Type',
            breakpoint: 1200,
            render: (row: T.Schema.Stripe.StripeTransactions) => (
              <Tooltip text={row.card_brand} width={100} position="top" className="m-1">
                <CreditCardIcon card_brand={row.card_brand as T.Schema.Stripe.CardBrand} />
              </Tooltip>
            ),
          },
          {
            title: 'Last 4 Digits',
            breakpoint: 1200,
            render: (row: T.Schema.Stripe.StripeTransactions) => <span>{row.card_last4}</span>,
          },
        ];

        const queryFilter = {
          sortKeys: [
            { label: 'Created', value: 'created' },
            { label: 'Charge', value: 'charge' },
            { label: 'Nett', value: 'nett' },
          ],
          filters: [
            {
              label: (
                <span>
                  Status
                  <StyledTooltip text={this.RenderInfoPayment} width={320} position="right">
                    <AiOutlineInfoCircle
                      style={{
                        fontSize: '20px',
                        marginLeft: '5px',
                      }}
                    />
                  </StyledTooltip>
                </span>
              ),
              key: 'status',
              values: [
                {
                  label: 'Succeeded',
                  value: 'succeeded',
                  tooltip: (
                    <div>
                      <p style={{ lineHeight: '1.25' }}>Payment was collected and order was created</p>
                    </div>
                  ),
                },
                {
                  label: 'Requires Payment method',
                  value: 'requires_payment_method',
                  tooltip: (
                    <div>
                      <p style={{ lineHeight: '1.25' }}>
                        Payment process started and awaits user to provide payment info.
                      </p>
                      <p
                        style={{
                          marginTop: '11px',
                          lineHeight: '1.25',
                        }}
                      >
                        Customer was not charged and order was not created.
                      </p>
                    </div>
                  ),
                },
                {
                  label: 'Requires Confirmation',
                  value: 'requires_confirmation',
                  tooltip: (
                    <div>
                      <p style={{ lineHeight: '1.25' }}>Payment info provided and awaits confirmation. </p>
                      <p
                        style={{
                          marginTop: '11px',
                          lineHeight: '1.25',
                        }}
                      >
                        Customer was not charged and order was not created.
                      </p>
                    </div>
                  ),
                },
                {
                  label: 'Requires Action',
                  value: 'requires_action',
                  tooltip: (
                    <div>
                      <p style={{ lineHeight: '1.25' }}>
                        Payment info is being is being verified in cases like a 3D Secure validation.
                      </p>
                      <p
                        style={{
                          marginTop: '11px',
                          lineHeight: '1.25',
                        }}
                      >
                        Customer was not charged and order was not created.
                      </p>
                    </div>
                  ),
                },
                {
                  label: 'Processing',
                  value: 'processing',
                  tooltip: (
                    <div>
                      <p style={{ lineHeight: '1.25' }}>
                        Payment is being processed with the bank. Some payment methods take longer to process e.g. ACH
                        debits. Order was created.
                      </p>
                    </div>
                  ),
                },
                {
                  label: 'Requires Capture',
                  value: 'requires_capture',
                  tooltip: (
                    <div>
                      <p style={{ lineHeight: '1.25' }}>
                        Payment info submission was deferred. Not applicable to {reseller}.
                      </p>
                      <p
                        style={{
                          marginTop: '11px',
                          lineHeight: '1.25',
                        }}
                      >
                        Customer was not charged and order was not created.
                      </p>
                    </div>
                  ),
                },
                {
                  label: 'Canceled',
                  value: 'canceled',
                  tooltip: (
                    <div>
                      <p style={{ lineHeight: '1.25' }}>Payment canceled. Order was not created.</p>
                    </div>
                  ),
                },
              ],
              defaultValues: ['succeeded'],
            },
            {
              label: <span>Refund Status</span>,
              key: 'stripe_connect_refunded_status',
              values: [
                {
                  label: 'Refunded',
                  value: 'full_refund',
                },
                {
                  label: 'Partially Refunded',
                  value: 'partial_refund',
                },
              ],
            },
          ],
        };

        const fetchData = async (data: T.API.RestaurantQueryRequest) => {
          data.query.restaurant_id = store.restaurant!._id;
          const response = await store.api.stripe_connect_order_find(data);

          if (response.outcome) throw new Error(response.message);

          store.updateOnlinePaymentOrders({
            items: response.items,
            count: response.count,
            page: data.page,
            chargeTotal: response.chargeTotal,
            feesTotal: response.feesTotal,
            netTotal: response.netTotal,
          });
        };

        const filterConfigs = {
          dateRange: true,
          noSearch: true,
          noSort: true,
        };

        const { openStripeConnectRefundModal } = this.state;

        return (
          <>
            {/* <div className="m-tb-10 grid-3 md sm-gap">
              <Box shadow="one" className="col no-border">
                <div className="p-3 flex-l-r-center">
                  <p className="big font-semi-bold">Total Charge</p>
                  <p className="big">
                    {t('currency', {
                      value: onlinePaymentOrders.chargeTotal || 0,
                    })}
                  </p>
                </div>
              </Box>
              <Box shadow="one" className="col no-border">
                <div className="p-3 flex-l-r-center">
                  <p className="big font-semi-bold">Total Fees</p>
                  <p className="big">
                    {t('currency', {
                      value: onlinePaymentOrders.feesTotal || 0,
                    })}
                  </p>
                </div>
              </Box>
              <Box shadow="one" className="col no-border">
                <div className="p-3 flex-l-r-center">
                  <p className="big font-semi-bold">Total Nett</p>
                  <p className="big">
                    {t('currency', {
                      value: onlinePaymentOrders.netTotal || 0,
                    })}
                  </p>
                </div>
              </Box>
            </div> */}

            <Protable<T.Schema.Stripe.StripeTransactions>
              region={r.settings.region}
              items={onlinePaymentOrders.items}
              count={onlinePaymentOrders.count}
              // exportSettings={orderExport}
              // openCustomExportModal={this.openCustomExportModal}
              exportUrl=""
              // ${
              // 	config.urls.api
              // }/exports/dashboard/online_payments?jwt=${
              // 	store.auth.token
              // }&rid=${store.restaurant!._id}
              screenWidth={store.view.screen_width}
              query={queryFilter}
              onClick={item => {
                store.router.push(`/restaurant/${rid}/online_payments?order_id=${item.orderId}`);
                store.notifications.mark_read_object('order', item.orderId);
              }}
              rowAlert={item => item.status !== 'succeeded'}
              fetch={fetchData}
              columns={columns}
              filterConfigs={filterConfigs}
            />

            {/* {orderExport.columns && (
              <Modal
                width={'xl'}
                active={customExportModal}
                close={() => {
                  this.closeAppModal();
                }}
              >
                <ModalTitle className="round-top">
                  <h4>Pick Report Columns</h4>
                </ModalTitle>

                <ModalContent>
                  <DragAndDropMultiple
                    columnsData={orderExport.columns}
                    chunk={11}
                    activeCol={activeCol}
                    updateActiveCol={this.updateActiveColumns}
                    updateColumnsOrder={this.updateColumnsOrder}
                  />
                  <ButtonWrapper>
                    <Button color="primary-inverse" size="lg" onClick={this.handleSubmit} className="m-r-2">
                      <span style={{ alignItems: 'center', display: 'flex' }}>
                        Save
                        {loading && <RotateLoader className="m-l-2" size={2} color={theme.colors.primary_text} />}
                      </span>
                    </Button>
                    <Button color="primary-inverse" size="lg" onClick={this.closeAppModal}>
                      Cancel
                    </Button>
                  </ButtonWrapper>
                </ModalContent>
              </Modal>
            )} */}

            <RestaurantOrderModal />

            {store.order && (
              <StripeConnectRefundModal
                order={store.order}
                restaurant={r}
                showModal={openStripeConnectRefundModal}
                onClose={this.handleStripeRefundModalClose}
              />
            )}
          </>
        );
      }
    },
  ),
);

export const OnlinePaymentList = withTheme(
  // @ts-ignore
  withTranslation()(OnlinePaymentListClass),
);
