import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { inject, observer } from 'mobx-react';
import { withTranslation, TranslationProps } from 'react-i18next';

import { PaymentMethods } from '@lib/common';
import { SettingsSection } from '../layout/section';
import { SettingsSectionBlock } from '../layout/block';
import { SettingsSectionHeading } from '../layout/heading';
import { PaymentMethodList } from './payments/payment-method-list';
import { SettingsFormPaymentsCustom } from '../forms/payments/custom';
import { PAYMENT_FORMS, PAYMENT_VALIDATORS } from './payments/contants';
import { AddPaymentMethodModal } from './payments/add-payment-method-modal';
import { MobxComponent, SaveRestaurantOpts, SaveRestaurantSilentOpts } from '../../../../../../mobx/components';

interface Props extends TranslationProps {}
interface State {
  active: string;
  addPaymentModal: boolean;
}

const RestaurantSettingsPaymentsClass = inject('store')(
  observer(
    class RestaurantSettingsPaymentsClass extends MobxComponent<Props, State> {
      _saveRestaurant: (opts: SaveRestaurantOpts) => Promise<void>;

      _saveRestaurantSilent: (opts: SaveRestaurantSilentOpts) => Promise<void>;

      constructor(props: Props) {
        super(props);
        this.state = {
          active: '',
          addPaymentModal: false,
        };

        // These internal methods will be passed down the child components.
        // As such, we need to bind the context of these methods to the class instance properly.
        this._saveRestaurant = this.saveRestaurant.bind(this);
        this._saveRestaurantSilent = this.saveRestaurantSilent.bind(this);
      }

      setActive = (active: string) => {
        if (this.state.active === active) this.setState({ active: '' });
        else this.setState({ active });
      };

      setAddPaymentModal = (value: boolean) => {
        this.setState({ addPaymentModal: value });
      };

      render() {
        const { store, t } = this.injected;
        const { restaurant } = store;
        const { payments } = restaurant!.settings;
        const methods = Object.keys(payments).map(key => key);
        const { active, addPaymentModal } = this.state;

        return (
          <>
            <SettingsSection>
              <SettingsSectionHeading className="flex-l-r-center">
                <div />
                <div
                  role="button"
                  className="flex-line centered cursor"
                  onClick={() => this.setState({ addPaymentModal: true })}
                >
                  <p className="underline">Add Payment Method</p>
                  <p className="m-l-2">
                    <FaPlus />
                  </p>
                </div>
              </SettingsSectionHeading>
              {methods.map(key => {
                const data = payments[key]!;
                const isBaseMethod = PaymentMethods.indexOf(key) !== -1;
                const name = isBaseMethod ? t(`constants.payment.backend_method.${key}`) : data.label || '';

                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                const component = isBaseMethod ? (
                  PAYMENT_FORMS[key as keyof typeof PAYMENT_FORMS]
                ) : (
                  <SettingsFormPaymentsCustom payment_id={key} />
                );

                const enabled = isBaseMethod
                  ? PAYMENT_VALIDATORS[key as keyof typeof PAYMENT_FORMS](payments)
                  : data.enabled;

                return (
                  <SettingsSectionBlock
                    key={key}
                    name={
                      <PaymentMethodList
                        saveRestaurant={this._saveRestaurant}
                        saveRestaurantSilent={this._saveRestaurantSilent}
                        store={store}
                        methodKey={key}
                        title={name}
                        active={enabled}
                      />
                    }
                    headerClass="p-tb-2"
                    active={active === key}
                    onClick={() => this.setActive(key)}
                  >
                    {component}
                  </SettingsSectionBlock>
                );
              })}
            </SettingsSection>

            <AddPaymentMethodModal
              store={store}
              addPaymentModal={addPaymentModal}
              setAddPaymentModal={this.setAddPaymentModal}
              saveRestaurant={this._saveRestaurant}
            />
          </>
        );
      }
    },
  ),
);

export const RestaurantSettingsPayments = withTranslation()(RestaurantSettingsPaymentsClass);
