import React from 'react';
import { Form, Formik, FormikHelpers as FormikActions, FormikProps } from 'formik';
import { inject, observer } from 'mobx-react';
import { MobxComponent } from './index';
import { FormHelpers } from '../../core/form';

interface Props<FormValues> {
  submit?: (
    w: T.Schema.Website.WebsiteSchema,
    values: FormValues,
  ) => Promise<{
    w: T.Schema.Website.WebsiteSchema;
    update: T.ObjectAny;
  }>;
  customSubmit?: (values: FormValues, form: FormikActions<FormValues>) => void;
  validators: { [key: string]: (values: FormValues) => void | { [key: string]: string } };
  initialValues: FormValues | null;
  onSuccess?: (w: T.Schema.Website.WebsiteSchema) => void;
  onError?: () => void;
  onFail?: () => void;
  onSuccessMessage: string;
  onErrorMessage: string;
  children: (opts: {
    form: FormikProps<FormValues>;
    error: string;
    setError: (error: string) => void;
    getFieldError: (form: FormikProps<FormValues>, field: string) => T.ObjectAny | string | undefined | null;
    showFormError: boolean;
  }) => React.ReactNode;
}

interface State {
  error: string;
}

export const WebsiteForm = inject('store')(
  observer(
    class WebsiteForm<FormValues> extends MobxComponent<Props<FormValues>, State> {
      constructor(props: Props<FormValues>) {
        super(props);
        this.state = {
          error: '',
        };
      }

      setError = (error: string) => {
        this.setState({ error });
      };

      getFieldError = (form: FormikProps<FormValues>, field: string) => FormHelpers.error(form, field);

      submit = async (values: FormValues, form: FormikActions<FormValues>) => {
        const { submit, onSuccess, onError, onFail, onSuccessMessage, onErrorMessage } = this.props;
        await FormHelpers.submit_website({
          store: this.injected.store,
          w: this.injected.store.website!,
          form,
          onError,
          onFail,
          onSuccess,
          onSuccessMessage,
          onErrorMessage,
          setError: error => this.setState({ error: error || '' }),
          setWebsite: w => this.injected.store.setWebsite(w),
          process: async w => submit!(w, values),
        });
      };

      validate = (values: FormValues) => {
        const { errors, isError } = FormHelpers.validate<FormValues>(values, this.props.validators);
        if (this.state.error && !isError) {
          this.setState({ error: '' });
        } else if (!this.state.error && isError) {
          this.setState({
            error:
              'There are errors in your form that need correcting, please scroll up and check other tabs if needed',
          });
        }
        return errors;
      };

      render() {
        const { children, initialValues, customSubmit } = this.props;
        const { error } = this.state;
        if (initialValues === null) return null;
        return (
          <Formik initialValues={initialValues} validate={this.validate} onSubmit={customSubmit || this.submit}>
            {form => {
              const { submitCount } = form;
              const showFormError = !!(submitCount > 0 && error);
              return (
                <Form>
                  {children({
                    form,
                    error: showFormError ? error : '',
                    setError: this.setError,
                    getFieldError: this.getFieldError,
                    showFormError,
                  })}
                </Form>
              );
            }}
          </Formik>
        );
      }
    },
  ),
);
