// @ts-nocheck

import { styled, CS, Box } from '@lib/components';

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 1;
  background-color: ${props => props.bg_color};
  ::before {
    background-image: url(${props => props.bg_img});
    opacity: 0.4;
    width: 100%;
    height: 100%;
    position: absolute;
    content: '';
    background-size: cover;
  }
`;

const Main = styled.div`
  ${CS.flex.center};
  padding: 20px;
  min-height: 90vh;
`;

const BoxWrapper = styled.div`
  background-color: ${props => (props.boxStyle === 'expanded' ? 'white' : '')};
  padding: ${props => (props.boxStyle === 'expanded' ? '75px 63px' : '')};
  z-index: 9;

  animation-duration: 0.3s;
  animation-name: fadeIn;
  animation-fill-mode: both;
  animation-timing-function: ease-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`;

const Content = styled('div')`
  width: 360px;
  margin: 0 auto;
  z-index: 90;
`;

const Logo = styled('div')`
  text-align: center;
  margin-bottom: 30px;
  img {
    max-height: 60px;
  }
  z-index: 90;
`;

const ContentBox = styled(Box)`
  border-radius: ${props => (props.boxStyle === 'expanded' ? '0px' : '3px')};
  border: ${props => (props.boxStyle === 'expanded' ? '0px' : '')};
  box-shadow: ${props => (props.boxStyle === 'expanded' ? '0px' : '0 4px 36px rgba(0, 0, 0, 0.2)')};
  .box-loader {
    border-radius: ${props => (props.boxStyle === 'expanded' ? '0px' : '3px')};
  }
  z-index: 90;
`;

export const AuthLayoutComponents = {
  Main,
  Content,
  Logo,
  ContentBox,
  Wrapper,
  BoxWrapper,
};
