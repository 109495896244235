import React from 'react';
import { FastField } from 'formik';
import {
  FormGroup,
  Input,
  InputAddress,
  FieldOpeningHours,
  Button,
  RotateLoader,
  withTheme,
  ButtonGroup,
  FieldSpecialHours,
} from '@lib/components';
import { inject, observer } from 'mobx-react';
import { validators } from '@lib/common';
import { MobxComponent } from '../../../../../../../mobx/components/index';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';
import { UI } from '../../../../../../../core/ui';
import { MapboxAPI } from '../../../../../../../core/libs/mapbox';
import { GoogleCreateService } from '../../../../../../../core/libs/google';

let GoogleService: any = {};

interface Props {}
interface State {
  error: string | null;
}
type FormValues = T.Schema.Restaurant.RestaurantSchema['location'];

const SettingsFormLocationClass = inject('store')(
  observer(
    class SettingsFormLocationClass extends MobxComponent<Props, State> {
      initialValues: FormValues;

      constructor(props: Props) {
        super(props);
        this.state = { error: null };
        const r = this.injected.store.restaurant!;
        this.initialValues = r.location;
        GoogleService = GoogleCreateService;
      }

      render() {
        return (
          <RestaurantForm<FormValues>
            submit={async (r, values) => {
              r.location = values;
              const update = { $set: { location: values } };
              return { r, update };
            }}
            validators={{
              // @ts-ignore
              address: values => {
                const md = values.map_data;
                if (md.type === 'google_maps' || md.type === 'osm') {
                  if (!values.address || !md.components || !md.lat || !md.lng) {
                    return { address: 'Required - search and select your store address' };
                  }
                } else if (!values.address) {
                  return { address: 'Required - enter your store address' };
                }
              },
              // @ts-ignore
              opening_hours: values => {
                const { error } = validators.business_hours(values.opening_hours);
                if (error) {
                  return { opening_hours: error };
                }
              },
            }}
            initialValues={this.initialValues}
            onSuccess={() => {}}
            onError={() => UI.notification.error('An error occurred')}
            onSuccessMessage="Settings Updated"
            onErrorMessage=""
          >
            {({ form, error, getFieldError }) => {
              const { isSubmitting, setFieldValue } = form;
              const mapType = form.values.map_data.type;
              return (
                <div className="p-4">
                  <FastField
                    name="map_data.type"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Map Data Source"
                        help="We recommend using Open Street Maps. If you cannot find you store address, try use Google Maps instead. Selecting 'None' will allow you to enter any address but certain delivery related features will be disabled."
                      >
                        <ButtonGroup
                          size="xs"
                          selected={field.value}
                          options={[
                            { value: 'osm', name: 'Open Street Maps' },
                            { value: 'google_maps', name: 'Google Maps' },
                            { value: 'custom', name: 'None' },
                          ]}
                          buttonClassName="p-lr-2"
                          onSelect={v => {
                            setFieldValue('address', '');
                            setFieldValue('map_data', { type: v.value });
                          }}
                          width="auto"
                        />
                      </FormGroup>
                    )}
                  />

                  {(mapType === 'google_maps' || mapType === 'osm') && (
                    <FormGroup
                      title="Store Address"
                      help="Search for your address and select from the dropdown"
                      error={getFieldError(form, 'address')}
                    >
                      <InputAddress
                        type={mapType}
                        gm={GoogleService}
                        mb={MapboxAPI}
                        value={form.values.address}
                        onChange={(address, map_data) => {
                          setFieldValue('address', address);
                          setFieldValue('map_data', map_data);
                        }}
                        onError={() => {}}
                      />
                    </FormGroup>
                  )}

                  {mapType === 'custom' && (
                    <FormGroup
                      title="Store Address"
                      help="Enter your complete store address"
                      error={getFieldError(form, 'address')}
                    >
                      <FastField name="address" render={({ field }: any) => <Input {...field} required />} />
                    </FormGroup>
                  )}

                  <FastField
                    name="name"
                    render={({ field }: any) => (
                      <FormGroup
                        optional
                        title="Location Name"
                        help="This will override your restaurant name in certain places when referring to just your store location. It's recommend to use your store suburb / locality / district name"
                      >
                        <Input {...field} />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="phone"
                    render={({ field }: any) => (
                      <FormGroup optional title="Phone Number" help="Enter your store contact number">
                        <Input {...field} />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="opening_hours"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Opening Hours"
                        help="Enter time in 24H format, e.g. 21:00 for 9:00pm. Ensure time slots do not overlap or close before they open"
                        error={getFieldError(form, 'opening_hours')}
                      >
                        <div className="m-tb-3">
                          <FieldOpeningHours
                            hours={field.value}
                            onChange={opening_hours => {
                              setFieldValue('opening_hours', opening_hours);
                            }}
                          />
                        </div>
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="special_hours"
                    render={({ field }: any) => (
                      <FormGroup optional title="Special Hours">
                        <p className="lhp m-b-4">
                          Set special opening hours for holidays or other periods. This will override your regular
                          opening hours for your store location and all services
                        </p>
                        <FieldSpecialHours
                          hours={field.value || []}
                          onChange={hours => setFieldValue('special_hours', hours)}
                          intl={this.injected.store.intl}
                        />
                      </FormGroup>
                    )}
                  />

                  {error && <FormGroup error={error} />}

                  <Button full color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting && <RotateLoader size={2} color="white" />}
                    {!isSubmitting && 'Save'}
                  </Button>
                </div>
              );
            }}
          </RestaurantForm>
        );
      }
    },
  ),
);

export const SettingsFormLocation = withTheme(SettingsFormLocationClass);
