import React from 'react';
import { FastField } from 'formik';
import {
  Button,
  FormGroup,
  RotateLoader,
  Switch,
  ModalContent,
  SelectAdv,
  Box,
  BoxHeading,
  BoxSection,
} from '@lib/components';
import { inject, observer } from 'mobx-react';
import { OAuthAppTokenManager } from './token_manager';
import { MobxComponent } from '../../../../../../mobx/components/index';
import { RestaurantForm } from '../../../../../../mobx/components/restaurant-form';

type AppFormValues = T.Schema.Restaurant.Integrations.OAuthApp;

export function validateUrl(value: string) {
  const regex = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port
      '(\\?[;&amp;a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  );
  let error;
  if (!regex.test(value)) {
    error = 'Invalid URL provided.';
  }
  return error;
}

interface Props {
  restaurant: T.Schema.Restaurant.RestaurantSchema;
  baseApp: T.Schema.Restaurant.Integrations.BaseApp;
  initialValues?: T.Schema.Restaurant.Integrations.OAuthApp | undefined;
  onSuccess?: (r: T.Schema.Restaurant.RestaurantSchema) => void;
  submitButtonText?: string;
}
interface Option {
  label: string;
  value: string;
}
type FormValues = T.Schema.Restaurant.Kounta.RestaurantKountaSettings;

interface State {
  tab: string;
  add_to: FormValues;
  companiesFetched: boolean;
  registersFetched: boolean;
  paymentsFetched: boolean;
  deliveryProductsFetched: boolean;
  options: Option[];
  register_options: Option[];
  payment_options: Option[];
  delivery_product_options: Option[];
  currentOption: string;
  fetching: boolean;
  fetching_registers: boolean;
  fetching_payments: boolean;
  fetching_delivery_products: boolean;
  generating: boolean;
  generated: boolean;
}

export const OAuthAppForm = inject('store')(
  observer(
    class OAuthAppForm extends MobxComponent<Props, State> {
      initialAppValues: AppFormValues;

      constructor(props: Props) {
        super(props);

        this.initialAppValues = {
          _id: '',
          enabled: this.props.initialValues?.enabled,
          base_app_id: this.props.baseApp._id,
          client_id: '',
          client_secret: '',
          authorization_url: '',
          token_url: '',
          token_scopes: '',
          api_url: '',
          tokens: {
            access_token: '',
            refresh_token: '',
            expires_at: 0,
            token_type: '',
          },
        };

        this.state = {
          tab: '0',
          add_to: {},
          companiesFetched: false,
          registersFetched: false,
          paymentsFetched: false,
          deliveryProductsFetched: false,
          options: [],
          register_options: [],
          payment_options: [],
          delivery_product_options: [],
          currentOption: '0',
          fetching: false,
          fetching_registers: false,
          fetching_payments: false,
          fetching_delivery_products: false,
          generating: false,
          generated: false,
        };
      }

      componentDidMount = () => {
        //
        const r = this.injected.store.restaurant;
        if (r && r.kounta && r.kounta.kounta_site_id && r.kounta.kounta_site_name) {
          this.setState({
            options: [
              {
                label: r.kounta.kounta_site_name,
                value: r.kounta.kounta_site_id.toString(),
              },
            ],
          });
        }
        //
        if (r && r.kounta && r.kounta.kounta_register_id && r.kounta.kounta_register_name) {
          this.setState({
            register_options: [
              {
                label: r.kounta.kounta_register_name,
                value: r.kounta.kounta_register_id.toString(),
              },
            ],
          });
        }
        //
        if (r && r.kounta && r.kounta.kounta_payment_method_id && r.kounta.kounta_payment_method_name) {
          this.setState({
            payment_options: [
              {
                label: r.kounta.kounta_payment_method_name,
                value: r.kounta.kounta_payment_method_id.toString(),
              },
            ],
          });
        }
        if (r && r.kounta && r.kounta.kounta_delivery_product_id && r.kounta.kounta_delivery_product_name) {
          this.setState({
            delivery_product_options: [
              {
                label: r.kounta.kounta_delivery_product_name,
                value: r.kounta.kounta_delivery_product_id.toString(),
              },
            ],
          });
        }
        //
      };

      setTab = (tab: string) => {
        this.setState({ tab });
      };

      fetchRegisters = async (site_id: any) => {
        this.setState({
          fetching_registers: true,
        });
        const _id =
          this.injected.store.restaurant && this.injected.store.restaurant._id
            ? this.injected.store.restaurant._id
            : '';
        const registers = await this.injected.store.getKountaRegisters(_id, site_id);
        const register_options = registers.registers.map((company: any) => ({
          value: company.id.toString(),
          label: company.name,
        }));
        this.setState({
          registersFetched: true,
          register_options,
          fetching_registers: false,
        });
      };

      fetchSites = async () => {
        this.setState({
          fetching: true,
        });
        const _id =
          this.injected.store.restaurant && this.injected.store.restaurant._id
            ? this.injected.store.restaurant._id
            : '';
        const sites = await this.injected.store.getKountaSites(_id);
        const options = sites.sites.map((company: any) => ({
          value: company.id.toString(),
          label: company.name,
        }));
        this.setState({
          companiesFetched: true,
          options,
          fetching: false,
        });
      };

      fetchPaymentMethods = async () => {
        this.setState({
          fetching_payments: true,
        });
        const _id =
          this.injected.store.restaurant && this.injected.store.restaurant._id
            ? this.injected.store.restaurant._id
            : '';
        const payments = await this.injected.store.getKountaPayments(_id);
        const payment_options = payments.payments.map((payment: any) => ({
          value: payment.id.toString(),
          label: payment.name,
        }));
        this.setState({
          paymentsFetched: true,
          payment_options,
          fetching_payments: false,
        });
      };

      fetchDeliveryProducts = async (site_id: any) => {
        this.setState({
          fetching_delivery_products: true,
        });
        const _id =
          this.injected.store.restaurant && this.injected.store.restaurant._id
            ? this.injected.store.restaurant._id
            : '';
        const delivery_products_result = await this.injected.store.getKountaDeliveryProducts(_id, site_id);
        const delivery_product_options = delivery_products_result.delivery_products.map((product: any) => ({
          value: product.id.toString(),
          label: product.name,
        }));
        this.setState({
          deliveryProductsFetched: true,
          delivery_product_options,
          fetching_delivery_products: false,
        });
      };

      generateMenu = async () => {
        this.setState({
          generating: true,
          generated: false,
        });
        const _id =
          this.injected.store.restaurant && this.injected.store.restaurant._id
            ? this.injected.store.restaurant._id
            : '';
        const site_id = this.state.currentOption;
        await this.injected.store.generateMenu(_id, site_id);
        this.setState(
          {
            generating: false,
            generated: true,
          },
          async () => {
            const res = await this.injected.store.api.restaurant({ _id });
            /// @ts-ignore
            const { restaurant } = res;
            this.injected.store.setRestaurant(restaurant);
          },
        );
      };

      render() {
        const formValues = this.props.initialValues || this.initialAppValues;
        const restaurant = this.injected.store.restaurant!;

        const initialValues = restaurant.kounta || {};
        if (!initialValues.kounta_pass_thru) {
          initialValues.kounta_pass_thru = 'false';
        }
        return (
          <>
            {/* {formValues._id && ( */}
            <OAuthAppTokenManager restaurant={this.props.restaurant} oauthApp={formValues} />
            {/* )} */}
            <Box style={{ marginBottom: '2rem' }}>
              <BoxHeading>Integration Settings</BoxHeading>
              <BoxSection>
                <RestaurantForm<FormValues>
                  submit={async (r, values) => {
                    //
                    r.kounta = values;
                    //
                    if (!r.kounta) {
                      r.kounta = {};
                    }
                    const update = {
                      $set: {
                        kounta: values,
                      },
                    };
                    //
                    return { r, update };
                  }}
                  validators={
                    {
                      // kounta_username: (values) => {
                      // 	if (!values.kounta_username)
                      // 		return { kounta_username: "This field is required" };
                      // },
                      // kounta_password: (values) => {
                      // 	if (!values.kounta_password)
                      // 		return { kounta_password: "This field is required" };
                      // },
                    }
                  }
                  initialValues={initialValues}
                  onSuccess={() => {}}
                  onSuccessMessage="Success"
                  onErrorMessage=""
                >
                  {({ form, error, getFieldError }) => {
                    const { isSubmitting, setFieldValue, values } = form;
                    // const restaurant = this.injected.store.restaurant!;
                    // const noUsernameOrPassword = !restaurant.kounta ||
                    // 	!restaurant.kounta.kounta_username ||
                    // 	!restaurant.kounta.kounta_password ||
                    // 	restaurant.kounta.kounta_username === "" ||
                    // 	restaurant.kounta.kounta_password === ""
                    const { options } = this.state;
                    const registerOptions = this.state.register_options;
                    const paymentOptions = this.state.payment_options;
                    const deliveryProductOptions = this.state.delivery_product_options;
                    return (
                      <div>
                        <ModalContent>
                          <FastField
                            name="enabled"
                            render={({ field }: any) => (
                              <FormGroup title="Enabled" help="Enable customers allow to pay by Kounta method">
                                <Switch
                                  id="enabled-switch"
                                  checked={field.value || false}
                                  onChange={e => setFieldValue('enabled', e.target.checked)}
                                />
                              </FormGroup>
                            )}
                          />
                          {/* <FastField
											name="kounta_username"
											render={({ field }: any) => (
												<FormGroup
													title="Username"
													help="Your Kounta username"
													error={getFieldError(form, "kounta_username")}>
													<Input type="text" {...field} required={false} />
												</FormGroup>
											)}
										/>

										<FastField
											name="kounta_password"
											render={({ field }: any) => (
												<FormGroup
													title="Password"
													help="Your Kounta password"
													error={getFieldError(form, "kounta_password")}>
													<Input type="text" {...field} required={false} />
												</FormGroup>
											)}
										/> */}

                          <Button
                            full={false}
                            color="primary"
                            type="button"
                            style={{ marginTop: '10px', marginBottom: '10px' }}
                            onClick={() => this.fetchSites()}
                            // disabled={noUsernameOrPassword}
                          >
                            Fetch Kounta Sites
                          </Button>

                          <div hidden={!this.state.fetching}>
                            <RotateLoader />
                          </div>

                          {/* {noUsernameOrPassword
											?
											<div style={{ marginBottom: "30px" }}>Please click settings and enter a username and password to interact with the Kounta service.</div>
											:
											<div></div>
										} */}

                          {this.state.options.length === 0 ? (
                            <div />
                          ) : (
                            <FormGroup
                              title="Kounta Sites"
                              help="Select a Kounta site to pull all items and generate a menu."
                              error={getFieldError(form, 'kounta_site_id')}
                            >
                              <SelectAdv
                                type="single"
                                options={options}
                                value={values.kounta_site_id ? values.kounta_site_id.toString() : ''}
                                onChange={(option: string) => {
                                  //
                                  setFieldValue('kounta_site_id', option);
                                  //
                                  const selectedItems = options.filter(
                                    optionElement => option === optionElement.value.toString(),
                                  );
                                  setFieldValue('kounta_site_name', selectedItems[0]?.label);
                                  //
                                }}
                              />
                            </FormGroup>
                          )}

                          <Button
                            full={false}
                            color="primary"
                            type="button"
                            style={{ marginTop: '10px', marginBottom: '10px' }}
                            onClick={() => {
                              this.fetchRegisters(values.kounta_site_id);
                            }}
                            disabled={!values.kounta_site_id}
                          >
                            Fetch Kounta Registers
                          </Button>

                          <div hidden={!this.state.fetching_registers}>
                            <RotateLoader />
                          </div>

                          {/* {noUsernameOrPassword
											?
											<div style={{ marginBottom: "30px" }}>Please click settings and enter a username and password to interact with the Kounta service.</div>
											:
											<div></div>
										} */}

                          {this.state.register_options.length === 0 ? (
                            <div />
                          ) : (
                            <FormGroup
                              title="Kounta Registers"
                              help="Select a Kounta register to send kounta orders and updates too."
                              error={getFieldError(form, 'kounta_register_id')}
                            >
                              <SelectAdv
                                type="single"
                                options={registerOptions}
                                value={values.kounta_register_id ? values.kounta_register_id.toString() : ''}
                                onChange={(option: string) => {
                                  //
                                  setFieldValue('kounta_register_id', option);
                                  //
                                  const selectedItems = this.state.register_options.filter(
                                    optionElement => option === optionElement.value.toString(),
                                  );
                                  setFieldValue('kounta_register_name', selectedItems[0]?.label);
                                  //
                                }}
                              />
                            </FormGroup>
                          )}

                          <Button
                            full={false}
                            color="primary"
                            type="button"
                            style={{ marginTop: '10px', marginBottom: '10px' }}
                            onClick={() => this.fetchPaymentMethods()}
                            // disabled={noUsernameOrPassword}
                          >
                            Fetch Kounta Payment Methods
                          </Button>

                          <div hidden={!this.state.fetching_payments}>
                            <RotateLoader />
                          </div>

                          {this.state.payment_options.length === 0 ? (
                            <div />
                          ) : (
                            <FormGroup
                              title="Kounta Payment Methods"
                              help="Select a Kounta Payment Method for restaurant payments in Kounta"
                              error={getFieldError(form, 'kounta_payment_method_id')}
                            >
                              <SelectAdv
                                type="single"
                                options={paymentOptions}
                                value={
                                  values.kounta_payment_method_id ? values.kounta_payment_method_id.toString() : ''
                                }
                                onChange={(option: string) => {
                                  //
                                  setFieldValue('kounta_payment_method_id', option);
                                  //
                                  const selectedItems = this.state.payment_options.filter(
                                    optionElement => option === optionElement.value.toString(),
                                  );
                                  setFieldValue('kounta_payment_method_name', selectedItems[0]?.label);
                                  //
                                }}
                              />
                            </FormGroup>
                          )}

                          <Button
                            full={false}
                            color="primary"
                            type="button"
                            style={{ marginTop: '10px', marginBottom: '10px' }}
                            onClick={() => this.fetchDeliveryProducts(values.kounta_site_id)}
                            // disabled={noUsernameOrPassword}
                          >
                            Fetch Kounta Products
                          </Button>

                          <div hidden={!this.state.fetching_delivery_products}>
                            <RotateLoader />
                          </div>

                          {this.state.delivery_product_options.length === 0 ? (
                            <div />
                          ) : (
                            <FormGroup
                              title="Kounta Delivery Product"
                              help="Select a Kounta Product to use for delivery fee's on orders"
                              error={getFieldError(form, 'kounta_delivery_product_id')}
                            >
                              <SelectAdv
                                type="single"
                                options={deliveryProductOptions}
                                value={
                                  values.kounta_delivery_product_id ? values.kounta_delivery_product_id.toString() : ''
                                }
                                onChange={(option: string) => {
                                  //
                                  setFieldValue('kounta_delivery_product_id', option);
                                  //
                                  const selectedItems = this.state.delivery_product_options.filter(
                                    optionElement => option === optionElement.value.toString(),
                                  );
                                  setFieldValue('kounta_delivery_product_name', selectedItems[0]?.label);
                                  //
                                }}
                              />
                            </FormGroup>
                          )}

                          <FormGroup
                            title="Kounta Pass Through Flag"
                            help="Enable Kounta pass through on all orders?"
                            error={getFieldError(form, 'kounta_pass_thru')}
                          >
                            <SelectAdv
                              type="single"
                              options={[
                                {
                                  label: 'No',
                                  value: 'false',
                                },
                                {
                                  label: 'Yes',
                                  value: 'true',
                                },
                              ]}
                              value={values.kounta_pass_thru ? values.kounta_pass_thru.toString() : ''}
                              onChange={(option: string) => {
                                //
                                setFieldValue('kounta_pass_thru', option);
                                //
                              }}
                            />
                          </FormGroup>
                        </ModalContent>

                        <ModalContent>
                          {error && <FormGroup error={error} />}
                          <Button full color="primary" type="submit" disabled={isSubmitting}>
                            {isSubmitting && <RotateLoader size={2} color="white" />}
                            {!isSubmitting && 'Save'}
                          </Button>
                        </ModalContent>
                      </div>
                    );
                  }}
                </RestaurantForm>
              </BoxSection>
            </Box>
            {/* <RestaurantForm<AppFormValues>
          submit={async (r, values) => {
            const isUpdating = !!values._id;
            if (!isUpdating) {
              values._id = shortid.generate();
            }

            let integrationApps = r!.settings.integrations || {};
            _set(integrationApps, this.props.baseApp.slug, values);
            r.settings.integrations = integrationApps;

            const update = { $set: { "settings.integrations": r.settings.integrations } };
            return { r, update };
          }}
          validators=
          initialValues={formValues}
          onSuccess={this.props.onSuccess}
          onError={() => UI.notification.error("An error occurred")}
          onSuccessMessage="Settings Updated"
          onErrorMessage="">
          {({ form, error }) => {
            const { errors, isSubmitting } = form;
            return (
              <>
                { <FastField
                  name="client_id"
                  render={({ field }: any) => (
                    <FormGroup
                      title="Client ID"
                      help="The public identifier for the application, obtained when first registered the application.">
                      <Input type="text" {...field} autoSave="false" autoCorrect="false" required={true} />
                      {errors.client_id && <ErrorBox>{errors.client_id}</ErrorBox>}
                    </FormGroup>
                  )}
                />

                <FastField
                  name="client_secret"
                  render={({ field }: any) => (
                    <FormGroup
                      title="Client Secret"
                      help="The secret for the application, obtained when first registered the application.">
                      <Input type="text" {...field} autoSave="false" autoCorrect="false" required={true} />
                      {errors.client_secret && <ErrorBox>{errors.client_secret}</ErrorBox>}
                    </FormGroup>
                  )}
                />

                <FastField
                  name="authorization_url"
                  validate={validateUrl}
                  render={({ field }: any) => (
                    <FormGroup
                      title="Authorization URL"
                      help="The OAuth2 authorization URL provided by application provider.">
                      <Input type="text" {...field} autoSave="false" autoCorrect="false" placeholder="https://example.com/authorize" required={true} />
                      {errors.authorization_url && <ErrorBox>{errors.authorization_url}</ErrorBox>}
                    </FormGroup>
                  )}
                />

                <FastField
                  name="token_url"
                  validate={validateUrl}
                  render={({ field }: any) => (
                    <FormGroup
                      title="Token URL"
                      help="The OAuth2 token generator URL provided by application provider.">
                      <Input type="text" {...field} autoSave="false" autoCorrect="false" placeholder="https://example.com/tokens" required={true} />
                      {errors.token_url && <ErrorBox>{errors.token_url}</ErrorBox>}
                    </FormGroup>
                  )}
                />

                <FastField
                  name="api_url"
                  validate={validateUrl}
                  render={({ field }: any) => (
                    <FormGroup
                      title="API URL"
                      help="The base API endpoint to access protected resources using application access token.">
                      <Input type="text" {...field} autoSave="false" autoCorrect="false" placeholder="https://api.example.com" required={true} />
                      {errors.api_url && <ErrorBox>{errors.api_url}</ErrorBox>}
                    </FormGroup>
                  )}
                />

                <FastField
                  name="token_scopes"
                  render={({ field }: any) => (
                    <FormGroup
                      title="Token Scopes"
                      optional={true}
                      help="The comma-separated list of scopes that is applied to the access token.">
                      <Input type="text" {...field} autoSave="false" autoCorrect="false" placeholder="read,write" required={false} />
                      {errors.token_scopes && <ErrorBox>{errors.token_scopes}</ErrorBox>}
                    </FormGroup>
                  )}
                /> }

                { {error && <FormGroup error={error} />} }

                { <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting && <RotateLoader size={2} color="white" />}
                  {!isSubmitting && (this.props.submitButtonText || "Create Application")}
                </Button> }
              </>
            );
          }}
        </RestaurantForm> */}
          </>
        );
      }
    },
  ),
);
