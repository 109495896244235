import React from 'react';
import { FastField } from 'formik';
import { FormGroup, Button, RotateLoader, Switch, Input } from '@lib/components';
import { inject, observer } from 'mobx-react';
import { MobxComponent } from '../../../../../../../mobx/components/index';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';
import { UI } from '../../../../../../../core/ui';
import { PaymentBaseFields } from './base';

interface Props {}
interface State {}
type FormValues = T.Schema.Restaurant.Payments.RestaurantPaymentGkash;

export const SettingsFormPaymentsGkash = inject('store')(
  observer(
    class SettingsFormPaymentsGkash extends MobxComponent<Props, State> {
      initialValues: FormValues;

      constructor(props: Props) {
        super(props);
        this.state = {};
        const r = this.injected.store.restaurant!;
        this.initialValues = r.settings.payments.gkash || {
          enabled: false,
          label: '',
          services: [],
          testing: false,
          merchant_id: '',
          signature_key: '',
        };
      }

      render() {
        return (
          <RestaurantForm<FormValues>
            submit={async (r, values) => {
              r.settings.payments.gkash = values;

              let update;
              if (values.enabled) {
                r.settings.business.using_awaiting_payment = true;
                update = {
                  $set: {
                    'settings.payments.gkash': r.settings.payments.gkash,
                    'settings.business.using_awaiting_payment': true,
                  },
                };
              } else {
                update = {
                  $set: {
                    'settings.payments.gkash': r.settings.payments.gkash,
                  },
                };
              }

              return { r, update };
            }}
            validators={{
              // @ts-ignore
              merchant_id: values => {
                if (values.enabled && !values.merchant_id)
                  return {
                    merchant_id: 'Merchant ID is required field!',
                  };
              },

              // @ts-ignore
              signature_key: values => {
                if (values.enabled && !values.signature_key)
                  return {
                    signature_key: 'Signature Key is required field',
                  };
              },
            }}
            initialValues={this.initialValues}
            onSuccess={() => {}}
            onError={() => UI.notification.error('An error occurred')}
            onSuccessMessage="Settings Updated"
            onErrorMessage=""
          >
            {({ form, error, getFieldError }) => {
              const { isSubmitting, setFieldValue } = form;
              return (
                <div className="p-4">
                  <FastField
                    name="enabled"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Enabled"
                        help="Allows customers to pay using Gkash method. Currently, Gkash only support Malaysian ringgit (MYR). Please make sure your restaurant is using MYR currency before turn it on."
                      >
                        <Switch
                          id="enable-switch"
                          checked={field.value}
                          onChange={e => setFieldValue('enabled', e.target.checked)}
                        />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="testing"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Testing Environment"
                        help="Enable this to use the GKash Payment demo environment to test and experiment with your integration."
                      >
                        <Switch
                          id="testing-switch"
                          checked={field.value}
                          onChange={e => setFieldValue('testing', e.target.checked)}
                        />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="merchant_id"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Merchant ID"
                        help="The merchant ID given by Gkash when setting up an account."
                        error={getFieldError(form, 'merchant_id')}
                      >
                        <Input type="text" {...field} autoSave="false" autoCorrect="false" />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="signature_key"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Signature Key"
                        help="The secret key given by Gkash when setting up an account."
                        error={getFieldError(form, 'signature_key')}
                      >
                        <Input type="text" {...field} autoSave="true" autoCorrect="false" />
                      </FormGroup>
                    )}
                  />

                  <PaymentBaseFields defaultLabel="Gkash" setFieldValue={setFieldValue} />

                  {error && <FormGroup error={error} />}

                  <Button full color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting && <RotateLoader size={2} color="white" />}
                    {!isSubmitting && 'Save'}
                  </Button>
                </div>
              );
            }}
          </RestaurantForm>
        );
      }
    },
  ),
);
