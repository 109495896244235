/* eslint-disable no-console */
import { computed, makeObservable } from 'mobx';
import { Untrusive, logger } from '@lib/common';
import { UI } from '../../core/ui';
import { RootStore } from '../store';

export class BillingStore {
  cb: any;

  store: RootStore;

  constructor(store: RootStore) {
    makeObservable(
      this,
      {
        isPaidRestaurant: computed,
      },
      { autoBind: true },
    );

    this.store = store;
  }

  get isPaidRestaurant() {
    const { restaurant } = this.store;
    if (restaurant && restaurant.billing.cb) {
      return restaurant.billing.cb.plan.indexOf('free') === -1;
    }
    return true;
  }

  initChargebeeSession = async (restaurant_id: string, close?: () => void) => {
    const { store } = this;
    const reseller = store.reseller!;
    const error = 'Error loading billing, try again or contact us';

    store.service.track('Subscription: View', {
      reseller_id: store.reseller!._id,
      restaurant_id,
    });

    try {
      Untrusive.start();
      const response = await store.api.billing_session({ restaurant_id });
      if (response.outcome) {
        UI.notification.error(error);
        Untrusive.stop();
        return;
      }

      this.cb = Chargebee.init({
        site: reseller.chargebee!.site,
      });

      this.cb.setPortalSession(() => Promise.resolve(response.session));

      setTimeout(() => {
        this.cb.createChargebeePortal().open({
          close: () => {
            this.cb.logout();
            UI.notification.success('Allow 10 seconds for any billing changes to take effect', { timeout: 10000 });
            if (close) close();
          },
          paymentSourceAdd: () => {
            console.log('Subscription: Payment Source Add');
            store.service.track('Subscription: Payment Source Add', {
              restaurant_id,
              reseller_id: store.reseller!._id,
            });
          },
          paymentSourceUpdate: () => {
            console.log('Subscription: Payment Source Update');
            store.service.track('Subscription: Payment Source Update', {
              restaurant_id,
              reseller_id: store.reseller!._id,
            });
          },
          paymentSourceRemove: () => {
            console.log('Subscription: Payment Source Remove');
            store.service.track('Subscription: Payment Source Remove', {
              restaurant_id,
              reseller_id: store.reseller!._id,
            });
          },
          subscriptionChanged: (data: { subscription: T.Lib.ChargeBee.ChargebeeSubscription }) => {
            console.log('subscriptionChanged');
            store.service.track('Subscription: Update', {
              restaurant_id,
              reseller_id: store.reseller!._id,
              plan_id: data.subscription.plan_id,
              status: data.subscription.status,
            });
          },
          subscriptionCancelled: (data: { subscription: T.Lib.ChargeBee.ChargebeeSubscription }) => {
            console.log('subscriptionCancelled');
            store.service.track('Subscription: Cancel', {
              restaurant_id,
              reseller_id: store.reseller!._id,
              plan_id: data.subscription.plan_id,
              status: data.subscription.status,
            });
          },
          subscriptionReactivated: (data: { subscription: T.Lib.ChargeBee.ChargebeeSubscription }) => {
            console.log('subscriptionReactivated');
            store.service.track('Subscription: Reactivated', {
              restaurant_id,
              reseller_id: store.reseller!._id,
              plan_id: data.subscription.plan_id,
              status: data.subscription.status,
            });
          },
        });
        Untrusive.stop();
      }, 200);
    } catch (e) {
      logger.captureException(e);
      UI.notification.error(error);
      Untrusive.stop();
    }
  };

  updateRestaurantSubscription = async (
    _id: string,
    subscription: T.Lib.ChargeBee.ChargebeeSubscription,
    refresh?: () => void,
  ) => {
    console.log('UPDATE SUBSCRIPTION', subscription);
    try {
      Untrusive.start();
      await this.store.api.restaurant_update_subscription({ _id, subscription });
    } catch (e) {
      logger.captureException(e);
    } finally {
      if (refresh) {
        setTimeout(() => {
          refresh();
          Untrusive.stop();
        }, 1000);
      } else {
        Untrusive.stop();
      }
    }
  };
}
