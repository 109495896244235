import React from 'react';
import { Button, RotateLoader, Modal, ModalTitle, ModalContent } from '@lib/components';
import { logger, Untrusive } from '@lib/common';
import { inject, observer } from 'mobx-react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { UI } from '../../../../../core/ui';
import { RestaurantFormSetup } from './form.setup';
import { MobxComponent } from '../../../../../mobx/components';
import { DashboardRestaurantsList } from './list';

interface Props extends WithTranslation {}
interface State {
  create_modal_active: boolean;
  delete_modal_active: number | null;
  cb_loaded: boolean;
  cb_error: boolean;
}

const DashboardRestaurantsClass = inject('store')(
  observer(
    class DashboardRestaurantsClass extends MobxComponent<Props, State> {
      constructor(props: Props) {
        super(props);
        this.state = {
          create_modal_active: false,
          delete_modal_active: null,
          cb_error: false,
          cb_loaded: false,
        };
      }

      componentDidMount() {
        this.get();
      }

      onChange = <T extends keyof State>(e: React.ChangeEvent<HTMLInputElement>) => {
        const newState = UI.helpers.handleChange(e);
        this.setState(newState as { [P in T]: State[P] });
      };

      loading = () => (
        <div className="m-t-12">
          <RotateLoader size={3} />
        </div>
      );

      start = () => {
        const { store } = this.injected;
        const { restrictions } = store;
        if (!restrictions.restaurant.create) {
          return null;
        }
        return (
          <div className="m-t-12 text-center">
            <h1 className="m-b-4">Create A Restaurant To Get Started</h1>
            <p className="big m-b-6">Every restaurant has it's own store, menus, settings, orders and bookings</p>
            <Button
              className="width200"
              color="primary"
              round
              onClick={() => this.setState({ create_modal_active: true })}
            >
              Create New Restaurant
            </Button>
          </div>
        );
      };

      restaurants = () => {
        const { store } = this.injected;
        const { restrictions } = store;
        return (
          <div>
            <div className="flex-l-r-center m-b-3">
              <h2>Restaurants</h2>
              {restrictions.restaurant.create && (
                <Button size="xs" round color="primary" onClick={() => this.setState({ create_modal_active: true })}>
                  New Restaurant
                </Button>
              )}
            </div>

            <div className="m-t-10">
              <DashboardRestaurantsList />
            </div>
          </div>
        );
      };

      modal_setup = () => {
        const { store, t } = this.injected;
        const { trialExpiry, trialExpired } = store;
        const { restrictions } = store;

        if (!restrictions.restaurant.create) {
          return null;
        }

        const res = store.reseller!;

        return (
          <Modal
            width={620}
            active={this.state.create_modal_active}
            close={() => this.setState({ create_modal_active: false })}
          >
            <ModalTitle className="round-top">
              <h3>New Restaurant Setup</h3>
              {res.chargebee && !!res.chargebee.subscription.trial_period_days && (
                <div className="m-t-1">
                  {trialExpired && (
                    <p className="lhp">
                      You subscription for this restaurant will commence immediately and you will be billed according to
                      the plan you select below
                    </p>
                  )}
                  {!trialExpired && (
                    <p className="lhp">
                      This restaurant will start with a trial subscription after which your billing will commence
                      according to the plan you select below. Your trail expires at{' '}
                      {t('dateFromTimestamp', { value: trialExpiry })}
                    </p>
                  )}
                </div>
              )}
            </ModalTitle>

            <ModalContent>
              <RestaurantFormSetup
                back={() => {
                  this.setState({ create_modal_active: false });
                  this.get();
                }}
              />
            </ModalContent>
          </Modal>
        );
      };

      modal_delete = () => {
        const { delete_modal_active } = this.state;
        const { store } = this.injected;
        const { restrictions } = store;
        const restaurants = store.restaurants.items;

        if (!restrictions.restaurant.delete) {
          return null;
        }

        let item;
        if (delete_modal_active !== null && restaurants[delete_modal_active]) {
          item = restaurants[delete_modal_active];
        }

        return (
          <Modal
            width="sm"
            close={() => this.setState({ delete_modal_active: null })}
            active={delete_modal_active !== null}
          >
            <ModalContent>
              <h4 className="m-b-1">Delete Restaurant</h4>
              <p className="underline">{item ? item.name : ''}</p>
            </ModalContent>
            <ModalContent>
              <p className="lhp big">
                Are you sure you want to delete this restaurant. Once deleted, none of it's data can be recovered.
                Proceed with caution
              </p>
            </ModalContent>
            <ModalContent className="text-right">
              <Button className="m-r-2 width100 max128" onClick={() => this.setState({ delete_modal_active: null })}>
                Cancel
              </Button>
              <Button color="primary" onClick={() => this.delete(delete_modal_active)}>
                Delete
              </Button>
            </ModalContent>
          </Modal>
        );
      };

      get = async () => {
        const { store } = this.injected;
        try {
          store.updateRestaurants({ loading: true, error: '' });
          const data = await this.injected.store.api.restaurants();
          if (data.outcome) {
            store.updateRestaurants({
              loading: false,
              error: data.message,
              items: [],
            });
          } else {
            const { items } = data;
            store.updateRestaurants({
              loading: false,
              error: '',
              items,
            });
            if (items.length === 1) {
              setTimeout(() => this.injected.store.service.tour_new_restaurant(), 500);
            }
          }
        } catch (e) {
          logger.captureException(e);
          store.updateRestaurants({
            loading: false,
            error: '',
            items: [],
          });
          UI.notification.error('Error loading restaurants, retrying in 5 seconds', { timeout: 5000 });
          setTimeout(this.get, 5000);
        }
        return null;
      };

      view = async (index: number | null) => {
        if (index === null) return;
        const { store } = this.injected;
        const { restrictions } = store;
        const rr = restrictions.restaurant;
        const r = store.restaurants.items[index]!;
        let path = `/restaurant/${r._id}`;

        if (rr.dashboard) {
          path = `/restaurant/${r._id}`;
        } else if (restrictions._.restaurantOrderViews.length > 0) {
          path = `/restaurant/${r._id}/orders`;
        } else if (rr.bookings) {
          path = `/restaurant/${r._id}/bookings`;
        } else if (rr.customers) {
          path = `/restaurant/${r._id}/customers`;
        } else if (rr.menus) {
          path = `/restaurant/${r._id}/menus`;
        } else if (restrictions._.restaurantSettingsEnabled) {
          path = `/restaurant/${r._id}/settings`;
        }

        store.router.push(path);
      };

      delete = async (index: number | null) => {
        if (index === null) return;
        const { store } = this.injected;
        try {
          // eslint-disable-next-line no-alert
          const p = prompt(
            "Are you sure you want to delete this restaurant. Once deleted, none of it's data can be recovered. Enter 'delete' into the field below to proceed with delation",
          );

          if (p === null) {
            return;
          }

          if (p.toLowerCase() !== 'delete') {
            UI.notification.error("Enter 'delete' to delete the restaurant");
            return;
          }

          Untrusive.start();

          const r = store.restaurants.items[index]!;
          const res = await this.injected.store.api.restaurant_delete({ _id: r._id });

          Untrusive.stop();

          if (res.outcome) {
            UI.notification.error(res.message);
            return;
          }

          const restaurants = [...store.restaurants.items];
          restaurants.splice(index, 1);

          this.setState({ delete_modal_active: null });
          store.updateRestaurants({ items: restaurants });

          UI.notification.success('Restaurant deleted');
        } catch (e) {
          logger.captureException(e);
          UI.notification.error('Something went wrong, try again or contact us');
          Untrusive.stop();
        }
      };

      render() {
        if (!this.state) return null;
        const { store } = this.injected;
        const { loading, items } = store.restaurants;
        return (
          <div>
            {loading && this.loading()}

            {!loading && items.length === 0 && this.start()}

            {!loading && items.length !== 0 && this.restaurants()}

            {this.modal_setup()}

            {this.modal_delete()}
          </div>
        );
      }
    },
  ),
);

export const DashboardRestaurants = withTranslation()(DashboardRestaurantsClass);
