import React from 'react';
import { GN } from '@lib/common';

interface NotificationArgs {
  timeout?: number;
}

// MAIN
export const UI = {
  notification: {
    success: (text: string, opts?: NotificationArgs) => {
      opts = opts || { timeout: 5000 };
      GN.add({
        type: 'success',
        duration: opts.timeout || 5000,
        message: text,
      });
    },
    error: (text?: string, opts?: NotificationArgs) => {
      const generic_error = 'Something went wrong, try again or contact us';
      opts = opts || { timeout: 5000 };
      GN.add({
        type: 'error',
        duration: opts.timeout || 5000,
        message: text || generic_error,
      });
    },
    info: (text: string, opts?: NotificationArgs) => {
      opts = opts || { timeout: 5000 };
      GN.add({
        type: 'info',
        duration: opts.timeout || 5000,
        message: text,
      });
    },
    warning: (text: string, opts?: NotificationArgs) => {
      opts = opts || { timeout: 5000 };
      GN.add({
        type: 'warning',
        duration: opts.timeout || 5000,
        message: text,
      });
    },
  },

  calcs: {
    breakpoint(width: number) {
      if (width >= 1200) return 'xl';
      if (width >= 920) return 'lg';
      if (width >= 720) return 'md';
      if (width >= 480) return 'sm';
      return 'xs';
    },
  },

  helpers: {
    handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLFormElement>) => {
      const change: { [key: string]: string | boolean } = {};

      let val;
      if (e.target.type === 'checkbox') {
        val = e.target.checked;
      } else {
        val = e.target.value;
      }

      change[e.target.name] = val;

      return change;
    },

    removeElement(name: string) {
      const el = document.getElementById(name);
      if (el) el.remove();
    },
  },
};
