import { AxiosError } from 'axios';
import Lalamove from '@lib/lalamove';

export const handleAxiosError = (e: AxiosError) => {
  if (e.response && e.response.data) {
    throw e.response.data;
  } else if (e.response) {
    throw e.response;
  } else {
    throw e;
  }
};

export const arrayToSidesPx = (a: number[], defaultValue: number) =>
  `${a[0] || defaultValue}px ${a[1] || defaultValue}px ${a[2] || defaultValue}px ${a[3] || defaultValue}px`;

export function getDeliveryVia(order: T.Schema.Order.OrderSchema): string {
  if (order.config.service !== 'delivery') {
    return '';
  }

  const provider = order.config.delivery_provider;
  if (!provider) {
    return '';
  }

  if (provider === 'lalamove') {
    const market = order.config.lalamove_market;
    if (!market) return '';
    const serviceType = order.config.lalamove_service_type;
    const option = Lalamove.serviceTypesByMarket(market).find(item => item.value === serviceType);
    return option ? option.label : '';
  }

  return '';
}

export function getDeliveryOrderId(order: T.Schema.Order.OrderSchema): string {
  if (order.config.service !== 'delivery') {
    return '';
  }

  const provider = order.config.delivery_provider;
  if (!provider) {
    return '';
  }

  if (provider === 'lalamove') {
    return order.config.lalamove_order_id || '';
  }

  if (provider === 'postmates') {
    return order.config.postmates_delivery_id || '';
  }

  return '';
}
