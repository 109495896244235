/// <reference types="@lib/types" />
import './types.d';
import throttle from 'lodash/throttle';
import { computed, observable, action, makeObservable, set } from 'mobx';
import { RouterState, RouterStore } from '@lib/router';
import { IntlStore } from '@lib/intl';
import { logger, CoreUtils, OrderUtils, cloneDeepSafe, PaymentMethods } from '@lib/common';
import localStore from 'store';
import cloneDeep from 'lodash/cloneDeep';
import { ThemeStore } from './stores/theme';
import { BillingStore } from './stores/billing';
import { ServiceStore } from './stores/service';
import { NotificationsStore } from './stores/notifications';
import { APIStore } from './stores/api';
import { routeIsMatch } from '../routes';
import { generateStaffRestrictions } from '../react/ui/dashboard/views/staff';
import { config } from '../config';

interface GenericQueryCollection<T> {
  items: T[];
  count: number;
  page: number;
  chargeTotal?: number;
  feesTotal?: number;
  netTotal?: number;
}
interface GenericAsyncListCollection<T> {
  loading: boolean;
  error: string;
  items: T[];
}

type RestaurantsStore = GenericAsyncListCollection<T.API.DashboardRestaurantsResponseItem>;
type StaffStore = GenericAsyncListCollection<T.Schema.User.UserSchema>;
type APISStore = GenericAsyncListCollection<T.Schema.API.APISchema>;

interface OrdersView {
  layout: 0 | 1;
  boardSize: 2 | 3 | 4 | 5;
  hideUnconfirmed: boolean;
}

export class RootStore {
  theme: ThemeStore;

  router: RouterStore;

  intl: IntlStore;

  api: APIStore;

  service: ServiceStore;

  notifications: NotificationsStore;

  billing: BillingStore;

  routeChangeCount: number = 0;

  auth: AuthState;

  loader: LoaderState;

  view: ViewState;

  ably: AblyState;

  reseller: T.Schema.Reseller.ResellerSchema | null;

  organisation: T.Schema.Organisation.OrganisationSchema | null;

  website: T.Schema.Website.WebsiteSchema | null;

  restaurants: RestaurantsStore;

  staff: StaffStore;

  apis: APISStore;

  restaurant: T.Schema.Restaurant.RestaurantSchema | null;

  restaurant_stock: T.Schema.RestaurantMenuStock.Schema | null;

  restaurant_integration_base_apps: T.Schema.Restaurant.Integrations.BaseApp[] | null;

  customers: GenericQueryCollection<T.Schema.Customer.CustomerSchema>;

  customer: T.Schema.Customer.CustomerSchema | null;

  ordersView: OrdersView;

  ordersBoard: T.Lib.ListBoard.GenericListBoardCollection<T.Schema.Order.OrderSchema>;

  orders: GenericQueryCollection<T.Schema.Order.OrderSchema>;

  order: T.Schema.Order.OrderSchema | null;

  bookings: GenericQueryCollection<T.Schema.Booking.BookingSchema>;

  booking: T.Schema.Booking.BookingSchema | null;

  onlinePaymentOrder: T.Schema.Stripe.StripeTransactions | null;

  onlinePaymentOrders: GenericQueryCollection<T.Schema.Stripe.StripeTransactions>;

  stripePayouts: GenericQueryCollection<T.Schema.Stripe.StripePayout>;

  constructor() {
    makeObservable(
      this,
      {
        auth: observable,
        loader: observable,
        view: observable,
        ably: observable,
        reseller: observable,
        organisation: observable,
        website: observable,
        restaurants: observable,
        staff: observable,
        apis: observable,
        restaurant: observable,
        restaurant_stock: observable,
        restaurant_integration_base_apps: observable,
        customers: observable,
        customer: observable,
        ordersView: observable,
        ordersBoard: observable,
        orders: observable,
        order: observable,
        bookings: observable,
        booking: observable,
        onlinePaymentOrder: observable,
        onlinePaymentOrders: observable,
        stripePayouts: observable,
        routeOnChange: action,
        showMainUserSupport: computed,
        showMainSupport: computed,
        isMainReseller: computed,
        isStaff: computed,
        restrictions: computed,
        trialExpiry: computed,
        trialExpired: computed,
        isMapped: computed,
        storeURL: computed,
        setAuth: action,
        updateAuth: action,
        setView: action,
        updateView: action,
        updateAbly: action,
        setLoader: action,
        updateLoader: action,
        toggleLoader: action,
        setOrganisation: action,
        updateOrganisation: action,
        setWebsite: action,
        updateWebsite: action,
        setRestaurant: action,
        updateRestaurant: action,
        updateRestaurants: action,
        updateRestaurantComplete: action,
        setRestaurantStock: action,
        setRestaurantIntegrationBaseApps: action,
        getRestaurantStock: action,
        getKountaSites: action,
        getKountaPayments: action,
        getKountaDeliveryProducts: action,
        getKountaRegisters: action,
        generateMenu: action,
        generateMenuv2: action,
        generateAbacusMenu: action,
        checkStatus: action,
        checkStatusAbacus: action,
        updateStaff: action,
        updateApis: action,
        setReseller: action,
        updateReseller: action,
        setOrder: action,
        updateOrder: action,
        updateOrders: action,
        updateOrdersBoard: action,
        updateOrderComplete: action,
        removeOrder: action,
        getOrder: action,
        getStripeTransaction: action,
        setBooking: action,
        updateBooking: action,
        updateBookingComplete: action,
        updateBookings: action,
        removeBooking: action,
        updateCustomers: action,
        setCustomer: action,
        updateCustomer: action,
        updateCustomerComplete: action,
        removeCustomer: action,
        setOnlinePaymentOrder: action,
        updateOnlinePaymentOrder: action,
        updateOnlinePaymentOrders: action,
        updateStripePayouts: action,
        windowResize: action,
        windowScroll: action,
      },
      { autoBind: true },
    );

    this.auth = {
      type: null,
      item: null,
      token: null,
      decoded: null,
      fetching: false,
      error: null,
    };

    this.view = {
      breakpoint: 'md',
      screen_width: 720,
      scroll_top: 0,
      sidenav_active: false,
    };

    this.loader = {
      active: true,
      opacity: 1,
      title: 'Loading...',
      message: 'This can take up to one minute the first time or if an update has been released',
    };

    this.ably = {
      status: 'disconnected',
      connected_once: false,
      printers: [],
    };

    this.reseller = null;
    this.organisation = null;
    this.website = null;
    this.restaurants = observable({
      loading: false,
      error: '',
      items: [],
    });
    this.staff = observable({
      loading: false,
      error: '',
      items: [],
    });
    this.apis = observable({
      loading: false,
      error: '',
      items: [],
    });

    this.restaurant = null;
    this.restaurant_stock = null;
    this.restaurant_integration_base_apps = null;

    this.customers = {
      items: [],
      count: 0,
      page: 0,
    };
    this.customer = null;

    this.onlinePaymentOrders = {
      items: [],
      count: 0,
      page: 0,
    };
    this.onlinePaymentOrder = null;

    this.stripePayouts = {
      items: [],
      count: 0,
      page: 0,
    };

    const orderViewSettings = localStore.get('store-ordersView') || {};
    this.ordersView = {
      layout: orderViewSettings.layout ? (parseInt(orderViewSettings.layout, 10) as 0 | 1) : 0,
      boardSize: orderViewSettings.boardSize ? (parseInt(orderViewSettings.boardSize, 10) as 2 | 3 | 4 | 5) : 3,
      hideUnconfirmed: !!orderViewSettings.hideUnconfirmed,
    };
    this.ordersBoard = {
      loading: false,
      error: '',
      lists: {},
    };
    this.orders = {
      items: [],
      count: 0,
      page: 0,
    };
    this.order = null;

    this.bookings = {
      items: [],
      count: 0,
      page: 0,
    };
    this.booking = null;

    this.theme = new ThemeStore(this);
    this.router = new RouterStore(undefined, this.routeOnChange);

    this.intl = new IntlStore({
      useReactModule: true,
    });

    this.notifications = new NotificationsStore(this);
    this.service = new ServiceStore(this);
    this.billing = new BillingStore(this);
    this.api = new APIStore(this, {
      auth_token_error: this.service.handle_auth_token_error,
    });

    this.routeOnChange(this.router.s);
    this.windowResize();
    this.windowScroll();
    window.addEventListener('resize', throttle(this.windowResize, 100));
    document.addEventListener('scroll', throttle(this.windowScroll, 50));
  }

  // LOGIN
  routeOnChange = (s: RouterState) => {
    try {
      const { restrictions, auth } = this;

      // INC
      this.routeChangeCount += 1;

      window.Intercom('update');

      // GET ROUTE
      const route = routeIsMatch(s.path);

      if (!route) {
        // NOT FOUND
        this.router.set404(true);
        document.title = '404 - Not Found';
      } else {
        // FOUND

        this.router.set404(false);

        document.title = route.title;

        if (this.routeChangeCount > 1 && route.auth && !auth.token) {
          // NOT AUTHENTICATED
          logger.info('ROUTE TAKE TO LOGIN');
          this.router.push('/login');
        } else if (
          auth.item &&
          auth.item.type === 'staff' &&
          route.match &&
          route.match.rid &&
          restrictions.restaurants.indexOf(route.match.rid) === -1
        ) {
          logger.info('ROUTE TAKE TO / RID');
          this.router.push('/');
        } else if (route.restriction_keys) {
          logger.info('ROUTE RESTRICTIONS');
          let is_restricted = true;

          // eslint-disable-next-line no-restricted-syntax
          for (const restriction_key of route.restriction_keys) {
            let available;
            const access_keys = restriction_key.split('.');

            if (access_keys.length === 3) {
              available =
                // @ts-ignore
                restrictions[access_keys[0]]?.[access_keys[1]]?.[access_keys[2]];
            } else {
              available =
                // @ts-ignore
                restrictions[access_keys[0]]?.[access_keys[1]];
            }

            if (available) {
              is_restricted = false;
              break;
            }
          }

          if (is_restricted) {
            logger.info('ROUTE RESTRICTIONS TAKE TO /');
            this.router.push('/');
          }
        }

        // SCROLL TOP
        const sr = document.getElementById('scroll-root');
        const noScrollPages = ['restaurant_bookings', 'restaurant_orders', 'restaurant_customers'];
        if (sr && sr.scroll && noScrollPages.indexOf(route.key) === -1) {
          sr.scroll({ top: 0, left: 0, behavior: 'auto' });
        }
      }
    } catch (err) {
      logger.captureException(err, 'ROUTE CHANGE ERROR');
      this.router.push('/');
    }
  };

  get showMainUserSupport() {
    const user = this.auth.item;
    return this.isMainReseller && user && user.type !== 'staff';
  }

  get showMainSupport() {
    return this.isMainReseller;
  }

  get isMainReseller() {
    if (this.reseller) {
      return ['cloudwaitress', 'cloudwaitress-test'].indexOf(this.reseller._id) !== -1;
    }
    return false;
  }

  get isStaff() {
    if (this.auth.item) {
      return this.auth.item.type === 'staff';
    }
    return false;
  }

  get restrictions() {
    let restrictions;
    if (this.auth.item && this.auth.item.restrictions) {
      restrictions = cloneDeepSafe(this.auth.item.restrictions);
    } else {
      restrictions = generateStaffRestrictions();
    }

    const restaurantRestrictions = restrictions.restaurant;

    let restaurantSettingsEnabled = false;
    if (restrictions.restaurant.settings_detail) {
      const settingDetail = restrictions.restaurant.settings_detail;
      if (
        settingDetail.system ||
        settingDetail.services ||
        settingDetail.payments ||
        settingDetail.website ||
        settingDetail.integrations
      ) {
        restaurantSettingsEnabled = true;
      }
    } else if (restrictions.restaurant.settings) {
      restaurantSettingsEnabled = true;
    }

    let onlinePaymentEnabled = false;
    if (restrictions.online_payment) {
      const onlinePaymentDetail = restrictions.online_payment;
      // for future extension
      if (onlinePaymentDetail.view_transaction) onlinePaymentEnabled = true;
    }

    const restaurantOrderViews: string[] = [];
    if (typeof restrictions.restaurant.orders_list === 'undefined') {
      if (restrictions.restaurant.orders) {
        restaurantOrderViews.push('board');
        restaurantOrderViews.push('list');
      }
    } else {
      if (restrictions.restaurant.orders_board) restaurantOrderViews.push('board');
      if (restrictions.restaurant.orders_list) restaurantOrderViews.push('list');
    }

    const restaurantView =
      restaurantRestrictions.dashboard ||
      restaurantOrderViews.length > 0 ||
      restaurantRestrictions.bookings ||
      restaurantRestrictions.menus ||
      restaurantRestrictions.customers ||
      restaurantSettingsEnabled;

    const restaurantNotificationsEnabled =
      restaurantOrderViews.length > 0 ||
      restaurantRestrictions.bookings ||
      restaurantRestrictions.customers ||
      restaurantSettingsEnabled;

    return {
      ...restrictions,
      _: {
        restaurantView,
        restaurantSettingsEnabled,
        restaurantOrderViews,
        restaurantNotificationsEnabled,
        onlinePaymentEnabled,
      },
    };
  }

  get trialExpiry() {
    const r = this.reseller!;
    const { organisation } = this;
    if (organisation && r.chargebee && r.chargebee.subscription.trial_period_days) {
      return organisation.created + 1000 * 60 * 60 * 24 * r.chargebee.subscription.trial_period_days;
    }
    return 0;
  }

  get trialExpired() {
    return Date.now() > this.trialExpiry; // 30 DAYS
  }

  get isMapped() {
    const r = this.restaurant;
    if (!r) return false;
    return r.location.map_data.type === 'google_maps' || r.location.map_data.type === 'osm';
  }

  get storeURL() {
    const { reseller } = this;
    const r = this.restaurant;
    if (!r) {
      return '';
    }
    if (config.isTest) {
      return 'http://localhost:3000';
    }
    return r.domain ? `https://${r.domain}` : `https://${r.subdomain}.${reseller!.store_host}`;
  }

  getPaymentMethodName = (method: string) => {
    const r = this.restaurant;

    const isBaseMethod = PaymentMethods.indexOf(method) !== -1;
    let paymentName = this.intl.i18n.t(`constants.payment.backend_method.${method}`);

    if (!r) {
      return isBaseMethod ? paymentName : method;
    }

    const paymentMethod = r.settings.payments[method];
    if (!isBaseMethod) {
      if (paymentMethod) {
        paymentName = paymentMethod.label || method;
      } else {
        paymentName = method;
      }
    }

    return paymentName;
  };

  setAuth = (data: AuthState) => {
    this.auth = data;
  };

  updateAuth = (data: Partial<AuthState>) => {
    Object.keys(data).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const value = data[key as keyof AuthState];
        if (value !== undefined) {
          set(this.auth, key, value);
        }
      }
    });
  };

  setView = (data: ViewState) => {
    this.view = data;
  };

  updateView = (data: Partial<ViewState>) => {
    Object.keys(data).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const value = data[key as keyof ViewState];
        if (value !== undefined) {
          set(this.view, key, value);
        }
      }
    });
  };

  updateAbly = (data: Partial<AblyState>) => {
    Object.keys(data).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const value = data[key as keyof AblyState];
        if (value !== undefined) {
          set(this.ably, key, value);
        }
      }
    });
  };

  setLoader = (data: LoaderState) => {
    this.loader = data;
  };

  updateLoader = (data: Partial<LoaderState>) => {
    Object.keys(data).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const value = data[key as keyof LoaderState];
        if (value !== undefined) {
          set(this.loader, key, value);
        }
      }
    });
  };

  toggleLoader = (data: boolean) => {
    this.loader.active = data;
  };

  setOrganisation = (obj: T.Schema.Organisation.OrganisationSchema | null) => {
    this.organisation = obj;
  };

  updateOrganisation = (data: Partial<T.Schema.Organisation.OrganisationSchema>) => {
    Object.keys(data).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const value = data[key as keyof T.Schema.Organisation.OrganisationSchema];
        if (value !== undefined && this.organisation) {
          set(this.organisation, key, value);
        }
      }
    });
  };

  setWebsite = (obj: T.Schema.Website.WebsiteSchema | null) => {
    this.website = obj;
  };

  updateWebsite = (data: Partial<T.Schema.Website.WebsiteSchema>) => {
    Object.keys(data).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const value = data[key as keyof T.Schema.Website.WebsiteSchema];
        if (value !== undefined && this.website) {
          set(this.website, key, value);
        }
      }
    });
  };

  setRestaurant = (r: T.Schema.Restaurant.RestaurantSchema | null) => {
    this.restaurant = r;
    if (r) {
      this.intl.set({
        lng: 'en',
        currency: {
          ...r.settings.region.currency,
          step: CoreUtils.currency.precision_to_step(r.settings.region.currency.precision),
        },
        tz: r.settings.region.timezone,
        locale: r.settings.region.locale,
        formats: r.settings.region.formats,
      });
    }
  };

  updateRestaurant = (data: Partial<T.Schema.Restaurant.RestaurantSchema>) => {
    Object.keys(data).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const value = data[key as keyof T.Schema.Restaurant.RestaurantSchema];
        if (value !== undefined && this.restaurant) {
          set(this.restaurant, key, value);
        }
      }
    });

    if (data && data.settings && data.settings.region) {
      if (data.settings.region.timezone) {
        this.intl.update({ tz: data.settings.region.timezone });
      }
      if (data.settings.region.locale) {
        this.intl.update({ locale: data.settings.region.locale });
      }
      if (data.settings.region.currency) {
        this.intl.update({
          currency: {
            ...data.settings.region.currency,
            step: CoreUtils.currency.precision_to_step(data.settings.region.currency.precision),
          },
        });
      }
      if (data.settings.region.formats && data.settings.region.currency) {
        this.intl.update({
          formats: data.settings.region.formats,
        });
      }
    }
  };

  updateRestaurants = (data: Partial<RestaurantsStore>) => {
    Object.keys(data).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const value = data[key as keyof RestaurantsStore];
        if (value !== undefined && this.restaurants) {
          set(this.restaurants, key, value);
        }
      }
    });
  };

  updateRestaurantComplete = (_id: string, update: Partial<T.Schema.Restaurant.RestaurantSchema>) => {
    // UPDATE SINGLE
    if (this.restaurant && this.restaurant._id === _id) {
      this.updateRestaurant(update);
    }

    // UPDATE COLLECTION
    const items = [...this.restaurants.items];
    Object.entries(items).forEach(([i, o]) => {
      if (o._id === _id) {
        // @ts-ignore
        items[i] = { ...items[i], ...update };
        this.updateRestaurants({ items });
      }
    });
  };

  setRestaurantStock = (stock: T.Schema.RestaurantMenuStock.Schema) => {
    this.restaurant_stock = stock;
  };

  setRestaurantIntegrationBaseApps = (apps: T.Schema.Restaurant.Integrations.BaseApp[]) => {
    this.restaurant_integration_base_apps = apps;
  };

  getRestaurantStock = async (_id: string) => {
    try {
      const result = await this.api.menu_stock_find({ _id });
      if (result.outcome) {
        throw new Error(result.message);
      }
      this.restaurant_stock = result.stock;
    } catch (e) {
      logger.captureException(e);
    }
  };

  // Kounta API's
  getKountaSites = async (_id: any): Promise<any> => {
    try {
      const result = await this.api.get_kounta_sites(_id);
      if (result.outcome) {
        throw new Error(result.message);
      }
      return result.data;
    } catch (e) {
      logger.captureException(e);
      return [];
    }
  };

  getKountaPayments = async (_id: any): Promise<any> => {
    try {
      const result = await this.api.get_kounta_payments(_id);
      if (result.outcome) {
        throw new Error(result.message);
      }
      return result.data;
    } catch (e) {
      logger.captureException(e);
      return [];
    }
  };

  getKountaDeliveryProducts = async (_id: any, site_id: any): Promise<any> => {
    try {
      const result = await this.api.get_kounta_delivery_products(_id, site_id);
      if (result.outcome) {
        throw new Error(result.message);
      }
      return result.data;
    } catch (e) {
      logger.captureException(e);
      return [];
    }
  };

  getKountaRegisters = async (_id: any, site_id: any): Promise<any> => {
    try {
      const result = await this.api.get_kounta_registers(_id, site_id);
      if (result.outcome) {
        throw new Error(result.message);
      }
      return result.data;
    } catch (e) {
      logger.captureException(e);
      return [];
    }
  };

  generateMenu = async (_id: any, menu_id: string): Promise<any> => {
    try {
      const result = await this.api.generate_menu(_id, menu_id);
      if (result.outcome) {
        throw new Error(result.message);
      }
      return result.data;
    } catch (e) {
      logger.captureException(e);
      return null;
    }
  };

  generateMenuv2 = async (_id: any, menu_id: string): Promise<any> => {
    try {
      const result = await this.api.generate_menuv2(_id, menu_id);
      if (result.outcome) {
        throw new Error(result.message);
      }
      return result.data;
    } catch (e) {
      logger.captureException(e);
      return null;
    }
  };

  generateAbacusMenu = async (_id: any, menu_id: string): Promise<any> => {
    try {
      const result = await this.api.generate_menu_abacus(_id, menu_id);
      if (result.outcome) {
        throw new Error(result.message);
      }
      return result.data;
    } catch (e) {
      logger.captureException(e);
      return null;
    }
  };

  checkStatus = async (_id: any): Promise<any> => {
    try {
      const result = await this.api.check_status(_id);
      if (result.outcome) {
        throw new Error(result.message);
      }
      return result.data;
    } catch (e) {
      logger.captureException(e);
      return null;
    }
  };

  checkStatusAbacus = async (_id: any): Promise<any> => {
    try {
      const result = await this.api.check_status_abacus(_id);
      if (result.outcome) {
        throw new Error(result.message);
      }
      return result.data;
    } catch (e) {
      logger.captureException(e);
      return null;
    }
  };

  updateStaff = (data: Partial<StaffStore>) => {
    Object.keys(data).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const value = data[key as keyof StaffStore];
        if (value !== undefined && this.staff) {
          set(this.staff, key, value);
        }
      }
    });
  };

  updateApis = (data: Partial<APISStore>) => {
    Object.keys(data).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const value = data[key as keyof APISStore];
        if (value !== undefined && this.apis) {
          set(this.apis, key, value);
        }
      }
    });
  };

  setReseller = (data: T.Schema.Reseller.ResellerSchema | null) => {
    this.reseller = data;
  };

  updateReseller = (data: Partial<T.Schema.Reseller.ResellerSchema>) => {
    Object.keys(data).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const value = data[key as keyof T.Schema.Reseller.ResellerSchema];
        if (value !== undefined && this.reseller) {
          set(this.reseller, key, value);
        }
      }
    });
  };

  setOrder = (data: T.Schema.Order.OrderSchema | null) => {
    this.order = data;
  };

  updateOrder = (data: Partial<T.Schema.Order.OrderSchema>) => {
    Object.keys(data).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const value = data[key as keyof T.Schema.Order.OrderSchema];
        if (value !== undefined && this.order) {
          set(this.order, key, value);
        }
      }
    });
  };

  updateOrders = (data: Partial<GenericQueryCollection<T.Schema.Order.OrderSchema>>) => {
    Object.keys(data).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const value = data[key as keyof GenericQueryCollection<T.Schema.Order.OrderSchema>];
        if (value !== undefined && this.orders) {
          set(this.orders, key, value);
        }
      }
    });
  };

  updateOrdersBoard = (data: Partial<T.Lib.ListBoard.GenericListBoardCollection<T.Schema.Order.OrderSchema>>) => {
    Object.keys(data).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const value = data[key as keyof T.Lib.ListBoard.GenericListBoardCollection<T.Schema.Order.OrderSchema>];
        if (value !== undefined && this.ordersBoard) {
          set(this.ordersBoard, key, value);
        }
      }
    });
  };

  updateOrderComplete = (order: T.Schema.Order.OrderSchema) => {
    const r = this.restaurant;

    if (r) {
      const tz = r.settings.region.timezone;

      // UPDATE SINGLE
      if (this.order && this.order._id === order._id) {
        this.order = order;
      }

      // UPDATE COLLECTION
      if (this.orders.items.length > 0) {
        // eslint-disable-next-line no-restricted-syntax
        for (const [i, o] of this.orders.items.entries()) {
          if (o._id === order._id) {
            this.orders.items[i] = order;
            break;
          }
        }
      }

      // UPDATE LISTBOARD
      const nextListId = OrderUtils.getOrderManagementStatus(order, tz);

      let listId = '';
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.ordersBoard.lists) {
        if (this.ordersBoard.lists[key]) {
          let index = -1;
          index = (this.ordersBoard.lists[key]?.items || []).findIndex(o => o._id === order._id);
          if (index !== -1) {
            listId = key;
            break;
          }
        }
      }

      if (listId) {
        // SPLICE
        const itemIndex = (this.ordersBoard.lists[listId]?.items || []).findIndex(o => o._id === order._id);
        if (itemIndex !== -1) {
          this.ordersBoard.lists[listId]?.items.splice(itemIndex, 1);
        }

        // PUSH
        if (nextListId === 'complete' || nextListId === 'cancelled') {
          if ((this.ordersBoard.lists[nextListId]?.items || []).length >= 5) {
            this.ordersBoard.lists[nextListId]?.items.pop();
          }
          this.ordersBoard.lists[nextListId]?.items.unshift(order);
        } else {
          this.ordersBoard.lists[nextListId]?.items.push(order);
          // @ts-ignore
          this.ordersBoard.lists[nextListId].items = (this.ordersBoard.lists[nextListId]?.items || [])
            .slice()
            .sort(OrderUtils.sortFunctionByStatus(nextListId, tz));
        }
      }
    }
  };

  removeOrder = (_id: string) => {
    // UPDATE SINGLE
    if (this.order && this.order._id === _id) {
      this.setOrder(null);
    }
    // UPDATE COLLECTION
    const orders = [...this.orders.items];
    // eslint-disable-next-line no-restricted-syntax
    for (const [i, o] of orders.entries()) {
      if (o._id === _id) {
        orders.splice(i, 1);
        this.updateOrders({ items: orders });
        break;
      }
    }
    // UPDATE BOARD
    // eslint-disable-next-line no-restricted-syntax
    for (const key in this.ordersBoard.lists) {
      if (this.ordersBoard.lists[key]) {
        const index = (this.ordersBoard.lists[key]?.items || []).findIndex(o => o._id === _id);
        if (index !== -1) {
          this.ordersBoard.lists[key]?.items.splice(index, 1);
          break;
        }
      }
    }
  };

  getOrder = async (orderId?: string) => {
    try {
      const { query } = this.router.s;
      const item = this.order;
      const queryId = query._id || query.order_id || orderId;
      const queryButNoItem = queryId && !item;
      const queryItemMismatch = queryId && item && item._id !== queryId;

      if (queryButNoItem || queryItemMismatch) {
        // CHECK ORDERS COLLECTION FOR ORDER
        const order = this.orders.items.find(o => o._id === queryId);
        if (order) {
          /// @ts-ignore
          this.setOrder(cloneDeep(order));
          return { outcome: 0 };
        }

        // QUERY ORDER IF NOT IN COLLECTION
        const response = await this.api.order_find({ _id: queryId });

        if (response.outcome) {
          this.router.push(`${this.router.s.path}`);
          this.setOrder(null);
          return {
            outcome: 1,
            message: response.message,
          };
        }

        const freshOrder = response.item;
        this.setOrder(freshOrder);
        return { outcome: 0 };
      }

      return { outcome: 0 };
    } catch (e) {
      logger.captureException(e);
      return {
        outcome: 1,
        message: 'Error finding order, please try again',
      };
    }
  };

  getStripeTransaction = async (paymentIntent: string) => {
    try {
      const response = await this.api.getStripeTransaction({ paymentIntent });

      if (response.outcome) {
        return;
      }

      if (!response.data.charges?.data[0]) {
        return;
      }

      return {
        amount_captured: response.data.charges.data[0].amount_captured,
        amount_refunded: response.data.charges.data[0].amount_refunded,
      };
    } catch (error: any) {
      console.log(error.message);
      return;
    }
  };

  setBooking = (data: T.Schema.Booking.BookingSchema | null) => {
    this.booking = data;
  };

  updateBooking = (data: Partial<T.Schema.Booking.BookingSchema>) => {
    Object.keys(data).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const value = data[key as keyof T.Schema.Booking.BookingSchema];
        if (value !== undefined && this.booking) {
          set(this.booking, key, value);
        }
      }
    });
  };

  updateBookingComplete = (item: T.Schema.Booking.BookingSchema) => {
    // UPDATE SINGLE
    this.setBooking(item);
    // UPDATE COLLECTION
    const items = [...this.bookings.items];
    // eslint-disable-next-line no-restricted-syntax
    for (const [i, o] of items.entries()) {
      if (o._id === item._id) {
        items[i] = item;
        this.updateBookings({ items });
        break;
      }
    }
  };

  updateBookings = (data: Partial<GenericQueryCollection<T.Schema.Booking.BookingSchema>>) => {
    Object.keys(data).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const value = data[key as keyof GenericQueryCollection<T.Schema.Booking.BookingSchema>];
        if (value !== undefined && this.bookings) {
          set(this.bookings, key, value);
        }
      }
    });
  };

  removeBooking = (_id: string) => {
    // UPDATE SINGLE
    if (this.booking && this.booking._id === _id) {
      this.setOrder(null);
    }
    // UPDATE COLLECTION
    const items = [...this.bookings.items];
    // eslint-disable-next-line no-restricted-syntax
    for (const [i, o] of items.entries()) {
      if (o._id === _id) {
        items.splice(i, 1);
        this.updateBookings({ items });
        break;
      }
    }
  };

  updateCustomers = (data: Partial<GenericQueryCollection<T.Schema.Customer.CustomerSchema>>) => {
    Object.keys(data).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const value = data[key as keyof GenericQueryCollection<T.Schema.Customer.CustomerSchema>];
        if (value !== undefined && this.customers) {
          set(this.customers, key, value);
        }
      }
    });
  };

  setCustomer = (data: T.Schema.Customer.CustomerSchema | null) => {
    this.customer = data;
  };

  updateCustomer = (data: Partial<T.Schema.Customer.CustomerSchema>) => {
    Object.keys(data).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const value = data[key as keyof T.Schema.Customer.CustomerSchema];
        if (value !== undefined && this.customer) {
          set(this.customer, key, value);
        }
      }
    });
  };

  updateCustomerComplete = (item: T.Schema.Customer.CustomerSchema) => {
    // UPDATE SINGLE
    this.setCustomer(item);
    // UPDATE COLLECTION
    const items = [...this.customers.items];
    // eslint-disable-next-line no-restricted-syntax
    for (const [i, o] of items.entries()) {
      if (o._id === item._id) {
        items[i] = item;
        this.updateCustomers({ items });
        break;
      }
    }
  };

  removeCustomer = (_id: string) => {
    // UPDATE SINGLE
    if (this.customer && this.customer._id === _id) {
      this.setCustomer(null);
    }
    // UPDATE COLLECTION
    const items = [...this.customers.items];
    // eslint-disable-next-line no-restricted-syntax
    for (const [i, o] of items.entries()) {
      if (o._id === _id) {
        items.splice(i, 1);
        this.updateCustomers({ items });
        break;
      }
    }
  };

  setOnlinePaymentOrder = (data: T.Schema.Stripe.StripeTransactions | null) => {
    this.onlinePaymentOrder = data;
  };

  updateOnlinePaymentOrder = (data: Partial<T.Schema.Stripe.StripeTransactions>) => {
    Object.keys(data).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const value = data[key as keyof T.Schema.Stripe.StripeTransactions];
        if (value !== undefined && this.onlinePaymentOrder) {
          set(this.onlinePaymentOrder, key, value);
        }
      }
    });
  };

  updateOnlinePaymentOrders = (data: Partial<GenericQueryCollection<T.Schema.Stripe.StripeTransactions>>) => {
    Object.keys(data).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const value = data[key as keyof GenericQueryCollection<T.Schema.Stripe.StripeTransactions>];
        if (value !== undefined && this.onlinePaymentOrders) {
          set(this.onlinePaymentOrders, key, value);
        }
      }
    });
  };

  updateStripePayouts = (data: Partial<GenericQueryCollection<T.Schema.Stripe.StripePayout[]>>) => {
    Object.keys(data).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const value = data[key as keyof GenericQueryCollection<T.Schema.Stripe.StripePayout>];
        if (value !== undefined && this.stripePayouts) {
          set(this.stripePayouts, key, value);
        }
      }
    });
  };

  windowResize = () => {
    const width = window.innerWidth;
    const breakpoint = CoreUtils.ui.breakpoint(width);
    this.view.screen_width = width;
    this.view.breakpoint = breakpoint;
  };

  windowScroll = () => {
    try {
      const h1 = window.pageYOffset;
      const h2 = document.documentElement ? document.documentElement.scrollTop : 0;
      const h3 = document.body.scrollTop;
      this.view.scroll_top = Math.max(h1, h2, h3);
    } catch (e) {
      logger.captureException(e, 'SCROLL FUNCTION ERROR');
    }
  };
}
