import React from 'react';
import { FastField } from 'formik';
import {
  FormGroup,
  Input,
  Button,
  RotateLoader,
  InputGroup,
  ButtonGroup,
  SelectAdv,
  Switch,
  FieldOpeningHours,
  Modal,
  ModalContent,
} from '@lib/components';
import { ListServicesOptions, ListPaymentsOptions, ListOrderTimesOptions } from '@lib/common';
import { inject, observer } from 'mobx-react';
import { MobxComponent } from '../../../../../../../mobx/components/index';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';

type FormValues = T.Schema.Restaurant.Services.RestaurantServiceFee;
interface Props {
  type: 'edit' | 'create';
  initialValues: FormValues | null;
  close: () => void;
}
interface State {}

export const SettingsFeesForm = inject('store')(
  observer(
    class SettingsFeesForm extends MobxComponent<Props, State> {
      constructor(props: Props) {
        super(props);
        this.state = {
          tab: '0',
          error: null,
        };
      }

      render() {
        const { type, initialValues, close } = this.props;
        const { currency } = this.injected.store.intl.s;
        return (
          <Modal width="md" alignTop active={!!initialValues} close={close}>
            <ModalContent>
              <h3 className="">{type === 'create' ? 'Create Fee' : 'Edit Fee'}</h3>
              {type === 'edit' && initialValues && <p className="m-t-1">{initialValues.name}</p>}
            </ModalContent>
            <RestaurantForm<FormValues>
              submit={async (r, values) => {
                const existingItem = r.settings.fees.findIndex(p => p._id === values._id);
                if (existingItem === -1) {
                  r.settings.fees.push(values);
                } else {
                  r.settings.fees[existingItem] = values;
                }
                return {
                  r,
                  update: { $set: { 'settings.fees': r.settings.fees } },
                };
              }}
              validators={{
                // @ts-ignore
                name: values => {
                  if (!values.name) return { name: 'This field is required' };
                },
              }}
              initialValues={initialValues}
              onSuccess={close}
              onSuccessMessage={type === 'edit' ? 'Fee edited' : 'Fee created'}
              onErrorMessage=""
            >
              {({ form, error, getFieldError }) => {
                const { isSubmitting, setFieldValue } = form;
                return (
                  <div>
                    <ModalContent>
                      <FastField
                        name="name"
                        render={({ field }: any) => (
                          <FormGroup
                            title="Name"
                            help="Give this fee a meaningful name. It will be displayed on your customers receipt if it's applied"
                            error={getFieldError(form, 'name')}
                          >
                            <Input type="text" {...field} required />
                          </FormGroup>
                        )}
                      />

                      <FastField
                        name="fixed_value"
                        render={({ field }: any) => (
                          <FormGroup
                            title="Fixed Fee"
                            help="This fixed amount will be added to your customers cart total"
                          >
                            <InputGroup iconHtml={<p className="font-semi-bold">{currency.symbol}</p>}>
                              <Input type="number" step={currency.step} min={0} required {...field} />
                            </InputGroup>
                          </FormGroup>
                        )}
                      />

                      <FastField
                        name="percent_value"
                        render={({ field }: any) => (
                          <FormGroup
                            title="Percent Fee"
                            help="Will add the respective percentage fee based off the customers cart total"
                          >
                            <InputGroup iconHtml={<p className="font-semi-bold">%</p>}>
                              <Input type="number" step={0.01} min={0} max={100} required {...field} />
                            </InputGroup>
                          </FormGroup>
                        )}
                      />

                      <FastField
                        name="match_condition"
                        render={({ field }: any) => (
                          <FormGroup
                            title="Fee Match Condition"
                            help="Determines under what conditions the fee will apply. If 'Any', then as long as any of the conditions below are met, the fee will apply. If 'Specific', then the fee will only be applied if all the conditions below are met"
                          >
                            <ButtonGroup
                              size="sm"
                              width={100}
                              selected={field.value}
                              options={[
                                { value: 'any', name: 'Any' },
                                { value: 'specific', name: 'Specific' },
                              ]}
                              onSelect={selection => setFieldValue('match_condition', selection.value)}
                            />
                          </FormGroup>
                        )}
                      />

                      <FastField
                        name="order_times"
                        render={({ field }: any) => (
                          <FormGroup
                            optional
                            title="Order Times"
                            help="Select which order times this fee will be applicable for. Leave empty for this fee to apply for all"
                          >
                            <SelectAdv
                              type="multi"
                              value={field.value}
                              onChange={(options: string[]) => {
                                setFieldValue('order_times', options);
                              }}
                              options={ListOrderTimesOptions}
                            />
                          </FormGroup>
                        )}
                      />

                      <FastField
                        name="services"
                        render={({ field }: any) => (
                          <FormGroup
                            optional
                            title="Services"
                            help="Select which services this fee will be applicable for. Leave empty for this fee to apply for all"
                          >
                            <SelectAdv
                              type="multi"
                              value={field.value}
                              onChange={(options: string[]) => {
                                setFieldValue('services', options);
                              }}
                              options={ListServicesOptions}
                            />
                          </FormGroup>
                        )}
                      />

                      <FastField
                        name="payments"
                        render={({ field }: any) => (
                          <FormGroup
                            optional
                            title="Payments"
                            help="Select which payment methods this fee will be applicable for. Leave empty for this fee to apply for all"
                          >
                            <SelectAdv
                              type="multi"
                              value={field.value}
                              onChange={(options: string[]) => {
                                setFieldValue('payments', options);
                              }}
                              options={ListPaymentsOptions}
                            />
                          </FormGroup>
                        )}
                      />

                      <FastField
                        name="hours"
                        render={({ field }: any) => (
                          <FormGroup
                            optional
                            title="Applicable Hours"
                            help="Set which hours this fee should be applicable for. If no hours entered, the fee is applicable at all times. Enter time in 24H format, e.g. 21:00 for 9:00pm. Ensure time slots do not overlap or close before they open"
                            error={getFieldError(form, 'hours')}
                          >
                            <div className="m-tb-3">
                              <FieldOpeningHours
                                allowNone
                                hours={field.value}
                                onChange={opening_hours => {
                                  setFieldValue('hours', opening_hours);
                                }}
                              />
                            </div>
                          </FormGroup>
                        )}
                      />

                      <FastField
                        name="disabled"
                        render={({ field }: any) => (
                          <FormGroup
                            title="Disable Fee"
                            help="Disable this fee to stop it from being applied without deleting it"
                          >
                            <Switch
                              id="disabled-fee"
                              checked={field.value}
                              onChange={e => setFieldValue('disabled', e.target.checked)}
                            />
                          </FormGroup>
                        )}
                      />

                      <FastField
                        name="tax_exempt"
                        render={({ field }: any) => (
                          <FormGroup title="Disable Tax on this Fee?" help="Should this fee be exempt from tax?">
                            <Switch
                              id="tax-exempt-fee"
                              checked={field.value}
                              onChange={e => setFieldValue('tax_exempt', e.target.checked)}
                            />
                          </FormGroup>
                        )}
                      />
                    </ModalContent>
                    <ModalContent>
                      {error && <FormGroup error={error} />}
                      <Button full color="primary" type="submit" disabled={isSubmitting}>
                        {isSubmitting && <RotateLoader size={2} color="white" />}
                        {!isSubmitting && 'Save'}
                      </Button>
                    </ModalContent>
                  </div>
                );
              }}
            </RestaurantForm>
          </Modal>
        );
      }
    },
  ),
);
