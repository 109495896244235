import React from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { LinkTag, Content, Tooltip, Button, TooltipText } from '@lib/components';
import { FaQuestionCircle } from 'react-icons/fa';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import styled from 'styled-components';
import lighten from 'polished/lib/color/lighten';
import { OnlinePaymentList } from './list';
import { MobxComponent } from '../../../../../mobx/components';
import { PayoutList } from './payouts';

interface Props extends WithTranslation {}
interface State {
  activeSection: string;
}

const TooltipInfoBox = styled(Tooltip)`
  ${TooltipText} {
    background-color: #fff;
    color: #383838;
    ::after {
      top: 50%;
      left: 100%;
      margin-top: -5px;
      border-color: transparent transparent transparent #fff;
    }
  }
`;

const StyledTooltip = styled(Tooltip)`
  ${TooltipText} {
    background-color: #fff;
    color: #383838;
    border: 1px solid ${props => lighten(0.2, props.theme.colors.primary)};
    text-align: left;
    padding: 11px 10px;
    border-radius: 8px;
    font-weight: 400;
    font-size: 16px;
    ::after {
      top: 50%;
      left: 100%;
      margin-top: -5px;
      border-color: transparent transparent transparent transparent;
    }
  }
`;

const RestaurantConnectClass = inject('store')(
  observer(
    class RestaurantConnectClass extends MobxComponent<Props, State> {
      constructor(props: Props) {
        super(props);
        this.state = {
          activeSection: '',
        };
      }

      RenderDetails = (
        <div>
          <p className="p-tb-2" style={{ fontSize: '16px' }}>
            This screen shows "Payment Intents" from Stripe.
          </p>
          <p style={{ fontSize: '16px' }}>
            More information can be found here:{' '}
            <LinkTag href="https://stripe.com/docs/payments/intents" target="_blank">
              Stripe Payment Intents
            </LinkTag>
          </p>

          <p className="p-tb-2" style={{ fontSize: '16px' }}>
            <u>Please note, this report is not realtime.</u>
          </p>
        </div>
      );

      RenderInfoPayment = (
        <p style={{ lineHeight: 1.25 }}>
          This report is <strong>not real-time</strong>. If you do not see a particular payment, please check back again
          in 30 minutes.
        </p>
      );

      render() {
        return (
          <div className="m-b-6 flex-l-r-center">
            <Content width="full">
              <div id="online-payments-section">
                <div className="m-b-10 flex-l-r-center">
                  <div>
                    <h1>
                      {this.state.activeSection === 'payouts' && 'Payouts'}
                      {this.state.activeSection !== 'payouts' && (
                        <>
                          Online Payments
                          <StyledTooltip text={this.RenderInfoPayment} width={325} position="right">
                            <AiOutlineInfoCircle style={{ fontSize: '20px', marginLeft: '10px' }} />
                          </StyledTooltip>
                        </>
                      )}
                    </h1>
                  </div>
                  <div className="flex-line centered">
                    <div className="m-l-2">
                      <Button
                        size="xxs"
                        color="primary-inverse"
                        className="p-lr-1"
                        onClick={() => {
                          this.setState({ activeSection: '' });
                        }}
                      >
                        Payments
                      </Button>
                      <Button
                        size="xxs"
                        color="primary-inverse"
                        className="p-lr-1"
                        value="Payouts"
                        onClick={() => {
                          this.setState({ activeSection: 'payouts' });
                        }}
                      >
                        Payouts
                      </Button>
                      <TooltipInfoBox text={this.RenderDetails} width={450} position="left">
                        <Button size="xxs" color="primary-inverse" className="p-lr-1">
                          <FaQuestionCircle />
                        </Button>
                      </TooltipInfoBox>
                    </div>
                  </div>
                </div>
              </div>
              {this.state.activeSection === 'payouts' && <PayoutList />}
              {this.state.activeSection !== 'payouts' && <OnlinePaymentList />}
            </Content>
          </div>
        );
      }
    },
  ),
);

export const RestaurantConnect = withTranslation()(RestaurantConnectClass);
