/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react';
import shortid from 'shortid';
import difference from 'lodash/difference';
import { useTranslation } from 'react-i18next';

import { PaymentMethods, ThreeDsPaymentMethods, Untrusive } from '@lib/common';
import { Modal, ModalTitle, ModalContent, ListSelect, Button, SecureIcon } from '@lib/components';
import { initPaymentData } from './contants';
import { IconWrapper, Centered } from './styled';
import { RootStore } from '../../../../../../../mobx/store';
import { SaveRestaurantOpts } from '../../../../../../../mobx/components';

type AddPaymentType = T.Schema.Restaurant.Payments.RestaurantPaymentTypesBase | 'custom' | '';

interface Props {
  store: RootStore;
  addPaymentModal: boolean;
  setAddPaymentModal: (show: boolean) => void;
  saveRestaurant: (opts: SaveRestaurantOpts) => Promise<void>;
}

export function AddPaymentMethodModal({ store, addPaymentModal, setAddPaymentModal, saveRestaurant }: Props) {
  const { t } = useTranslation();
  const [addPaymentType, setAddPaymentType] = React.useState<AddPaymentType>('');

  const { payments } = store.restaurant!.settings;
  const methods = Object.keys(payments).map(key => key);
  const allMethods = difference([...PaymentMethods, 'custom'], methods);

  const addPaymentMethod = async () => {
    const key = addPaymentType === 'custom' ? shortid.generate() : addPaymentType;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const paymentData = await initPaymentData(addPaymentType, store);

    Untrusive.start();

    await saveRestaurant({
      successMsg: 'Payment method created',
      errorMsg: 'Failed to create payment method, try again or contact us',
      process: r => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        r.settings.payments[key] = paymentData;
        return {
          update: {
            $set: {
              'settings.payments': r.settings.payments,
            },
          },
        };
      },
      onSuccess: () => {
        setAddPaymentModal(false);
        setAddPaymentType('');
      },
    });

    Untrusive.stop();
  };

  return (
    <Modal
      width={420}
      active={addPaymentModal}
      close={() => {
        setAddPaymentModal(false);
        setAddPaymentType('');
      }}
    >
      <ModalTitle className="round-top-sm">
        <h4>Add Payment Method</h4>
      </ModalTitle>
      <ModalContent>
        <p
          style={{
            lineHeight: '1.6rem',
          }}
        >
          Payment methods with shield icon are considered Strong Customer Authentication (SCA) readiness. If your
          business requires SCA, you may consider using those payment methods. Learn more about SCA{' '}
          <strong>
            <a
              href="https://stripe.com/guides/strong-customer-authentication#what-is-strong-customer-authentication"
              rel="noopener noreferrer"
              target="_blank"
            >
              here
            </a>
          </strong>
          .
        </p>
      </ModalContent>
      <ModalContent>
        <ListSelect
          selected={addPaymentType}
          onChange={value => setAddPaymentType(value as AddPaymentType)}
          items={allMethods.map(method => ({
            value: method,
            render: () => (
              <Centered>
                <div className="width100 flex-line centered">{t(`constants.payment.backend_method.${method}`)}</div>
                {ThreeDsPaymentMethods.includes(method) && (
                  <IconWrapper>
                    <SecureIcon />
                  </IconWrapper>
                )}
              </Centered>
            ),
          }))}
        />
      </ModalContent>
      {!!addPaymentType && (
        <ModalContent>
          <Button color="primary" full onClick={addPaymentMethod}>
            Add Method
          </Button>
        </ModalContent>
      )}
    </Modal>
  );
}
