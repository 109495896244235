import React from 'react';
import { FastField } from 'formik';
import { FormGroup, Button, RotateLoader, Input, Switch } from '@lib/components';
import { inject, observer } from 'mobx-react';
import { UI } from '../../../../../../../core/ui';
import { MobxComponent } from '../../../../../../../mobx/components/index';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';

interface Props {
  close?: () => void;
}
interface State {}

type FormValues = T.Schema.Restaurant.RestaurantSchema['settings']['services']['delivery']['providers'];

export const SettingsFormIntegrationQuestTag = inject('store')(
  observer(
    class SettingsFormIntegrationQuestTag extends MobxComponent<Props, State> {
      initialValues: FormValues;

      constructor(props: Props) {
        super(props);
        this.state = {};
        const r = this.injected.store.restaurant!;
        this.initialValues = r.settings.services.delivery.providers;
      }

      render() {
        return (
          <RestaurantForm<FormValues>
            submit={async (r, values) => {
              r.settings.services.delivery.providers = values;
              const update = {
                $set: { 'settings.services.delivery.providers': r.settings.services.delivery.providers },
              };
              return { r, update };
            }}
            validators=
            initialValues={this.initialValues}
            onSuccess={() => {
              if (this.props.close) {
                this.props.close();
              }
            }}
            onError={() => UI.notification.error('An error occurred')}
            onSuccessMessage="Settings Updated"
            onErrorMessage=""
          >
            {({ form, error }) => {
              const { isSubmitting, setFieldValue } = form;
              return (
                <div className="p-4">
                  <FormGroup>
                    <p className="font-semi-bold big m-b-2 color-red">IMPORTANT</p>
                    <p className="lhp">The following settings need to be activated for this integration to work.</p>
                    <br />
                    <p className="lhp">
                      Under Settings &gt; Services &gt; Deliveries &gt; Wait times & Auto statuses. Enter values for
                      ‘time till confirm’, ‘time till ready’ and ‘time till on route’. These settings are used to
                      calculate the pickup and delivery times for orders accurately. More instructions{' '}
                      <a
                        className="underline"
                        href="https://support.cloudwaitress.com/how-to-guides/questtag-integration"
                        target="_blank"
                        rel="noreferrer"
                      >
                        here
                      </a>
                      .
                    </p>
                  </FormGroup>

                  <FastField
                    name="questtag.api_key"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Shipday API Key"
                        help="Your unique Shipday API key. This is provided under your Shipday account settings."
                      >
                        <Input type="text" {...field} value={field.value || ''} />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="questtag.enabled"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Enabled"
                        help="Enabling this will automatically trigger the Shipday delivery process for new orders."
                      >
                        <Switch
                          id="questtag-enabled-switch"
                          checked={field.value || false}
                          onChange={e => setFieldValue('questtag.enabled', e.target.checked)}
                        />
                      </FormGroup>
                    )}
                  />

                  {/* <FastField
                    name="questtag.events.order_new_enabled"
                    render={({ field }: any) => (
                      <FormGroup title="Order - Created" help="Triggered when a new order has been placed.">
                        <Switch
                          id="questtag-events-order-new-enabled-switch"
                          checked={field.value || false}
                          onChange={e => setFieldValue('questtag.events.order_new_enabled', e.target.checked)}
                        />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="questtag.events.order_update_status_enabled"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Order - Update Status"
                        help="Triggered when the status of an order has been updated."
                      >
                        <Switch
                          id="questtag-events-order-update-status-enabled-switch"
                          checked={field.value || false}
                          onChange={e => setFieldValue('questtag.events.order_update_status_enabled', e.target.checked)}
                        />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="questtag.events.order_update_readytime_enabled"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Order - Update Ready Time"
                        help="Triggered when ready time of an order has been updated."
                      >
                        <Switch
                          id="questtag-events-order-update-ready-time-enabled-switch"
                          checked={field.value || false}
                          onChange={e =>
                            setFieldValue('questtag.events.order_update_readytime_enabled', e.target.checked)
                          }
                        />
                      </FormGroup>
                    )}
                  /> */}

                  {error && <FormGroup error={error} />}

                  <Button full color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting && <RotateLoader size={2} color="white" />}
                    {!isSubmitting && 'Save'}
                  </Button>
                </div>
              );
            }}
          </RestaurantForm>
        );
      }
    },
  ),
);
