import React from 'react';
import { FastField } from 'formik';
import { FormGroup, Button, RotateLoader, Switch } from '@lib/components';
import { inject, observer } from 'mobx-react';
import { MobxComponent } from '../../../../../../../mobx/components/index';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';
import { UI } from '../../../../../../../core/ui';
import { PaymentBaseFields } from './base';

interface Props {}
interface State {}
type FormValues = T.Schema.Restaurant.RestaurantSchema['settings']['payments']['card'];

export const SettingsFormPaymentsCard = inject('store')(
  observer(
    class SettingsFormPaymentsCard extends MobxComponent<Props, State> {
      initialValues: FormValues;

      constructor(props: Props) {
        super(props);
        this.state = {};
        const r = this.injected.store.restaurant!;
        this.initialValues = r.settings.payments.card;
      }

      render() {
        return (
          <RestaurantForm<FormValues>
            submit={async (r, values) => {
              r.settings.payments.card = values;
              const update = { $set: { 'settings.payments.card': r.settings.payments.card } };
              return { r, update };
            }}
            validators=
            initialValues={this.initialValues}
            onSuccess={() => {}}
            onError={() => UI.notification.error('An error occurred')}
            onSuccessMessage="Settings Updated"
            onErrorMessage=""
          >
            {({ form, error }) => {
              const { isSubmitting, setFieldValue } = form;
              return (
                <div className="p-4">
                  <FastField
                    name="enabled"
                    render={({ field }: any) => (
                      <FormGroup title="Enabled" help="Allows customers to pay using this method">
                        <Switch
                          id="enable-switch"
                          checked={field.value}
                          onChange={e => setFieldValue('enabled', e.target.checked)}
                        />
                      </FormGroup>
                    )}
                  />

                  <PaymentBaseFields defaultLabel="Card" setFieldValue={setFieldValue} />

                  {error && <FormGroup error={error} />}

                  <Button full color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting && <RotateLoader size={2} color="white" />}
                    {!isSubmitting && 'Save'}
                  </Button>
                </div>
              );
            }}
          </RestaurantForm>
        );
      }
    },
  ),
);
