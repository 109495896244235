import React from 'react';
import { Button } from '@lib/components';

interface Props extends T.Lib.Form.Button.ButtonProps {}

export function MenuListButton(props: Props) {
  return (
    <div className="list-item">
      <Button full color="transparent-with-border" {...props}>
        {props.children}
      </Button>
    </div>
  );
}
