import React from 'react';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import styled, { withTheme } from 'styled-components';
import {
  Modal,
  ModalContent,
  ModalTitle,
  RotateLoader,
  Button,
  SelectAdv,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@lib/components';
import Lalamove from '@lib/lalamove';
import { Quotation, QuotationCreateParams } from '@lib/lalamove/types/resources/Quotations';
import { withTranslation } from 'react-i18next';
import { UI } from '../../../../../core/ui';
import { MobxComponent } from '../../../../../mobx/components';

interface Props {
  order: T.Schema.Order.OrderSchema;
  restaurant: T.Schema.Restaurant.RestaurantSchema;
  active: boolean;
  close: () => void;
}

interface State {
  error: string;
  service: string;
  loading: boolean;
  quote: Quotation | null;
  quoteParams: QuotationCreateParams | null;
}

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SelectWrapper = styled.div`
  width: 100%;
  padding-bottom: 0.5rem;
  border-bottom: 1px dashed #d6d6d6;
`;

const TableWrapper = styled.div`
  tr {
    border-bottom: 1px dashed #d6d6d6 !important;
  }
`;

const ErrorWrapper = styled.div`
  margin-top: 0.5rem;
`;

const LalamoveSchedulingClass = inject('store')(
  observer(
    class LalamoveSchedulingClass extends MobxComponent<Props, State> {
      constructor(props: Props) {
        super(props);
        this.state = {
          error: '',
          service: '',
          loading: false,
          quote: null,
          quoteParams: null,
        };
      }

      transformServiceTypes = () => {
        const { restaurant } = this.props;
        const { market } = restaurant.settings.services.delivery.providers.lalamove!;
        const allServiceTypes = Lalamove.serviceTypesByMarket(market);
        const serviceTypes = restaurant.settings.services.delivery.providers.lalamove?.service_types || [];
        return allServiceTypes.filter(item => serviceTypes.includes(item.value));
      };

      constructQuotationParams = (serviceType: any): QuotationCreateParams => {
        const { order, restaurant } = this.props;
        const orderConfig = order.config;
        const { location } = restaurant;
        const lalamoveConfig = restaurant.settings.services.delivery.providers.lalamove!;
        const locale = Lalamove.localeByMarket(lalamoveConfig.market);

        let scheduleAt;
        if (order.config.due === 'later' && order.delivery_in) {
          scheduleAt = moment(order.delivery_in.timestamp).toISOString();
        }

        return {
          data: {
            serviceType,
            specialRequests: [],
            scheduleAt,
            stops: [
              {
                coordinates: {
                  lat: location.map_data.type !== 'custom' ? location.map_data.lat.toString() : '',
                  lng: location.map_data.type !== 'custom' ? location.map_data.lng.toString() : '',
                },
                address: location.address,
              },
              {
                coordinates: {
                  lat: orderConfig.lat.toString(),
                  lng: orderConfig.lng.toString(),
                },
                address: orderConfig.destination,
              },
            ],
            language: locale,
          },
        };
      };

      createQuotation = async (serviceType: any) => {
        const { store } = this.injected;
        const { restaurant } = this.props;

        this.setState({ loading: true });
        const params = this.constructQuotationParams(serviceType);
        const response = await store.api.create_lalamove_quotation({
          restaurantId: restaurant._id,
          params,
        });
        if (response.outcome === 1) {
          this.setState({
            error: response.message,
            quote: null,
            quoteParams: null,
            loading: false,
          });
        } else if (response.quote) {
          UI.notification.success('Lalamove quotation created');
          this.setState({
            quote: response.quote,
            quoteParams: params,
            error: '',
            loading: false,
          });
        }
      };

      createDelivery = async () => {
        const { store } = this.injected;
        const { quote, quoteParams } = this.state;
        if (!quote || !quoteParams) {
          UI.notification.warning('Lalamove quotation is not available. Please choose a service type to continue.');
          return;
        }

        const { order, restaurant, close } = this.props;

        // Reset Lalamove data in order config
        // Create brand new Lalamove order
        const payload: T.API.DashboardCreateLalamoveDeliveryRequest = {
          orderId: order._id,
          restaurantId: restaurant._id,
          quote,
          quoteParams,
        };

        this.setState({ loading: true });
        const response = await store.api.create_lalamove_delivery(payload);
        if (response.outcome === 1) {
          UI.notification.error('Failed to create Lalamove delivery please try again.');
        } else {
          UI.notification.success('Lalamove delivery created.');
        }

        this.setState({
          error: '',
          quote: null,
          quoteParams: null,
          loading: false,
        });

        close();
      };

      render() {
        const { t, theme } = this.injected;
        const { active, close } = this.props;
        const { service, loading, quote, error } = this.state;
        const serviceTypes = this.transformServiceTypes();

        return (
          <Modal width="sm" alignTop active={active} close={close}>
            <ModalTitle>
              <h4>Lalamove Delivery Scheduling</h4>
            </ModalTitle>

            <ModalContent>
              <SelectWrapper>
                <SelectAdv
                  type="single"
                  value={service}
                  options={serviceTypes}
                  placeholder="Deliver via... (Required field)"
                  onChange={(option: string) => {
                    this.setState({ service: option });
                    this.createQuotation(option);
                  }}
                />
              </SelectWrapper>

              <TableWrapper>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell className="small">Delivery Fee</TableCell>
                      {!loading && (
                        <TableCell className="small">
                          {quote?.data.priceBreakdown.total
                            ? `${quote?.data.priceBreakdown.total} ${quote?.data.priceBreakdown.currency}`
                            : 0}
                        </TableCell>
                      )}
                      {loading && (
                        <TableCell>
                          <RotateLoader size={1} color={theme.colors.primary} />
                        </TableCell>
                      )}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableWrapper>

              {error && (
                <ErrorWrapper className="error-text" style={{ fontSize: '0.85rem' }}>
                  {t('store.modals.checkout.delivery_estimation.quotation_error')}
                </ErrorWrapper>
              )}
            </ModalContent>

            <ModalContent>
              <ButtonGroup>
                <Button size="xs" type="button" onClick={close} disabled={loading} color="primary-inverse">
                  Cancel
                </Button>

                <Button
                  size="xs"
                  type="button"
                  color="primary"
                  disabled={loading}
                  onClick={this.createDelivery}
                  style={{ marginLeft: '10px' }}
                >
                  Confirm
                </Button>
              </ButtonGroup>
            </ModalContent>
          </Modal>
        );
      }
    },
  ),
);

// @ts-ignore
export const LalamoveScheduling = withTheme(withTranslation()(LalamoveSchedulingClass));
