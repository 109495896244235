import React from 'react';
import { inject, observer } from 'mobx-react';
import { ThemeProvider } from 'styled-components';
import { MobxComponent } from '../mobx/components';

class ThemeWrapper extends MobxComponent {
  render() {
    const { store } = this.injected;
    const children = this.props.children as React.ReactElement;
    return <ThemeProvider theme={store.theme.s}>{children}</ThemeProvider>;
  }
}

export default inject('store')(observer(ThemeWrapper));
