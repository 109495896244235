import React from 'react';
import { FastField } from 'formik';
import { FormGroup, Button, RotateLoader, Switch, Input, SelectAdv, LinkTag } from '@lib/components';
import { DataPayPalCurrencies } from '@lib/common';
import { inject, observer } from 'mobx-react';
import { MobxComponent } from '../../../../../../../mobx/components/index';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';
import { UI } from '../../../../../../../core/ui';
import { PaymentBaseFields } from './base';

interface Props {}
interface State {}
type FormValues = T.Schema.Restaurant.Payments.RestaurantPaymentPayPal;

export const SettingsFormPaymentsPayPal = inject('store')(
  observer(
    class SettingsFormPaymentsPayPal extends MobxComponent<Props, State> {
      initialValues: FormValues;

      constructor(props: Props) {
        super(props);
        this.state = {};
        const r = this.injected.store.restaurant!;
        this.initialValues = r.settings.payments.paypal || {
          enabled: false,
          label: '',
          services: [],
          secret: '',
          client_id: '',
          currency: '',
        };
      }

      render() {
        const { showMainSupport } = this.injected.store;
        return (
          <RestaurantForm<FormValues>
            submit={async (r, values) => {
              r.settings.payments.paypal = values;
              const update = { $set: { 'settings.payments.paypal': r.settings.payments.paypal } };
              return { r, update };
            }}
            validators=
            initialValues={this.initialValues}
            onSuccess={() => {}}
            onError={() => UI.notification.error('An error occurred')}
            onSuccessMessage="Settings Updated"
            onErrorMessage=""
          >
            {({ form, error }) => {
              const { isSubmitting, setFieldValue } = form;
              return (
                <div className="p-4">
                  {showMainSupport && (
                    <FormGroup>
                      <LinkTag
                        className="block font-semi-bold"
                        target="_blank"
                        href="https://support.cloudwaitress.com/how-to-guides/setup-paypal-payments"
                      >
                        Read the setup guide
                      </LinkTag>
                    </FormGroup>
                  )}

                  <FastField
                    name="enabled"
                    render={({ field }: any) => (
                      <FormGroup title="Enabled" help="Allows customers to pay using this method">
                        <Switch
                          id="enable-switch"
                          checked={field.value}
                          onChange={e => setFieldValue('enabled', e.target.checked)}
                        />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="client_id"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Application Client ID (Live)"
                        help="Your Paypal REST application live client ID"
                      >
                        <Input type="text" {...field} autoSave="false" autoCorrect="false" />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="secret"
                    render={({ field }: any) => (
                      <FormGroup title="Application Secret (Live)" help="Your Paypal REST application live secret ID">
                        <Input type="text" {...field} autoSave="false" autoCorrect="false" />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="currency"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Currency"
                        help="Select the currency you would like to accept PayPal charges in. Make sure you are authorized to accept charges in that currency and that it is supported by PayPal"
                      >
                        <SelectAdv
                          type="single"
                          options={DataPayPalCurrencies}
                          value={field.value}
                          onChange={(code: string) => setFieldValue('currency', code)}
                        />
                      </FormGroup>
                    )}
                  />

                  <PaymentBaseFields defaultLabel="PayPal" setFieldValue={setFieldValue} />

                  {error && <FormGroup error={error} />}

                  <Button full color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting && <RotateLoader size={2} color="white" />}
                    {!isSubmitting && 'Save'}
                  </Button>
                </div>
              );
            }}
          </RestaurantForm>
        );
      }
    },
  ),
);
