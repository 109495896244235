import React from 'react';
import { styled, CS } from '@lib/components';

const SettingsSectionWrapper = styled('div')`
  width: 100%;
`;

const SettingsSectionContent = styled('div')`
  position: relative;
  width: 100%;
  border-radius: 3px;
  ${CS.shadow.med.one}
  > div {
    &:first-child {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }
    &:last-child {
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      border-bottom: none;
    }
  }
`;

interface SettingsSectionProps {
  title?: string;
  children: React.ReactNode | React.ReactNode[];
}

export function SettingsSection(props: SettingsSectionProps) {
  return (
    <SettingsSectionWrapper>
      {props.title && <p className="m-b-3 font-semi-bold font-heading">{props.title}</p>}
      <SettingsSectionContent>{props.children}</SettingsSectionContent>
    </SettingsSectionWrapper>
  );
}
