/* eslint-disable @typescript-eslint/no-unsafe-return */
import axios from 'axios';
import { config } from '../../config';
import { RootStore } from '../store';

interface APIHandlers {
  auth_token_error: () => void;
}

export class APIStore {
  readonly handlers: APIHandlers;

  store: RootStore;

  constructor(store: RootStore, handlers: APIHandlers) {
    this.store = store;
    this.handlers = handlers;
  }

  // API REQUEST HELPERS
  request = async (args: T.API.RequestArgs): Promise<any> => {
    const { path, data, params, headers } = args;
    try {
      const res = await axios({
        baseURL: `${config.urls.api}/`,
        url: path,
        method: 'post',
        headers: headers || {},
        params: params || {},
        data: data || {},
      });
      return res.data;
    } catch (e) {
      // @ts-ignore
      if (e.response && e.response.data) {
        // @ts-ignore
        if (e.response.data.message === 'Invalid Authentication') {
          this.handlers.auth_token_error();
          return { outcome: 1, message: '' };
        }
        // @ts-ignore
        throw e.response.data;
      } else {
        throw e;
      }
    }
  };

  request_public = async (args: T.API.RequestPublicPrivateArgs): Promise<any> => {
    const { path, data, params } = args;
    const headers = {
      'Authorization-Public': config.services.api.public_key,
    };
    return this.request({ path, data, params, headers });
  };

  request_auth = async (args: T.API.RequestPublicPrivateArgs): Promise<any> => {
    const { path, data, params } = args;
    const token = this._get_auth_token();
    if (config.isTest && !token) return;
    const headers = { 'Authorization-Dashboard': `${token}` };
    return this.request({ path, data, params, headers });
  };

  // RESELLER
  reseller_find_public = async (data: T.API.ResellerFindPublicRequest): Promise<T.API.ResellerFindPublicResponse> => {
    return this.request_public({
      path: '/resellers/find/dashboard/public',
      data,
    });
  };

  // USER
  user_register_commence = async (
    data: T.API.UserRegisterCommenceRequest,
  ): Promise<T.API.UserRegisterCommenceResponse> => {
    return this.request_public({
      path: '/users/register/commence',
      data,
    });
  };

  user_register_complete = async (
    data: T.API.UserRegisterCompleteRequest,
  ): Promise<T.API.UserRegisterCompleteResponse> => {
    return this.request_public({
      path: '/users/register/complete',
      data,
    });
  };

  user_login = async (data: T.API.UserLoginRequest): Promise<T.API.UserLoginResponse> => {
    return this.request_public({
      path: '/users/login',
      data,
    });
  };

  user_password_reset_init = async (
    data: T.API.UserPasswordResetInitRequest,
  ): Promise<T.API.UserPasswordResetInitResponse> => {
    return this.request_public({
      path: '/users/password-reset/init',
      data,
    });
  };

  user_password_reset_change = async (
    data: T.API.UserPasswordResetChangeRequest,
  ): Promise<T.API.UserPasswordResetChangeResponse> => {
    return this.request_public({
      path: '/users/password-reset/change',
      data,
    });
  };

  user_token_refresh = async (): Promise<T.API.UserTokenRefreshResponse> => {
    return this.request_auth({
      path: '/users/token/refresh',
    });
  };

  user_profile_update = async (data: T.API.UserUpdateProfileRequest): Promise<T.API.UserUpdateProfileResponse> => {
    return this.request_auth({
      path: '/users/update/profile',
      data,
    });
  };

  // RESTAURANT
  restaurant_create = async (data: T.API.RestaurantCreateRequest): Promise<T.API.RestaurantCreateResponse> => {
    return this.request_auth({
      path: '/restaurants/create',
      data,
    });
  };

  restaurant_update = async (data: T.API.RestaurantUpdateRequest): Promise<T.API.RestaurantUpdateResponse> => {
    return this.request_auth({
      path: '/restaurants/update',
      data,
    });
  };

  restaurant_update_subscription = async (
    data: T.API.RestaurantUpdateSubscriptionRequest,
  ): Promise<T.API.RestaurantUpdateSubscriptionResponse> => {
    return this.request_auth({
      path: '/restaurants/update/subscription',
      data,
    });
  };

  restaurant_delete = async (data: T.API.RestaurantDeleteRequest): Promise<T.API.RestaurantDeleteResponse> => {
    return this.request_auth({
      path: '/restaurants/delete',
      data,
    });
  };

  // PROXY
  proxy_subdomain_check = async (
    data: T.API.ProxySubdomainCheckUpdateRequest,
  ): Promise<T.API.ProxySubdomainCheckUpdateResponse> => {
    return this.request_auth({
      path: '/proxy/subdomain/check',
      data,
    });
  };

  proxy_subdomain_update = async (
    data: T.API.ProxySubdomainCheckUpdateRequest,
  ): Promise<T.API.ProxySubdomainCheckUpdateResponse> => {
    return this.request_auth({
      path: '/proxy/subdomain/update',
      data,
    });
  };

  proxy_domain_check = async (
    data: T.API.ProxyDomainCheckUpdateRequest,
  ): Promise<T.API.ProxyDomainCheckUpdateResponse> => {
    return this.request_auth({
      path: '/proxy/domain/check',
      data,
    });
  };

  proxy_domain_update = async (
    data: T.API.ProxyDomainCheckUpdateRequest,
  ): Promise<T.API.ProxyDomainCheckUpdateResponse> => {
    return this.request_auth({
      path: '/proxy/domain/update',
      data,
    });
  };

  proxy_domain_cert_check = async (data: T.API.ProxyCertCheckRequest): Promise<T.API.ProxyCertCheckResponse> => {
    return this.request_auth({
      path: '/proxy/cert/check',
      data,
    });
  };

  proxy_domain_cert_issue = async (data: T.API.ProxyCertIssueRequest): Promise<T.API.ProxyCertIssueResponse> => {
    return this.request_auth({
      path: '/proxy/cert/issue',
      data,
    });
  };

  // STAFF
  staff_find = async (data: T.API.StaffFindRequest): Promise<T.API.StaffFindResponse> => {
    return this.request_auth({
      path: '/dashboard/staff/find',
      data,
    });
  };

  staff_create = async (data: T.API.StaffCreateRequest): Promise<T.API.StaffCreateResponse> => {
    return this.request_auth({
      path: '/dashboard/staff/create',
      data,
    });
  };

  staff_update = async (data: T.API.StaffUpdateRequest): Promise<T.API.StaffUpdateResponse> => {
    return this.request_auth({
      path: '/dashboard/staff/update',
      data,
    });
  };

  getStripeTransaction = async (data: any): Promise<any> => {
    return this.request_auth({
      path: '/dashboard/connect/get-transaction',
      data,
    });
  };

  staff_delete = async (data: T.API.StaffDeleteRequest): Promise<T.API.StaffDeleteResponse> => {
    return this.request_auth({
      path: '/dashboard/staff/delete',
      data,
    });
  };

  // API ACCESS
  api_find = async (data: T.API.APIFindRequest): Promise<T.API.APIFindResponse> => {
    return this.request_auth({
      path: '/dashboard/api/find',
      data,
    });
  };

  api_create = async (data: T.API.APICreateRequest): Promise<T.API.APICreateResponse> => {
    return this.request_auth({
      path: '/dashboard/api/create',
      data,
    });
  };

  api_update = async (data: T.API.APIUpdateRequest): Promise<T.API.APIUpdateResponse> => {
    return this.request_auth({
      path: '/dashboard/api/update',
      data,
    });
  };

  api_delete = async (data: T.API.APIDeleteRequest): Promise<T.API.APIDeleteResponse> => {
    return this.request_auth({
      path: '/dashboard/api/delete',
      data,
    });
  };

  // WEBSITE
  website_find = async (data: T.API.WebsiteFindRequest): Promise<T.API.WebsiteFindResponse> => {
    return this.request_auth({
      path: '/website/dashboard/find',
      data,
    });
  };

  website_create = async (data: T.API.DashboardWebsiteCreateRequest): Promise<T.API.DashboardWebsiteCreateResponse> => {
    return this.request_auth({
      path: '/website/dashboard/create',
      data,
    });
  };

  website_update = async (data: T.API.DashboardWebsiteUpdateRequest): Promise<T.API.DashboardWebsiteUpdateResponse> => {
    return this.request_auth({
      path: '/website/dashboard/update',
      data,
    });
  };

  // DASHBOARD
  dashboard_data = async (): Promise<T.API.DashboardDataResponse> => {
    return this.request_auth({
      path: '/dashboard/data',
    });
  };

  restaurants = async (): Promise<T.API.DashboardRestaurantsResponse> => {
    return this.request_auth({
      path: '/dashboard/restaurants',
    });
  };

  restaurant = async (data: T.API.DashboardRestaurantFindRequest): Promise<T.API.DashboardRestaurantFindResponse> => {
    return this.request_auth({
      path: '/dashboard/restaurant',
      data,
    });
  };

  billing_session = async (
    data: T.API.DashboardBillingSessionRequest,
  ): Promise<T.API.DashboardBillingSessionResponse> => {
    return this.request_auth({
      path: '/dashboard/billing/session',
      data,
    });
  };

  // DASHBOARD - RESTAURANT SPECIFIC
  reports_basic = async (data: T.API.DashboardReportsBasicRequest): Promise<T.API.DashboardReportsBasicResponse> => {
    return this.request_auth({
      path: '/dashboard/reports/basic',
      data,
    });
  };

  send_overview_report_email = async (
    data: T.API.DashboardReportsSendingEmailRequest,
  ): Promise<T.API.DashboardReportsSendingEmailResponse> => {
    return this.request_auth({
      path: '/dashboard/reports/sendEmail',
      data,
    });
  };

  menu_stock_find = async (data: T.API.DashboardMenuStockFindReq): Promise<T.API.DashboardMenuStockFindRes> => {
    return this.request_auth({
      path: '/dashboard/menu/stock/find',
      data,
    });
  };

  // Kounta Requests
  get_kounta_sites = async (_id: any): Promise<any> => {
    return this.request_auth({
      path: '/dashboard/kounta/sites',
      data: { _id },
    });
  };

  get_kounta_registers = async (_id: any, site_id: any): Promise<any> => {
    return this.request_auth({
      path: '/dashboard/kounta/registers',
      data: { _id, site_id },
    });
  };

  get_kounta_payments = async (_id: any): Promise<any> => {
    return this.request_auth({
      path: '/dashboard/kounta/payment_methods',
      data: { _id },
    });
  };

  get_kounta_delivery_products = async (_id: any, site_id: any): Promise<any> => {
    return this.request_auth({
      path: '/dashboard/kounta/delivery_products',
      data: { _id, site_id },
    });
  };

  generate_menu = async (_id: any, menu_id: string): Promise<any> => {
    return this.request_auth({
      path: '/dashboard/kounta/generate_menu',
      data: { _id, menu_id },
    });
  };

  generate_menuv2 = async (_id: any, menu_id: string): Promise<any> => {
    return this.request_auth({
      path: '/dashboard/kounta/generate_menu_v2',
      data: { _id, menu_id },
    });
  };

  generate_menu_abacus = async (_id: any, menu_id: string): Promise<any> => {
    return this.request_auth({
      path: '/dashboard/abacus/generate_menu',
      data: { _id, menu_id },
    });
  };

  check_status = async (_id: any): Promise<any> => {
    return this.request_auth({
      path: '/dashboard/kounta/check_status',
      data: { _id },
    });
  };

  check_status_abacus = async (_id: any): Promise<any> => {
    return this.request_auth({
      path: '/dashboard/abacus/check_status',
      data: { _id },
    });
  };

  menu_stock_update = async (data: T.API.DashboardMenuStockUpdateReq): Promise<T.API.DashboardMenuStockUpdateRes> => {
    return this.request_auth({
      path: '/dashboard/menu/stock/update',
      data,
    });
  };

  orders_board_find = async (
    data: T.API.DashboardOrdersBoardFindRequest,
  ): Promise<T.API.DashboardOrdersBoardFindResponse> => {
    return this.request_auth({
      path: '/dashboard/orders/board',
      data,
    });
  };

  orders_find = async (data: T.API.DashboardOrdersFindRequest): Promise<T.API.DashboardOrdersFindResponse> => {
    return this.request_auth({
      path: '/dashboard/orders',
      data,
    });
  };

  order_find = async (data: T.API.DashboardOrderFindRequest): Promise<T.API.DashboardOrderFindResponse> => {
    return this.request_auth({
      path: '/dashboard/order',
      data,
    });
  };

  order_update_status = async (
    data: T.API.DashboardOrderUpdateStatusRequest,
  ): Promise<T.API.DashboardOrderUpdateStatusResponse> => {
    return this.request_auth({
      path: '/dashboard/order/update/status',
      data,
    });
  };

  order_update_ready_time = async (
    data: T.API.DashboardOrderUpdateReadyTimeRequest,
  ): Promise<T.API.DashboardOrderUpdateReadyTimeResponse> => {
    return this.request_auth({
      path: '/dashboard/order/update/ready-time',
      data,
    });
  };

  order_book_tookan = async (
    data: T.API.DashboardOrderTookanBookRequest,
  ): Promise<T.API.DashboardOrderTookanBookResponse> => {
    return this.request_auth({
      path: '/dashboard/order/tookan/book',
      data,
    });
  };

  order_delete = async (data: T.API.DashboardOrderDeleteRequest): Promise<T.API.DashboardOrderDeleteResponse> => {
    return this.request_auth({
      path: '/dashboard/order/delete',
      data,
    });
  };

  order_stripe_refund = async (
    data: T.API.DashboardOrderStripeRefundRequest,
  ): Promise<T.API.DashboardOrderStripeRefundResponse> => {
    return this.request_auth({
      path: '/dashboard/order/stripe/refund',
      data,
    });
  };

  order_stripe_connect_refund = async (
    data: T.API.DashboardOrderStripeConnectRefundRequest,
  ): Promise<T.API.DashboardOrderStripeRefundResponse> => {
    return this.request_auth({
      path: '/dashboard/order/stripe-connect/refund',
      data,
    });
  };

  order_cardconnect_refund = async (
    data: T.API.DashboardOrderCardConnectRefundRequest,
  ): Promise<T.API.DashboardOrderCardConnectRefundResponse> => {
    return this.request_auth({
      path: '/dashboard/order/cardconnect/refund',
      data,
    });
  };

  order_verify_checkout_payment = async (
    data: T.API.DashboardOrderCheckoutPaymentVerificationRequest,
  ): Promise<T.API.DashboardOrderCheckoutPaymentVerificationResponse> => {
    return this.request_auth({
      path: '/dashboard/order/checkout/verify-payment',
      data,
    });
  };

  orderVerifyPaywayPayment = async (
    data: T.API.DashboardOrderPaywayVerificationRequest,
  ): Promise<T.API.DashboardOrderPaywayVerificationResponse> => {
    return this.request_auth({
      path: '/dashboard/order/payway/verify-payment',
      data,
    });
  };

  order_verify_gkash_payment = async (
    data: T.API.DashboardOrderGkashPaymentVerificationRequest,
  ): Promise<T.API.DashboardOrderGkashPaymentVerificationResponse> => {
    return this.request_auth({
      path: '/dashboard/order/gkash/verify-payment',
      data,
    });
  };

  bookings_find = async (data: T.API.DashboardBookingsFindRequest): Promise<T.API.DashboardBookingsFindResponse> => {
    return this.request_auth({
      path: '/dashboard/bookings',
      data,
    });
  };

  booking_find = async (data: T.API.DashboardBookingFindRequest): Promise<T.API.DashboardBookingFindResponse> => {
    return this.request_auth({
      path: '/dashboard/booking',
      data,
    });
  };

  booking_update_status = async (
    data: T.API.DashboardBookingUpdateStatusRequest,
  ): Promise<T.API.DashboardBookingUpdateStatusResponse> => {
    return this.request_auth({
      path: '/dashboard/booking/update/status',
      data,
    });
  };

  booking_delete = async (data: T.API.DashboardBookingDeleteRequest): Promise<T.API.DashboardBookingDeleteResponse> => {
    return this.request_auth({
      path: '/dashboard/booking/delete',
      data,
    });
  };

  print_receipt = async (data: T.API.DashboardPrintRequest): Promise<T.API.DashboardPrintResponse> => {
    return this.request_auth({
      path: '/printing/dashboard/print',
      data,
    });
  };

  print_report = async (data: T.API.DashboardReportsBasicRequest): Promise<T.API.DashboardPrintResponse> => {
    return this.request_auth({
      path: '/printing/dashboard/report/print',
      data,
    });
  };

  customers_find = async (data: T.API.DashboardCustomersFindRequest): Promise<T.API.DashboardCustomersFindResponse> => {
    return this.request_auth({
      path: '/dashboard/customers',
      data,
    });
  };

  customer_find = async (data: T.API.DashboardCustomerFindRequest): Promise<T.API.DashboardCustomerFindResponse> => {
    return this.request_auth({
      path: '/dashboard/customer',
      data,
    });
  };

  customer_create = async (
    data: T.API.DashboardCustomerCreateRequest,
  ): Promise<T.API.DashboardCustomerCreateResponse> => {
    return this.request_auth({
      path: '/dashboard/customer/create',
      data,
    });
  };

  customer_update_email = async (
    data: T.API.DashboardCustomerUpdateEmailRequest,
  ): Promise<T.API.DashboardCustomerUpdateEmailResponse> => {
    return this.request_auth({
      path: '/dashboard/customer/update/email',
      data,
    });
  };

  customer_update_password = async (
    data: T.API.DashboardCustomerUpdatePasswordRequest,
  ): Promise<T.API.DashboardCustomerUpdatePasswordResponse> => {
    return this.request_auth({
      path: '/dashboard/customer/update/password',
      data,
    });
  };

  customer_age_verification_update = async (
    data: T.API.DashboardCustomerAgeVerificationUpdateRequest,
  ): Promise<T.API.DashboardCustomerAgeVerificationUpdateResponse> => {
    return this.request_auth({
      path: '/dashboard/customer/age-verification/update',
      data,
    });
  };

  customer_delete = async (
    data: T.API.DashboardCustomerDeleteRequest,
  ): Promise<T.API.DashboardCustomerDeleteResponse> => {
    return this.request_auth({
      path: '/dashboard/customer/delete',
      data,
    });
  };

  dashboard_restaurant_domain_verify = async (
    data: T.API.DashboardRestaurantDomainVerificationRequest,
  ): Promise<T.API.DashboardRestaurantDomainVerificationResponse> => {
    return this.request_auth({
      path: '/dashboard/restaurant/domain/verify',
      data,
    });
  };

  dashboard_restaurant_payment_prefill_stripedigitalpay = async (
    data: T.API.DashboardPaymentPrefillStripeDigitalPayRequest,
  ): Promise<T.API.DashboardPaymentPrefillStripeDigitalPayResponse> => {
    return this.request_auth({
      path: '/dashboard/restaurant/payment/prefill/stripe_digital_pay',
      data,
    });
  };

  printer_status = async (data: T.API.DashboardPrinterStatusReq): Promise<T.API.DashboardPrinterStatusRes> => {
    return this.request_auth({
      path: '/dashboard/printers/status',
      data,
    });
  };

  getStripeConnect = async (data: any): Promise<any> => {
    return this.request_auth({
      path: '/dashboard/connect/get-transactions',
      data,
    });
  };

  stripe_connect_order_find = async (data: any): Promise<any> => {
    return this.request_auth({
      path: '/dashboard/connect/transactions',
      data,
    });
  };

  stripe_connect_payout_list = async (data: any): Promise<any> => {
    return this.request_auth({
      path: '/v2/stripe/payout/list',
      data,
    });
  };

  stripe_connect_payout_details = async (data: any): Promise<any> => {
    return this.request_auth({
      path: '/v2/stripe/payout/get-transactions',
      data,
    });
  };

  stripe_connect_payout_request_report = async (data: any): Promise<any> => {
    return this.request_auth({
      path: '/v2/stripe/payout/request-report',
      data,
    });
  };

  create_lalamove_quotation = async (
    data: T.API.CreateLalamoveQuoteRequest,
  ): Promise<T.API.CreateLalamoveQuoteResponse> => {
    return this.request_auth({
      path: '/dashboard/order/lalamove/delivery-quotes',
      data,
    });
  };

  create_lalamove_delivery = async (
    data: T.API.DashboardCreateLalamoveDeliveryRequest,
  ): Promise<T.API.DashboardCreateLalamoveDeliveryResponse> => {
    return this.request_auth({
      path: '/dashboard/order/lalamove/deliveries',
      data,
    });
  };

  findOrderByLalamoveDeliveryId = async (
    data: T.API.DashboardOrderFindByLalamoveOrderIdRequest,
  ): Promise<T.API.DashboardOrderFindResponse> => {
    return this.request_auth({
      path: '/dashboard/order/find-by-lalamove-order-id',
      data,
    });
  };

  getWalletlyDeal = async (params: T.API.GetDealsRequest): Promise<T.API.GetDealsResponse> => {
    return this.request_auth({
      path: '/v1/loyalty/walletly/get-deals',
      params,
    });
  };

  updateDeal = async (data: any): Promise<T.API.UpdateDealsRequest> => {
    return this.request_auth({
      path: '/v1/loyalty/walletly/update-deal',
      data,
    });
  };

  // HELPERS
  private _get_auth_token(): string {
    return this.store.auth.token || '';
  }
}
