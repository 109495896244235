import React from 'react';
import { FastField } from 'formik';
import { FormGroup, Button, RotateLoader, Textarea } from '@lib/components';
import { inject, observer } from 'mobx-react';
import { MobxComponent } from '../../../../../../mobx/components/index';
import { WebsiteForm } from '../../../../../../mobx/components/website-form';
import { UI } from '../../../../../../core/ui';

interface Props {}
interface State {}
type FormValues = T.Schema.Restaurant.RestaurantSchema['website']['inject'];

export const WebsiteFormInjections = inject('store')(
  observer(
    class WebsiteFormInjections extends MobxComponent<Props, State> {
      initialValues: FormValues;

      constructor(props: Props) {
        super(props);
        this.state = {};
        const website = this.injected.store.website!;
        this.initialValues = website.sections.inject || {
          html_head: '',
          html_body: '',
        };
      }

      render() {
        return (
          <WebsiteForm<FormValues>
            submit={async (website, values) => {
              website.sections.inject = values;
              const update = { $set: { 'sections.inject': values } };
              return { w: website, update };
            }}
            validators=
            initialValues={this.initialValues}
            onSuccess={() => {}}
            onError={() => UI.notification.error('An error occurred')}
            onSuccessMessage="Settings Updated"
            onErrorMessage=""
          >
            {({ form, error }) => {
              const { isSubmitting, setFieldValue } = form;
              return (
                <div className="p-4">
                  <FormGroup>
                    <p className="lhp">
                      Use this section to add custom HTML to your website. Custom CSS should be added to the HTML head
                    </p>
                  </FormGroup>

                  <FastField
                    name="html_head"
                    render={({ field }: any) => (
                      <FormGroup
                        optional
                        title="HTML Head Code"
                        help="Code that will be inserted at the end of the HEAD tag"
                      >
                        <Textarea
                          onChange={e => setFieldValue('html_head', e.target.value)}
                          value={field.value || ''}
                        />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="html_body"
                    render={({ field }: any) => (
                      <FormGroup
                        optional
                        title="HTML Body Code"
                        help="Code that will be inserted at the end of the BODY tag"
                      >
                        <Textarea
                          onChange={e => setFieldValue('html_body', e.target.value)}
                          value={field.value || ''}
                        />
                      </FormGroup>
                    )}
                  />

                  {error && <FormGroup error={error} />}

                  <Button full color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting && <RotateLoader size={2} color="white" />}
                    {!isSubmitting && 'Save'}
                  </Button>
                </div>
              );
            }}
          </WebsiteForm>
        );
      }
    },
  ),
);
