import React from 'react';
import { Button, Input, FormGroup, RotateLoader, SelectAdv, Tag } from '@lib/components';
import { logger } from '@lib/common';
import { Formik, Form, FastField, FormikProps, FormikHelpers as FormikActions } from 'formik';
import { inject, observer } from 'mobx-react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { MobxComponent } from '../../../../../mobx/components/index';
import { FormHelpers } from '../../../../../core/form';
import { UI } from '../../../../../core/ui/index';

type FormValues = T.Schema.API.APICESchema;

interface Props extends WithTranslation {
  restaurants: Array<{ value: string; label: string }>;
  initialValues: FormValues;
  edit_id: string;
  back: () => void;
}

const APIFormClass = inject('store')(
  observer(
    class APIFormClass extends MobxComponent<Props, { error: string }> {
      constructor(props: Props) {
        super(props);
        this.state = {
          error: '',
        };
      }

      onSubmit = async (values: FormValues, form: FormikActions<FormValues>) => {
        const { edit_id, back } = this.props;
        const { store } = this.injected;
        const isCreate = !edit_id;
        try {
          this.setState({ error: '' });

          const res = isCreate
            ? await this.injected.store.api.api_create({ api: values })
            : await this.injected.store.api.api_update({ _id: edit_id, api: values });

          form.setSubmitting(false);

          if (res.outcome) {
            this.setState({ error: res.message });
            return;
          }

          const { item } = res;
          const items = [...this.injected.store.apis.items];
          if (isCreate) {
            items.push(item);
          } else {
            const index = items.findIndex(s => s._id === item._id);
            items[index] = item;
          }
          store.updateApis({ items });
          UI.notification.success('API saved');
          back();
        } catch (e) {
          logger.captureException(e);
          this.setState({ error: 'Error saving api, please try again or contact us' });
          form.setSubmitting(false);
        }
      };

      validate = (values: FormValues) => {
        const { errors, isError } = FormHelpers.validate<FormValues>(values, {});
        if (this.state.error && !isError) {
          this.setState({ error: '' });
        } else if (!this.state.error && isError) {
          this.setState({ error: 'There are errors in your form that need correcting, please scroll up' });
        }
        return errors;
      };

      getFieldError = (form: FormikProps<FormValues>, field: keyof FormValues) => FormHelpers.error(form, field);

      render() {
        const { initialValues, restaurants } = this.props;
        return (
          <Formik initialValues={initialValues} validate={this.validate} onSubmit={this.onSubmit}>
            {form => {
              const { isSubmitting, setFieldValue, submitCount } = form;
              return (
                <Form>
                  <FastField
                    name="name"
                    render={({ field }: any) => (
                      <FormGroup title="Name" help="Internal reference name for this key">
                        <Input type="text" {...field} required />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="restaurants"
                    render={({ field }: any) => (
                      <FormGroup title="Restaurant Access">
                        <SelectAdv
                          type="multi"
                          value={field.value || []}
                          options={restaurants}
                          onChange={(selectedValues: string[]) => {
                            setFieldValue('restaurants', selectedValues);
                          }}
                        />
                        <p className="lhp m-t-2">
                          Restrict your key access to particular restaurants. Leaving this empty will disable access to
                          any restaurants
                        </p>
                        {(field.value || []).length > 0 && (
                          <div className="m-t-2">
                            <p className="font-semi-bold lhp big">Selected restaurant ID's</p>
                            {field.value.map((id: string) => {
                              const r = restaurants.find(v => v.value === id);
                              if (!r) return null;
                              return (
                                <p key={id} className="m-t-2 lhp">
                                  {r.label} - <Tag>{id}</Tag>
                                </p>
                              );
                            })}
                          </div>
                        )}
                      </FormGroup>
                    )}
                  />

                  {submitCount > 0 && this.state.error && <FormGroup error={this.state.error} />}

                  <Button full color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting && <RotateLoader size={2} color="white" />}
                    {!isSubmitting && 'Save'}
                  </Button>
                </Form>
              );
            }}
          </Formik>
        );
      }
    },
  ),
);

export const APIForm = withTranslation()(APIFormClass);
