import React from 'react';
import { inject, observer } from 'mobx-react';
import { TabSelect } from '@lib/components';
import { MobxComponent } from '../../../../../../../mobx/components';
import { SettingsFormTerms } from './terms';
import { SettingsFormPrivacy } from './privacy-policy';
import { SettingsFormCookie } from './cookie-policy';

interface Props {}
interface State {
  tab: string;
}

export const SettingsFormPoliciesAndAgreements = inject('store')(
  observer(
    class SettingsFormPoliciesAndAgreements extends MobxComponent<Props, State> {
      constructor(props: Props) {
        super(props);
        this.state = {
          tab: '0',
        };
      }

      render() {
        const { tab } = this.state;
        return (
          <div>
            <TabSelect
              id="notifications-tab-options"
              className="border-white-tb-10"
              hasBorder
              screenWidth={this.injected.store.view.screen_width}
              onChange={v => this.setState({ tab: v.value })}
              value={tab}
              values={[
                { label: 'Terms & Conditions', value: '0' },
                { label: 'Privacy Policy', value: '1' },
                { label: 'Cookie Policy', value: '2' },
              ]}
            />

            {tab === '0' && <SettingsFormTerms />}
            {tab === '1' && <SettingsFormPrivacy />}
            {tab === '2' && <SettingsFormCookie />}
          </div>
        );
      }
    },
  ),
);
