import React, { useState, useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';

import { Button, FormGroup, ModalContent, RotateLoader, TabSelect, Modal } from '@lib/components';
import { PromoParser } from '@lib/common';
import { LimitsTab } from './limits-tab';
import { ConditionTab } from './condition-tab';
import { FreeItemPromo, ConventionalDiscountPromo, NewGenericPromo } from '../promos/type';
import { UI } from '../../../../../../../../core/ui';
import { GeneralInformationTab } from './general-tab';
import { FreeItemPromoValidationSchema } from './validationSchema';
import { RestaurantForm } from '../../../../../../../../mobx/components/restaurant-form';
import { ConditionDiscountPromoTab } from './condition-discount-promo-tab';
import { ATTRIBUTE_MAP } from '../promos/constants';

interface Props {
  type: 'edit' | 'create';
  promoType: 'free_item' | 'conventional_discount';
  initialValues: NewGenericPromo;
  close: () => void;
}

export function SettingsFreeItemPromosForm(props: Props) {
  const [tab, setTab] = useState('0');
  const { store } = useContext(MobXProviderContext);
  const { restaurant } = store;
  const { close, initialValues: currentPromo, promoType } = props;
  const promoList: any[] = [
    ...restaurant.promos,
    ...(restaurant.free_item_promos || []),
    ...(restaurant.conventional_discount_promos || []),
  ];
  const promoCodeList = promoList.map(promo => promo.code.toLowerCase());

  const updatePromosQuery = (restaurant: T.Schema.Restaurant.RestaurantSchema, item: FreeItemPromo) => {
    const promos = restaurant[ATTRIBUTE_MAP[promoType]] || [];
    const idx = promos.findIndex((p: any) => p._id === item._id);
    if (idx === -1) {
      promos.push(item);
    } else {
      promos[idx] = item;
    }

    // @ts-ignore
    restaurant[ATTRIBUTE_MAP[promoType]] = promos;

    return {
      $set: { [ATTRIBUTE_MAP[promoType]]: promos },
    };
  };

  const updateWalletlyPromo = async (restaurant: T.Schema.Restaurant.RestaurantSchema, item: NewGenericPromo) => {
    const promoParser = new PromoParser(item);
    const isDisable = promoParser.isDisable();
    const dealId = promoParser.getDeal();
    const apiKey = restaurant.settings.loyalty_providers?.walletly?.api_key;
    if (dealId) {
      const response = await store.api.updateDeal({
        dealId,
        valid: !isDisable,
        apiKey,
      });

      if (response.outcome) {
        throw new Error('An error occurred');
      }
      return {
        r: restaurant,
        // @ts-ignore
        update: updatePromosQuery(restaurant, item),
      };
    }

    return {
      r: restaurant,
      // @ts-ignore
      update: updatePromosQuery(restaurant, item),
    };
  };

  return (
    <Modal
      width="md"
      close={close}
      alignTop
      active={!!currentPromo}
      scrollRoot={typeof document !== 'undefined' ? document.getElementsByTagName('html')[0] : undefined}
      id="free-item-promo"
    >
      <ModalContent className="">
        <h3 className="">{`${currentPromo.code ? 'Update' : 'Create'} Promo: ${
          promoType === 'free_item' ? 'Free Item' : 'Discount'
        } `}</h3>
      </ModalContent>

      <TabSelect
        id="free-item-promo-tab"
        className="border-white-tb-10"
        hasBorder
        screenWidth={store.view.screen_width}
        onChange={v => setTab(v.value)}
        value={tab}
        values={[
          { label: 'General', value: '0' },
          { label: 'Condition', value: '1' },
          { label: 'Limits', value: '2' },
        ]}
      />

      <RestaurantForm<FreeItemPromo | ConventionalDiscountPromo>
        initialValues={currentPromo}
        validators={{
          // @ts-ignore
          code: values => {
            if (!currentPromo.code && promoCodeList.some(e => e === values.code.toLowerCase()))
              return { code: 'This code is already taken' };
          },
        }}
        validationSchema={FreeItemPromoValidationSchema}
        onSuccess={() => close()}
        onError={() => UI.notification.error('An error occurred')}
        onSuccessMessage="Settings Updated"
        submit={async (restaurant, item) => updateWalletlyPromo(restaurant, item)}
      >
        {({ form, getFieldError }) => {
          const { isSubmitting, errors } = form;
          return (
            <>
              {tab === '0' && <GeneralInformationTab form={form} getFieldError={getFieldError} promoType={promoType} />}

              {tab === '1' && (
                <>
                  {promoType === 'free_item' && <ConditionTab form={form} getFieldError={getFieldError} />}
                  {promoType === 'conventional_discount' && (
                    <ConditionDiscountPromoTab form={form} getFieldError={getFieldError} />
                  )}
                </>
              )}

              {tab === '2' && <LimitsTab form={form} getFieldError={getFieldError} />}

              <ModalContent>
                <Button full color="primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting && <RotateLoader size={2} color="white" />}
                  {!isSubmitting && 'Save'}
                </Button>

                {errors && Object.keys(errors).length > 0 && (
                  <FormGroup no_border error="Validation failed. Please check the promotion data again." />
                )}
              </ModalContent>
            </>
          );
        }}
      </RestaurantForm>
    </Modal>
  );
}
