import React from 'react';
import { FastField } from 'formik';
import { FormGroup, Button, RotateLoader, Switch, Input } from '@lib/components';
import { inject, observer } from 'mobx-react';
import { MobxComponent } from '../../../../../../../mobx/components/index';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';
import { UI } from '../../../../../../../core/ui';
import { PaymentBaseFields } from './base';

interface Props {}
interface State {}
type FormValues = T.Schema.Restaurant.Payments.RestaurantPaymentSGE;

export const SettingsFormPaymentsSGE = inject('store')(
  observer(
    class SettingsFormPaymentsSGE extends MobxComponent<Props, State> {
      initialValues: FormValues;

      constructor(props: Props) {
        super(props);
        this.state = {};
        const r = this.injected.store.restaurant!;
        this.initialValues = r.settings.payments.sgepay || {
          enabled: false,
          label: '',
          services: [],
          testing: false,
          Mid: '',
          RequestId: '',
        };
      }

      render() {
        const r = this.injected.store.restaurant!;
        const callback_url = `https://api.orderingdashboard.com/v1/${r._id}/sgepay_callback`;
        return (
          <RestaurantForm<FormValues>
            submit={async (r, values) => {
              r.settings.payments.sgepay = values;
              let update;
              if (values.enabled) {
                r.settings.business.using_awaiting_payment = true;
                update = {
                  $set: {
                    'settings.payments.sgepay': r.settings.payments.sgepay,
                    'settings.business.using_awaiting_payment': true,
                  },
                };
              } else {
                update = {
                  $set: {
                    'settings.payments.sgepay': r.settings.payments.sgepay,
                  },
                };
              }

              return { r, update };
            }}
            validators={{
              // @ts-ignore
              Mid: values => {
                if (values.enabled && !values.Mid)
                  return {
                    Mid: 'Merchant ID is required field!',
                  };
              },

              // @ts-ignore
              RequestId: values => {
                if (values.enabled && !values.RequestId)
                  return {
                    RequestId: 'Request ID is required field',
                  };
              },
            }}
            initialValues={this.initialValues}
            onSuccess={() => {}}
            onError={() => UI.notification.error('An error occurred')}
            onSuccessMessage="Settings Updated"
            onErrorMessage=""
          >
            {({ form, error, getFieldError }) => {
              const { isSubmitting, setFieldValue } = form;
              return (
                <div className="p-4">
                  <FastField
                    name="enabled"
                    render={({ field }: any) => (
                      <FormGroup title="Enabled" help="Allows customers to pay using SGEPAY payment method.">
                        <Switch
                          id="enable-switch"
                          checked={field.value}
                          onChange={e => setFieldValue('enabled', e.target.checked)}
                        />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="testing"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Testing Environment"
                        help="Enable this to use the SGEPAY demo environment to test and experiment with your integration."
                      >
                        <Switch
                          id="testing-switch"
                          checked={field.value}
                          onChange={e => setFieldValue('testing', e.target.checked)}
                        />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="Mid"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Merchant ID"
                        help="The merchant ID given by SGEPAY when setting up an account."
                        error={getFieldError(form, 'Mid')}
                      >
                        <Input type="text" {...field} autoSave="false" autoCorrect="false" />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="RequestId"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Request ID"
                        help="The secret key given by SGEPAY when setting up an account."
                        error={getFieldError(form, 'RequestId')}
                      >
                        <Input type="text" {...field} autoSave="true" autoCorrect="false" />
                      </FormGroup>
                    )}
                  />

                  <FormGroup title="Callback URL" help="">
                    <Input id="callback-url-input" type="text" value={callback_url} readOnly />

                    <Button
                      type="button"
                      color="primary"
                      size="xs"
                      className="m-t-2"
                      onClick={() => {
                        const input = document.querySelector('#callback-url-input');
                        if (input) {
                          // @ts-ignore
                          input.select();
                          document.execCommand('copy');
                        }
                      }}
                    >
                      Copy URL
                    </Button>
                  </FormGroup>

                  <PaymentBaseFields defaultLabel="Credit Card" setFieldValue={setFieldValue} />

                  {error && <FormGroup error={error} />}

                  <Button full color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting && <RotateLoader size={2} color="white" />}
                    {!isSubmitting && 'Save'}
                  </Button>
                </div>
              );
            }}
          </RestaurantForm>
        );
      }
    },
  ),
);
