import { observable, action, makeObservable, set } from 'mobx';
import { logger, RestaurantUtils } from '@lib/common';
import stream from 'getstream';
import cloneDeep from 'lodash/cloneDeep';
import Push, { PushNotificationParams } from 'push.js';
import { Howl, Howler } from 'howler';
import { Realtime, Types } from 'ably';
import { config } from '../../config';
import { ExpiryCache } from '../../core/expiry_cache';
import { UI } from '../../core/ui';
import { RootStore } from '../store';

const client = stream.connect('6ue6uqqptjeq', null, '44988');

(() => {
  try {
    Push.config({
      serviceWorker: '/serviceWorker.js',
    });
    Howler.autoSuspend = false;
    Howler.mobileAutoEnable = true;
    // @ts-ignore
    Howler.autoUnlock = true;
    new Howl({
      src: [`${config.urls.cloudfront}/assets/audio/job-done.mp3`],
      volume: 0,
    }).play();
  } catch (e) {
    logger.captureException(e);
  }
})();

export interface NotificationsState {
  items: T.Lib.GetStream.NotificationItem[];
  unread: number;
  unseen: number;
  active: boolean;
  loading: boolean;
  all_items_queried: boolean;
  audio_unlocked: boolean;
}

const baseNotificationState = () => ({
  items: [],
  unread: 0,
  unseen: 0,
  active: false,
  loading: false,
  all_items_queried: false,
  audio_unlocked: Howler.ctx ? Howler.ctx.state === 'running' : false,
});

export class NotificationsStore {
  store: RootStore;

  feed?: stream.Feed;

  subscription?: any;

  pushNotificationsInitialized = false;

  soundPlaying = false;

  ablyOrg?: Realtime;

  ablyRestaurant?: Realtime;

  s: NotificationsState;

  constructor(store: RootStore) {
    makeObservable(
      this,
      {
        s: observable,
        stop: action,
        open: action,
        close: action,
        onScrollBottom: action,
        get: action,
        mark_seen: action,
        mark_read: action,
        mark_read_object: action,
        handle_click: action,
        set: action,
        update: action,
      },
      { autoBind: true },
    );

    this.store = store;
    this.s = baseNotificationState();
    this.initPushNotification();
    this.anInit();
  }

  // ABLY ORGANISATION
  ablyOrgStart = (authToken: string, orgId: string) => {
    this.ablyOrgStop();

    const ably = new Ably.Realtime({
      authUrl: `${config.urls.api}/dashboard/ably/organisation/token-request`,
      authHeaders: {
        'Authorization-Dashboard': authToken,
      },
      recover: (_lastConnectionDetails, cb) => {
        cb(true);
      },
    });

    this.ablyOrg = ably;

    ably.connection.on(({ current }) => logger.info(`ABLY ORGANISATION STATE: ${current}`));

    const channel = ably.channels.get(`private:organisation:${orgId}`);

    channel.subscribe('restaurant:updated', this.ablyOrgHandleRestaurantUpdate);
  };

  ablyOrgStop = () => {
    if (this.ablyOrg) {
      this.ablyOrg.close();
      delete this.ablyOrg;
    }
  };

  ablyOrgHandleRestaurantUpdate = (message: Types.Message) => {
    try {
      logger.info('RESTAURANT UPDATE', message);
      const { restaurant_id } = message.data;
      const update = message.data.update as Partial<T.Schema.Restaurant.RestaurantSchema>;
      this.store.updateRestaurantComplete(restaurant_id, update);
    } catch (e) {
      logger.captureException(e);
    }
  };

  // ABLY RESTAURANT
  ablyRestaurantInit = (rid: string) => {
    // DELAY BY 2 SECONDS TO PREVENT SIMULTANEOUS CONNECTION ISSUE
    setTimeout(() => {
      try {
        this.ablyRestaurantStop();

        this.store.updateAbly({
          status: 'disconnected',
          connected_once: false,
          printers: [],
        });

        const rne = this.store.restrictions._.restaurantNotificationsEnabled;
        if (!rne) {
          return;
        }

        const { auth } = this.store;

        const ably = new Ably.Realtime({
          authUrl: `${config.urls.api}/dashboard/ably/token-request`,
          authHeaders: {
            'Authorization-Dashboard': `${auth.token}`,
            'Authorization-Restaurant': `${rid}`,
          },
          recover: (_lastConnectionDetails, cb) => {
            cb(true);
          },
        });

        this.ablyRestaurant = ably;

        ably.connection.on(({ current }) => {
          logger.info(`ABLY RESTAURANT STATE: ${current}`);
          if (current === 'initialized' || current === 'connecting') {
            this.store.updateAbly({ status: 'connecting' });
          } else if (current === 'connected') {
            this.store.updateAbly({
              status: current,
              connected_once: true,
            });
          } else {
            this.store.updateAbly({ status: 'disconnected' });
          }
        });

        const channel = ably.channels.get(`private:restaurant:${rid}`);

        channel.subscribe('order:created', this.ablyRestaurantHandleOrderNew);
        channel.subscribe('order:updated', this.ablyRestaurantHandleOrderUpdate);
        channel.subscribe('printer:job-update', this.ablyRestaurantHandlePrinterJobUpdate);

        // UPDATE PRINTERS THAT ARE ONLINE
        channel.presence.subscribe('enter', member => {
          if (member.data && member.data.type === 'printer') {
            logger.info('PRINTER ONLINE', member);
            this.ablyRestaurantPrinterAdd(member.clientId);
          }
        });
        channel.presence.subscribe('leave', member => {
          logger.info('PRINTER OFFLINE', member);
          this.ablyRestaurantPrinterRemove(member.clientId);
        });

        const sync_printers = () => {
          channel.presence.get((err, members) => {
            if (!err && members) {
              members.forEach(member => {
                if (member.data && member.data.type === 'printer') {
                  this.ablyRestaurantPrinterAdd(member.clientId);
                }
              });
            }
          });
        };

        sync_printers();

        setInterval(sync_printers, 120000);

        // channel.subscribe("booking:created", this.ably_handle_booking_created);
      } catch (e) {
        logger.captureException(e);
      }
    }, 2000);
  };

  ablyRestaurantStop = () => {
    if (this.ablyRestaurant) {
      this.ablyRestaurant.close();
      delete this.ablyRestaurant;
    }
  };

  ablyRestaurantPrinterAdd = (id: string) => {
    const { store } = this;
    if (store.ably.printers.indexOf(id) === -1) {
      const printers = [...store.ably.printers];
      printers.push(id);
      store.updateAbly({ printers });
    }
  };

  ablyRestaurantPrinterRemove = (id: string) => {
    const { store } = this;
    if (store.ably.printers.indexOf(id) !== -1) {
      const printers = [...store.ably.printers];
      printers.splice(store.ably.printers.indexOf(id), 1);
      store.updateAbly({ printers });
    }
  };

  ablyRestaurantHandlePrinterJobUpdate = (message: Types.Message) => {
    try {
      logger.info('PRINT JOB UPDATE', message);

      const { data } = message;

      const cacheKey = `printer:job-update:${data.job_id}`;
      const alreadyProcessed = ExpiryCache.get(cacheKey);
      if (alreadyProcessed) {
        return;
      }
      ExpiryCache.clearExpired();
      ExpiryCache.set(cacheKey, {}, 1000 * 60 * 60 * 2);

      const order_number = data.order_number ? `${data.order_number} ` : '';
      if (data.error) {
        UI.notification.error(`Order ${order_number}failed to print`);
      } else {
        UI.notification.success(`Order ${order_number}printed`);
      }
    } catch (e) {
      logger.captureException(e);
    }
  };

  ablyRestaurantHandleOrderNew = (message: Types.Message) => {
    logger.info('ABLY NEW ORDER', message);

    if (this.store.restrictions._.restaurantOrderViews.length === 0) {
      return;
    }

    const { order_id } = message.data;

    // CHECK PROCESSED CACHE
    ExpiryCache.clearExpired();
    const cacheKey = `order:notification:created:${order_id}`;
    const alreadyProcessed = ExpiryCache.get(cacheKey);
    if (alreadyProcessed) {
      return;
    }
    ExpiryCache.set(cacheKey, {}, 1000 * 60 * 60 * 2);

    // PLAY AUDIO
    const an = this.anGet();
    if (an.play_on.order_new) {
      this.anPlay();
    }

    // SEND PN
    this.sendPushNotification('New Order Received', {
      timeout: 30000,
      requireInteraction: true,
    });

    // ADD TO BOARD OR LIST
    if (this.store.router.s.path.indexOf('/orders') !== -1) {
      this.store.service.order.handle_new(order_id);
    }
  };

  ablyRestaurantHandleOrderUpdate = (message: Types.Message) => {
    logger.info('ABLY NEW ORDER', message);

    if (this.store.restrictions._.restaurantOrderViews.length === 0) {
      return;
    }

    const { order_id } = message.data;

    // ADD TO BOARD OR LIST
    if (this.store.router.s.path.indexOf('/orders') !== -1) {
      this.store.service.order.handle_update(order_id);
    }
  };

  // GET STREAM
  start = (api: string, token: string) => {
    if (this.subscription) {
      this.subscription.cancel();
    }
    // DONT SUBSCRIBE IF NOTIFICATIONS NOT ENABLED
    if (!this.store.restrictions._.restaurantNotificationsEnabled) {
      return;
    }
    this.feed = client.feed('restaurant_notifications', api, token);
    this.subscription = this.feed.subscribe(this.handle_subscription);
    this.get({
      limit: 15,
      mark_seen: false,
      strategy: 'replace',
    });
  };

  stop = () => {
    if (this.subscription) {
      this.subscription.cancel();
    }
    this.s = baseNotificationState();
    delete this.feed;
    delete this.subscription;
  };

  open = () => {
    this.s.active = true;
    this.mark_seen();
  };

  close = () => {
    this.s.active = false;
  };

  onScrollBottom = () => {
    const { items, loading, all_items_queried } = this.s;
    if (!all_items_queried && !loading) {
      this.s.loading = true;
      const lastActivity = items[items.length - 1];
      if (lastActivity) {
        this.get({
          strategy: 'back',
          limit: 15,
          mark_seen: false,
          id_lt: lastActivity.group_id,
        });
      }
    }
  };

  get = async (opts: {
    limit?: number;
    id_lt?: string;
    mark_seen: boolean;
    strategy: 'front' | 'back' | 'replace';
  }) => {
    if (!this.feed) return;
    try {
      const { limit, id_lt, mark_seen, strategy } = opts;
      this.s.loading = true;

      const query: T.ObjectAny = {
        limit: limit || 15,
        mark_seen,
      };
      if (id_lt) query.id_lt = id_lt; // PAGINATE

      const res = await this.feed.get(query);
      const data = res as T.Lib.GetStream.StreamNotificationResponse;
      logger.info('NOTIFICATIONS FETCHED');

      this.s.loading = false;
      this.s.unread = data.unread;
      this.s.unseen = mark_seen ? 0 : data.unseen;

      const newItems = data.results.map(item => ({
        ...item.activities[0],
        is_read: item.is_read,
        is_seen: item.is_seen,
        group_id: item.id,
      }));

      if (strategy === 'front') {
        // @ts-ignore
        this.s.items = newItems.concat(this.s.items);
      } else if (strategy === 'back') {
        // @ts-ignore
        this.s.items = this.s.items.concat(newItems);
      } else if (strategy === 'replace') {
        // @ts-ignore
        this.s.items = newItems;
      }

      // DON'T LOAD MORE THAN THE LAST 100 NOTIFICATIONS
      if (this.s.items.length > 100 || (id_lt && data.results.length === 0)) {
        this.s.all_items_queried = true;
      }
    } catch (e) {
      logger.captureException(e);
    }
  };

  mark_seen = async () => {
    if (!this.feed) return;
    const old = this.s.unseen;
    const oldItems = cloneDeep(this.s.items);
    try {
      this.s.unseen = 0;
      this.s.items = this.s.items.map(i => ({ ...i, is_seen: true }));
      await this.feed.get({ limit: 0, mark_seen: true });
    } catch (e) {
      logger.captureException(e);
      this.s.unseen = old;
      this.s.items = oldItems;
    }
  };

  mark_read = async (id: string, index: number) => {
    if (!this.feed) return;
    try {
      this.s.unread -= 1;
      // @ts-ignore
      this.s.items[index].is_read = true;
      await this.feed.get({ limit: 0, mark_read: [id] });
    } catch (e) {
      logger.captureException(e);
      this.s.unread += 1;
      // @ts-ignore
      this.s.items[index].is_read = false;
    }
  };

  mark_read_object = async (type: 'customer' | 'order' | 'booking', id: string) => {
    try {
      if (!this.feed) return;

      const mark_read = [];
      const mark_seen = [];

      for (let index = 0; index < this.s.items.length; index += 1) {
        const item = this.s.items[index]!;
        if ((!item.is_read || item.is_seen) && item.object) {
          const parts = item.object.split('::');
          if (type === parts[0] && id === parts[1]) {
            if (!item.is_read) {
              this.s.items[index]!.is_read = true;
              mark_read.push(item.group_id);
            }
            if (!item.is_seen) {
              this.s.items[index]!.is_seen = true;
              mark_seen.push(item.group_id);
            }
          }
        }
      }

      this.s.unread -= mark_read.length;
      this.s.unseen -= mark_seen.length;
      this.feed.get({ limit: 0, mark_read, mark_seen }).catch(logger.captureException);
    } catch (e) {
      logger.captureException(e);
    }
  };

  handle_subscription = (res: any) => {
    try {
      logger.info('NEW NOTIFICATIONS', res);
      const data = res as T.Lib.GetStream.StreamSubscriptionResponse;
      let newNotifications = 0;
      data.new.reverse().forEach(activity => {
        this.handle_activity(activity);
        newNotifications += 1;
      });
      this.get({
        limit: newNotifications,
        mark_seen: this.s.active, // IF DROPDOWN ACTIVE MARK IT AS SEEN
        strategy: 'front',
      });
    } catch (e) {
      logger.captureException(e);
    }
  };

  handle_activity = (activity: T.Lib.GetStream.StreamActivityItem) => {
    const rr = this.store.restrictions.restaurant;
    const an = this.anGet();
    if (activity.verb === 'age_verification' && rr.customers) {
      if (an.play_on.customer_age_verification) {
        this.anPlay();
      }
    } else if (activity.verb === 'booking_new' && rr.bookings) {
      const booking_id = activity.object.split('::')[1] || '';
      const { page } = this.store.bookings;
      if (an.play_on.booking_new) {
        this.anPlay();
      }
      this.sendPushNotification('New Booking Received', {
        timeout: 30000,
        requireInteraction: true,
      });
      if (page === 1) {
        this.store.service.booking.handle_new_booking(booking_id);
      }
    }
  };

  handle_click = (activity: T.Lib.GetStream.NotificationItem, i: number) => {
    const { router, restaurant } = this.store;
    const { verb, object, is_read, group_id } = activity;

    this.s.active = false;

    if (!is_read) {
      this.mark_read(group_id, i);
    }

    if (verb === 'age_verification') {
      const customer_id = object.split('::')[1];
      router.push(`/restaurant/${restaurant!._id}/customers?_id=${customer_id}`);
    } else if (verb === 'order_new') {
      const order_id = object.split('::')[1];
      router.push(`/restaurant/${restaurant!._id}/orders?_id=${order_id}`);
    } else if (verb === 'booking_new') {
      const booking_id = object.split('::')[1];
      router.push(`/restaurant/${restaurant!._id}/bookings?_id=${booking_id}`);
    }
  };

  // AUDIO NOTIFICATIONS
  anInit = () => {
    document.body.addEventListener('touchstart', this.anInitCallback, false);
    document.body.addEventListener('mousedown', this.anInitCallback, false);

    // CHECK AUDIO CONTEXT STATUS EVERY 3 SECONDS AND UPDATE ACCORDINGLY
    setInterval(() => {
      if (!Howler.ctx || Howler.ctx.state !== 'running') {
        this.update({ audio_unlocked: false });
      } else {
        this.update({ audio_unlocked: true });
      }
    }, 3000);
  };

  anInitCallback = () => {
    logger.info('AUDIO STATE CALLBACK');
    setTimeout(() => {
      if (Howler.ctx && Howler.ctx.state === 'running') {
        this.update({ audio_unlocked: true });
        document.body.removeEventListener('touchstart', this.anInitCallback, false);
        document.body.removeEventListener('mousedown', this.anInitCallback, false);
      }
    }, 300);
  };

  anGet = () => {
    const defaultSettings = RestaurantUtils.settings.defaultAudioNotifications();
    if (!this.store.restaurant) {
      return defaultSettings;
    }
    return this.store.restaurant.settings.notifications.audio || defaultSettings;
  };

  anPlay = async () => {
    try {
      if (this.soundPlaying) return;

      const an = this.anGet();

      const s = !an.sound
        ? null
        : new Howl({
            src: [`${config.urls.cloudfront}/assets/audio/${`${an.sound.split('.')[0]}.mp3`}`],
            volume: 1,
            loop: true,
          });

      if (!s) return;

      let playCount = 0;
      s.on('end', () => {
        playCount += 1;
        if (playCount >= an.repeat_count) {
          s.stop();
          this.soundPlaying = false;
        }
      });

      s.play();

      this.soundPlaying = true;

      const stopSoundOnClick = () => {
        if (s && s.playing()) s.stop();
        this.soundPlaying = false;
        document.body.removeEventListener('mousedown', stopSoundOnClick);
        document.body.removeEventListener('touchstart', stopSoundOnClick);
      };

      document.body.addEventListener('mousedown', stopSoundOnClick);
      document.body.addEventListener('touchstart', stopSoundOnClick);
    } catch (e) {
      logger.captureException(e);
    }
  };

  // PUSH NOTIFICATIONS
  initPushNotification = () => {
    try {
      if (!Push.Permission.has() && !this.pushNotificationsInitialized) {
        Push.Permission.request();
        this.pushNotificationsInitialized = true;
      }
    } catch (e) {
      logger.captureException(e);
    }
  };

  sendPushNotification = async (title: string, opts: PushNotificationParams) => {
    try {
      if (Push.Permission.has()) {
        const n = await Push.create(title, {
          vibrate: true,
          icon: '/store-notification-icon.png',
          link: this.store.router.s.path,
          onClick: () => {
            try {
              n.close();
              window.focus();
            } catch (e) {
              logger.captureException(e);
            }
          },
          ...opts,
        });
      }
    } catch (e) {
      logger.captureException(e);
    }
  };

  set = (data: NotificationsState) => {
    this.s = data;
  };

  update = (data: Partial<NotificationsState>) => {
    Object.keys(data).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const value = data[key as keyof NotificationsState];
        if (value !== undefined) {
          set(this.s, key, value);
        }
      }
    });
  };
}
