import React from 'react';
import { FastField } from 'formik';
import {
  FormGroup,
  Input,
  Button,
  RotateLoader,
  Switch,
  TabSelect,
  ModalContent,
  Modal,
  Tag,
  FastFieldSwitch,
} from '@lib/components';
import { inject, observer } from 'mobx-react';
import { action, observable, makeObservable } from 'mobx';
import { MobxComponent } from '../../../../../../../mobx/components/index';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';

type FormValues = T.Schema.Restaurant.Webhooks.RestaurantWebhook;
interface Props {
  type: 'edit' | 'create';
  initialValues: FormValues | null;
  close: () => void;
}

export const SettingsWebhookForm = inject('store')(
  observer(
    class SettingsWebhookForm extends MobxComponent<Props, {}> {
      tab: string = '0';

      setTab = (tab: string) => {
        this.tab = tab;
      };

      constructor(props: Props) {
        super(props);

        makeObservable(this, {
          tab: observable,
          setTab: action,
        });
      }

      componentDidUpdate(prevProps: Props) {
        if (this.props.initialValues && !prevProps.initialValues) {
          this.setTab('0');
        }
      }

      render() {
        const { type, initialValues, close } = this.props;
        const { tab } = this;
        return (
          <Modal width="md" alignTop active={!!initialValues} close={close}>
            <ModalContent>
              <h3 className="">{type === 'create' ? 'Create Webhook' : 'Edit Webhook'}</h3>
              {initialValues && (
                <p className="m-t-1">
                  Secret Key: <Tag className="m-l-1">{initialValues.secret}</Tag>
                </p>
              )}
            </ModalContent>

            <TabSelect
              id="webhook-tab-options"
              className="border-white-tb-15"
              hasBorder
              screenWidth={this.injected.store.view.screen_width}
              onChange={v => this.setTab(v.value)}
              value={tab}
              values={[
                { label: 'General', value: '0' },
                { label: 'Events', value: '1' },
              ]}
            />

            <RestaurantForm<FormValues>
              submit={async (r, item) => {
                r.webhooks = r.webhooks || [];

                const existing = r.webhooks.findIndex(p => p._id === item._id);

                if (existing === -1) {
                  r.webhooks.push(item);
                } else {
                  r.webhooks[existing] = item;
                }

                const update = {
                  $set: { webhooks: r.webhooks },
                };

                return { r, update };
              }}
              validators={{
                // @ts-ignore
                name: values => {
                  if (!values.url) return { url: 'A URL endpoint is required' };
                  // TODO: JUST FOR POC. UNCOMMENT THIS FOR PRODUCTION.
                  // if (values.url.indexOf("https://") === -1)
                  // 	return { url: "URL endpoint must be HTTPS secure" };
                },
              }}
              initialValues={initialValues}
              onSuccess={close}
              onSuccessMessage={type === 'edit' ? 'Webhook edited' : 'Webhook created'}
              onErrorMessage=""
            >
              {({ form, error, getFieldError }) => {
                const { isSubmitting, setFieldValue } = form;
                return (
                  <div>
                    {tab === '0' && (
                      <ModalContent>
                        <FastField
                          name="enabled"
                          render={({ field }: any) => (
                            <FormGroup title="Enabled" help="Determines if this webhook is active or not">
                              <Switch
                                id="webhook-enabled"
                                checked={field.value}
                                onChange={e => setFieldValue('enabled', e.target.checked)}
                              />
                            </FormGroup>
                          )}
                        />
                        <FastField
                          name="url"
                          render={({ field }: any) => (
                            <FormGroup
                              title="URL Endpoint"
                              help="Enter the complete URL to call when an event is triggered. Must start with https://, http endpoints are not allowed"
                              error={getFieldError(form, 'url')}
                            >
                              <Input type="url" {...field} required />
                            </FormGroup>
                          )}
                        />
                      </ModalContent>
                    )}

                    {tab === '1' && (
                      <ModalContent>
                        <FastFieldSwitch
                          name="events.order_new"
                          title="Order - Created"
                          help="Triggered when a new order has been placed"
                          setValue={setFieldValue}
                        />

                        <FastFieldSwitch
                          name="events.order_cancel"
                          title="Order - Canceled"
                          help="Triggered when an order has been canceled"
                          setValue={setFieldValue}
                        />

                        <FastFieldSwitch
                          name="events.order_update_status"
                          title="Order - Update Status"
                          help="Triggered when the status of an order has been updated"
                          setValue={setFieldValue}
                        />

                        <FastFieldSwitch
                          name="events.order_update_ready_time"
                          title="Order - Update Ready Time"
                          help="Triggered when the ready time of an order has been updated"
                          setValue={setFieldValue}
                        />

                        <FastFieldSwitch
                          name="events.booking_new"
                          title="Booking - Created"
                          help="Triggered when a new booking has been placed"
                          setValue={setFieldValue}
                        />

                        <FastFieldSwitch
                          name="events.booking_cancel"
                          title="Booking - Cancelled"
                          help="Triggered when a booking has been cancelled"
                          setValue={setFieldValue}
                        />

                        <FastFieldSwitch
                          name="events.booking_update_status"
                          title="Booking - Update Status"
                          help="Triggered when the status of a booking has been updated"
                          setValue={setFieldValue}
                        />
                      </ModalContent>
                    )}

                    <ModalContent>
                      {error && <FormGroup error={error} />}
                      <Button full color="primary" type="submit" disabled={isSubmitting}>
                        {isSubmitting && <RotateLoader size={2} color="white" />}
                        {!isSubmitting && 'Save'}
                      </Button>
                    </ModalContent>
                  </div>
                );
              }}
            </RestaurantForm>
          </Modal>
        );
      }
    },
  ),
);
