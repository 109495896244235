import React from 'react';
import { FastField } from 'formik';
import { FormGroup, Button, RotateLoader, Switch } from '@lib/components';
import { inject, observer } from 'mobx-react';
import { PaymentMethodsUsingAwaitingPayment } from '@lib/common';
import { MobxComponent } from '../../../../../../../mobx/components/index';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';
import { UI } from '../../../../../../../core/ui';

interface Props {}
interface State {}
interface FormValues {
  business: T.Schema.Restaurant.RestaurantSchema['settings']['business'];
}

export const SettingFormOrdering = inject('store')(
  observer(
    class SettingFormOrdering extends MobxComponent<Props, State> {
      initialValues: FormValues;

      r: T.Schema.Restaurant.RestaurantSchema;

      constructor(props: Props) {
        super(props);
        this.state = {};
        this.r = this.injected.store.restaurant!;
        this.initialValues = {
          business: this.r.settings.business,
        };
      }

      render() {
        const restaurantPayments = Object.keys(this.r.settings.payments);

        const freezeAwaitingPaymentSwitch = PaymentMethodsUsingAwaitingPayment.some(
          payment => restaurantPayments.includes(payment) && this.r.settings.payments[payment]?.enabled,
        );

        return (
          <RestaurantForm<FormValues>
            submit={async (r, values) => {
              r.settings.business = values.business;
              const update = { $set: { 'settings.business': values.business } };
              return { r, update };
            }}
            validators=
            initialValues={this.initialValues}
            onSuccess={() => {}}
            onError={() => UI.notification.error('An error occurred')}
            onSuccessMessage="Settings Updated"
            onErrorMessage=""
          >
            {({ form, error }) => {
              const { isSubmitting, setFieldValue } = form;
              return (
                <div className="p-4">
                  <FormGroup
                    title="Using Awaiting Payment Status"
                    help="This option is always on when your restaurant enables any payment method uses Awaiting Payment status."
                  >
                    <FastField
                      name="business.using_awaiting_payment"
                      render={({ field }: any) => (
                        <Switch
                          id="business.using_awaiting_payment"
                          checked={!!field.value}
                          onChange={e => {
                            if (!freezeAwaitingPaymentSwitch)
                              setFieldValue('business.using_awaiting_payment', e.target.checked);
                          }}
                        />
                      )}
                    />
                  </FormGroup>

                  <FormGroup
                    title="Disable Ordering"
                    help="Toggle this to disable online ordering for this restaurant without needing to delete it"
                  >
                    <FastField
                      name="business.ordering_disabled"
                      render={({ field }: any) => (
                        <Switch
                          id="business.ordering_disabled"
                          checked={field.value}
                          onChange={e => setFieldValue('business.ordering_disabled', e.target.checked)}
                        />
                      )}
                    />
                  </FormGroup>

                  {error && <FormGroup error={error} />}

                  <Button full color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting && <RotateLoader size={2} color="white" />}
                    {!isSubmitting && 'Save'}
                  </Button>
                </div>
              );
            }}
          </RestaurantForm>
        );
      }
    },
  ),
);
