import React from 'react';
import { FastField } from 'formik';
import { FormGroup, Button, RotateLoader, Input } from '@lib/components';
import { inject, observer } from 'mobx-react';
import { UI } from '../../../../../../../core/ui';
import { MobxComponent } from '../../../../../../../mobx/components/index';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';

interface Props {
  close?: () => void;
}
interface State {}

type FormValues = T.Schema.Restaurant.RestaurantSchema['settings']['integrations'];

export const SettingsFormIntegrationGoogleAnalytics = inject('store')(
  observer(
    class SettingsFormIntegrationGoogleAnalytics extends MobxComponent<Props, State> {
      initialValues: FormValues;

      constructor(props: Props) {
        super(props);
        this.state = {};
        const r = this.injected.store.restaurant!;
        this.initialValues = r.settings.integrations || {};
      }

      render() {
        return (
          <RestaurantForm<FormValues>
            submit={async (r, values) => {
              r.settings.integrations = values;
              const update = { $set: { 'settings.integrations': r.settings.integrations } };
              return { r, update };
            }}
            validators=
            initialValues={this.initialValues}
            onSuccess={() => {
              if (this.props.close) {
                this.props.close();
              }
            }}
            onError={() => UI.notification.error('An error occurred')}
            onSuccessMessage="Settings Updated"
            onErrorMessage=""
          >
            {({ form, error }) => {
              const { isSubmitting } = form;
              return (
                <div className="p-4">
                  <FastField
                    name="google_analytics.property_id"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Property / Tracking ID"
                        help="Your unique analytics property ID. It resembles the following, 'UA-XXXXXXXX-X'"
                      >
                        <Input type="text" {...field} value={field.value || ''} />
                      </FormGroup>
                    )}
                  />

                  {error && <FormGroup error={error} />}

                  <Button full color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting && <RotateLoader size={2} color="white" />}
                    {!isSubmitting && 'Save'}
                  </Button>
                </div>
              );
            }}
          </RestaurantForm>
        );
      }
    },
  ),
);
