import React from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import Column from './column';
import { StyledColumns } from './component';

interface InitialColumns {
  [key: string]: {
    id: string;
    list: string[];
  };
}

interface ColumnsData {
  [key: string]: {
    name: string;
    active: boolean;
  };
}

interface Props {
  columnsData: ColumnsData;
  chunk: number;
  activeCol: string[];
  updateActiveCol: (colName: string) => void;
  updateColumnsOrder: (columnOrder: any) => void;
}

interface State {
  columns: InitialColumns;
}

class DragAndDropMultipleClass extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      columns: this.separateColumns(props),
    };
  }

  // Separate columns for drag and drop
  separateColumns = (opts: { columnsData: ColumnsData; chunk: number }): InitialColumns => {
    const outputColumns: InitialColumns = {};
    let i;
    let j;
    let tempArray;
    let id = 0;
    const columnsData = Object.keys(opts.columnsData).map(col => opts.columnsData[col]?.name);
    for (i = 0, j = columnsData.length; i < j; i += opts.chunk) {
      tempArray = columnsData.map((col, index) => `${index + 1}.${col}`).slice(i, i + opts.chunk);
      id++;
      outputColumns[`column_${id}`] = {
        id: `column_${id}`,
        list: tempArray,
      };
    }
    return outputColumns;
  };

  // Combine columns for order
  combineColumns = (inputColumns: InitialColumns) => {
    const { updateColumnsOrder, columnsData } = this.props;

    const outputColumns: ColumnsData = {};

    Object.values(inputColumns).forEach((column: any) => {
      column.list
        .map((inputCol: string) => inputCol.split('.').pop())
        .forEach((inputColName: string) => {
          Object.keys(columnsData).forEach(colData => {
            if (columnsData[colData]?.name === inputColName) {
              // @ts-ignore
              outputColumns[colData] = columnsData[colData];
            }
          });
        });
    });

    updateColumnsOrder(outputColumns);
  };

  findObjectByName = (items: ColumnsData, name: string) => {
    name = name.split('.').pop()!;
    // @ts-ignore
    return { [name]: items[Object.keys(items).filter(item => items[item].name === name)[0]] };
  };

  onDragEnd = ({ source, destination }: DropResult) => {
    const { columns } = this.state;
    const { columnsData, chunk } = this.props;

    // Make sure we have a valid destination
    if (destination === undefined || destination === null) return null;

    // Make sure we're actually moving the item
    if (source.droppableId === destination.droppableId && destination.index === source.index) return null;

    // Set start and end variables
    const start = columns[source.droppableId]!;
    const end = columns[destination.droppableId]!;

    // If start is the same as end, we're in the same column
    if (start === end) {
      // Move the item within the list
      // Start by making a new list without the dragged item
      const newList = start.list.filter((_: any, idx: number) => idx !== source.index);

      // Then insert the item at the right location
      newList.splice(destination.index, 0, start.list[source.index]!);

      // Then create a new copy of the column object
      const newCol = {
        id: start.id,
        list: newList,
      };

      columns[newCol.id] = newCol;
    } else {
      // If start is different from end, we need to update multiple columns
      // Filter the start list like before
      const newStartList = start.list.filter((_: any, idx: number) => idx !== source.index);

      // Create a new start column
      const newStartCol = {
        id: start.id,
        list: newStartList,
      };

      // Make a new end list array
      const newEndList = end.list;

      // Insert the item into the end list
      newEndList.splice(destination.index, 0, start.list[source.index]!);

      // Create a new end column
      const newEndCol = {
        id: end.id,
        list: newEndList,
      };

      columns[newStartCol.id] = newStartCol;
      columns[newEndCol.id] = newEndCol;
    }
    let colArr: ColumnsData = {};

    Object.keys(columns).forEach((colunm: any) => {
      // Remove index order from string
      // @ts-ignore
      columns[colunm]?.list.forEach(col => {
        const name = Object.keys(this.findObjectByName(columnsData, col))[0];
        // @ts-ignore
        colArr = { ...colArr, ...{ [name]: this.findObjectByName(columnsData, col)[name] } };
      });
    });

    // Update the state

    const outputColumns = this.separateColumns({ columnsData: colArr, chunk });
    this.setState({ columns: outputColumns });

    this.combineColumns(outputColumns);

    return null;
  };

  render() {
    const { activeCol, updateActiveCol } = this.props;
    const { columns } = this.state;
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <StyledColumns>
          {Object.values(columns).map((col: any) => (
            <Column col={col} key={col.id} activeCol={activeCol} updateActiveCol={updateActiveCol} />
          ))}
        </StyledColumns>
      </DragDropContext>
    );
  }
}

export const DragAndDropMultiple = DragAndDropMultipleClass;
