import React from 'react';
import { Button, ButtonGroup, FormGroup, RotateLoader, FormUpload, LogoRender } from '@lib/components';
import { inject, observer } from 'mobx-react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { FastField } from 'formik';
import { MobxComponent } from '../../../../../../../mobx/components';
import { UI } from '../../../../../../../core/ui';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';

const MAXIMUM_SIZE_LOGO_UPLOAD = 100;

interface Props extends WithTranslation {}
interface State {}

interface FormValues {
  printers_receipt_logo: T.Schema.Restaurant.RestaurantSettings['printers_receipt_logo'];
}

const PrinterReceiptLogoClass = inject('store')(
  observer(
    class PrinterReceiptLogoClass extends MobxComponent<Props, State> {
      initialValues: FormValues;

      currentLogo: T.Core.Business.BusinessImage | undefined | null;

      constructor(props: Props) {
        super(props);
        const r = this.injected.store.restaurant!;
        this.initialValues = {
          printers_receipt_logo: r.settings.printers_receipt_logo || {
            custom_logo: false,
            logo: null,
          },
        };

        this.currentLogo = r.website.sections.top_nav.images.logo;
      }

      render() {
        return (
          <RestaurantForm<FormValues>
            submit={async (r, values) => {
              r.settings.printers_receipt_logo = values.printers_receipt_logo;
              const update = {
                $set: {
                  'settings.printers_receipt_logo': values.printers_receipt_logo,
                },
              };
              return { r, update };
            }}
            validators=
            initialValues={this.initialValues}
            onSuccess={() => {}}
            onError={() => UI.notification.error('An error occurred')}
            onSuccessMessage="Settings Updated"
            onErrorMessage=""
          >
            {({ form, error }) => {
              const { isSubmitting, setFieldValue } = form;

              return (
                <div className="p-4">
                  <FastField
                    name="printers_receipt_logo"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Source"
                        help={
                          field.value.custom_logo
                            ? `Set custom receipt logo. A pure black-white color is recommended! The maximum file size limit is ${MAXIMUM_SIZE_LOGO_UPLOAD}Kb. The logo won’t work on ESCPOS text mode.`
                            : "Take from the current restaurant's logo (Settings > Website > Top Nav Bar > Logo Image). It may not print well with the colorful/multi-shades logo. The logo won’t work on ESCPOS text mode."
                        }
                      >
                        <ButtonGroup
                          size="sm"
                          selected={field.value.custom_logo ? 1 : 0}
                          options={[
                            {
                              value: 0,
                              name: 'Restaurant Logo',
                            },
                            {
                              value: 1,
                              name: 'Custom Receipt Logo',
                            },
                          ]}
                          onSelect={v => setFieldValue('printers_receipt_logo.custom_logo', !!v.value)}
                          width={200}
                        />

                        <div className="p-t-4">
                          {!field.value.custom_logo && (
                            <LogoRender
                              image={this.currentLogo}
                              transformations={{
                                resize: 'x150',
                                format: 'auto',
                              }}
                            />
                          )}

                          {field.value.custom_logo && (
                            <FormUpload
                              maxSize={MAXIMUM_SIZE_LOGO_UPLOAD}
                              multi={false}
                              imagesOnly
                              value={[field.value.logo]}
                              customSource
                              onRemove={() => setFieldValue('printers_receipt_logo.logo', null)}
                              onChange={files => setFieldValue('printers_receipt_logo.logo', files[0])}
                            />
                          )}
                        </div>
                      </FormGroup>
                    )}
                  />

                  {error && <FormGroup error={error} />}

                  <Button full color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting && <RotateLoader size={2} color="white" />}
                    {!isSubmitting && 'Save'}
                  </Button>
                </div>
              );
            }}
          </RestaurantForm>
        );
      }
    },
  ),
);

export const PrinterReceiptLogo = withTranslation()(PrinterReceiptLogoClass);
