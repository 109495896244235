import React from 'react';
import { FastField } from 'formik';
import { FormGroup, Button, RotateLoader, Switch, Input, SelectAdv, LinkTag } from '@lib/components';
import { DataCurrenciesList } from '@lib/common';
import { inject, observer } from 'mobx-react';
import { MobxComponent } from '../../../../../../../mobx/components/index';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';
import { UI } from '../../../../../../../core/ui';
import { PaymentBaseFields } from './base';

interface Props {}
interface State {}
type FormValues = T.Schema.Restaurant.Payments.RestaurantPaymentStripe;

export const SettingsFormPaymentsStripe = inject('store')(
  observer(
    class SettingsFormPaymentsStripe extends MobxComponent<Props, State> {
      initialValues: FormValues;

      constructor(props: Props) {
        super(props);
        this.state = {};
        const r = this.injected.store.restaurant!;
        this.initialValues = r.settings.payments.stripe || {
          enabled: false,
          label: '',
          services: [],
          secret_key: '',
          publishable_key: '',
          connect_account_id: '',
          currency: '',
          disable_email_receipt: false,
          custom_payment_email: '',
          min_order: 0,
        };
      }

      render() {
        const { showMainSupport } = this.injected.store;
        return (
          <RestaurantForm<FormValues>
            submit={async (r, values) => {
              r.settings.payments.stripe = values;
              const update = { $set: { 'settings.payments.stripe': r.settings.payments.stripe } };
              return { r, update };
            }}
            validators={{
              // @ts-ignore
              connect_account_id: values => {
                if (
                  values.connect_account_id &&
                  values.connect_account_id.length > 0 &&
                  !values.connect_account_id.includes('acct_')
                )
                  return { connect_account_id: 'This account ID is in the wrong format' };
              },
              // @ts-ignore
              min_order: values => {
                if (!values.min_order) {
                  return { min_order: 'Minimum amount required for this payment method.' };
                }
                if (values.min_order && values.min_order < 0)
                  return { min_order: 'Minimum amount need to be greater than 0' };
              },
            }}
            initialValues={this.initialValues}
            onSuccess={() => {}}
            onError={() => UI.notification.error('An error occurred')}
            onSuccessMessage="Settings Updated"
            onErrorMessage=""
          >
            {({ form, error, getFieldError }) => {
              const { isSubmitting, setFieldValue } = form;
              return (
                <div className="p-4">
                  {showMainSupport && (
                    <FormGroup>
                      <LinkTag
                        className="block font-semi-bold"
                        target="_blank"
                        href="https://support.cloudwaitress.com/how-to-guides/setup-stripe-payments"
                      >
                        Read the setup guide
                      </LinkTag>
                    </FormGroup>
                  )}

                  <FastField
                    name="enabled"
                    render={({ field }: any) => (
                      <FormGroup title="Enabled" help="Allows customers to pay using this method">
                        <Switch
                          id="enable-switch"
                          checked={field.value}
                          onChange={e => setFieldValue('enabled', e.target.checked)}
                        />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="secret_key"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Stripe Secret Key"
                        help="Your Stripe secret API key, do not to share this with anyone"
                      >
                        <Input type="text" {...field} autoSave="false" autoCorrect="false" />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="publishable_key"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Stripe Publishable Key"
                        help="Your Stripe publishable API key that will be used to initiate online charges"
                      >
                        <Input type="text" {...field} autoSave="false" autoCorrect="false" />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="currency"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Currency"
                        help="Select the currency you would like to accept Stripe charges in. Make sure you are authorized to accept charges in that currency and that it is supported by Stripe"
                      >
                        <SelectAdv
                          type="single"
                          options={DataCurrenciesList}
                          value={field.value}
                          onChange={(code: string) => setFieldValue('currency', code)}
                        />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="disable_email_receipt"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Disable E-Mail Receipt"
                        help="Toggle this to disable Stripe payment receipts from being sent to your customer's e-mail"
                      >
                        <Switch
                          id="disable_email_receipt-switch"
                          checked={field.value || false}
                          onChange={e => setFieldValue('disable_email_receipt', e.target.checked)}
                        />
                      </FormGroup>
                    )}
                  />

                  <FastField
                    name="custom_payment_email"
                    render={({ field }: any) => (
                      <FormGroup
                        optional
                        title="Enable Custom Payment Form"
                        help="Entering an e-mail address will enable the custom payment form at your-store-domain.com/online-payment"
                      >
                        <Input
                          {...field}
                          type="email"
                          placeholder="Enter E-Mail Address For Payment Notification"
                          value={field.value || ''}
                        />
                      </FormGroup>
                    )}
                  />

                  <PaymentBaseFields defaultLabel="Credit Card" setFieldValue={setFieldValue} />
                  <FastField
                    name="min_order"
                    render={({ field }: any) => (
                      <FormGroup
                        title="Minimum Order Value ($)"
                        error={getFieldError(form, 'min_order')}
                        help="Only allow payments when order total is greater than or equal to this value "
                      >
                        <Input
                          {...field}
                          type="number"
                          value={field.value || 1}
                          step="0.01"
                          onChange={e => setFieldValue('min_order', e.target.value)}
                        />
                      </FormGroup>
                    )}
                  />
                  <FastField
                    name="connect_account_id"
                    render={({ field }: any) => (
                      <FormGroup
                        optional
                        title="SC Account ID"
                        error={getFieldError(form, 'connect_account_id')}
                        help="Admin use only"
                      >
                        <Input type="text" {...field} autoSave="false" autoCorrect="false" />
                      </FormGroup>
                    )}
                  />

                  {error && <FormGroup error={error} />}

                  <Button full color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting && <RotateLoader size={2} color="white" />}
                    {!isSubmitting && 'Save'}
                  </Button>
                </div>
              );
            }}
          </RestaurantForm>
        );
      }
    },
  ),
);
